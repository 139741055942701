import React, { useState, useEffect, useContext } from "react";
import { Context } from "../../store/appContext";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import ScrollToTop from "../../component/scrollToTop";
import { BackendURL } from "../../component/backendURL";
import { Redirect } from "react-router-dom";
import "../../../styles/AddDriverForm.css";

import LoadingOverlay from "../../pages/LadingOverlay";

import { Front2 } from "../../pages/Front2";
import { UserHome_activacion } from "./userHome_activacion";
import { Navbar_analista } from "./Navbar_analista";
import { Navbar_sup_analista } from "./Navbar_sup_analista";
import { TerminalEnVivo } from "../Dir/DIR_TERMINAL/TerminalEnVivo";
import { RegistrosCpsEdit } from "../Dir/DIR_TERMINAL/RegistrosCpsEdit";
import { AyudaYSoporte } from "../Dir/DIR_TERMINAL/AyudaYSoporte";
import { AgregarTurnoHoraTerminal } from "../Dir/DIR_TERMINAL/AgregarTurnoHoraTerminal";
import { AgregarTurnoHoraTerminalContingencia } from "../Dir/DIR_TERMINAL/AgregarTurnoHoraTerminalContingencia";
import { DTplacaTerminalEmpresa } from "../Dir/DIR_TERMINAL/DTplacaTerminalEmpresa";
import { DTContingenciaTerminal } from "../Dir/DIR_TERMINAL/DTContingenciaTerminal";
import { RegistroConductorEmpresas } from "../Dir/DIR_TERMINAL/RegistroConductorEmpresas";
import { RegistrosCpsEditRegistroDeCpsEditNuevo } from "../Dir/DIR_TERMINAL/RegistroDeCpsEditNuevo";
import { ActivacionNuevoRegistro } from "../Dir/DIR_TERMINAL/ActivacionNuevoRegistro";
import { ActivacionesHoyStatusNuevo } from "../Dir/DIR_TERMINAL/ActivacionesHoyStatusNuevo";
import { VistaRetornosAgregados } from "../Dir/DIR_TERMINAL/VistaRetornosAgregados";
import { ReporteEntreFechasEst } from "../Dir/DIR_TERMINAL/ReporteEntreFechasEst";
import { RegistroRetornosEst } from "../Dir/DIR_TERMINAL/RegistroRetornosEst";
import { EmpresaRetornosEst } from "../Dir/DIR_TERMINAL/EmpresasRetornosEst";
import { DestinosRetornosEst } from "../Dir/DIR_TERMINAL/DestinosRetornosEst";

export const Layout_asistente_dir = () => {
  const { store } = useContext(Context);
  const [isLoading, setIsLoading] = useState(false);

  const toggleLoading = (loading) => {
    setIsLoading(loading);
  };

  const handleRouteChange = (event) => {
    localStorage.setItem("lastVisitedRoute", event.url);
  };

  const basename = process.env.BASENAME || "";

  if (!process.env.BACKEND_URL || process.env.BACKEND_URL === "")
    return <BackendURL />;

  return (
    <div style={{ display: "flex" }}>
      <BrowserRouter basename={basename}>
        <ScrollToTop>
          <Navbar_analista />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              alignItems: "center",
              overflow: "auto",
            }}
          >
            <Navbar_sup_analista />
            <Routes onChange={handleRouteChange}>
              <Route element={<UserHome_activacion />} path="/" />
              <Route
                element={<RegistrosCpsEditRegistroDeCpsEditNuevo />}
                path="/RegistrosCpsVision"
              />
              <Route
                element={<RegistroConductorEmpresas />}
                path="/RegistroConductorEmpresa"
              />
              <Route
                element={<DTplacaTerminalEmpresa />}
                path="/DTplacaTerminalEmpresa"
              />
              <Route
                element={<AgregarTurnoHoraTerminal />}
                path="/ruta/:ruta_id/agregar-horas"
              />
              <Route
                element={<AgregarTurnoHoraTerminalContingencia />}
                path="/rutaContingencia/:ruta_id/agregar-horas"
              />
              <Route element={<AyudaYSoporte />} path="/AyudaYSoporte" />
              <Route element={<EmpresaRetornosEst />} path="/EmpresaRetorno" />
              <Route element={<DestinosRetornosEst />} path="/DestinoRetorno" />
              <Route
                element={<VistaRetornosAgregados />}
                path="/VistaRetornosAgregados"
              />
              <Route
                element={<RegistroRetornosEst />}
                path="/RegistroRetornosEst"
              />
              <Route
                element={<ReporteEntreFechasEst />}
                path="/ReporteEntreFechas"
              />
              <Route
                element={<VistaRetornosAgregados />}
                path="/VistaRetornosAgregados"
              />
              <Route element={<Front2 />} path="/Configuraciones" />
              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
          </div>
        </ScrollToTop>
      </BrowserRouter>
      {isLoading && <LoadingOverlay />}
    </div>
  );
};
