import React, { useState, useEffect, useContext, Fragment } from "react";
import axios from "axios";
import { Context } from "../store/appContext";
import "../../styles/CierreListinTerminal.css";
import "../../styles/Consulta.css";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

export const Consulta = () => {
  const [destino, setDestino] = useState([]);
  const [empresas, setEmpresas] = useState([]);
  const [formData, setFormData] = useState({
    destino_final: "",
    km_distancia: "",
    destino_final_front: "",
    empresa: "",
    empresa_terminal_id: "",
    rif: "",
    fecha_viaje: "",
  });

  const fetchActivacionesFiltradas = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.get(
        process.env.BACKEND_URL +
          "/api/public/get_filtered_activations/terminal_en_vivo",
        {
          params: {
            fecha_viaje: formData.fecha_viaje,
            destino_final: formData.destino_final_front,
            rif: formData.rif || undefined, // Solo se envía si tiene valor
          },
        }
      );

      console.log("Activaciones:", response.data.activations);
      // Aquí podrías guardar los datos en un estado
      setActivaciones(response.data.activations);
    } catch (error) {
      MySwal.fire({
        title: "Error",
        text: "Error al cargar las activaciones.",
        icon: "error",
        confirmButtonText: "Cerrar",
      });
    }
  };

  useEffect(() => {
    fetchempresasysuscps();
  }, []);

  const fetchempresasysuscps = async () => {
    try {
      const response = await axios.get(
        process.env.BACKEND_URL + "/api/ver_empresas_terminal_solo_cps_public"
      );
      setEmpresas(response.data.empresas_terminal);
    } catch (error) {
      MySwal.fire({
        title: "Error",
        text: "Error al cargar las empresas disponibles.",
        icon: "error",
        confirmButtonText: "Cerrar",
      });
    }
  };

  const fetchTodayRecords = async () => {
    try {
      const response = await axios.get(
        process.env.BACKEND_URL + "/api/public/GetDestinoGeneral"
      );

      setDestino(response.data.destinos_finales);
    } catch (error) {
      MySwal.fire({
        title: "Error",
        text: "Error al cargar los destinos, por favor vuelve a intentarlo.",
        icon: "error",
        confirmButtonText: "Cerrar",
      });
    }
  };

  useEffect(() => {
    fetchTodayRecords();
  }, []);

  const openDestinoSelection = () => {
    MySwal.fire({
      title: (
        <div className="d-flex text-align-center justify-content-center align-item-center">
          <i
            className="fa-solid fa-route"
            style={{ marginRight: "7px", fontSize: "20px" }}
          ></i>
          <h6 style={{ margin: "0" }}>Destino Final</h6>
        </div>
      ),
      html: `
          <input
            type="text"
            id="companySearch"
            placeholder="Buscar empresa"
            class="swal2-input"
            style="width: 100%"
          />
          <div id="companyList" class="company-list">
            ${destino.map((destino) => `<button data-id="${destino.id}" data-destino-final="${destino.destino_final}" class="btn company-option company-list-activation-list"><strong>${destino.code} - </strong> ${destino.destino_final}</button>`).join("")}
          </div>
        `,
      showConfirmButton: false,
      showCancelButton: true,
      cancelButtonText: "Cerrar",
      didOpen: () => {
        const companySearch = document.getElementById("companySearch");
        const companyList = document.getElementById("companyList");

        // Manejador de filtro para el input de búsqueda
        companySearch.addEventListener("input", (e) => {
          const query = e.target.value.toLowerCase();
          const filteredCompanies = destino.filter(
            (destino) =>
              destino.destino_final.toLowerCase().includes(query) ||
              destino.code.toLowerCase().includes(query)
          );
          companyList.innerHTML = filteredCompanies
            .map(
              (destino) =>
                `<button 
                      class="btn company-option company-list-activation-list" 
                      data-id="${destino.id}" 
                      data-destino-final="${destino.destino_final}">
                      <strong>${destino.code} - </strong> ${destino.destino_final}
                  </button>`
            )
            .join("");

          // Agregar listeners a cada opción filtrada
          const buttons = companyList.querySelectorAll(".company-option");
          buttons.forEach((button) => {
            button.addEventListener("click", () => {
              const selectedCompany = {
                id: button.getAttribute("data-id"),
                destino_final: button.getAttribute("data-destino-final"),
              };
              selectDestino(selectedCompany);
            });
          });
        });

        // Listener para opciones de empresa inicialmente
        const buttons = companyList.querySelectorAll(".company-option");
        buttons.forEach((button) => {
          button.addEventListener("click", () => {
            const selectedCompany = {
              id: button.getAttribute("data-id"),
              destino_final: button.getAttribute("data-destino-final"),
            };
            selectDestino(selectedCompany);
          });
        });
      },
    });
  };

  const selectDestino = (selectedCompany) => {
    setFormData((prevData) => ({
      ...prevData,
      destino_final: selectedCompany.id, // Asigna el id
      destino_final_front: selectedCompany.destino_final, // Asigna el texto
    }));

    Swal.close(); // Cierra el modal
  };

  const openCompanySelection = () => {
    setTimeout(() => {
      MySwal.fire({
        title: (
          <p>
            <i
              className="fa-solid fa-user-tie"
              style={{ marginRight: "7px", fontSize: "20px" }}
            ></i>
            Empresas de Transporte
          </p>
        ),
        html: `
        <input
          type="text"
          id="companySearch"
          placeholder="Buscar empresa"
          class="swal2-input"
          style="width: 100%"
        />
        <div id="companyList" class="company-list">
          ${empresas
            .map(
              (company) => `
            <button 
              class="btn company-option company-list-activation-list" 
              data-id="${company.id}"
              data-name="${company.empresa.nombre}"
              data-rif="${company.empresa.rif}"
              >  <!-- Añadimos data-name con el nombre -->
              ${company.empresa.nombre} <br> ${company.empresa.rif}
            </button>`
            )
            .join("")}
        </div>
      `,
        showConfirmButton: false,
        showCancelButton: true,
        cancelButtonText: "Cerrar",
        didOpen: () => {
          const companySearch = document.getElementById("companySearch");
          const companyList = document.getElementById("companyList");

          // Manejador de filtro para el input de búsqueda
          companySearch.addEventListener("input", (e) => {
            const query = e.target.value.toLowerCase();
            const filteredEmpresas = empresas.filter(
              (company) =>
                company.empresa.nombre.toLowerCase().includes(query) ||
                company.empresa.rif.toLowerCase().includes(query)
            );
            companyList.innerHTML = filteredEmpresas
              .map(
                (company) => `
                <button 
                  class="btn company-option company-list-activation-list" 
                  data-id="${company.id}"
                  data-name="${company.empresa.nombre}"
                  data-rif="${company.empresa.rif}"
                  >  <!-- Añadimos data-name con el nombre -->
                  ${company.empresa.nombre} <br> ${company.empresa.rif}
                </button>`
              )
              .join("");

            // Agregar listeners a cada opción filtrada
            const buttons = companyList.querySelectorAll(".company-option");
            buttons.forEach((button) => {
              button.addEventListener("click", () => {
                const companyId = button.dataset.id;
                const companyName = button.dataset.name; // Usamos el nombre de la empresa
                const companyRif = button.dataset.rif; // Usamos el nombre de la empresa
                selectCompany(companyId, companyName, companyRif); // Pasamos el ID y el nombre
              });
            });
          });

          // Listener para opciones de empresa inicialmente
          const buttons = companyList.querySelectorAll(".company-option");
          buttons.forEach((button) => {
            button.addEventListener("click", () => {
              const companyId = button.dataset.id;
              const companyName = button.dataset.name;
              const companyRif = button.dataset.rif; // Usamos el nombre de la empresa
              selectCompany(companyId, companyName, companyRif); // Pasamos el ID y el nombre
            });
          });
        },
      });
    }, 100);
  };

  const selectCompany = (companyId, companyName, companyRif) => {
    setFormData({
      ...formData,
      empresa: companyName,
      empresa_terminal_id: companyId,
      rif: companyRif,
    });

    MySwal.close(); // Cierra el modal
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  return (
    <div
      className="container-fluid contarinerGeneralUserHomejs"
      style={{
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
        padding: 0,
        background: "none",
        minHeight: "100vh",
      }}
    >
      <div
        className="container asklnduiashndaijsnxansxsaas69 m-0 p-0"
        style={{ marginTop: 0, minHeight: "100vh" }}
      >
        <div
          className="sdfdfgrt54w98e7we9contewe69 w-100"
          style={{ marginTop: "-50px" }}
        >
          <div>
            <i
              className="fa-solid seraUnMarinsupre fa-route"
              id="icon-tittle-general"
              style={{ color: "white" }}
            ></i>
          </div>
          <p>Te ayudamos a conseguir tu viaje</p>
        </div>
        <div
          className="container asklnduiashndaijsnxansxsaas79"
          style={{ marginBottom: "20px" }}
        >
          <div className="sdjndsd56489ytg98ef4rd9s8f4ae5tfr8sd">
            <div className="sdjndsd56489ytg98ef4rd9s8f4ae5tfr8sd2">Viaje</div>
          </div>
          <form
            className="formularioDeLogin sd98f4e8wf4cwe987cwe984cwe w-100"
            style={{ marginTop: "20px" }}
          >
            <div className="aoshduiahsdkasnmdasdas">
              <div className="input-group-login2">
                <input
                  className="inputDeLogin ds65f4d8h9tr4y98jt4yt9g8we7f89e"
                  id="inputDeLogin123456"
                  type="text"
                  name="destino"
                  required
                  readOnly
                  value={formData.destino_final_front}
                  onClick={openDestinoSelection}
                />
                <label className="a65d4g98tr7efe1tyjyrt8er7f8wj8utytgf8s">
                  Destino
                </label>
              </div>
              <div className="input-group-login2">
                <input
                  className="inputDeLogin ds65f4d8h9tr4y98jt4yt9g8we7f89e"
                  type="date"
                  name="fecha_viaje"
                  required
                  onChange={handleChange}
                  value={formData.fecha_viaje}
                />
                <label className="a65d4g98tr7efe1tyjyrt8er7f8wj8utytgf8s">
                  Fecha de Viaje
                </label>
              </div>
              <div className="input-group-login2">
                <input
                  className="inputDeLogin ds65f4d8h9tr4y98jt4yt9g8we7f89e"
                  type="text"
                  name="empresa"
                  readOnly
                  id="inputDeLogin123456"
                  onClick={openCompanySelection}
                  value={formData.empresa}
                />
                <label className="a65d4g98tr7efe1tyjyrt8er7f8wj8utytgf8s">
                  Línea de Transporte (opcional)
                </label>
              </div>
            </div>

            <button
              className="buttonCargadeDatosDeLoginregistromovimiento"
              type="submit"
              onClick={(e) => fetchActivacionesFiltradas(e)}
            >
              Buscar Viajes
              <i
                className="fa-solid fa-magnifying-glass-location fa-bounce"
                style={{ marginLeft: "10px" }}
              ></i>
            </button>
          </form>
        </div>
        <hr style={{ width: "90%" }}></hr>

        <div className="d-flex" style={{ marginTop: "10px" }}>
          <Link
            style={{
              marginLeft: "5px",
              textDecoration: "none",
              color: "#24827C",
            }}
            to="/Terminal"
          >
            <strong>Terminal en Vivo</strong>
          </Link>
        </div>

        <div className="d-flex" style={{ marginTop: "20px" }}>
          <p>¿Alguna duda?,</p>
          <Link
            style={{
              marginLeft: "5px",
              textDecoration: "none",
              color: "#24827C",
            }}
            to="/AyudaYSoporte"
          >
            <strong>Contáctanos</strong>
          </Link>
        </div>
      </div>
    </div>
  );
};
