import React, { useState, useEffect } from "react";
import axios from "axios";
import { format } from "date-fns";
import { es } from "date-fns/locale";
import { formatInTimeZone, toDate } from "date-fns-tz";
import differenceInMinutes from "date-fns/differenceInMinutes";
import "../../styles/PublicBillboard.css";
import moment from "moment-timezone";
import "../../styles/UserHome.css";

const estatusColors = {
  ACTIVO: "#28a745", // Verde
  CANCELADO: "#dc3545", // Rojo
  "EN PISTA": "#ffc107", // Amarillo
  ABORDANDO: "#17a2b8", // Azul
  SALIENDO: "#f8f9fa", // Blanco
  SALIO: "#6c757d", // Gris
  "CON RETRASO": "#ff6347", // Rojo claro
};

export const PublicBillboard = () => {
  const [records, setRecords] = useState([]);
  const [currentTime, setCurrentTime] = useState(new Date());
  const timeZone = "America/Caracas";

  useEffect(() => {
    const interval = setInterval(() => {
      fetchCurrentTime();
      fetchTodayRecords();
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  const fetchCurrentTime = async () => {
    try {
      const response = await axios.get(
        process.env.BACKEND_URL + "/api/public/venezuela-time"
      );
      const zonedTime = toDate(new Date(response.data.currentTime), {
        timeZone,
      });
      setCurrentTime(zonedTime);
    } catch (error) {
      console.error("Error al obtener la hora actual de Venezuela", error);
    }
  };

  const fetchTodayRecords = async () => {
    try {
      const response = await axios.get(
        process.env.BACKEND_URL + "/api/public/getTodayActivacionPista"
      );
      const data = response.data;

      const updatedData = data.map((record) => {
        const recordTime = toDate(
          `${record.fecha_viaje.split("-").reverse().join("-")}T${record.hora_salida}:00`,
          { timeZone }
        );
        let newStatus = record.estatus;
        let delayMinutes = 0;

        // Cambiar "ANULADO" a "CANCELADO"
        if (newStatus === "ANULADO") {
          newStatus = "CANCELADO";
        }

        // Cambiar "CARGANDO" a "ABORDANDO"
        if (newStatus === "CARGANDO") {
          newStatus = "ABORDANDO";
        }

        if (newStatus === "RETRASADO") {
          newStatus = "CON RETRASO";
        }

        // Verificación de retraso
        if (record.estatus !== "SALIO" && recordTime < currentTime) {
          delayMinutes = differenceInMinutes(currentTime, recordTime);
          newStatus = `${newStatus} - RETRASADO ${delayMinutes} minutos`;
        }

        // Usamos la nueva función calculateTimeDiff
        const { time, color } = calculateTimeDiff(record.hora_salida);

        // Si es "ACTIVO" y faltan 30 minutos o menos, cambia el estado a "CON RETRASO"
        if (
          record.estatus === "ACTIVO" &&
          time.includes("min restantes") &&
          parseInt(time) <= 30
        ) {
          newStatus = "CON RETRASO";
        }

        // Si ya está en "ACTIVO" y la diferencia es 0 o negativa, cambia a "CON RETRASO"
        if (record.estatus === "ACTIVO" && time.includes("min retrasado")) {
          newStatus = "CON RETRASO";
        }

        return { ...record, estatus: newStatus };
      });

      const sortedData = updatedData.sort((a, b) =>
        a.hora_salida.localeCompare(b.hora_salida)
      );
      setRecords(sortedData.slice(0, 20));
    } catch (error) {
      console.error("Error al obtener registros de hoy", error);
    }
  };

  const calculateTimeDiff = (horaSalida) => {
    // Crea la fecha y hora de salida combinando la fecha actual y la hora de salida
    const today = moment.tz(timeZone).format("YYYY-MM-DD"); // Obtén la fecha actual en formato YYYY-MM-DD
    const salidaTime = moment.tz(
      `${today} ${horaSalida}`,
      "YYYY-MM-DD HH:mm",
      timeZone
    ); // Combina

    // Asegúrate de que currentTime esté en la misma zona horaria
    const currentMoment = moment.tz(currentTime, timeZone); // Convierte currentTime a la zona horaria

    // Calcula la diferencia en minutos
    const diffInMinutes = salidaTime.diff(currentMoment, "minutes");

    if (diffInMinutes > 0) {
      return { time: `${diffInMinutes} min restantes`, color: "green" };
    } else {
      return { time: `${Math.abs(diffInMinutes)} min retrasado`, color: "red" };
    }
  };

  const formatDateTime = (date) => {
    return formatInTimeZone(date, timeZone, "dd-MM-yyyy HH:mm:ss", {
      locale: es,
    });
  };

  return (
    <div className="container-fluid public-billboard">
      <div className="d-flex sd5654fdg89wr7ef98e4gry98r7thgf align-items-center m-0">
        <h1 className="mty68he7r9w7ed89q7dqw">
          PROGRAMACIÓN TERMINAL LA BANDERA
        </h1>
        <div className="current-time">
          <h4 className="n56nr48er9v7ew98m7uy9t8erwe7fq">
            {formatDateTime(currentTime)}
          </h4>
        </div>
      </div>
      <div
        className="w-100 d-flex"
        style={{ color: "green", marginBottom: "20px", alignItems: "center" }}
      >
        <div
          style={{
            borderRadius: "50%",
            width: "20px",
            height: "20px",
            backgroundColor: "green",
          }}
        ></div>
        <span style={{ marginLeft: "15px", fontSize: "20px" }}>
          Terminal La Bandera En Vivo
        </span>
      </div>

      <div style={{ overflow: "auto" }}>
        <table className="table table-bordered" style={{ minWidth: "700px" }}>
          <thead>
            <tr style={{ color: "white" }}>
              <th>Hora de Salida</th>
              <th>Empresa</th>
              <th>Destino Final</th>
              <th>Puerta</th>
              <th>Estatus</th>
            </tr>
          </thead>
          <tbody>
            {records.map((record, index) => {
              const { empresa, destino_final, hora_salida, estatus, anden } =
                record;
              const { time, color } = calculateTimeDiff(hora_salida);

              return (
                <tr key={index} style={{ color: "white" }}>
                  <td style={{ color: "yellow" }}>{hora_salida}</td>
                  <td>{empresa}</td>
                  <td>{destino_final}</td>
                  <td>{anden}</td>
                  <td style={{ alignItems: "center" }}>
                    <div className="d-flex" style={{ alignItems: "center" }}>
                      <div
                        style={{
                          width: "10px",
                          height: "10px",
                          backgroundColor: estatusColors[estatus],
                          borderRadius: "50%",
                          marginRight: "7px",
                        }}
                      ></div>

                      <div style={{ color: estatusColors[estatus] }}>
                        {estatus}
                      </div>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};
