import React, { useState, useEffect, useContext } from "react";
import { Context } from "../../store/appContext";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import ScrollToTop from "../../component/scrollToTop";
import { BackendURL } from "../../component/backendURL";
import "../../../styles/AddDriverForm.css";

import LoadingOverlay from "../../pages/LadingOverlay";

import { Front2 } from "../../pages/Front2";
import { Navbar } from "./Navbar";
import { Navbar_sup } from "./Navbar_sup";
import { UserHome } from "./userHome";
import { RegistroCps } from "./DIR_TERMINAL/RegistroCps";
import { RegistroUsuarios } from "./DIR_TERMINAL/RegistroUsuarios";
import { ActivacionNuevoRegistro } from "./DIR_TERMINAL/ActivacionNuevoRegistro";
import { RegistrosCpsEdit } from "./DIR_TERMINAL/RegistrosCpsEdit";
import { TerminalEnVivo } from "./DIR_TERMINAL/TerminalEnVivo";
import { RegistroActivacionesHoy } from "./DIR_TERMINAL/RegistroActivacionesHoy";
import { AyudaYSoporte } from "./DIR_TERMINAL/AyudaYSoporte";
import { EmpresasTerminal } from "./DIR_TERMINAL/EmpresasTerminal";
import { RegistrosCpsEditRegistroDeCpsEditNuevo } from "./DIR_TERMINAL/RegistroDeCpsEditNuevo";
import { AgregarTurnoHoraTerminal } from "./DIR_TERMINAL/AgregarTurnoHoraTerminal";
import { DestinoFinal } from "./DIR_TERMINAL/DestinoFinal";
import { DTplacaTerminalEmpresa } from "./DIR_TERMINAL/DTplacaTerminalEmpresa";
import { RegistroRutaContingencia } from "./DIR_TERMINAL/RegistroRutaContingencia.";
import { DTContingenciaTerminal } from "./DIR_TERMINAL/DTContingenciaTerminal";
import { RegistroConductorEmpresas } from "./DIR_TERMINAL/RegistroConductorEmpresas";
import { AgregarTurnoHoraTerminalContingencia } from "./DIR_TERMINAL/AgregarTurnoHoraTerminalContingencia";
import { ActivacionesHoyStatusNuevo } from "./DIR_TERMINAL/ActivacionesHoyStatusNuevo";
import { ReporteEntreFechasEst } from "./DIR_TERMINAL/ReporteEntreFechasEst";
import { EditConductor } from "./DIR_TERMINAL/EditConductor";
import { DestinosRetornosEst } from "./DIR_TERMINAL/DestinosRetornosEst";
import { EmpresaRetornosEst } from "./DIR_TERMINAL/EmpresasRetornosEst";
import { RegistroRetornosEst } from "./DIR_TERMINAL/RegistroRetornosEst";
import { ReporteDiarioActivaciones } from "./DIR_TERMINAL/ReporteDiarioActivaciones";
import { UserHome_calendario } from "./UserHome_calendario";
import { PlantillaCorreos } from "./DIR_TERMINAL/EnvioCorreosPersonalizados/PlantillasCorreos";
import { EditPlantillaCorreo } from "./DIR_TERMINAL/EnvioCorreosPersonalizados/EditPlantillaCorreo";
import { RegistroUsuariosEmpresa } from "./DIR_TERMINAL/RegistroUsuariosEmpresa";
import { VistaRetornosAgregados } from "./DIR_TERMINAL/VistaRetornosAgregados";
import { ActivacionesPendientesAprobar } from "./DIR_TERMINAL/ActivacionesPendientesAprobar";
import { EditRoute } from "./DIR_TERMINAL/EditRoute";
import { EditRouteContingencia } from "./DIR_TERMINAL/EditRouteContingencia";
import { ReporteExcelEmpresas } from "./DIR_TERMINAL/ReporteExcelEmpresas";
import { RegistroPasajeroIntravialca } from "./DIR_TERMINAL/RegistroPasajeroIntravialca";

export const Layout_dir_terminal = () => {
  const { store } = useContext(Context);
  const [isLoading, setIsLoading] = useState(false);

  const toggleLoading = (loading) => {
    setIsLoading(loading);
  };

  const handleRouteChange = (event) => {
    localStorage.setItem("lastVisitedRoute", event.url);
  };

  const basename = process.env.BASENAME || "";

  if (!process.env.BACKEND_URL || process.env.BACKEND_URL === "")
    return <BackendURL />;

  return (
    <div style={{ display: "flex" }}>
      <BrowserRouter basename={basename}>
        <ScrollToTop>
          <Navbar />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              alignItems: "center",
              overflow: "auto",
            }}
          >
            <Navbar_sup />
            <Routes onChange={handleRouteChange}>
              <Route element={<UserHome />} path="/" />
              <Route element={<UserHome_calendario />} path="/2" />
              <Route element={<RegistroCps />} path="/RegistroCps" />
              <Route element={<EditRoute />} path="/EditRoute/:id" />
              <Route
                element={<ReporteExcelEmpresas />}
                path="/ReporteExcelEmpresas"
              />
              <Route
                element={<EditRouteContingencia />}
                path="/EditRouteContingencia/:id"
              />
              <Route
                element={<ActivacionesPendientesAprobar />}
                path="/ActivacionesPendientesAprobar"
              />
              <Route
                element={<RegistroPasajeroIntravialca />}
                path="/RegistroPasajeroIntravialca"
              />
              <Route
                element={<VistaRetornosAgregados />}
                path="/VistaRetornosAgregados"
              />
              <Route element={<PlantillaCorreos />} path="/Plantillas" />
              <Route element={<EditPlantillaCorreo />} path="/edit-template" />
              <Route
                element={<ReporteDiarioActivaciones />}
                path="/ReporteDiarioActivaciones"
              />
              <Route
                element={<ReporteEntreFechasEst />}
                path="/ReporteEntreFechas"
              />
              <Route
                element={<ActivacionesHoyStatusNuevo />}
                path="/ActivacionesHoyNuevo"
              />
              <Route
                element={<ActivacionNuevoRegistro />}
                path="/ActivacionNuevo"
              />
              <Route
                element={<RegistroConductorEmpresas />}
                path="/RegistroConductorEmpresa"
              />
              <Route
                element={<RegistroRutaContingencia />}
                path="/RegistroContingenciaRuta"
              />
              <Route
                element={<DTplacaTerminalEmpresa />}
                path="/DTplacaTerminalEmpresa"
              />
              <Route
                element={<DTContingenciaTerminal />}
                path="/DTContingenciaPlaca"
              />
              <Route element={<DestinoFinal />} path="/DestinoFinal" />
              <Route element={<AyudaYSoporte />} path="/AyudaYSoporte" />
              <Route element={<EmpresasTerminal />} path="/EmpresaTerminal" />
              <Route
                element={<AgregarTurnoHoraTerminal />}
                path="/ruta/:ruta_id/agregar-horas"
              />
              <Route
                element={<AgregarTurnoHoraTerminalContingencia />}
                path="/rutaContingencia/:ruta_id/agregar-horas"
              />
              <Route
                element={<RegistroActivacionesHoy />}
                path="/RegistroActivacionesHoy"
              />
              <Route element={<RegistroUsuarios />} path="/RegistroUsuarios" />
              <Route
                element={<RegistroUsuariosEmpresa />}
                path="/RegistroUsuariosEmpresa"
              />
              <Route
                element={<TerminalEnVivo />}
                path="/ActivacionParaBillBoard"
              />
              <Route element={<RegistrosCpsEdit />} path="/RegistrosCpsEdit" />
              <Route element={<DestinosRetornosEst />} path="/DestinoRetorno" />
              <Route element={<EmpresaRetornosEst />} path="/EmpresaRetorno" />
              <Route
                element={<RegistroRetornosEst />}
                path="/RegistroRetornosEst"
              />
              <Route
                element={<RegistrosCpsEditRegistroDeCpsEditNuevo />}
                path="/RegistrosCpsVision"
              />
              <Route element={<EditConductor />} path="/EditConductor/:id" />
              <Route element={<Front2 />} path="/Configuraciones" />
              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
          </div>
        </ScrollToTop>
      </BrowserRouter>
      {isLoading && <LoadingOverlay />}
    </div>
  );
};
