import React, { useState, useEffect, useContext } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import ScrollToTop from "./component/scrollToTop";

import { Login } from "./pages/Login";
import injectContext from "./store/appContext";

import LoadingOverlay from "./pages/LadingOverlay";
import { jwtDecode } from "jwt-decode";
import { Layout_dir_terminal } from "./Rol/Dir/Layout_dir_terminal";
import { PublicBillboard } from "./pages/PublicBillBoard";
import { Layout_analista } from "./Rol/ANALISTA/layout_analista";
import { Layout_pista } from "./Rol/ESTATUS_PISTA/Layout_pista";
import { Layout_super_admin } from "./Rol/SUPER_ADMIN/Layout_super_admin";
import { Layout_dir_recau } from "./Rol/Dir_recaudacion/Layout_dir_recau";

const Layout = () => {
  const [isMounted, setIsMounted] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const basename = process.env.BASENAME || "";

  useEffect(() => {
    return () => {
      setIsMounted(false);
    };
  }, []);

  const toggleLoading = (loading) => {
    setIsLoading(loading);
  };

  const token = localStorage.getItem("token");
  let userRole = null;
  if (token) {
    const decoded = jwtDecode(token);
    userRole = decoded.rol; // Aquí obtienes el rol del token
  }

  const renderLayout = () => {
    if (isLoading) {
      return <div>{isLoading && <LoadingOverlay />}</div>;
    }
    switch (userRole) {
      case "TER_ANALISTA_TER":
        return <Layout_analista toggleLoading={toggleLoading} />;
      case "TER_PISTA_TER":
        return <Layout_pista toggleLoading={toggleLoading} />;
      case "ADMINISTRADOR_TER":
        return <Layout_dir_terminal toggleLoading={toggleLoading} />;
      case "SUPER_ADMIN_SUPER":
        return <Layout_super_admin toggleLoading={toggleLoading} />;
      case "ADMINISTRADOR_RECAU_TER":
        return <Layout_dir_recau toggleLoading={toggleLoading} />;
      default:
        return (
          <div>
            <BrowserRouter basename={basename}>
              <ScrollToTop>
                <Routes>
                  <Route element={<Login />} path="/" />
                  <Route
                    element={<PublicBillboard />}
                    path="/SDF54ER98NT7Y98BWE49VE8R4V9EW8U4MT6Y9BEWCQ4W65W4R98BR7H9E4G984WF98WE7F8WE4F9WE8F798R4F8E9RG7E98R4G9E8RF7ER98F4ER984"
                  />
                  <Route element={<LoadingOverlay />} path="/loadingoverlay" />
                  <Route path="*" element={<Navigate to="/" replace />} />
                </Routes>
              </ScrollToTop>
            </BrowserRouter>
          </div>
        );
    }
  };
  return renderLayout();
};

export default injectContext(Layout);
