import React, { useState, useEffect, useContext } from "react";
import { Context } from "../../store/appContext";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import ScrollToTop from "../../component/scrollToTop";
import { BackendURL } from "../../component/backendURL";
import "../../../styles/AddDriverForm.css";

import LoadingOverlay from "../../pages/LadingOverlay";

import { Front2 } from "../../pages/Front2";
import { Navbar } from "./Navbar";
import { Navbar_sup } from "./Navbar_sup";
import { AyudaYSoporte } from "../Dir_recaudacion/DIR_RECAUDACION/AyudaYSoporte";
import { Elab_listin_rl } from "../Dir_recaudacion/DIR_RECAUDACION/Elab_listin_rl";
import { UserHome } from "./userHome";
import { UserHome_calendario } from "./UserHome_calendario";
import { SolicitudActivacion } from "../EMPRESA_ADMIN/EMPRESA_ADMIN/SolicitudActivacion";
import { MisActivaciones } from "../EMPRESA_ADMIN/EMPRESA_ADMIN/MisActivaciones";
import { RegistroUsuarios } from "../EMPRESA_ADMIN/EMPRESA_ADMIN/RegistroUsuarios";
import {
  RegistroRuta,
  RutasEmpresas,
} from "../EMPRESA_ADMIN/EMPRESA_ADMIN/RutasEmpresas";
import { RegistroRutaContingencia } from "../EMPRESA_ADMIN/EMPRESA_ADMIN/RegistroRutaContingencia.";
import { DTplacaTerminalEmpresa } from "../EMPRESA_ADMIN/EMPRESA_ADMIN/DTplacaTerminalEmpresa";
import { DTContingenciaTerminal } from "../EMPRESA_ADMIN/EMPRESA_ADMIN/DTContingenciaTerminal";
import { RegistroConductorEmpresas } from "../EMPRESA_ADMIN/EMPRESA_ADMIN/RegistroConductorEmpresas";
import { VistaPasajeros } from "../EMPRESA_ADMIN/EMPRESA_ADMIN/VistaPasajeros";
import { RegistroPasajeroEmpresa } from "../EMPRESA_ADMIN/EMPRESA_ADMIN/RegistroPasajeroEmpresa";
import { PreviewBoleto } from "../EMPRESA_ADMIN/EMPRESA_ADMIN/PreviewBoleto";
import { FrontConductores } from "../../pages/FrontConductores";

export const Layout_empresa_worker = () => {
  const { store } = useContext(Context);
  const [isLoading, setIsLoading] = useState(false);

  const toggleLoading = (loading) => {
    setIsLoading(loading);
  };

  const handleRouteChange = (event) => {
    localStorage.setItem("lastVisitedRoute", event.url);
  };

  const basename = process.env.BASENAME || "";

  if (!process.env.BACKEND_URL || process.env.BACKEND_URL === "")
    return <BackendURL />;

  return (
    <div style={{ display: "flex" }}>
      <BrowserRouter basename={basename}>
        <ScrollToTop>
          <Navbar />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              alignItems: "center",
              overflow: "auto",
            }}
          >
            <Navbar_sup />
            <Routes onChange={handleRouteChange}>
              <Route element={<UserHome />} path="/" />
              <Route
                element={<UserHome_calendario />}
                path="/CalendarioEmpresa"
              />
              <Route
                element={<SolicitudActivacion />}
                path="/SolicitudActivacion"
              />
              <Route element={<MisActivaciones />} path="/MisActivaciones" />
              <Route element={<PreviewBoleto />} path="/PreviewBoleto" />
              <Route
                element={<RegistroPasajeroEmpresa />}
                path="/RegistroPasajero"
              />
              <Route element={<VistaPasajeros />} path="/VistaPasajeros" />
              <Route
                element={<RegistroRutaContingencia />}
                path="/RegistroContingenciaRuta"
              />
              <Route element={<RutasEmpresas />} path="/RutasEmpresas" />
              <Route
                element={<FrontConductores />}
                path="/RegistroConductorEmpresa"
              />
              <Route
                element={<DTplacaTerminalEmpresa />}
                path="/DTplacaTerminalEmpresa"
              />
              <Route
                element={<DTContingenciaTerminal />}
                path="/DTContingenciaPlaca"
              />
              {/* <Route
                element={<RegistroConductorEmpresas />}
                path="/RegistroConductorEmpresa"
              /> */}
              <Route element={<AyudaYSoporte />} path="/AyudaYSoporte" />
              <Route element={<Front2 />} path="/Configuraciones" />
              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
          </div>
        </ScrollToTop>
      </BrowserRouter>
      {isLoading && <LoadingOverlay />}
    </div>
  );
};
