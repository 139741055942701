import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { Context } from "../../../../store/appContext.js";
import "../../../../../styles/PlantillaDeCorreos.css";

export const PlantillaCorreos = () => {
  const { store } = useContext(Context);
  const [templates, setTemplates] = useState([]);
  const navigate = useNavigate();

  const fetchTemplates = async () => {
    try {
      const response = await axios.get(
        process.env.BACKEND_URL + "/api/get_templates",
        {
          headers: {
            Authorization: "Bearer " + store.token,
          },
        }
      );
      setTemplates(response.data.template.versions); // Ajustar según la estructura de respuesta
    } catch (error) {
      console.error("Error al obtener las plantillas", error);
    }
  };

  const handleEditTemplate = (template) => {
    // Redirigir a la vista de edición pasando todos los datos de la plantilla
    navigate("/edit-template", { state: { template } });
  };

  useEffect(() => {
    fetchTemplates(); // Obtener las plantillas cuando se carga el componente
  }, []);

  return (
    <div
      className="container-fluid contarinerGeneralUserHomejs"
      style={{
        display: "flex",
        paddingBottom: "40px",
      }}
    >
      <div className="container asklnduiashndaijsnxansxsaas m-0 p-0">
        <div className="sdfdfgrt54w98e7we9contewe w-100">
          <div style={{ margin: "0", marginTop: "20px" }}>
            <i
              className="fa-solid seraUnMarinsupre fa-wand-magic-sparkles"
              id="icon-tittle-general"
            ></i>
          </div>
          <p>
            Ecoge alguna de las increíbles plantillas que tenemos para ti, y
            adecúala a tus necesidades. (En Construcción...)
          </p>
        </div>
        <div className="container d-flex blogs-container mt-5 mb-6">
          {templates.map((template) => (
            <div key={template.id} className="blog-card">
              <div
                className="blog-image"
                style={{
                  backgroundImage: `url(
                    ${
                      template.thumbnail_url
                        ? `https:${template.thumbnail_url}`
                        : "default-thumbnail.jpg"
                    }
                  )`,
                  backgroundSize: "cover",
                  backgroundPosition: "start",
                  borderRadius: "15px 0px 15px 0px",
                  alignItems: "end",
                  display: "flex",
                  width: "100%",
                }}
              ></div>
              <div className="blog-details">
                <h2 className="s-wd5we984fw9e84t98rb4v">{template.name}</h2>
                {/* <p className="ny-tbrt84ve94h89ytb4v89er">
                {blog.descripcion.slice(0, 100)}...
              </p> */}
                <div
                  onClick={() => handleEditTemplate(template)}
                  className="btn btn-ds98sd4f8d4-sd594csdc489d"
                >
                  Usar Plantilla <i className="fa-solid fa-chevron-right"></i>
                </div>
              </div>
            </div>
          ))}
        </div>

        <hr style={{ width: "90%" }}></hr>

        <div className="d-flex" style={{ marginTop: "12px" }}>
          <p>¿Alguna duda?,</p>
          <Link
            style={{
              marginLeft: "5px",
              textDecoration: "none",
              color: "#24827C",
            }}
            to="/AyudaYSoporte"
          >
            <strong>Contáctanos</strong>
          </Link>
        </div>
        <hr style={{ width: "90%" }}></hr>
      </div>
    </div>
    // <div className="template-list-container">
    //   <div className="container d-flex blogs-container mt-5 mb-6">
    //     {templates.map((template) => (
    //       <div key={template.id} className="blog-card">
    //         <div
    //           className="blog-image"
    //           style={{
    //             backgroundImage: `url(
    //                 ${
    //                   template.thumbnail_url
    //                     ? `https:${template.thumbnail_url}`
    //                     : "default-thumbnail.jpg"
    //                 }
    //               )`,
    //             backgroundSize: "cover",
    //             backgroundPosition: "start",
    //             borderRadius: "15px 0px 15px 0px",
    //             alignItems: "end",
    //             display: "flex",
    //             width: "100%",
    //           }}
    //         ></div>
    //         <div className="blog-details">
    //           <h2 className="s-wd5we984fw9e84t98rb4v">{template.name}</h2>
    //           {/* <p className="ny-tbrt84ve94h89ytb4v89er">
    //             {blog.descripcion.slice(0, 100)}...
    //           </p> */}
    //           <div
    //             onClick={() => handleEditTemplate(template)}
    //             className="btn btn-ds98sd4f8d4-sd594csdc489d"
    //           >
    //             Usar Plantilla <i className="fa-solid fa-chevron-right"></i>
    //           </div>
    //         </div>
    //       </div>
    //     ))}
    //   </div>
    // </div>
  );
};
