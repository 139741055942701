import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Context } from "../../../store/appContext.js";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFDownloadLink,
} from "@react-pdf/renderer";

const MySwal = withReactContent(Swal);

export const EditRouteContingencia = () => {
  const navigate = useNavigate();
  const { store } = useContext(Context);
  const [formData, setFormData] = useState({
    empresa_terminal_id: "",
    empresa: "",
    numero_cps: "",
    ruta_n: "",
    ruta: "",
    parada: "",
    destino_final: "",
    status: "",
    horas: [], // Nuevo campo para manejar las horas
  });
  const [isSubmitting, setIsSubmitting] = useState(false); // Nuevo estado para controlar el estado de envío
  const [empresas, setEmpresas] = useState([]); // Nuevo estado para controlar el estado de envío
  const [cpsOptions, setCpsOptions] = useState([]); // Opciones de CPS para el <select>
  const [destinos, setDestinos] = useState([]);
  const { id } = useParams(); // ID del conductor desde la URL

  useEffect(() => {
    const fetchRuta = async () => {
      setIsSubmitting(true); // Activar el estado de carga
      const loadingSwal = MySwal.fire({
        title: "Cargando",
        text: "Por favor espera...",
        allowOutsideClick: false,
        didOpen: () => {
          MySwal.showLoading(); // Mostrar el spinner
        },
      });

      try {
        const response = await axios.get(
          `${process.env.BACKEND_URL}/api/RutaContingenciaEmpresasTerminal/${id}`,
          {
            headers: { Authorization: `Bearer ${store.token}` },
          }
        );
        setFormData({
          ...response.data,
          destino_final:
            response.data.destino_final?.destino_final_detalle?.descripcion ||
            "",
        });

        setIsSubmitting(false); // Desactivar el estado de carga
        MySwal.close(); // Cerrar la ventana emergente
      } catch (error) {
        setIsSubmitting(false); // Desactivar el estado de carga
        MySwal.fire({
          title: "Error",
          text: "No se pudo cargar los datos de la ruta.",
          icon: "error",
          confirmButtonText: "Cerrar",
        });
      }
    };

    fetchRuta();
  }, [id, store.token]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      await axios.put(
        `${process.env.BACKEND_URL}/api/RutaContingenciaEmpresasTerminal/${id}`,
        formData,
        {
          headers: { Authorization: `Bearer ${store.token}` },
        }
      );
      MySwal.fire({
        title: "Éxito",
        text: "Ruta Actualizada Correctamente.",
        icon: "success",
        confirmButtonText: "Cerrar",
      });
      navigate("/RegistrosCpsVision"); // Redirige a la lista de conductores
    } catch (error) {
      MySwal.fire({
        title: "Error",
        text: "No se pudo actualizar la ruta.",
        icon: "error",
        confirmButtonText: "Cerrar",
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div
      className="container-fluid contarinerGeneralUserHomejs"
      style={{
        display: "flex",
        paddingBottom: "40px",
      }}
    >
      <div className="container asklnduiashndaijsnxansxsaas m-0 p-0">
        <div className="sdfdfgrt54w98e7we9contewe w-100">
          <div style={{ margin: "0", marginTop: "20px" }}>
            <i
              className="fa-solid seraUnMarinsupre fa-file-circle-plus"
              id="icon-tittle-general"
            ></i>
          </div>
          <p style={{ fontSize: "13px" }}>
            Edita la ruta, y adecúala a la empresa correspondiente.
          </p>
        </div>
        <div
          className="container asklnduiashndaijsnxansxsaas3"
          style={{ marginBottom: "20px" }}
        >
          <form
            onSubmit={handleSubmit}
            className="formularioDeLogin sd98f4e8wf4cwe987cwe984cwe w-100"
          >
            <div className="aoshduiahsdkasnmdasdas">
              <div className="input-group-login2">
                <input
                  className="inputDeLogin"
                  type="text"
                  name="nombre_empresa"
                  readOnly
                  onChange={handleChange}
                  value={formData.nombre_empresa}
                  style={{ textTransform: "uppercase" }}
                />
                <label>Línea de Transporte</label>
              </div>
              <div className="input-group-login2">
                <input
                  className="inputDeLogin"
                  type="number"
                  name="ruta_n"
                  onChange={handleChange}
                  placeholder="12"
                  value={formData.ruta_n}
                  style={{ textTransform: "uppercase" }}
                  readOnly
                />
                <label>Número de Ruta</label>
              </div>
            </div>
            <div className="aoshduiahsdkasnmdasdas">
              <div className="input-group-login2">
                <textarea
                  className="inputDeLogin"
                  type="text"
                  name="ruta"
                  placeholder="TERMINAL LA BANDERA, MERIDA"
                  onChange={handleChange}
                  value={formData.ruta}
                  style={{ textTransform: "uppercase", minHeight: "200px" }}
                />
                <label>Ruta</label>
              </div>
            </div>
            <div className="aoshduiahsdkasnmdasdas">
              <div className="input-group-login2">
                <input
                  className="inputDeLogin"
                  type="text"
                  name="destino_final"
                  onChange={handleChange}
                  placeholder="12"
                  value={formData.destino_final}
                  style={{ textTransform: "uppercase" }}
                  readOnly
                />
                <label>Destino Final</label>
              </div>
            </div>
            <button
              className="buttonCargadeDatosDeLoginregistromovimiento"
              type="submit"
              disabled={isSubmitting}
            >
              {isSubmitting ? "Guardando..." : "Guardar"}{" "}
              <i className="fa-solid fa-arrow-right"></i>
            </button>
          </form>
        </div>
        <hr style={{ width: "90%" }}></hr>
        <div className="d-flex" style={{ marginTop: "12px" }}>
          <p>¿Alguna duda?,</p>
          <Link
            style={{
              marginLeft: "5px",
              textDecoration: "none",
              color: "#24827C",
            }}
            to="/AyudaYSoporte"
          >
            <strong>Contáctanos</strong>
          </Link>
        </div>
      </div>
    </div>
  );
};
