import React, { useContext, useState, useEffect } from "react";
import axios from "axios";
import { Context } from "../../../store/appContext";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useNavigate, useParams } from "react-router-dom";

import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFDownloadLink,
  pdf,
  Image,
  PDFViewer,
} from "@react-pdf/renderer";

// const fetchTodayRecords = async () => {
//   try {
//     // Configurar el token de autorización en los headers
//     const response = await axios.get(
//       process.env.BACKEND_URL + "/api/audit_envio_avisos",
//       {
//         headers: {
//           Authorization: `Bearer ${store.token}`, // Corrige el uso del token
//         },
//       }
//     );
//     setRecords(response.data.audit_envio); // Guarda los datos obtenidos
//   } catch (error) {
//     console.error("Error al obtener registros de hoy:", error);
//   }
// };

// useEffect(() => {
//   fetchTodayRecords();
// }, []);

const MySwal = withReactContent(Swal);

const tableStyles = StyleSheet.create({
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 0.5,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  table2: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 0.5,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    marginLeft: 60,
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
    backgroundColor: "#FFF",
  },
  tableColHeader: {
    borderStyle: "solid",
    borderWidth: 0.5,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    backgroundColor: "#24827c",
    alignItems: "center",
    justifyContent: "center",
  },
  tableColHeader2: {
    borderStyle: "solid",
    borderWidth: 0.5,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    backgroundColor: "#b8b8b8",
    alignItems: "center",
    justifyContent: "center",
  },
  tableCol: {
    borderStyle: "solid",
    borderWidth: 0.5,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    alignItems: "center",
    justifyContent: "center",
  },
  tableCellHeader: {
    margin: 5,
    fontSize: 7,
    fontWeight: "bold",
    color: "white",
  },
  tableCell: {
    margin: 4,
    fontSize: 7,
  },
});

tableStyles.tableColHeaderOther = {
  ...tableStyles.tableColHeader,
  width: "17.5%", // Mayor ancho para los demás
};
tableStyles.tableColHeaderOtherinfinity = {
  ...tableStyles.tableColHeader,
  width: "20%", // Mayor ancho para los demás
};

tableStyles.tableColHeaderOther3 = {
  ...tableStyles.tableColHeader,
  width: "33.33333333%", // Mayor ancho para los demás
};

tableStyles.tableColHeaderOther44 = {
  ...tableStyles.tableColHeader2,
  width: "25%", // Mayor ancho para los demás
};

tableStyles.tableColHeaderOther2 = {
  ...tableStyles.tableColHeader2,
  width: "14%", // Mayor ancho para los demás
};

tableStyles.tableColHeaderRuta = {
  ...tableStyles.tableColHeader,
  width: "30%", // Mayor ancho para los demás
};
tableStyles.tableColHeaderRuta2 = {
  ...tableStyles.tableColHeader2,
  width: "16%", // Mayor ancho para los demás
};
tableStyles.tableColHeaderRuta3 = {
  ...tableStyles.tableColHeader2,
  width: "33.333333%", // Mayor ancho para los demás
};

tableStyles.tableColHeaderEstatus = {
  ...tableStyles.tableColHeader,
  width: "10%", // Mayor ancho para los demás
};

tableStyles.tableColOther = {
  ...tableStyles.tableCol,
  width: "17.5%", // Mayor ancho para los demás
};

tableStyles.tableColOtherinfinity = {
  ...tableStyles.tableCol,
  width: "20%", // Mayor ancho para los demás
};

tableStyles.tableColOther20 = {
  ...tableStyles.tableCol,
  width: "14%", // Mayor ancho para los demás
};

tableStyles.tableColOther3 = {
  ...tableStyles.tableCol,
  width: "33.333333333%", // Mayor ancho para los demás
};
tableStyles.tableColOther44 = {
  ...tableStyles.tableCol,
  width: "25%", // Mayor ancho para los demás
};

tableStyles.tableColRuta = {
  ...tableStyles.tableCol,
  width: "30%", // Mayor ancho para los demás
};
tableStyles.tableColRuta3 = {
  ...tableStyles.tableCol,
  width: "33.33333333%", // Mayor ancho para los demás
};

tableStyles.tableColRuta20 = {
  ...tableStyles.tableCol,
  width: "16%", // Mayor ancho para los demás
};

tableStyles.tableColEstatus = {
  ...tableStyles.tableCol,
  width: "10%", // Mayor ancho para los demás
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    borderBottomColor: "#000",
    borderBottomWidth: 1,
    alignItems: "center",
    height: 15,
    margin: 0,
    padding: 0,
    justifyContent: "center",
    textAlign: "center",
  },
  cell: {
    fontSize: 9,
    textAlign: "center",
    borderRightColor: "#000",
    borderRightWidth: 1,
    flex: 1,
  },
  cell2: {
    fontSize: 9,
    textAlign: "center",
    borderRightColor: "#000",
    borderRightWidth: 1,
    width: 20,
    borderLeftColor: "#000",
    borderLeftWidth: 1,
  },
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    textAlign: "center",
  },
  logo: {
    width: 90, // Ajusta el tamaño según tus necesidades
    height: 30, // Ajusta el tamaño según tus necesidades
  },
  logo2: {
    width: 100,
    height: 20,
  },
  page: {
    padding: 20, // Ajusta el valor del margen según tus necesidades
  },
  letter: {
    fontSize: 10,
    padding: 5,
  },
  letter2: {
    fontSize: 10,
  },
  ruta: {
    fontSize: 7,
    padding: 4,
  },
  bottomText: {
    position: "absolute",
    bottom: 0,
    left: 20, // Ajusta según sea necesario
    right: 20, // Ajusta según sea necesario
    textAlign: "center",
    bottom: 30,
    fontSize: 8,
  },
  footer: {
    position: "absolute",
    bottom: 10,
    left: 0,
    right: 0,
    textAlign: "center",
    fontSize: 10,
    paddingTop: 5,
  },
  bottomText2: {
    position: "absolute",
    bottom: 0,
    left: 20, // Ajusta según sea necesario
    right: 20, // Ajusta según sea necesario
    textAlign: "center",
    bottom: 50,
    fontSize: 11,
    fontWeight: "bold",
  },
});

const formatDate = (dateString) => {
  const date = new Date(dateString); // Asegúrate de que `dateString` esté en formato válido
  const day = String(date.getUTCDate()).padStart(2, "0"); // Usa UTC para evitar problemas de zonas horarias
  const month = String(date.getUTCMonth() + 1).padStart(2, "0"); // Meses son 0 indexados
  const year = date.getUTCFullYear();
  return `${day}-${month}-${year}`;
};

const formatDate2 = (timestamp) => {
  // Dividir fecha y zona horaria
  const [datePart, timePart] = timestamp.split("T");
  const [year, month, day] = datePart.split("-");
  const [time, offset] = timePart.split("-");

  // Extraer la hora, minutos y segundos
  const [hours, minutes] = time.split(":");

  // Formatear en dd/mm/yyyy hh:mm
  return `${day}/${month}/${year} ${hours}:${minutes}`;
};

const CierreDiario = (
  timestamp,
  nombre_apellido_user,
  email_user,
  code,
  detalle_paquetes,
  numero_de_avisos_de_cobro,
  numero_de_paquetes,
  total_pagado,
  totales_formas_pago
) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.header} fixed>
        <Image
          src="https://res.cloudinary.com/dirpdlfbu/image/upload/v1701880341/logo/cmyemflfryypewryq6ux.jpg"
          style={styles.logo}
        />
        <Image
          src="https://res.cloudinary.com/dirpdlfbu/image/upload/v1711324140/aeezebl5y4pzhcmgm2da.jpg"
          style={styles.logo2}
        />
      </View>
      <View fixed>
        <View>
          <Text style={{ textAlign: "center" }}>CIERRE DE CAJA DIARIO</Text>
          <Text
            style={{ textAlign: "center", fontSize: "12px", color: "gray" }}
          >
            FECHA: {formatDate(timestamp.timestamp)}
          </Text>
        </View>
      </View>
      <View>
        <View style={{ marginTop: 10 }}>
          <Text style={{ textAlign: "end", fontSize: 12 }}>
            Cierre hecho por: {timestamp.nombre_apellido_user},{" "}
            {timestamp.email_user}
          </Text>
        </View>
      </View>
      <View style={{ marginTop: 10 }}></View>
      <View style={tableStyles.table}>
        <View style={tableStyles.tableRow} fixed>
          <View style={tableStyles.tableColHeaderOther3}>
            <Text style={tableStyles.tableCellHeader}>
              N° RECIBO(S) DE PAGO
            </Text>
          </View>
          <View style={tableStyles.tableColHeaderOther3}>
            <Text style={tableStyles.tableCellHeader}>N° LISTINE(S)</Text>
          </View>
          <View style={tableStyles.tableColHeaderOther3}>
            <Text style={tableStyles.tableCellHeader}>TOTAL PAGADO</Text>
          </View>
        </View>
        <View style={tableStyles.tableRow}>
          <View style={tableStyles.tableColOther3}>
            <Text style={tableStyles.tableCell}>
              {timestamp.numero_de_paquetes}
            </Text>
          </View>
          <View style={tableStyles.tableColOther3}>
            <Text style={tableStyles.tableCell}>
              {timestamp.numero_de_avisos_de_cobro}
            </Text>
          </View>
          <View style={tableStyles.tableColOther3}>
            <Text style={tableStyles.tableCell}>
              {Number(timestamp.total_pagado).toFixed(2)} Bs.
            </Text>
          </View>
        </View>
      </View>
      <View style={{ marginTop: 10 }}></View>
      <View style={tableStyles.table}>
        <View style={tableStyles.tableRow} fixed>
          <View style={tableStyles.tableColHeaderOther44}>
            <Text style={tableStyles.tableCellHeader}>TRANSFERENCIA</Text>
          </View>
          <View style={tableStyles.tableColHeaderOther44}>
            <Text style={tableStyles.tableCellHeader}>PUNTO DE VENTA</Text>
          </View>
          <View style={tableStyles.tableColHeaderOther44}>
            <Text style={tableStyles.tableCellHeader}>EFECTIVO</Text>
          </View>
          <View style={tableStyles.tableColHeaderOther44}>
            <Text style={tableStyles.tableCellHeader}>ME</Text>
          </View>
        </View>
        <View style={tableStyles.tableRow}>
          <View style={tableStyles.tableColOther44}>
            <Text style={tableStyles.tableCell}>
              {Number(
                timestamp.totales_formas_pago.total_ingreso_transferencia
              ).toFixed(2)}{" "}
              Bs.
            </Text>
          </View>
          <View style={tableStyles.tableColOther44}>
            <Text style={tableStyles.tableCell}>
              {Number(
                timestamp.totales_formas_pago.total_ingreso_punto_de_venta
              ).toFixed(2)}{" "}
              Bs.
            </Text>
          </View>
          <View style={tableStyles.tableColOther44}>
            <Text style={tableStyles.tableCell}>
              {Number(
                timestamp.totales_formas_pago.total_ingreso_efectivo
              ).toFixed(2)}{" "}
              Bs.
            </Text>
          </View>
          <View style={tableStyles.tableColOther44}>
            <Text style={tableStyles.tableCell}>
              {Number(timestamp.totales_formas_pago.total_ingreso_me).toFixed(
                2
              )}{" "}
              Bs.
            </Text>
          </View>
        </View>
      </View>
      <View style={{ marginTop: 10 }}></View>
      {timestamp.detalle_paquetes.map((paquete, index) => (
        <View style={styles.section} key={index} wrap={false}>
          <View wrap={false}>
            <View style={{ marginTop: 10 }}>
              <Text style={{ fontSize: 11 }}>PAGO ({index + 1}):</Text>
            </View>
            <View style={tableStyles.table}>
              <View style={tableStyles.tableRow} fixed>
                <View style={tableStyles.tableColHeaderOtherinfinity}>
                  <Text style={tableStyles.tableCellHeader}>FECHA Y HORA</Text>
                </View>
                <View style={tableStyles.tableColHeaderRuta}>
                  <Text style={tableStyles.tableCellHeader}>EMPRESA</Text>
                </View>
                <View style={tableStyles.tableColHeaderRuta}>
                  <Text style={tableStyles.tableCellHeader}>TOTAL PAGADO</Text>
                </View>
                <View style={tableStyles.tableColHeaderOtherinfinity}>
                  <Text style={tableStyles.tableCellHeader}>CÓDIGO</Text>
                </View>
              </View>
              <View style={tableStyles.tableRow}>
                <View style={tableStyles.tableColOtherinfinity}>
                  <Text style={tableStyles.tableCell}>
                    {formatDate2(paquete.timestamp)}
                  </Text>
                </View>
                <View style={tableStyles.tableColRuta}>
                  <Text style={tableStyles.tableCell}>{paquete.empresa}</Text>
                </View>
                <View style={tableStyles.tableColRuta}>
                  <Text style={tableStyles.tableCell}>
                    {paquete.total_pagado} Bs.
                  </Text>
                </View>
                <View style={tableStyles.tableColOtherinfinity}>
                  <Text style={tableStyles.tableCell}>{paquete.code}</Text>
                </View>
              </View>
            </View>
          </View>
          <View wrap={false}>
            <View style={{ marginTop: 10 }}>
              <Text style={{ fontSize: 11, marginLeft: 60 }}>
                FORMA(S) DE PAGO ({index + 1}):
              </Text>
            </View>
            <View style={tableStyles.table2}>
              <View style={tableStyles.tableRow}>
                <View style={tableStyles.tableColHeaderRuta3}>
                  <Text style={tableStyles.tableCellHeader}>FORMA DE PAGO</Text>
                </View>
                <View style={tableStyles.tableColHeaderRuta3}>
                  <Text style={tableStyles.tableCellHeader}>
                    TOTAL PAGADO (BS.)
                  </Text>
                </View>
                <View style={tableStyles.tableColHeaderRuta3}>
                  <Text style={tableStyles.tableCellHeader}>
                    N° TRANSACCIÓN
                  </Text>
                </View>
              </View>
            </View>

            {timestamp.detalle_paquetes[index].formas_pago.map(
              (forma_pago, index) => (
                <View key={index}>
                  <View style={tableStyles.table2}>
                    <View style={tableStyles.tableRow}>
                      <View style={tableStyles.tableColRuta3}>
                        <Text style={tableStyles.tableCell}>
                          {forma_pago.forma_de_pago}
                        </Text>
                      </View>
                      <View style={tableStyles.tableColRuta3}>
                        <Text style={tableStyles.tableCell}>
                          {" "}
                          {forma_pago.total_pagado} Bs.
                        </Text>
                      </View>
                      <View style={tableStyles.tableColRuta3}>
                        <Text style={tableStyles.tableCell}>
                          {forma_pago.n_transaccion}
                        </Text>
                      </View>
                    </View>
                  </View>
                </View>
              )
            )}
          </View>

          <View wrap={false}>
            <View style={{ marginTop: 10 }}>
              <Text style={{ fontSize: 11, marginLeft: 60 }}>
                DETALLADO ({index + 1}):
              </Text>
            </View>
            <View style={tableStyles.table2}>
              <View style={tableStyles.tableRow}>
                <View style={tableStyles.tableColHeaderRuta2}>
                  <Text style={tableStyles.tableCellHeader}>N° LISTÍN</Text>
                </View>
                <View style={tableStyles.tableColHeaderOther2}>
                  <Text style={tableStyles.tableCellHeader}>FECHA VIAJE</Text>
                </View>
                <View style={tableStyles.tableColHeaderOther2}>
                  <Text style={tableStyles.tableCellHeader}>VALOR DOLAR</Text>
                </View>
                <View style={tableStyles.tableColHeaderOther2}>
                  <Text style={tableStyles.tableCellHeader}>DESTINO</Text>
                </View>
                <View style={tableStyles.tableColHeaderOther2}>
                  <Text style={tableStyles.tableCellHeader}>LISTÍN %</Text>
                </View>
                <View style={tableStyles.tableColHeaderOther2}>
                  <Text style={tableStyles.tableCellHeader}>T.SALIDA</Text>
                </View>
                <View style={tableStyles.tableColHeaderOther2}>
                  <Text style={tableStyles.tableCellHeader}>TOTAL (BS)</Text>
                </View>
              </View>
            </View>

            {timestamp.detalle_paquetes[index].avisos_de_cobro.map(
              (aviso, index) => (
                <View style={tableStyles.table2}>
                  <View style={tableStyles.tableRow}>
                    <View style={tableStyles.tableColRuta20}>
                      <Text style={tableStyles.tableCell}>
                        {aviso.n_listin}
                      </Text>
                    </View>
                    <View style={tableStyles.tableColOther20}>
                      <Text style={tableStyles.tableCell}>
                        {aviso.fecha_de_viaje}
                      </Text>
                    </View>
                    <View style={tableStyles.tableColOther20}>
                      <Text style={tableStyles.tableCell}>
                        {aviso.valor_dolar} Bs.
                      </Text>
                    </View>
                    <View style={tableStyles.tableColOther20}>
                      <Text style={tableStyles.tableCell}>{aviso.destino}</Text>
                    </View>
                    <View style={tableStyles.tableColOther20}>
                      <Text style={tableStyles.tableCell}>
                        {aviso.listin_bs} Bs.
                      </Text>
                    </View>
                    <View style={tableStyles.tableColOther20}>
                      <Text style={tableStyles.tableCell}>
                        {aviso.tasa_salida} Bs.
                      </Text>
                    </View>
                    <View style={tableStyles.tableColOther20}>
                      <Text style={tableStyles.tableCell}>
                        {aviso.total_bolivares} Bs.
                      </Text>
                    </View>
                  </View>
                </View>
              )
            )}
          </View>
        </View>
      ))}

      <View style={styles.footer} fixed>
        <Text>Reporte generado por el sistema login.intravialca-ve.com</Text>
      </View>
    </Page>
  </Document>
);

export const CierreCajaDiario = () => {
  const { store } = useContext(Context);
  const [reporte, setReporte] = useState(null);
  const navigate = useNavigate();
  const [records, setRecords] = useState(null);
  const [recordsTotals, setRecordsTotals] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [empresas, setEmpresas] = useState([]);
  const [empresaId, setEmpresaId] = useState(0);
  const [formData2, setFormData2] = useState({
    fecha_cierre: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Actualizar el estado del formulario
    setFormData2((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    // Si el campo modificado es fecha_cierre, buscar registros
    if (name === "fecha_cierre") {
      fetchRecordsByDate(value); // Pasa el valor actualizado directamente
    }
  };

  const fetchRecordsByDate = async (fechaCierre) => {
    setIsSubmitting(true);

    try {
      const response = await axios.get(
        `${process.env.BACKEND_URL}/api/get_avisos_del_dia_seleccionado_pagados`,
        {
          params: { fecha_cierre: fechaCierre },
          headers: { Authorization: `Bearer ${store.token}` },
        }
      );

      // Actualizar los registros en el estado
      setRecords(response.data.data);
      setRecordsTotals(response.data);
    } catch (error) {
      console.error("Error al obtener registros por fecha:", error);
      MySwal.fire({
        title: "Error",
        text: "Hubo un problema al obtener los registros. Intenta nuevamente.",
        icon: "error",
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const CierreCaja = async () => {
    try {
      setIsSubmitting(true);

      // 1. Pedir confirmación al usuario
      const result = await MySwal.fire({
        title: "¿Estás seguro de que quieres hacer el cierre?",
        text: "Recuerda que solo puedes hacer un cierre de caja al día.",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Sí, cerrar",
        cancelButtonText: "Cancelar",
      });

      // Si el usuario cancela, no hacer nada
      if (!result.isConfirmed) {
        return;
      }

      // 2. Generar y subir el PDF
      const { pdfUrl, paqueteCode, timestamp } = await handlePDFUpload();

      if (!pdfUrl || !paqueteCode) {
        throw new Error(
          "No se pudo generar o subir el PDF. Intenta nuevamente."
        );
      }

      // 3. Procesar el pago después de subir el PDF
      await axios.post(
        `${process.env.BACKEND_URL}/api/register_cierre_caja_recau`,
        {
          paqueteCode,
          pdfUrl,
          timestamp,
          formData2,
        },
        {
          headers: { Authorization: `Bearer ${store.token}` },
        }
      );

      // Mostrar mensaje de éxito
      MySwal.fire({
        title: "Cierre Exitoso",
        text: "El cierre ha sido procesado correctamente.",
        icon: "success",
      }).then(() => {
        navigate("/Cierres");
      });
    } catch (error) {
      console.error("Error al procesar el cierre:", error);

      MySwal.fire({
        title: "Error",
        text: `Hubo un problema al procesar el pago: ${
          error.response?.data?.message ||
          error.message ||
          "Intenta nuevamente."
        }`,
        icon: "error",
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const generatePDF = async (
    timestamp_ve,
    nombre_apellido_user,
    email_user,
    paquete_code,
    detalle_paquetes,
    numero_de_avisos_de_cobro,
    numero_de_paquetes,
    total_pagado,
    totales_formas_pago
  ) => {
    try {
      const pdfBlob = await pdf(
        <CierreDiario
          timestamp={timestamp_ve}
          nombre_apellido_user={nombre_apellido_user}
          email_user={email_user}
          code={paquete_code}
          detalle_paquetes={detalle_paquetes}
          numero_de_avisos_de_cobro={numero_de_avisos_de_cobro}
          numero_de_paquetes={numero_de_paquetes}
          total_pagado={total_pagado}
          totales_formas_pago={totales_formas_pago}
        />
      ).toBlob();

      return new File([pdfBlob], `CIERRE_CAJA_.pdf`, {
        type: "application/pdf",
      });
    } catch (error) {
      console.error("Error al generar el PDF Blob:", error);
      throw new Error("Error al convertir el PDF a Blob.");
    }
  };

  const handlePDFUpload = async () => {
    try {
      const response = await axios.post(
        process.env.BACKEND_URL +
          "/api/generar_firma_cloudinary_cierre_paquetes_diarios",
        formData2,
        {
          headers: { Authorization: "Bearer " + store.token },
        }
      );

      const {
        signature,
        timestamp_ve,
        timestamp,
        api_key,
        cloud_name,
        folder,
        email_user,
        nombre_apellido_user,
        paquete_code,
        detalle_paquetes,
        numero_de_avisos_de_cobro,
        numero_de_paquetes,
        total_pagado,
        totales_formas_pago,
      } = response.data;

      const pdfFile = await generatePDF(
        timestamp_ve,
        nombre_apellido_user,
        email_user,
        paquete_code,
        detalle_paquetes,
        numero_de_avisos_de_cobro,
        numero_de_paquetes,
        total_pagado,
        totales_formas_pago
      );

      const formData = new FormData();
      formData.append("file", pdfFile);
      formData.append("timestamp", timestamp);
      formData.append("signature", signature);
      formData.append("api_key", api_key);
      formData.append("folder", folder);

      const response2 = await axios.post(
        `https://api.cloudinary.com/v1_1/${cloud_name}/auto/upload`,
        formData
      );

      if (!response2.data || !response2.data.secure_url) {
        throw new Error("Cloudinary no devolvió una URL válida del PDF.");
      }

      return {
        pdfUrl: response2.data.secure_url,
        paqueteCode: paquete_code,
        timestamp: response.data,
      };
    } catch (error) {
      console.error("Error en handlePDFUpload:", error);
      throw new Error(
        error.response?.data?.message ||
          "Error al generar o subir el PDF. Intenta nuevamente."
      );
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString); // Asegúrate de que `dateString` esté en formato válido
    const day = String(date.getUTCDate()).padStart(2, "0"); // Usa UTC para evitar problemas de zonas horarias
    const month = String(date.getUTCMonth() + 1).padStart(2, "0"); // Meses son 0 indexados
    const year = date.getUTCFullYear();
    return `${day}-${month}-${year}`;
  };

  return (
    <div
      className="container-fluid contarinerGeneralUserHomejs"
      style={{
        display: "flex",
        paddingBottom: "40px",
      }}
    >
      <div className="container asklnduiashndaijsnxansxsaas m-0 p-0">
        <div className="sdfdfgrt54w98e7we9contewe w-100">
          <div style={{ margin: "0", marginTop: "20px" }}>
            <i
              className="fa-solid seraUnMarinsupre fa-check-to-slot"
              id="icon-tittle-general"
            ></i>
          </div>
          <p>Diariamente, haz un cierre de todos los pagos recibidos.</p>
        </div>
        <div className="formularioDeLogin sd98f4e8wf4cwe987cwe984cwe w-100">
          <div
            className="container asklnduiashndaijsnxansxsaas3"
            style={{ marginBottom: "20px", marginTop: "-40px" }}
          >
            <div className="aoshduiahsdkasnmdasdas">
              <div className="input-group-login2">
                <input
                  className="inputDeLogin"
                  type="date"
                  name="fecha_cierre"
                  onChange={handleChange}
                  value={formData2.fecha_cierre}
                  required
                />
                <label>Fecha de Cierre</label>
              </div>
            </div>
            <button
              className="buttonCargadeDatosDeLoginregistromovimiento m-0"
              onClick={CierreCaja}
              disabled={isSubmitting}
              style={{ width: "200px" }}
            >
              {isSubmitting ? "Espera..." : "Hacer Cierre"}{" "}
              <i className="fa-solid fa-arrow-right"></i>
            </button>
          </div>
        </div>
        <div className="d-flex" style={{ marginTop: "12px" }}>
          <p>¿Alguna duda?,</p>
          <Link
            style={{
              marginLeft: "5px",
              textDecoration: "none",
              color: "#24827C",
            }}
            to="/AyudaYSoporte"
          >
            <strong>Contáctanos</strong>
          </Link>
        </div>
        <hr style={{ width: "90%" }}></hr>
        {!isSubmitting ? (
          <div
            className="custom-table-container m-0"
            style={{
              maxWidth: "100%",
              overflow: "auto",
              maxHeight: "60vh",
            }}
          >
            {records ? (
              <div style={{ padding: "10px", width: "100%" }}>
                <table
                  className="custom-table rounded-table"
                  style={{ minWidth: "100%", marginBottom: "20px" }}
                >
                  <thead>
                    <tr>
                      <th className="table-header">N° Avisos</th>
                      <th className="table-header">Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr style={{ fontSize: "11px" }}>
                      <td>{recordsTotals.num_registros}</td>
                      <td>{recordsTotals.total_bolivares_suma} Bs.</td>
                    </tr>
                  </tbody>
                </table>
                <h4 style={{ textAlign: "center" }}>
                  Listines Pagados el día <br></br>{" "}
                  {formatDate(formData2.fecha_cierre)}
                </h4>
                <div
                  className="custom-table-container m-0"
                  style={{
                    maxWidth: "100%",
                    overflow: "auto",
                    maxHeight: "60vh",
                  }}
                >
                  <table
                    className="custom-table rounded-table m-0"
                    style={{ minWidth: "100%", minWidth: "650px" }}
                  >
                    <thead>
                      <tr>
                        <th className="table-header">Fecha Viaje</th>
                        <th className="table-header">N° Listín</th>
                        <th className="table-header">Empresa</th>
                        <th className="table-header">Valor Dólar</th>
                        <th className="table-header">Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      {records.map((record, index) => (
                        <tr key={index} style={{ fontSize: "11px" }}>
                          <td>{record.fecha_de_viaje}</td>
                          <td>{record.n_listin}</td>
                          <td>{record.empresa}</td>
                          <td>{record.valor_dolar} Bs.</td>
                          <td>{record.total_bolivares} Bs.</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            ) : (
              <div></div>
            )}
          </div>
        ) : (
          <div style={{ textAlign: "center", padding: "20px" }}>
            <img
              src="https://res.cloudinary.com/dirpdlfbu/image/upload/v1731494258/1/dvhl0hwbx4adb8uceymr.gif"
              alt="Cargando..."
              style={{ width: "150px" }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

// CARGAR PREVIEW DEL PDFFFFFFFFFFFFFFFFFF___________::::::::::::::::::::::::::::::::::::::::::::::::. OJO
{
  /* <div className="text-center mt-3">
  <button onClick={handlePreviewPDF} className="btn btn-primary">
    Vista Previa del Recibo
  </button>
</div> */
}

// const handlePreviewPDF = async () => {
//   try {
//     // Mostrar mensaje de carga
//     Swal.fire({
//       title: "Generando vista previa...",
//       html: "<p>Por favor, espera mientras preparamos el PDF.</p>",
//       allowOutsideClick: false,
//       didOpen: () => {
//         Swal.showLoading();
//       },
//     });

//     // Solicitar los datos necesarios al backend
//     //   const response = await axios.post(
//     //     process.env.BACKEND_URL + "/api/generar_firma_cloudinary_recibo_pago",
//     //     {},
//     //     {
//     //       headers: {
//     //         Authorization: `Bearer ${localStorage.getItem("token")}`,
//     //       },
//     //     }
//     //   );

//     //   const {
//     //     email_user,
//     //     nombre_apellido_user,
//     //     paquete_code,
//     //     timestamp_ve,
//     //     timestamp,
//     //   } = response.data;

//     // Generar PDF con los datos obtenidos
//     const pdfBlob = await pdf(<CierreDiario />).toBlob();

//     // Crear URL temporal para el PDF
//     const pdfUrl = URL.createObjectURL(pdfBlob);
//     window.open(pdfUrl, "_blank");

//     Swal.close(); // Cerrar la ventana de carga
//   } catch (error) {
//     console.error("Error al generar PDF:", error);
//     Swal.fire({
//       title: "Error",
//       text: "Ocurrió un error al generar la vista previa del PDF. Inténtalo nuevamente.",
//       icon: "error",
//     });
//   }
// };
