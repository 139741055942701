import React, { useContext, useState, useEffect } from "react";
import axios from "axios";
import "../../../../styles/SolicitudActivaciones.css";
import { Context } from "../../../store/appContext";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Link } from "react-router-dom";

const MySwal = withReactContent(Swal);

export const VistaPasajeros = () => {
  const { store } = useContext(Context);
  const [records, setRecords] = useState({ control_entrada_vehicular: [] });
  const [recordss, setRecordss] = useState([]);
  const [pasajeros, setPasajeros] = useState([]);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    numero_listin: "",
    fecha_de_viaje: "",
    tipo_servicio: "",
    photo_cara_link: "",
    photo_cara_link_2: "",
    unidad: "",
    url_pdf: "",
    placa: "",
    numero_puestos: "",
    destino: "",
    anden: "",
    destino_final: "",
    hora_salida: "",
    ruta: "",
    empresa: "",
    rif: "",
    conductor_1: "",
    cedula_1: "",
    nombre_1: "",
    conductor_2: "",
    cedula_2: "",
    nombre_2: "",
    vista: "",
    activationId: "",
    conductor_1_id: "",
    conductor_2_id: "",
    empresaId: "",
    km_destino_final: 0.0,
    url_pdf: "",
  });

  const [selectedIds, setSelectedIds] = useState([]);
  const [activationIdd, setActivationIdd] = useState({});

  const handleCheckboxChange = (id) => {
    setSelectedIds((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((selectedId) => selectedId !== id)
        : [...prevSelected, id]
    );
  };

  const handleDelete = async () => {
    if (selectedIds.length === 0) {
      Swal.fire("Atención", "Selecciona al menos un pasajero", "warning");
      return;
    }

    // Mostrar mensaje de confirmación antes de eliminar
    const result = await Swal.fire({
      title: "¿Estás seguro?",
      text: "Esta acción eliminará permanentemente los pasajeros seleccionados.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sí, eliminar",
      cancelButtonText: "Cancelar",
      reverseButtons: true,
    });

    if (!result.isConfirmed) {
      return; // Si el usuario cancela, no hacemos nada
    }

    // Mostrar alerta de carga mientras se eliminan los pasajeros
    Swal.fire({
      title: "Eliminando pasajeros...",
      text: "Por favor espera",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    setLoading(true);

    try {
      await axios.delete(process.env.BACKEND_URL + "/api/pasajeros/eliminar", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${store.token}`,
        },
        data: { pasajeros_ids: selectedIds }, // Enviar datos en el body
      });

      // Actualizar la lista de pasajeros eliminando los seleccionados
      setRecords((prevRecords) => ({
        ...prevRecords,
        control_entrada_vehicular: prevRecords.control_entrada_vehicular.filter(
          (detalle) => !selectedIds.includes(detalle.id)
        ),
      }));

      setSelectedIds([]);
      fetchUsersActivation(); // 🔄 Actualizar la lista después de eliminar

      // Mostrar mensaje de éxito
      Swal.fire("Éxito", "Pasajeros eliminados correctamente", "success");
    } catch (error) {
      console.error("Error al eliminar pasajeros", error);
      Swal.fire("Error", "No se pudieron eliminar los pasajeros", "error");
    }

    setLoading(false);
  };

  const fetchTodayRecords = async () => {
    try {
      const response = await axios.get(
        process.env.BACKEND_URL + "/api/empresas/get_activations_future",
        {
          headers: {
            Authorization: `Bearer ${store.token}`,
          },
        }
      );
      setRecordss(response.data.activations);
    } catch (error) {
      console.error("Error al obtener registros de hoy", error);
    }
  };

  const fetchUsersActivation = async () => {
    setLoading(true);
    setPasajeros([]);
    try {
      const response = await axios.get(
        process.env.BACKEND_URL + "/api/pasajeros/activation/" + activationIdd,
        {
          headers: {
            Authorization: `Bearer ${store.token}`,
          },
        }
      );
      setPasajeros(response.data.pasajeros);
    } catch (error) {
      console.error("Error al obtener registros de hoy", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTodayRecords();
  }, []);

  useEffect(() => {
    fetchUsersActivation();
  }, [activationIdd]);

  const formatDate = (dateString) => {
    const date = new Date(dateString); // Asegúrate de que `dateString` esté en formato válido
    const day = String(date.getUTCDate()).padStart(2, "0"); // Usa UTC para evitar problemas de zonas horarias
    const month = String(date.getUTCMonth() + 1).padStart(2, "0"); // Meses son 0 indexados
    const year = date.getUTCFullYear();
    return `${day}-${month}-${year}`;
  };

  const openActivationSelection = () => {
    MySwal.fire({
      title: (
        <p>
          <i
            className="fa-solid fa-bus"
            style={{ marginRight: "7px", fontSize: "20px" }}
          ></i>
          Mis Activaciones
        </p>
      ),
      html: `
        <input
          type="text"
          id="companySearch"
          placeholder="Buscar activación"
          class="swal2-input"
          style="width: 100%"
        />
        <div id="companyList" class="company-list">
          ${recordss
            .map(
              (activation) => `
            <button 
              class="btn company-option company-list-activation-list" 
              data-id="${activation.id}"
              data-name="${activation.empresa.nombre}"
              data-unidad="${activation.numero_de_unidad}"
              data-rif="${activation.empresa.rif}"
              data-placa="${activation.dt_terminal_empresa.placa}"
              data-salida="${activation.hora_de_salida}"
              data-destino="${activation.ruta.destino_final.destino_final}"
              data-ruta="${activation.ruta.ruta}"
              data-empresaid="${activation.empresa_terminal_id}"
              data-fecha="${activation.fecha_de_viaje}"
              data-anden="${activation.anden}"
              data-kmdistancia="${activation.ruta.destino_final.km_distancia}"
              >
                Destino: ${activation.ruta.destino_final.destino_final} <br> Unidad: <strong>${activation.numero_de_unidad} </strong> - ${activation.dt_terminal_empresa.placa} <br> Pasajeros: ${activation.n_pasajeros} <br> Fecha Viaje: ${formatDate(activation.fecha_de_viaje)} - ${activation.hora_de_salida}
            </button>`
            )
            .join("")}
        </div>
      `,
      showConfirmButton: false,
      showCancelButton: true,
      cancelButtonText: "Cerrar",
      didOpen: () => {
        const companySearch = document.getElementById("companySearch");
        const companyList = document.getElementById("companyList");

        // Manejador de filtro para el input de búsqueda
        companySearch.addEventListener("input", (e) => {
          const query = e.target.value.toLowerCase();
          const filteredEmpresas = recordss.filter(
            (activation) =>
              activation.empresa.nombre.toLowerCase().includes(query) ||
              activation.numero_de_unidad.toLowerCase().includes(query)
          );
          companyList.innerHTML = filteredEmpresas
            .map(
              (activation) => `
                <button 
                  class="btn company-option company-list-activation-list" 
                  data-id="${activation.id}"
                  data-name="${activation.empresa.nombre}"
                  data-unidad="${activation.numero_de_unidad}"
                  data-rif="${activation.empresa.rif}"
                  data-placa="${activation.dt_terminal_empresa.placa}"
                  data-salida="${activation.hora_de_salida}"
                  data-destino="${activation.ruta.destino_final.destino_final}"
                  data-ruta="${activation.ruta.ruta}"
                  data-empresaid="${activation.empresa_terminal_id}"
                  data-fecha="${activation.fecha_de_viaje}"
                  data-anden="${activation.anden}"
                  data-kmdistancia="${activation.ruta.destino_final.km_distancia}"
                  >
                     Destino: ${activation.ruta.destino_final.destino_final} <br> Unidad: <strong>${activation.numero_de_unidad} </strong> - ${activation.dt_terminal_empresa.placa} <br> Pasajeros: ${activation.n_pasajeros} <br> Fecha Viaje: ${formatDate(activation.fecha_de_viaje)} - ${activation.hora_de_salida}
                </button>`
            )
            .join("");

          // Agregar listeners a cada opción filtrada
          const buttons = companyList.querySelectorAll(".company-option");
          buttons.forEach((button) => {
            button.addEventListener("click", () => {
              const activationId = button.dataset.id;
              const empresa = button.dataset.name;
              const numero_unidad = button.dataset.unidad;
              const rif = button.dataset.rif;
              const placa = button.dataset.placa;
              const hora_de_salida = button.dataset.salida;
              const destino_final = button.dataset.destino;
              const ruta = button.dataset.ruta;
              const empresaId = button.dataset.empresaid;
              const fecha_de_viaje = button.dataset.fecha;
              const anden = button.dataset.anden;
              const km_distancia = button.dataset.kmdistancia;
              selectCompany(
                activationId,
                empresa,
                numero_unidad,
                rif,
                placa,
                hora_de_salida,
                destino_final,
                ruta,
                empresaId,
                fecha_de_viaje,
                anden,
                km_distancia
              ); // Pasamos el ID y el nombre
            });
          });
        });

        // Listener para opciones de empresa inicialmente
        const buttons = companyList.querySelectorAll(".company-option");
        buttons.forEach((button) => {
          button.addEventListener("click", () => {
            const activationId = button.dataset.id;
            const empresa = button.dataset.name;
            const numero_unidad = button.dataset.unidad;
            const rif = button.dataset.rif;
            const placa = button.dataset.placa;
            const hora_de_salida = button.dataset.salida;
            const destino_final = button.dataset.destino;
            const ruta = button.dataset.ruta;
            const empresaId = button.dataset.empresaid;
            const fecha_de_viaje = button.dataset.fecha;
            const anden = button.dataset.anden;
            const km_distancia = button.dataset.kmdistancia;
            selectCompany(
              activationId,
              empresa,
              numero_unidad,
              rif,
              placa,
              hora_de_salida,
              destino_final,
              ruta,
              empresaId,
              fecha_de_viaje,
              anden,
              km_distancia
            ); // Pasamos el ID y el nombre
          });
        });
      },
    });
  };

  const selectCompany = (
    activationId,
    empresa,
    numero_de_unidad,
    rif,
    placa,
    hora_de_salida,
    destino_final,
    ruta,
    empresaId,
    fecha_de_viaje,
    anden,
    km_distancia
  ) => {
    setFormData({
      ...formData,
      activationId: activationId,
      vista: numero_de_unidad + " - " + placa + " - " + destino_final,
      empresa: empresa,
      rif: rif,
      placa: placa,
      hora_salida: hora_de_salida,
      unidad: numero_de_unidad,
      destino: destino_final,
      ruta: ruta,
      fecha_de_viaje: fecha_de_viaje,
      anden: anden,
      empresaId: empresaId,
      km_destino_final: km_distancia,
    });
    setActivationIdd(activationId);
    MySwal.close(); // Cerrar la ventana de SweetAlert
  };

  const trasladar = () => {
    MySwal.fire({
      title: <p>Muy Pronto!</p>,
      html: (
        <p>
          Traslada pasajeros entre activaciones, incluso entre diferentes
          empresas con autorización, facilitando así los transbordos de manera
          eficiente.
        </p>
      ),
      icon: "warning",
      position: "center",
      showConfirmButton: false,
      timer: 10000,
      timerProgressBar: true,
    });
  };

  return (
    <div
      className="container-fluid contarinerGeneralUserHomejs"
      style={{
        display: "flex",
        paddingBottom: "40px",
      }}
    >
      <div className="container asklnduiashndaijsnxansxsaas m-0 p-0">
        <div
          style={{
            textAlign: "center",
            padding: "10px",
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
          className="sdfdfgrt54w98e7we9contewe"
        >
          <div style={{ margin: "0" }}>
            <i
              className="fa-solid seraUnMarinsupre fa-person-walking-luggage"
              id="icon-tittle-general"
            ></i>
          </div>
          <p
            style={{ fontSize: "13px", margin: "0" }}
            className="asdjhfnerer4pdeterminal"
          >
            Escoge una Activación y visualiza los pasajeros que tienes
            actualmente y su detalle.
          </p>
        </div>
        <div className="container-table-pds-padre">
          <div className="container-table-pds">
            <div className="container-table-pds-hijo-1">
              <div
                className="input-group-login69"
                style={{
                  fontSize: "12px",
                  maxWidth: "300px",
                }}
              >
                <input
                  className="inputDeLogin"
                  style={{ height: "33px", fontSize: "12px" }}
                  type="text"
                  required
                  readOnly
                  onClick={openActivationSelection}
                  value={formData.vista}
                  // value={fechaInicio}
                  // onChange={(e) => setFechaInicio(e.target.value)}
                />
                <label>Activación</label>
              </div>

              <button
                className="btn btn-info"
                style={{
                  height: "76%",
                  marginRight: "5px",
                  marginLeft: "auto",
                  color: "white",
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                  fontSize: "12px",
                }}
                onClick={() => trasladar()}
              >
                <i className="fa-solid fa-arrows-turn-to-dots"></i>
              </button>

              <button
                className="btn btn-danger"
                style={{
                  height: "76%",
                  marginRight: "5px",
                  color: "white",
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                  fontSize: "12px",
                }}
                onClick={handleDelete}
                disabled={loading || selectedIds.length === 0}
              >
                <i className="fa-solid fa-trash-can"></i>
              </button>
              <Link
                className="btn btn-success"
                to="/RegistroPasajero"
                style={{
                  height: "76%",
                  marginRight: "5px",
                  color: "white",
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                  fontSize: "12px",
                }}
              >
                <i className="fa-solid fa-plus"></i>
              </Link>
            </div>
            <div
              className="w-100 h-100"
              style={{ overflow: "auto", padding: "10px" }}
            >
              <div style={{ width: "100%" }}>
                {loading ? (
                  <div className="loading-container">
                    <i className="fa-solid fa-spinner fa-spin-pulse"></i>{" "}
                    Cargando pasajeros...
                  </div>
                ) : pasajeros && pasajeros.length > 0 ? (
                  <div
                    className="custom-table-container m-0"
                    style={{
                      maxHeight: "50vh",
                      display: "flex",
                      flexDirection: "column",
                      minWidth: "500px",
                      width: "100%",
                    }}
                  >
                    <table
                      className="custom-table rounded-table m-0"
                      style={{ minWidth: "500px" }}
                    >
                      <thead>
                        <tr>
                          <th
                            className="table-header"
                            style={{ fontSize: "14px" }}
                          >
                            <input
                              type="checkbox"
                              onChange={(e) =>
                                setSelectedIds(
                                  e.target.checked
                                    ? pasajeros.map((d) => d.id)
                                    : []
                                )
                              }
                              checked={selectedIds.length === pasajeros.length}
                            />
                          </th>
                          <th
                            className="table-header"
                            style={{ fontSize: "14px" }}
                          >
                            Nombre y Apellido
                          </th>
                          <th
                            className="table-header"
                            style={{ fontSize: "14px" }}
                          >
                            Cédula
                          </th>
                          <th
                            className="table-header"
                            style={{ fontSize: "14px" }}
                          >
                            Destino
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {pasajeros.map((detalle, index) => (
                          <tr className="table-row" key={index}>
                            <td
                              className="table-cell"
                              style={{
                                fontSize: "14px",
                                padding: "6px",
                                display: "flex",
                                justifyContent: "space-evenly",
                                alignItems: "center",
                              }}
                            >
                              <input
                                type="checkbox"
                                checked={selectedIds.includes(detalle.id)}
                                onChange={() =>
                                  handleCheckboxChange(detalle.id)
                                }
                              />
                              {index + 1}
                            </td>
                            <td
                              className="table-cell"
                              style={{ fontSize: "12px", padding: "6px" }}
                            >
                              {detalle.first_name} {detalle.last_name}
                            </td>
                            <td
                              className="table-cell"
                              style={{ fontSize: "12px", padding: "6px" }}
                            >
                              {detalle.identificacion}
                            </td>
                            <td
                              className="table-cell"
                              style={{ fontSize: "12px", padding: "6px" }}
                            >
                              {detalle.destino_abreviado}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <p style={{ color: "gray", fontSize: "11px" }}>
                    No hay pasajeros registrados, selecciona otra activación.
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
        <hr style={{ width: "90%" }}></hr>

        <div className="d-flex" style={{ marginTop: "12px" }}>
          <p>¿Alguna duda?,</p>
          <Link
            style={{
              marginLeft: "5px",
              textDecoration: "none",
              color: "#24827C",
            }}
            to="/AyudaYSoporte"
          >
            <strong>Contáctanos</strong>
          </Link>
        </div>
      </div>
    </div>
  );
};
