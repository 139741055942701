import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { Context } from "../../../store/appContext";
import { Link, useNavigate } from "react-router-dom";
import Chart from "chart.js/auto";
import ApexCharts from "react-apexcharts";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

export const AyudaYSoporte = () => {
  const { store } = useContext(Context);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formData, setFormData] = useState({
    asunto: "",
    descripcion: "",
  });
  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const mostrarAlerta1 = () => {
    MySwal.fire({
      title: <p>Gracias por comunicarte con nosotros!</p>,
      html: (
        <p>Te enviaremos un correo electrónico muy pronto con una respuesta</p>
      ),
      icon: "success",
      position: "center",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
    });
  };

  const mostrarAlerta2 = (errorMessage) => {
    MySwal.fire({
      title: <p>Algo salió mal, por favor vuelve a intentarlo</p>,
      html: <p>{errorMessage}</p>, // Se muestra el mensaje de error del servidor
      icon: "error",
      position: "center",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      // Enviar el correo al backend
      await axios.post(
        process.env.BACKEND_URL + "/api/AyudaYSoporteIntravialca",

        formData,
        {
          headers: {
            Authorization: "Bearer " + store.token,
          },
        }
      );

      mostrarAlerta1();
      // Limpiar el formulario después de enviar los datos
      setFormData({
        name: "",
        asunto: "",
        email: "",
        descripcion: "",
      });
    } catch (error) {
      mostrarAlerta2();
    } finally {
      setIsSubmitting(false); // Rehabilitar el botón al finalizar
    }
  };
  return (
    <div
      className="container-fluid contarinerGeneralUserHomejs"
      style={{
        display: "flex",
        paddingBottom: "40px",
      }}
    >
      <div
        className="container asklnduiashndaijsnxansxsaas"
        style={{ backgroundColor: "white" }}
      >
        <div className="d-flex justify-content-center w-100 sdfs4df9e4g98r4t8j9tyb9erf48w9e7fd9qe4we8">
          <div
            className="d-flex justify-content-center w-100"
            style={{
              flexDirection: "column",
              alignContent: "center",
              width: "100%",
              alignItems: "center",
              padding: "5px",
            }}
          >
            <div className="tittle-80 w-100" style={{ maxWidth: "900px" }}>
              <h2
                style={{
                  color: "#24827C",
                  textAlign: "center",
                }}
              >
                Contáctenos para cualquier consulta o sugerencia
              </h2>
              <p
                style={{ textAlign: "center", color: "gray", fontSize: "13px" }}
              >
                Estamos a su disposición para atender cualquier duda o
                comentario que desee compartir sobre el sistema. Su
                retroalimentación es sumamente valiosa para nosotros y
                contribuye a mejorar nuestro servicio. Tomamos mucho en cuenta
                tu opinión, recibirás un correo de respuesta a tu pregunta,
                comenario o sugerencia al correo electrónico con el cual te
                registraste.
              </p>
            </div>
            <div style={{ marginBottom: "20px" }}>
              <a
                href="https://api.whatsapp.com/send?phone=584242086513&text=Cont%C3%A1ctanos%20f%C3%A1cilmente%20por%20cualquier%20ayuda%20o%20sugerencia%20que%20necesites"
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: "none" }}
              >
                <button
                  style={{
                    backgroundColor: "#25d366",
                    color: "white",
                    borderRadius: "50%",
                    width: "50px",
                    height: "50px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: "35px",
                    border: "none",
                    cursor: "pointer",
                  }}
                  className="btn btn-success"
                >
                  <i className="fa-brands fa-whatsapp"></i>
                </button>
              </a>
            </div>
            <form
              onSubmit={handleSubmit}
              className="formularioDeLogin s6d54fsd89f7sd98f4we98f7wr89 w-100"
            >
              <div className="input-group-login2">
                <input
                  className="inputDeLogin"
                  type="text"
                  name="asunto"
                  value={formData.asunto}
                  onChange={handleChange}
                  required
                />
                <label
                  htmlFor="asunto"
                  style={{ backgroundColor: "white", border: "1px solid gray" }}
                >
                  Asunto
                </label>
              </div>
              <div className="input-group-login2" style={{ marginTop: "10px" }}>
                <textarea
                  className="inputDeLogin"
                  type="text"
                  name="descripcion"
                  value={formData.descripcion}
                  onChange={handleChange}
                  required
                  style={{ minHeight: "200px" }}
                />
                <label
                  htmlFor="descripcion"
                  style={{ backgroundColor: "white", border: "1px solid gray" }}
                >
                  Describe detalladamente
                </label>
              </div>
              <button
                className="buttonCargadeDatosDeLoginregistromovimiento"
                type="submit"
                disabled={isSubmitting}
              >
                {isSubmitting ? "Enviando..." : "Enviar Datos"}{" "}
                <i className="fa-solid fa-arrow-right"></i>
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
