import React, { useState, useContext } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { Context } from "../../../store/appContext.js";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFDownloadLink,
} from "@react-pdf/renderer";

const MySwal = withReactContent(Swal);

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#ffffff",
    padding: 20,
  },
  section: {
    margin: 10,
    padding: 10,
    borderBottomWidth: 1,
    borderBottomColor: "#cccccc",
    borderBottomStyle: "solid",
  },
  header: {
    fontSize: 18,
    marginBottom: 10,
  },
  body: {
    fontSize: 12,
  },
  userDetails: {
    fontSize: 14,
    marginBottom: 10,
  },
});

export const Terminales = () => {
  const navigate = useNavigate();
  const { store } = useContext(Context);
  const [formData, setFormData] = useState({
    code: "",
    nombre: "",
    empresa: "",
    rif: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false); // Nuevo estado para controlar el estado de envío

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true); // Desactiva el botón

    try {
      await axios.post(process.env.BACKEND_URL + "/api/terminal", formData, {
        headers: {
          Authorization: "Bearer " + store.token,
        },
      });
      setFormData({
        code: "",
        nombre: "",
        empresa: "",
        rif: "",
      });
      mostrarAlerta1();
    } catch (error) {
      const errorMessage =
        error.response?.data?.message ||
        "Error desconocido al registrar al usuario";
      mostrarAlerta2(errorMessage);
      console.error("Error al registrar", error);
    } finally {
      setIsSubmitting(false); // Reactiva el botón después del envío
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const mostrarAlerta1 = () => {
    MySwal.fire({
      title: <p>Terminal Registrado con éxito</p>,
      icon: "success",
      position: "center",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
    });
  };

  const mostrarAlerta2 = (errorMessage) => {
    MySwal.fire({
      title: <p>Error al Registrar el Terminal</p>,
      html: <p>{errorMessage}</p>,
      icon: "error",
      position: "center",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
    });
  };

  // Diccionario para roles y estados
  const roleMap = {
    ADMINISTRADOR_TER: "DIRECTOR TERMINAL",
    TER_ANALISTA_TER: "ANALISTA",
    TER_PISTA_TER: "OPERADOR",
  };
  const statusMap = {
    ACTIVE: "ACTIVO",
    NO_ACTIVE: "BLOQUEADO",
  };

  // Función para asignar eventos a los botones de cambio de estado
  const addStatusChangeEventListeners = () => {
    document.querySelectorAll(".change-status").forEach((button) => {
      button.addEventListener("click", async (event) => {
        const userId = event.currentTarget.getAttribute("data-id");

        const confirmation = await MySwal.fire({
          title: "¿Estás seguro?",
          text: "¡Si haces esto el usuario no podrá usar más su cuenta!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#d33",
          cancelButtonColor: "#3085d6",
          confirmButtonText: "Sí, bloquear",
          cancelButtonText: "Cancelar",
        });

        if (confirmation.isConfirmed) {
          MySwal.fire({
            title: "Bloqueando...",
            html: `<p style="text-align:center; margin:20px;">Bloqueando Actividades de Usuario...</p>`,
            allowOutsideClick: false,
            didOpen: () => {
              MySwal.showLoading();
            },
          });

          try {
            await axios.put(
              `${process.env.BACKEND_URL}/api/terminal/${userId}/updateStatus`,
              {},
              {
                headers: {
                  Authorization: `Bearer ${store.token}`,
                },
              }
            );

            MySwal.fire({
              title: "Usuario Bloqueado",
              text: "El Usuario ha sido bloqueado exitosamente.",
              icon: "success",
              confirmButtonText: "Cerrar",
            });

            // Refrescar los usuarios después del cambio de estado
            fetchTerminal();
          } catch (error) {
            MySwal.fire({
              title: "Error",
              text: "Hubo un error al intentar bloquear el usuario",
              icon: "error",
              confirmButtonText: "Cerrar",
            });
            console.error("Error al bloquear al usuario:", error);
          }
        }
      });
    });
  };

  // Función para filtrar usuarios y actualizar la tabla
  const filterAndDisplayUsers = (data, searchTerm) => {
    const listinesList = document.getElementById("listines-list");
    const filteredRows = data
      .filter(
        (item) =>
          item.first_name.toLowerCase().includes(searchTerm) ||
          item.last_name.toLowerCase().includes(searchTerm) ||
          item.rol.toLowerCase().includes(searchTerm)
      )
      .map(
        (item) => `
        <tr>
          <td>${item.first_name + " " + item.last_name}</td>
          <td>${roleMap[item.rol] || item.rol}</td>
          <td>${item.email}</td>
          <td>
            <button class="change-status" data-id="${item.id}" style="color: white; background-color: ${item.status == "ACTIVE" ? "green" : "red"}; border: none; padding: 5px; cursor: pointer;">${statusMap[item.status] || item.status}</button>
          </td>
        </tr>
      `
      )
      .join("");

    listinesList.innerHTML = filteredRows;

    // Vuelve a asignar los event listeners después de actualizar la lista
    addStatusChangeEventListeners();
  };

  const fetchTerminal = async () => {
    MySwal.fire({
      title: "Cargando...",
      html: `<p style="text-align:center; margin:20px;">Cargando Terminales...</p>`,
      allowOutsideClick: false,
      didOpen: () => {
        MySwal.showLoading();
      },
    });

    try {
      const response = await axios.get(
        process.env.BACKEND_URL + "/api/GetTerminal",
        {
          headers: {
            Authorization: `Bearer ${store.token}`,
          },
        }
      );

      const data = response.data.terminales;

      MySwal.close();

      if (!data || data.length === 0) {
        MySwal.fire({
          title: "Terminales",
          html: `<p style="text-align:center; margin:20px;">No se encontraron Terminales.</p>`,
          confirmButtonText: "Cerrar",
        });
      } else {
        const tableHeaders = `
          <tr>
            <th style="text-align: start">CÓDIGO</th>
            <th style="text-align: start">NOMBRE</th>
            <th style="text-align: start">EMPRESA</th>
            <th style="text-align: start">RIF</th>
            <th style="text-align: start">ESTATUS</th>
          </tr>
        `;
        const roleMap = {
          ADMINISTRADOR_TER: "DIRECTOR TERMINAL",
          TER_ANALISTA_TER: "ANALISTA",
          TER_PISTA_TER: "OPERADOR",
        };
        const statusMap = {
          ACTIVE: "ACTIVO",
          NO_ACTIVE: "BLOQUEADO",
        };

        const tableRows = data
          .map(
            (item) => `
              <tr>
                <td>${item.code}</td>
                <td>${item.nombre}</td>
                <td>${item.empresa}</td>
                <td>${item.rif}</td>
                <td>
                  <button class="change-status" data-id="${item.id}" data-status="${item.status}" style="color: white; background-color: ${item.status == "ACTIVE" ? "green" : "red"}; border: none; padding: 5px; cursor: pointer;">
                    ${statusMap[item.status] || item.status}
                  </button>
                </td>
              </tr>
            `
          )
          .join("");

        const table = `
          <input type="text" id="search-listines" placeholder="Buscar..." class="swal2-input" style="margin-bottom: 10px;">
          <div style="max-height: 400px; overflow-y: auto;">
            <table style="width: 700px; border-collapse: collapse; font-size: 12px;">
              <thead>
                ${tableHeaders}
              </thead>
              <tbody id="listines-list" style="text-align: start">
                ${tableRows}
              </tbody>
            </table>
          </div>
        `;

        MySwal.fire({
          title: "Terminales",
          html: table,
          showConfirmButton: true,
          confirmButtonText: "Cerrar",
          didRender: () => {
            const searchInput = document.getElementById("search-listines");
            searchInput.addEventListener("input", (event) => {
              const searchTerm = event.target.value.toLowerCase();
              const listinesList = document.getElementById("listines-list");
              const filteredRows = data
                .filter(
                  (item) =>
                    item.code.toLowerCase().includes(searchTerm) ||
                    item.nombre.toLowerCase().includes(searchTerm) ||
                    item.empresa.toLowerCase().includes(searchTerm) ||
                    item.rif.toLowerCase().includes(searchTerm)
                )
                .map(
                  (item) => `
                    <tr>
                        <td>${item.code}</td>
                        <td>${item.nombre}</td>
                        <td>${item.empresa}</td>
                        <td>${item.rif}</td>
                        <td>
                        <button class="change-status" data-id="${item.id}" data-status="${item.status}" style="color: white; background-color: ${item.status == "ACTIVE" ? "green" : "red"}; border: none; padding: 5px; cursor: pointer;">
                            ${statusMap[item.status] || item.status}
                        </button>
                        </td>
                    </tr>
                  `
                )
                .join("");
              listinesList.innerHTML = filteredRows;
            });

            // Delegación de eventos para los botones de cambio de estado
            document
              .getElementById("listines-list")
              .addEventListener("click", async (event) => {
                if (event.target.classList.contains("change-status")) {
                  const button = event.target;
                  const userId = button.getAttribute("data-id");
                  const userStatus = button.getAttribute("data-status");

                  const actionText =
                    userStatus === "ACTIVE" ? "bloquear" : "activar";
                  const confirmationText =
                    userStatus === "ACTIVE"
                      ? "¡Si haces esto el usuario no podrá usar más su cuenta!"
                      : "¡El usuario podrá usar su cuenta nuevamente!";

                  const confirmation = await MySwal.fire({
                    title: `¿Estás seguro de que quieres ${actionText} este usuario?`,
                    text: confirmationText,
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#d33",
                    cancelButtonColor: "#3085d6",
                    confirmButtonText: `Sí, ${actionText}`,
                    cancelButtonText: "Cancelar",
                  });

                  if (confirmation.isConfirmed) {
                    MySwal.fire({
                      title: `${actionText.charAt(0).toUpperCase() + actionText.slice(1)}...`,
                      html: `<p style="text-align:center; margin:20px;">Actualizando estado del usuario...</p>`,
                      allowOutsideClick: false,
                      didOpen: () => {
                        MySwal.showLoading();
                      },
                    });

                    try {
                      await axios.put(
                        `${process.env.BACKEND_URL}/api/terminal/${userId}/updateStatus`,
                        {},
                        {
                          headers: {
                            Authorization: `Bearer ${store.token}`,
                          },
                        }
                      );

                      MySwal.fire({
                        title: `Usuario ${actionText === "bloquear" ? "bloqueado" : "activado"}`,
                        text: `El usuario ha sido ${actionText === "bloquear" ? "bloqueado" : "activado"} exitosamente.`,
                        icon: "success",
                        confirmButtonText: "Cerrar",
                      });

                      fetchTerminal();
                    } catch (error) {
                      MySwal.fire({
                        title: "Error",
                        text: "Hubo un error al intentar actualizar el estado del usuario",
                        icon: "error",
                        confirmButtonText: "Cerrar",
                      });
                      console.error(
                        "Error al cambiar el estado del usuario:",
                        error
                      );
                    }
                  }
                }
              });
          },
        });
      }
    } catch (error) {
      console.error("Error al obtener usuarios", error);
      MySwal.fire({
        title: "Error",
        html: `<p>Error al obtener usuarios. Por favor, inténtelo de nuevo.</p>`,
        icon: "error",
        confirmButtonText: "Cerrar",
      });
    }
  };

  return (
    <div
      className="container-fluid contarinerGeneralUserHomejs"
      style={{
        display: "flex",
        paddingBottom: "40px",
      }}
    >
      <div className="container asklnduiashndaijsnxansxsaas m-0 p-0">
        <div className="sdfdfgrt54w98e7we9contewe w-100">
          <div
            className="i-fewf5c9ew5ce9wdc1w4c1s sd56f4e8r97g8yrh4be8g9e4rf89er45s4"
            style={{
              marginTop: "30px",
              backgroundImage:
                "url(https://res.cloudinary.com/dirpdlfbu/image/upload/v1722184710/1/SASHA%20TECH/uer8zc1spuqpoax4k4xj.png)",
            }}
          ></div>
          <p>Registra tus Terminales</p>
        </div>
        <div
          className="container asklnduiashndaijsnxansxsaas3"
          style={{ marginBottom: "20px" }}
        >
          <form
            onSubmit={handleSubmit}
            className="formularioDeLogin sd98f4e8wf4cwe987cwe984cwe w-100"
          >
            <div className="aoshduiahsdkasnmdasdas">
              <div className="input-group-login">
                <input
                  className="inputDeLogin"
                  type="text"
                  name="code"
                  required
                  onChange={handleChange}
                  value={formData.code}
                  style={{ textTransform: "uppercase" }}
                />
                <label>CÓDIGO</label>
              </div>
              <div className="input-group-login">
                <input
                  className="inputDeLogin"
                  type="text"
                  name="nombre"
                  required
                  onChange={handleChange}
                  value={formData.nombre}
                  style={{ textTransform: "uppercase" }}
                />
                <label>Nombre</label>
              </div>
            </div>
            <div className="aoshduiahsdkasnmdasdas">
              <div className="input-group-login">
                <input
                  className="inputDeLogin"
                  type="text"
                  name="empresa"
                  required
                  onChange={handleChange}
                  value={formData.empresa}
                  style={{ textTransform: "uppercase" }}
                />
                <label>Empresa Encargada</label>
              </div>
              <div className="input-group-login">
                <input
                  className="inputDeLogin"
                  type="text"
                  name="rif"
                  required
                  onChange={handleChange}
                  value={formData.rif}
                  style={{ textTransform: "uppercase" }}
                />
                <label>Rif</label>
              </div>
            </div>
            <button
              className="buttonCargadeDatosDeLoginregistromovimiento"
              type="submit"
              disabled={isSubmitting}
            >
              {isSubmitting ? "Registrando..." : "Registrar"}{" "}
              <i className="fa-solid fa-arrow-right"></i>
            </button>
          </form>
        </div>
        <div className="w-100 asd9as-da9s84dx9a8qdw-qdw asdqw98d4qv895y4n9r84bvr98ebtnbtn poja65s4d8a9s7d89errg98er7fqwd9849iu9ykj7asj">
          <button
            className="asd98q7wd65q1wd8xqw49x8e4wc"
            onClick={fetchTerminal}
          >
            <i
              className="fa-solid fa-list"
              style={{ marginRight: "7px", fontSize: "20px" }}
            ></i>
            Ver Terminales
          </button>
        </div>
        <div className="d-flex" style={{ marginTop: "12px" }}>
          <p>¿Alguna duda?,</p>
          <Link
            style={{
              marginLeft: "5px",
              textDecoration: "none",
              color: "#24827C",
            }}
            to="/AyudaYSoporte"
          >
            <strong>Contáctanos</strong>
          </Link>
        </div>
      </div>
    </div>
  );
};
