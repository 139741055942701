import React, { useContext, useState, useEffect } from "react";
import axios from "axios";
import "../../../../styles/SolicitudActivaciones.css";
import { Context } from "../../../store/appContext";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Link } from "react-router-dom";

const MySwal = withReactContent(Swal);

export const DTplacaTerminalEmpresa = () => {
  const { store } = useContext(Context);
  const [records, setRecords] = useState({ control_entrada_vehicular: [] });
  const [recordss, setRecordss] = useState([]);
  const [pasajeros, setPasajeros] = useState([]);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    numero_listin: "",
    fecha_de_viaje: "",
    tipo_servicio: "",
    photo_cara_link: "",
    photo_cara_link_2: "",
    unidad: "",
    url_pdf: "",
    placa: "",
    numero_puestos: "",
    destino: "",
    anden: "",
    destino_final: "",
    hora_salida: "",
    ruta: "",
    empresa: "",
    rif: "",
    conductor_1: "",
    cedula_1: "",
    nombre_1: "",
    conductor_2: "",
    cedula_2: "",
    nombre_2: "",
    vista: "",
    activationId: "",
    conductor_1_id: "",
    conductor_2_id: "",
    empresaId: "",
    km_destino_final: 0.0,
    url_pdf: "",
  });

  const [selectedIds, setSelectedIds] = useState([]);
  const [activationIdd, setActivationIdd] = useState({});

  const handleCheckboxChange = (id) => {
    setSelectedIds((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((selectedId) => selectedId !== id)
        : [...prevSelected, id]
    );
  };

  const handleDelete = async () => {
    if (selectedIds.length === 0) {
      Swal.fire("Atención", "Selecciona al menos un pasajero", "warning");
      return;
    }

    // Mostrar mensaje de confirmación antes de eliminar
    const result = await Swal.fire({
      title: "¿Estás seguro?",
      text: "Esta acción eliminará permanentemente los pasajeros seleccionados.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sí, eliminar",
      cancelButtonText: "Cancelar",
      reverseButtons: true,
    });

    if (!result.isConfirmed) {
      return; // Si el usuario cancela, no hacemos nada
    }

    // Mostrar alerta de carga mientras se eliminan los pasajeros
    Swal.fire({
      title: "Eliminando pasajeros...",
      text: "Por favor espera",
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });

    setLoading(true);

    try {
      await axios.delete(process.env.BACKEND_URL + "/api/pasajeros/eliminar", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${store.token}`,
        },
        data: { pasajeros_ids: selectedIds }, // Enviar datos en el body
      });

      // Actualizar la lista de pasajeros eliminando los seleccionados
      setRecords((prevRecords) => ({
        ...prevRecords,
        control_entrada_vehicular: prevRecords.control_entrada_vehicular.filter(
          (detalle) => !selectedIds.includes(detalle.id)
        ),
      }));

      setSelectedIds([]);
      fetchUsersActivation(); // 🔄 Actualizar la lista después de eliminar

      // Mostrar mensaje de éxito
      Swal.fire("Éxito", "Pasajeros eliminados correctamente", "success");
    } catch (error) {
      console.error("Error al eliminar pasajeros", error);
      Swal.fire("Error", "No se pudieron eliminar los pasajeros", "error");
    }

    setLoading(false);
  };

  const fetchTodayRecords = async () => {
    try {
      const response = await axios.get(
        process.env.BACKEND_URL + "/api/empresa/dtTerminalEmpresa_priv",
        {
          headers: {
            Authorization: `Bearer ${store.token}`,
          },
        }
      );
      setRecordss(response.data.data);
    } catch (error) {
      console.error("Error al obtener registros de hoy", error);
    }
  };

  const fetchUsersActivation = async () => {
    setLoading(true);
    setPasajeros([]);
    try {
      const response = await axios.get(
        process.env.BACKEND_URL + "/api/pasajeros/activation/" + activationIdd,
        {
          headers: {
            Authorization: `Bearer ${store.token}`,
          },
        }
      );
      setPasajeros(response.data.pasajeros);
    } catch (error) {
      console.error("Error al obtener registros de hoy", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTodayRecords();
  }, []);

  useEffect(() => {
    fetchUsersActivation();
  }, [activationIdd]);

  const formatDate = (dateString) => {
    const date = new Date(dateString); // Asegúrate de que `dateString` esté en formato válido
    const day = String(date.getUTCDate()).padStart(2, "0"); // Usa UTC para evitar problemas de zonas horarias
    const month = String(date.getUTCMonth() + 1).padStart(2, "0"); // Meses son 0 indexados
    const year = date.getUTCFullYear();
    return `${day}-${month}-${year}`;
  };

  const trasladar = () => {
    MySwal.fire({
      title: <p>Agrega una unidad faltante a tu DT!</p>,
      html: (
        <p>
          Si falta una unidad, contacta al administrador de tu Terminal para
          verificar la DT de tu empresa y agregarla.
        </p>
      ),
      icon: "warning",
      position: "center",
      showConfirmButton: false,
      timer: 10000,
      timerProgressBar: true,
    });
  };

  return (
    <div
      className="container-fluid contarinerGeneralUserHomejs"
      style={{
        display: "flex",
        paddingBottom: "40px",
      }}
    >
      <div className="container asklnduiashndaijsnxansxsaas m-0 p-0">
        <div
          style={{
            textAlign: "center",
            padding: "20px",
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
          className="sdfdfgrt54w98e7we9contewe"
        >
          <div style={{ margin: "0" }}>
            <i
              className="fa-solid seraUnMarinsupre fa-bus"
              id="icon-tittle-general"
            ></i>
          </div>
          <p
            style={{ fontSize: "13px", margin: "0" }}
            className="asdjhfnerer4pdeterminal"
          >
            Agrega y personaliza las fotos de tus unidades para orientar a los
            usuarios. Si el fondo es rojo, la unidad está en contingencia y no
            figura en la DT.
          </p>
        </div>
        <div className="container-table-pds-padre">
          <div className="container-table-pds">
            <div className="container-table-pds-hijo-1">
              <div
                className="input-group-login69"
                style={{
                  fontSize: "12px",
                  maxWidth: "300px",
                }}
              >
                <input
                  className="inputDeLogin"
                  style={{ height: "33px", fontSize: "12px" }}
                  type="text"
                  value={formData.vista}
                  // value={fechaInicio}
                  // onChange={(e) => setFechaInicio(e.target.value)}
                />
                <label>Buscar</label>
              </div>

              {/* <button
                className="btn btn-info"
                style={{
                  height: "76%",
                  marginRight: "5px",
                  marginLeft: "auto",
                  color: "white",
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                  fontSize: "12px",
                }}
                onClick={() => trasladar()}
              >
                <i className="fa-solid fa-arrows-turn-to-dots"></i>
              </button> */}

              <button
                className="btn btn-danger"
                style={{
                  height: "76%",
                  marginRight: "5px",
                  color: "white",
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                  fontSize: "12px",
                }}
                onClick={handleDelete}
                disabled={loading || selectedIds.length === 0}
              >
                <i className="fa-solid fa-trash-can"></i>
              </button>
              <button
                className="btn btn-success"
                style={{
                  height: "76%",
                  marginRight: "5px",
                  color: "white",
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                  fontSize: "12px",
                }}
                onClick={() => trasladar()}
              >
                <i className="fa-solid fa-plus"></i>
              </button>
            </div>
            <div
              className="w-100 h-100"
              style={{ overflow: "auto", padding: "10px" }}
            >
              <div style={{ width: "100%" }}>
                {loading ? (
                  <div className="loading-container">
                    <i className="fa-solid fa-spinner fa-spin-pulse"></i>{" "}
                    Cargando Dts...
                  </div>
                ) : recordss && recordss.length > 0 ? (
                  <div
                    className="custom-table-container m-0"
                    style={{
                      maxHeight: "50vh",
                      display: "flex",
                      flexDirection: "column",
                      // minWidth: "500px",
                      width: "100%",
                    }}
                  >
                    <table
                      className="custom-table rounded-table m-0"
                      // style={{ minWidth: "500px" }}
                    >
                      <thead>
                        <tr>
                          <th
                            className="table-header"
                            style={{ fontSize: "14px" }}
                          >
                            <input
                              type="checkbox"
                              onChange={(e) =>
                                setSelectedIds(
                                  e.target.checked
                                    ? recordss.map((d) => d.id)
                                    : []
                                )
                              }
                              checked={selectedIds.length === recordss.length}
                            />
                          </th>
                          <th
                            className="table-header"
                            style={{ fontSize: "14px" }}
                          >
                            Placa
                          </th>
                          <th
                            className="table-header"
                            style={{ fontSize: "14px" }}
                          >
                            Estatus
                          </th>
                          {/* <th
                            className="table-header"
                            style={{ fontSize: "14px" }}
                          >
                            Destino
                          </th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {recordss.map((detalle, index) => (
                          <tr
                            className="table-row"
                            key={index}
                            style={
                              detalle.contingencia == true
                                ? { backgroundColor: "#FFE2E2" }
                                : { backgroundColor: "none" }
                            }
                          >
                            <td
                              className="table-cell"
                              style={{
                                fontSize: "14px",
                                padding: "6px",
                                display: "flex",
                                justifyContent: "space-evenly",
                                alignItems: "center",
                              }}
                            >
                              <input
                                type="checkbox"
                                checked={selectedIds.includes(detalle.id)}
                                onChange={() =>
                                  handleCheckboxChange(detalle.id)
                                }
                              />
                              {index + 1}
                            </td>
                            <td
                              className="table-cell"
                              style={{ fontSize: "12px", padding: "6px" }}
                            >
                              {detalle.placa}
                            </td>
                            <td
                              className="table-cell"
                              style={{ fontSize: "12px", padding: "6px" }}
                            >
                              {detalle.status == "ACTIVE" ? (
                                <button
                                  className="btn btn-success"
                                  style={{
                                    fontSize: "12px",
                                    pointerEvents: "none",
                                  }}
                                >
                                  ACTIVO
                                </button>
                              ) : (
                                <button
                                  className="btn btn-danger"
                                  style={{
                                    fontSize: "12px",
                                    pointerEvents: "none",
                                  }}
                                >
                                  ACTIVO
                                </button>
                              )}
                              {/* {detalle.status} */}
                            </td>
                            {/* <td
                              className="table-cell"
                              style={{ fontSize: "12px", padding: "6px" }}
                            >
                              {detalle.destino_abreviado}
                            </td> */}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <p style={{ color: "gray", fontSize: "11px" }}>
                    No tienes ninguna DT registrada, comunícate con personal
                    autorizado.
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
        <hr style={{ width: "90%" }}></hr>

        <div className="d-flex" style={{ marginTop: "12px" }}>
          <p>¿Alguna duda?,</p>
          <Link
            style={{
              marginLeft: "5px",
              textDecoration: "none",
              color: "#24827C",
            }}
            to="/AyudaYSoporte"
          >
            <strong>Contáctanos</strong>
          </Link>
        </div>
      </div>
    </div>
  );
};
