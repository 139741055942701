import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import { Context } from "../../../store/appContext";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  pdf,
} from "@react-pdf/renderer";

const MySwal = withReactContent(Swal);

export const Cierre_control_diario = () => {
  const { store } = useContext(Context);
  const [listines, setListines] = useState([]);
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [user, setUser] = useState({
    firstName: "",
    lastName: "",
    greeting: "",
  });

  return (
    <div
      className="container-fluid contarinerGeneralUserHomejs"
      style={{
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
        paddingBottom: "40px",
      }}
    >
      <div className="container asklnduiashndaijsnxansxsaas m-0 p-0">
        <div className="sdfdfgrt54w98e7we9contewe w-100">
          <div
            className="i-fewf5c9ew5ce9wdc1w4c1s sd56f4e8r97g8yrh4be8g9e4rf89er45s4"
            style={{ marginTop: "30px" }}
          ></div>
          <p>Cierre de Listines Rutas Largas</p>
        </div>
      </div>
      {/* <h2
        className="asjdansdas-d4as6489text w-100"
        style={{ zIndex: "15", marginTop: "-17vh" }}
      >
        Cierre Listines Rutas Largas
      </h2>
      <div className="container movimientoss9a87d89qwdqw98d4qw">
        <div className="aoshduiahsdkasnmdasdas">
          <button
            className="btn btn-primary"
            style={{
              height: "4vh",
              background: "rgba(128, 128, 128, 0.463)",
              margin: 0,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              border: "none",
            }}
            disabled={loading || saving}
          >
            Vista Cierre Actual
            <i className="fa-regular fa-eye" style={{ marginLeft: "8px" }}></i>
          </button>
          <button
            className="btn btn-primary"
            style={{
              height: "4vh",
              background: "#151B26",
              margin: 0,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              border: "none",
            }}
            disabled={loading || saving}
          >
            Visualiza tus Cierres
            <i
              className="fa-solid fa-list-ul"
              style={{ marginLeft: "8px" }}
            ></i>
          </button>
          <button
            className="btn btn-primary"
            style={{
              minWidth: "180px",
              height: "4vh",
              background: "#24827C",
              margin: 0,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              border: "none",
            }}
            disabled={loading || saving}
          >
            Hacer Cierre
            <i
              className="fa-solid fa-cloud-arrow-down"
              style={{ marginLeft: "8px" }}
            ></i>
          </button>
        </div> */}
      <div className="custom-table-container desktop-er65g48e9r7we984fwe594 m-0">
        <table className="custom-table">
          <thead>
            <tr className="aoshduishdihwe89f78w9e7we7we7fcw">
              <th className="table-header">Número Listín</th>
              <th className="table-header">Fecha</th>
              <th className="table-header">Empresa</th>
              <th className="table-header">Destino</th>
              <th className="table-header">Registrado por</th>
            </tr>
          </thead>
          <tbody>
            {listines.map((item) => (
              <tr
                key={item.id}
                style={{
                  backgroundColor:
                    item.estatus === "ANULADO" ? "#ffcccc" : "transparent",
                }}
              >
                <td className="table-cell">{item.n_listin}</td>
                <td className="table-cell">{item.fecha}</td>
                <td className="table-cell">{item.empresa}</td>
                <td className="table-cell">{item.destino_final}</td>
                <td className="table-cell">{item.registrado_por}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
    // </div>
  );
};
