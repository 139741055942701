import React, { useState, useContext, useEffect, useCallback } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { Context } from "../../../store/appContext.js";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
const MySwal = withReactContent(Swal);
import QRCode from "qrcode";
import { Font } from "@react-pdf/renderer";
import debounce from "lodash.debounce";

Font.register({
  family: "Quicksand",
  src: "/Quicksand-Medium.ttf", // URL de la fuente Quicksand
});

import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFDownloadLink,
  pdf,
  Image,
  PDFViewer,
} from "@react-pdf/renderer";

const formatDate2 = (dateString) => {
  const date = new Date(dateString); // Asegúrate de que `dateString` esté en formato válido
  const day = String(date.getUTCDate()).padStart(2, "0"); // Usa UTC para evitar problemas de zonas horarias
  const month = String(date.getUTCMonth() + 1).padStart(2, "0"); // Meses son 0 indexados
  const year = date.getUTCFullYear();
  return `${day}/${month}/${year}`;
};

const tableStyles = StyleSheet.create({
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 0.5,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    borderRadius: 10, // Ajusta el valor según el redondeo deseado
    overflow: "hidden",
    marginTop: 10,
  },
  table2: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 0.5,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    marginTop: 10,
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
    backgroundColor: "transparent",
  },
  tableColHeader: {
    borderStyle: "solid",
    borderWidth: 0.5,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    borderColor: "gray",
    backgroundColor: "#24827cbe",
    alignItems: "center",
    justifyContent: "center",
  },
  tableColHeader2: {
    borderStyle: "solid",
    borderWidth: 0.5,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    backgroundColor: "#8c1010",
    alignItems: "center",
    justifyContent: "center",
  },
  tableCol: {
    borderStyle: "solid",
    borderWidth: 0.5,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    alignItems: "center",
    justifyContent: "center",
  },
  tableCellHeader: {
    margin: 5,
    fontSize: 9,
    fontWeight: "bold",
    color: "white",
    textAlign: "center",
  },
  tableCell: {
    margin: 4,
    fontSize: 7,
  },
});

tableStyles.tableColHeaderOthertaxi = {
  ...tableStyles.tableColHeader,
  width: "50%", // Mayor ancho para los demás
};

tableStyles.tableColOthertaxi = {
  ...tableStyles.tableCol,
  width: "50%", // Mayor ancho para los demás
};

// Modificar anchos específicos aquí
tableStyles.tableColHeaderNumber = {
  ...tableStyles.tableColHeader,
  width: "5%", // Menor ancho para "N°"
};

tableStyles.tableColHeaderOther = {
  ...tableStyles.tableColHeader,
  width: "13.6%", // Mayor ancho para los demás
};

tableStyles.tableColHeaderOther4 = {
  ...tableStyles.tableColHeader,
  width: "32%", // Mayor ancho para los demás
};

tableStyles.tableColHeaderOtherDT = {
  ...tableStyles.tableColHeader,
  width: "9%", // Mayor ancho para los demás
};
tableStyles.tableColHeaderOtherDT2 = {
  ...tableStyles.tableColHeader,
  width: "5%", // Mayor ancho para los demás
};
tableStyles.tableColHeaderOtherDT3 = {
  ...tableStyles.tableColHeader,
  width: "7%", // Mayor ancho para los demás
};

tableStyles.tableColHeaderOtherDT4 = {
  ...tableStyles.tableColHeader,
  width: "10%", // Mayor ancho para los demás
};

tableStyles.tableColHeaderOther2 = {
  ...tableStyles.tableColHeader2,
  width: "13.6%", // Mayor ancho para los demás
};

tableStyles.tableColHeaderOther3 = {
  ...tableStyles.tableColHeader2,
  width: "32%", // Mayor ancho para los demás
};

tableStyles.tableColNumber = {
  ...tableStyles.tableCol,
  width: "5%", // Menor ancho para "N°"
};

tableStyles.tableColOther = {
  ...tableStyles.tableCol,
  width: "13.6%", // Mayor ancho para los demás
};
tableStyles.tableColOther2 = {
  ...tableStyles.tableCol,
  width: "32%", // Mayor ancho para los demás
};

tableStyles.tableColOtherDT = {
  ...tableStyles.tableCol,
  width: "9%", // Mayor ancho para los demás
};
tableStyles.tableColOtherDT2 = {
  ...tableStyles.tableCol,
  width: "5%", // Mayor ancho para los demás
};
tableStyles.tableColOtherDT4 = {
  ...tableStyles.tableCol,
  width: "10%", // Mayor ancho para los demás
};

tableStyles.tableColHeaderOthertaxi2 = {
  ...tableStyles.tableColHeader,
  width: "83.33333%",
  color: "white",
};
tableStyles.tableColHeaderOthertaxi22 = {
  ...tableStyles.tableColHeader,
  width: "16.66666%",
  color: "white",
};
tableStyles.tableColHeaderOthertaxi3 = {
  ...tableStyles.tableColHeader,
  width: "100%", // Mayor ancho para los demás
};

tableStyles.tableColOthertaxi2 = {
  ...tableStyles.tableCol,
  width: "33.3%", // Mayor ancho para los demás
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    borderBottomColor: "#000",
    borderBottomWidth: 1,
    alignItems: "center",
    height: 15,
    margin: 0,
    padding: 0,
    justifyContent: "center",
    textAlign: "center",
  },
  cell: {
    fontSize: 9,
    textAlign: "center",
    borderRightColor: "#000",
    borderRightWidth: 1,
    flex: 1,
  },
  cell2: {
    fontSize: 9,
    textAlign: "center",
    borderRightColor: "#000",
    borderRightWidth: 1,
    width: 20,
    borderLeftColor: "#000",
    borderLeftWidth: 1,
  },
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    textAlign: "center",
  },
  logo: {
    width: 90, // Ajusta el tamaño según tus necesidades
    height: 30, // Ajusta el tamaño según tus necesidades
  },
  logo2: {
    width: 100,
    height: 20,
  },
  logo22: {
    width: 100,
    height: 20,
    opacity: 0.2,
  },
  page: {
    padding: "30 30 50 30", // Ajusta el valor del margen según tus necesidades
    fontFamily: "Quicksand",
  },
  letter: {
    fontSize: 10,
    padding: 5,
  },
  letter2: {
    fontSize: 10,
  },
  ruta: {
    fontSize: 7,
    padding: 4,
  },
  bottomText: {
    position: "absolute",
    bottom: 0,
    left: 20, // Ajusta según sea necesario
    right: 20, // Ajusta según sea necesario
    textAlign: "center",
    bottom: 30,
    fontSize: 8,
  },
  footer: {
    position: "absolute",
    bottom: 10,
    left: 0,
    right: 0,
    textAlign: "center",
    fontSize: 10,
    paddingTop: 5,
    marginTop: 5,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  bottomText2: {
    position: "absolute",
    bottom: 0,
    left: 20, // Ajusta según sea necesario
    right: 20, // Ajusta según sea necesario
    textAlign: "center",
    bottom: 50,
    fontSize: 11,
    fontWeight: "bold",
  },
  backgroundImage: {
    minwidth: "3508px", // Hace que la imagen ocupe todo el ancho de la página
    height: "auto", // Mantiene la proporción de la imagen, ajustando la altura proporcional al ancho
    position: "absolute", // Asegura que la imagen se quede en el fondo
    top: 0, // Se pega a la parte superior
    left: 0, // Se pega a la parte izquierda
    right: 0,
  },
  tiltedText: {
    fontSize: 70,
    transform: "rotate(-15deg)", // Inclina el texto hacia la izquierda
    position: "absolute",
    top: 120, // Ajusta la posición vertical
    left: 30, // Ajusta la posición horizontal
  },
  encima: {
    zIndex: 999999,
  },
});

const formatDate63 = (timestamp) => {
  // Dividir fecha y zona horaria
  const [datePart, timePart] = timestamp.split("T");
  const [year, month, day] = datePart.split("-");
  const [time, offset] = timePart.split("-");

  // Extraer la hora, minutos y segundos
  const [hours, minutes] = time.split(":");

  // Formatear en dd/mm/yyyy hh:mm
  return `${day}/${month}/${year} ${hours}:${minutes}`;
};

const Boleto = ({ formData, nombre_apellido_user, timestamp_ve }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          width: "100%",
        }}
      >
        <View
          style={{
            width: "505px",
            height: "673px",
            border: "1px solid gray",
            borderRadius: "10px",
            padding: "17px",
          }}
        >
          <View
            style={{
              position: "relative",
              width: "472px",
              height: "307px",
              padding: "4px",
            }}
          >
            <Image
              src="https://res.cloudinary.com/dqt0d66h7/image/upload/v1742837583/Fondos%20PDF/mahjz00ne3wmfiirgvpi.png"
              style={{ width: "472px", height: "307px", position: "absolute" }}
            />
            <View style={styles.tiltedText}>
              <Text style={{ color: "#c2c2c255" }}>{formData.code_boleto}</Text>
            </View>
            <View
              style={{
                width: "100%",
                height: "100%",
                position: "relative",
                display: "flex",
                alignItems: "flex-end",
                zIndex: "999999",
              }}
            >
              <Text style={{ fontSize: "10px", marginRight: "164px" }}>
                Localizador:{" "}
                <Text style={{ fontWeight: "bold" }}>
                  {formData.code_boleto}
                </Text>
              </Text>
              <View
                style={{
                  backgroundColor: "white",
                  position: "absolute",
                  borderRadius: "10px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "5px",
                  width: "88px",
                  height: "88px",
                  zIndex: 999,
                }}
              >
                <Image
                  src={
                    "https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=" +
                    formData.code_boleto
                  } // Usa la URL construida
                  style={{ width: "100%", height: "100%" }}
                />
              </View>
              <View
                style={{
                  marginTop: "40px",
                  marginLeft: "23px",
                  fontSize: "10px",
                  display: "flex",
                  alignItems: "flex-start",
                  width: "97%",
                }}
              >
                <Text>{formData.first_name + formData.last_name}</Text>
                <Text>Identificación: {formData.identificacion_numero}</Text>
              </View>
              <View
                style={{
                  marginTop: "13px",
                  marginLeft: "23px",
                  fontSize: "10px",
                  display: "flex",
                  alignItems: "flex-start",
                  width: "97%",
                }}
              >
                <View
                  style={{
                    width: "382px",
                    height: "182px",
                    border: "1px solid #bfbfbf",
                    padding: "10px",
                    borderRadius: "10px",
                  }}
                >
                  <View
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                      flexDirection: "row",
                    }}
                  >
                    <Text>Origen: TLB - Terminal La Bandera</Text>
                    <Text>
                      {formatDate2(formData.fecha_de_viaje) +
                        " " +
                        formData.hora_de_salida}
                    </Text>
                  </View>
                  <View
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                      flexDirection: "row",
                    }}
                  >
                    <Text>
                      Destino:{" "}
                      {formData.destino_code + " - " + formData.destino}
                    </Text>
                    <Text>
                      {(() => {
                        const velocidadPromedio = 68; // km/h
                        const tiempoHoras =
                          formData.km_distancia / velocidadPromedio;

                        // SOLUCIÓN DEFINITIVA PARA EL ERROR DE FECHA
                        const crearFechaSegura = (fechaStr, horaStr) => {
                          try {
                            // Primero intentamos con formato ISO completo
                            const fechaCompleta = `${fechaStr}T${horaStr}`;
                            const fechaIntento1 = new Date(fechaCompleta);
                            if (!isNaN(fechaIntento1.getTime()))
                              return fechaIntento1;

                            // Si falla, intentamos con formato local (para navegadores más antiguos)
                            const [year, month, day] = fechaStr.split("-");
                            const [hours, minutes] = horaStr.split(":");
                            const fechaIntento2 = new Date(
                              year,
                              month - 1,
                              day,
                              hours,
                              minutes
                            );
                            if (!isNaN(fechaIntento2.getTime()))
                              return fechaIntento2;

                            // Si todo falla, usamos fecha actual con la hora proporcionada
                            console.warn(
                              "Fecha inválida, usando fecha actual con hora proporcionada"
                            );
                            const ahora = new Date();
                            const [h, m] = horaStr.split(":");
                            ahora.setHours(parseInt(h) || 0, parseInt(m) || 0);
                            return ahora;
                          } catch (e) {
                            console.error("Error al crear fecha:", e);
                            return new Date(); // Último recurso
                          }
                        };

                        const fechaHoraSalida = crearFechaSegura(
                          formData.fecha_de_viaje,
                          formData.hora_de_salida
                        );

                        const tiempoViajeMs = tiempoHoras * 60 * 60 * 1000;
                        const fechaHoraLlegada = new Date(
                          fechaHoraSalida.getTime() + tiempoViajeMs
                        );

                        return (
                          formatDate2(
                            fechaHoraLlegada.toISOString().split("T")[0]
                          ) +
                          " " +
                          fechaHoraLlegada.toLocaleTimeString("es-ES", {
                            hour: "2-digit",
                            minute: "2-digit",
                            hour12: false,
                          })
                        );
                      })()}
                    </Text>
                  </View>

                  <View
                    style={{
                      width: "100%",
                      marginTop: "10px",
                      marginBottom: "10px",
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <Text style={{ marginRight: "20px" }}>
                      Bus-Placa: {formData.placa}
                    </Text>
                    <Text>Unidad: {formData.numero_de_unidad}</Text>
                  </View>
                  <View
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Text style={{ marginRight: "20px" }}>
                      Empresa de Transporte: {formData.nombre_empresa}
                    </Text>
                    <Text>RIF: {formData.rif_empresa}</Text>
                    <Text>Taquilla: {formData.taquilla}</Text>
                    <Text style={{ marginRight: "20px" }}>
                      Ruta: {formData.ruta}
                    </Text>
                  </View>
                </View>
              </View>
              <View
                style={{
                  fontSize: "10px",
                  marginTop: "7px",
                  width: "97%",
                  display: "flex",
                  alignItems: "flex-start",
                }}
              >
                <Text>Emitido: {formatDate63(timestamp_ve)} - Nombre</Text>
              </View>
            </View>
          </View>
          <View>
            <Image
              src="https://res.cloudinary.com/dqt0d66h7/image/upload/v1742837770/Fondos%20PDF/wambvhiwx4btibonbjjc.png"
              style={{ width: "472px", height: "322px", marginTop: "10px" }}
            />
          </View>
        </View>
        <Text
          style={{
            marginTop: "5px",
            fontWeight: "bold",
            fontSize: "10px",
            color: "#FF0000",
          }}
        >
          Mantenga la Integridad de toda la hoja, sin cortar ninguna de las
          zonas impresas
        </Text>
      </View>

      <View style={styles.footer} fixed>
        <Image
          src="https://res.cloudinary.com/dqt0d66h7/image/upload/v1738835293/Fondos%20PDF/d9fqduuf6i7lely21fnx.png"
          style={styles.logo22}
        />
        <View style={{ display: "flex", flexDirection: "row" }}>
          <Text style={{ opacity: 0.2 }}>
            www.intravialca-ve.com - Página:{" "}
          </Text>
          <Text
            render={({ pageNumber, totalPages }) =>
              `${pageNumber} / ${totalPages}`
            }
            fixed
          />
        </View>
      </View>
    </Page>
  </Document>
);

export const RegistroPasajeroEmpresa = () => {
  const navigate = useNavigate();
  const { store } = useContext(Context);
  const [loading, setLoading] = useState({
    photo_cara_link: false,
    photo_licencia_link: false,
  });
  const [isSubmitting, setIsSubmitting] = useState(false); // Nuevo estado para controlar el estado de envío
  const [empresas, setEmpresas] = useState([]); // Nuevo estado para controlar el estado de envío
  const [cedula, setCedula] = useState(true);
  const [TieneNinos, setTieneNinos] = useState(false);
  const [recordss, setRecordss] = useState([]);
  const [nacionalidad, setNacionalidad] = useState([]);
  const [buscarUsuario, setBuscarUsuario] = useState({
    nacionalidad: "V",
    identificacion_numero: "",
    cedula: cedula,
  });

  const [formDataUsuarioCedula, setFormDataUsuarioCedula] = useState({
    activation_id: "",
    vista_activacion: "",
    sexo: "",
    first_name: "",
    last_name: "",
    nacionalidad: "", // Se asignará después con useEffect
    identificacion_numero: "",
    fecha_nacimiento: "",
    correo_electronico: "",
    cedula: cedula,
    condicion_usuario: "NINGUNA",
    ninos: TieneNinos,
    pdf_url: "",
    codigo: "",
    destino: "",
    destino_code: "",
    code_boleto: "",
    pdf_url_boleto: "",
    destinos_disponibles: [],
    fecha_de_viaje: "",
    hora_de_salida: "",
    nombre_empresa: "",
    rif_empresa: "",
    placa: "",
    numero_de_unidad: "",
    taquilla: "",
    km_distancia: "",
    ruta: "",
  });
  const [mensajeBusqueda, setMensajeBusqueda] = useState("");
  const [mensajeBusquedaColor, setMensajeBusquedaColor] = useState("");

  // Actualizar formData cuando buscarUsuario cambie
  useEffect(() => {
    setFormDataUsuarioCedula((prev) => ({
      ...prev,
      nacionalidad: buscarUsuario.nacionalidad,
      identificacion_numero: buscarUsuario.identificacion_numero,
    }));
  }, [buscarUsuario]);

  const [ninos, setNinos] = useState([]);
  const [destino, setDestino] = useState([]);

  const handlePDFUpload = async (file, folderPath) => {
    try {
      // Solicitar firma y número de listín al backend
      const response = await axios.post(
        process.env.BACKEND_URL + "/api/generar_firma_cloudinary_boleto",
        {},
        {
          headers: { Authorization: "Bearer " + store.token },
        }
      );

      const {
        signature,
        timestamp,
        timestamp_ve,
        api_key,
        cloud_name,
        email_user,
        nombre_apellido_user,
        boleto_code,
        folder,
      } = response.data;

      // Generar el PDF con el número de listín
      const pdfFile = await generatePDF(
        boleto_code,
        nombre_apellido_user,
        email_user,
        timestamp_ve
      );

      // Crear FormData para subir a Cloudinary
      const formData = new FormData();
      formData.append("file", pdfFile);
      formData.append("timestamp", timestamp);
      formData.append("signature", signature);
      formData.append("api_key", api_key);
      formData.append("folder", folder);

      // Subir el PDF a Cloudinary
      const uploadResponse = await axios.post(
        `https://api.cloudinary.com/v1_1/${cloud_name}/auto/upload`,
        formData
      );

      console.log(uploadResponse.data.secure_url);
      // Retornar la URL del PDF subido y el codigo del boleto
      return { pdfUrl: uploadResponse.data.secure_url, boleto_code };
    } catch (error) {
      console.error("Error al subir el PDF:", error);
      throw error;
    }
  };

  const generatePDF = async (
    boleto_code,
    nombre_apellido_user,
    email_user,
    timestamp_ve
  ) => {
    try {
      // Actualizamos el formData con el número del listín recibido
      const updatedFormData = {
        ...formDataUsuarioCedula,
        code_boleto: boleto_code,
      };

      let pdfBlob; // Declaramos la variable fuera del bloque if/else

      console.log(updatedFormData);

      pdfBlob = await pdf(
        <Boleto
          formData={updatedFormData}
          nombre_apellido_user={nombre_apellido_user}
          timestamp_ve={timestamp_ve}
        />
      ).toBlob();

      // Convertir el PDF Blob a File para subirlo
      return new File([pdfBlob], `${boleto_code}.pdf`, {
        type: "application/pdf",
      });
    } catch (error) {
      console.error("Error al generar el PDF:", error);
      throw error;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true); // Desactiva el botón

    const camposObligatorios = [
      "sexo",
      "first_name",
      "last_name",
      "identificacion_numero",
      "fecha_nacimiento",
      "correo_electronico",
      "activation_id",
    ];

    const nombresCampos = {
      sexo: "Sexo",
      first_name: "Primer nombre",
      last_name: "Apellido",
      identificacion_numero: "Número de identificación",
      fecha_nacimiento: "Fecha de nacimiento",
      correo_electronico: "Correo electrónico",
      activation_id: "Activación", // Aquí se reemplaza por el nombre deseado
    };

    const camposVacios = camposObligatorios.filter((campo) => {
      const valor = formDataUsuarioCedula[campo];
      // Verificamos si el valor no es una cadena o está vacío
      return !valor || typeof valor !== "string" || !valor.trim();
    });

    // Convertimos los nombres de los campos vacíos a su versión en español
    const camposVaciosTraducidos = camposVacios.map(
      (campo) => nombresCampos[campo] || campo
    );

    if (camposVaciosTraducidos.length > 0) {
      mostrarAlerta2(
        `Faltan datos obligatorios: ${camposVaciosTraducidos.join(", ")}`
      );
      setIsSubmitting(false);
      return;
    }

    const { pdfUrl, boleto_code } = await handlePDFUpload();

    if (!pdfUrl || !boleto_code) {
      throw new Error("No se pudo generar o subir el PDF. Intenta nuevamente.");
    }

    try {
      await axios.post(
        process.env.BACKEND_URL + "/api/registerPassenger",
        {
          ...formDataUsuarioCedula,
          ninos: ninos,
          pdf_url: pdfUrl,
          boleto_code: boleto_code, // Asegurar que se envía la lista de niños
        },
        {
          headers: {
            Authorization: "Bearer " + store.token,
          },
        }
      );
      setFormDataUsuarioCedula({
        activation_id: "",
        vista_activacion: "",
        sexo: "",
        first_name: "",
        last_name: "",
        nacionalidad: "",
        identificacion_numero: "",
        fecha_nacimiento: "",
        correo_electronico: "",
        cedula: cedula,
        condicion_usuario: "NINGUNA",
        ninos: TieneNinos,
        pdf_url: "",
        codigo: "",
        destino: "",
        destino_code: "",
        code_boleto: "",
        pdf_url_boleto: "",
        destinos_disponibles: [],
        fecha_de_viaje: "",
        hora_de_salida: "",
        nombre_empresa: "",
        rif_empresa: "",
        placa: "",
        numero_de_unidad: "",
        taquilla: "",
        km_distancia: "",
        ruta: "",
      });
      setBuscarUsuario({
        nacionalidad: "V",
        identificacion_numero: "",
        cedula: cedula,
      });
      setCedula(cedula);
      setNinos([]);
      mostrarAlerta1();
    } catch (error) {
      const errorMessage =
        error.response?.data?.message ||
        "Error desconocido al registrar al usuario";
      mostrarAlerta2(errorMessage);

      console.error("Error al registrar", error);
    } finally {
      setIsSubmitting(false); // Reactiva el botón después del envío
    }
  };

  const handleSeleccionNino = (index) => {
    setNinos((prev) =>
      prev.map((nino, idx) =>
        idx === index ? { ...nino, va_a_viajar: !nino.va_a_viajar } : nino
      )
    );
  };

  // Seleccionar/Deseleccionar todos los niños
  const handleSeleccionarTodos = () => {
    const allSelected = ninos.every((nino) => nino.va_a_viajar);
    setNinos((prev) =>
      prev.map((nino) => ({ ...nino, va_a_viajar: !allSelected }))
    );
  };

  const openAgregarNinoModal = () => {
    MySwal.fire({
      title: "Agregar Niño",
      html: (
        <div style={{ marginTop: "10px" }}>
          <div className="aoshduiahsdkasnmdasdas">
            <div className="input-group-login2">
              <input
                id="first_name"
                type="text"
                className="inputDeLogin"
                required
              />
              <label>Primer Nombre</label>
            </div>
            <div className="input-group-login2">
              <input
                required
                id="last_name"
                type="text"
                className="inputDeLogin"
              />
              <label>Primer Apellido</label>
            </div>
          </div>
          <div className="aoshduiahsdkasnmdasdas">
            <div className="input-group-login2">
              <select id="sexo" className="inputDeLogin" required>
                <option value="" disabled>
                  Selecciona el Sexo
                </option>
                <option value="M">Masculino</option>
                <option value="F">Femenino</option>
              </select>
              <label>Sexo</label>
            </div>
            <div className="input-group-login2">
              <input
                id="fecha_nacimiento"
                type="date"
                className="inputDeLogin"
                required
              />
              <label>Fecha Nacimiento</label>
            </div>
          </div>
        </div>
      ),
      preConfirm: () => {
        const first_name = document.getElementById("first_name").value.trim();
        const last_name = document.getElementById("last_name").value.trim();
        const sexo = document.getElementById("sexo").value;
        const fecha_nacimiento =
          document.getElementById("fecha_nacimiento").value;

        if (!first_name || !last_name || !sexo || !fecha_nacimiento) {
          Swal.showValidationMessage("Todos los campos son obligatorios.");
          return false;
        }

        return {
          first_name,
          last_name,
          sexo,
          fecha_nacimiento,
        };
      },
      confirmButtonText: "Agregar Niño",
      showCancelButton: true,
      cancelButtonText: "Cancelar",
      cancelButtonColor: "#d33",
    }).then((result) => {
      if (result.isConfirmed) {
        setNinos((prevNinos) => [...prevNinos, result.value]);
      }
    });
  };

  const fetchTodayRecords = async () => {
    try {
      const response = await axios.get(
        process.env.BACKEND_URL + "/api/empresas/get_activations_future",
        {
          headers: {
            Authorization: `Bearer ${store.token}`,
          },
        }
      );
      setRecordss(response.data.activations);
    } catch (error) {
      console.error("Error al obtener registros de hoy", error);
    }
  };

  const fetchUser = async (e) => {
    if (e) e.preventDefault();
    setIsSubmitting(true); // Desactiva el botón

    const data = {
      nacionalidad: buscarUsuario.nacionalidad || "",
      identificacion_numero: buscarUsuario.identificacion_numero || "",
      cedula: cedula,
    };

    try {
      const response = await axios.post(
        process.env.BACKEND_URL + "/api/buscarUsuario/empresa/seguro",
        data,
        {
          headers: {
            Authorization: `Bearer ${store.token}`,
          },
        }
      );

      // Asegurar que la respuesta tiene datos y extraer el primer usuario del array
      const usuario = response.data.usuario[0];

      setFormDataUsuarioCedula((prevState) => ({
        ...prevState,
        sexo: usuario.sexo || "",
        first_name: usuario.first_name || "",
        last_name: usuario.last_name || "",
        fecha_nacimiento: usuario.fecha_nacimiento || "",
        correo_electronico: usuario.correo_electronico || "",
        identificacion_numero: cedula
          ? usuario.cedula || ""
          : usuario.pasaporte || "",
        nacionalidad: cedula
          ? usuario.nacionalidad_residencia || ""
          : usuario.nacionalidad || "",
      }));
      const ninosConEstado = (usuario.ninos || []).map((nino) => ({
        ...nino,
        va_a_viajar: false,
      }));

      setNinos(ninosConEstado);

      setMensajeBusqueda("Usuario Encontrado!");
      setMensajeBusquedaColor("green");
    } catch (error) {
      const errorMessage =
        error.response?.data?.message ||
        "Error desconocido al traerse al usuario";
      setMensajeBusqueda("Usuario no encontrado, registra!");
      setMensajeBusquedaColor("red");

      console.error("Error al obtener registros de usuario:", error);
    } finally {
      setIsSubmitting(false); // Reactiva el botón después del envío
    }
  };

  const debouncedFetchUser = useCallback(debounce(fetchUser, 500), [
    buscarUsuario,
  ]);

  // Cada vez que el número de identificación cambie, se dispara la búsqueda
  useEffect(() => {
    if (buscarUsuario.identificacion_numero.trim() !== "") {
      debouncedFetchUser();
    } else {
      setMensajeBusqueda("");
    }
    return () => {
      debouncedFetchUser.cancel();
    };
  }, [buscarUsuario.identificacion_numero, debouncedFetchUser]);

  const mostrarAlertaUsuarioEcontrado = () => {
    MySwal.fire({
      title: <p>Usuario Encontrado!</p>,
      icon: "success",
      position: "center",
      showConfirmButton: false,
      timer: 1000,
      timerProgressBar: true,
    });
  };

  const fetchTodayRecords2 = async () => {
    try {
      const response = await axios.get(
        process.env.BACKEND_URL + "/api/nacionalidades_todas",
        {
          headers: {
            Authorization: `Bearer ${store.token}`,
          },
        }
      );
      setNacionalidad(response.data.nacionalidades);
    } catch (error) {
      console.error("Error al obtener registros de hoy", error);
    }
  };

  useEffect(() => {
    fetchTodayRecords();
    fetchTodayRecords2();
  }, []);

  const openActivationSelection = () => {
    MySwal.fire({
      title: (
        <p>
          <i
            className="fa-solid fa-bus"
            style={{ marginRight: "7px", fontSize: "20px" }}
          ></i>
          Mis Activaciones
        </p>
      ),
      html: `
        <input
          type="text"
          id="companySearch"
          placeholder="Buscar activación"
          class="swal2-input"
          style="width: 100%"
        />
        <div id="companyList" class="company-list">
          ${recordss
            .map(
              (activation) => `
            <button 
              class="btn company-option company-list-activation-list" 
              data-id="${activation.id}"
              data-name="${activation.empresa.nombre}"
              data-unidad="${activation.numero_de_unidad}"
              data-rif="${activation.empresa.rif}"
              data-placa="${activation.dt_terminal_empresa.placa}"
              data-salida="${activation.hora_de_salida}"
              data-destino="${activation.ruta.destino_final.destino_final}"
              data-ruta="${activation.ruta.ruta}"
              data-empresaid="${activation.empresa_terminal_id}"
              data-fecha="${activation.fecha_de_viaje}"
              data-anden="${activation.anden}"
              data-kmdistancia="${activation.ruta.destino_final.km_distancia}"
              data-taquilla="${activation.empresa.taquilla}"
              >
              Destino: ${activation.ruta.destino_final.destino_final} <br> Unidad: <strong>${activation.numero_de_unidad} </strong> - ${activation.dt_terminal_empresa.placa} <br> Pasajeros: ${activation.n_pasajeros} <br> Fecha Viaje: ${formatDate(activation.fecha_de_viaje)} - ${activation.hora_de_salida}
            </button>`
            )
            .join("")}
        </div>
      `,
      showConfirmButton: false,
      showCancelButton: true,
      cancelButtonText: "Cerrar",
      didOpen: () => {
        const companySearch = document.getElementById("companySearch");
        const companyList = document.getElementById("companyList");

        // Manejador de filtro para el input de búsqueda
        companySearch.addEventListener("input", (e) => {
          const query = e.target.value.toLowerCase();
          const filteredEmpresas = recordss.filter(
            (activation) =>
              activation.empresa.nombre.toLowerCase().includes(query) ||
              activation.numero_de_unidad.toLowerCase().includes(query)
          );
          companyList.innerHTML = filteredEmpresas
            .map(
              (activation) => `
                <button 
                  class="btn company-option company-list-activation-list" 
                  data-id="${activation.id}"
                  data-name="${activation.empresa.nombre}"
                  data-unidad="${activation.numero_de_unidad}"
                  data-rif="${activation.empresa.rif}"
                  data-placa="${activation.dt_terminal_empresa.placa}"
                  data-salida="${activation.hora_de_salida}"
                  data-destino="${activation.ruta.destino_final.destino_final}"
                  data-ruta="${activation.ruta.ruta}"
                  data-empresaid="${activation.empresa_terminal_id}"
                  data-fecha="${activation.fecha_de_viaje}"
                  data-anden="${activation.anden}"
                  data-kmdistancia="${activation.ruta.destino_final.km_distancia}"
                  data-taquilla="${activation.empresa.taquilla}"
                  >
                  Destino: ${activation.ruta.destino_final.destino_final} <br> Unidad: <strong>${activation.numero_de_unidad} </strong> - ${activation.dt_terminal_empresa.placa} <br> Pasajeros: ${activation.n_pasajeros} <br> Fecha Viaje: ${formatDate(activation.fecha_de_viaje)} - ${activation.hora_de_salida}
                </button>`
            )
            .join("");

          // Agregar listeners a cada opción filtrada
          const buttons = companyList.querySelectorAll(".company-option");
          buttons.forEach((button) => {
            button.addEventListener("click", () => {
              const activationId = button.dataset.id;
              const empresa = button.dataset.name;
              const numero_unidad = button.dataset.unidad;
              const rif = button.dataset.rif;
              const placa = button.dataset.placa;
              const hora_de_salida = button.dataset.salida;
              const destino_final = button.dataset.destino;
              const ruta = button.dataset.ruta;
              const empresaId = button.dataset.empresaid;
              const fecha_de_viaje = button.dataset.fecha;
              const anden = button.dataset.anden;
              const km_distancia = button.dataset.kmdistancia;
              const taquilla = button.dataset.taquilla;
              selectCompany(
                activationId,
                empresa,
                numero_unidad,
                rif,
                placa,
                hora_de_salida,
                destino_final,
                ruta,
                empresaId,
                fecha_de_viaje,
                anden,
                km_distancia,
                taquilla
              ); // Pasamos el ID y el nombre
            });
          });
        });

        // Listener para opciones de empresa inicialmente
        const buttons = companyList.querySelectorAll(".company-option");
        buttons.forEach((button) => {
          button.addEventListener("click", () => {
            const activationId = button.dataset.id;
            const empresa = button.dataset.name;
            const numero_unidad = button.dataset.unidad;
            const rif = button.dataset.rif;
            const placa = button.dataset.placa;
            const hora_de_salida = button.dataset.salida;
            const destino_final = button.dataset.destino;
            const ruta = button.dataset.ruta;
            const empresaId = button.dataset.empresaid;
            const fecha_de_viaje = button.dataset.fecha;
            const anden = button.dataset.anden;
            const km_distancia = button.dataset.kmdistancia;
            const taquilla = button.dataset.taquilla;
            selectCompany(
              activationId,
              empresa,
              numero_unidad,
              rif,
              placa,
              hora_de_salida,
              destino_final,
              ruta,
              empresaId,
              fecha_de_viaje,
              anden,
              km_distancia,
              taquilla
            ); // Pasamos el ID y el nombre
          });
        });
      },
    });
  };

  const selectCompany = (
    activationId,
    empresa,
    numero_de_unidad,
    rif,
    placa,
    hora_de_salida,
    destino_final,
    ruta,
    empresaId,
    fecha_de_viaje,
    anden,
    km_distancia,
    taquilla
  ) => {
    const selectedActivation = recordss.find(
      (activation) => activation.id === activationId
    );
    setFormDataUsuarioCedula({
      ...formDataUsuarioCedula,
      vista_activacion:
        numero_de_unidad + " - " + placa + " - " + destino_final,
      activation_id: activationId,
      destinos_disponibles: selectedActivation.destinos_disponibles,
      fecha_de_viaje: fecha_de_viaje,
      hora_de_salida: hora_de_salida,
      nombre_empresa: empresa,
      rif_empresa: rif,
      placa: placa,
      numero_de_unidad: numero_de_unidad,
      taquilla: taquilla,
      ruta: ruta,
    });

    MySwal.close(); // Cerrar la ventana de SweetAlert
  };

  const handleChange69 = (e) => {
    setTieneNinos(e.target.value === "true"); // Convierte el string "true"/"false" a booleano
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === "destino") {
      const selectedDestino = formDataUsuarioCedula.destinos_disponibles.find(
        (dest) => dest.code === value
      );

      setFormDataUsuarioCedula((prevData) => ({
        ...prevData,
        destino: selectedDestino ? selectedDestino.destino : "",
        destino_code: value, // Guardamos solo el código en el `value`
        km_distancia: selectedDestino.km_distancia,
      }));
    } else {
      setFormDataUsuarioCedula((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleChange2 = (e) => {
    const { name, value } = e.target;

    setBuscarUsuario((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const mostrarAlerta1 = () => {
    MySwal.fire({
      title: <p>Pasajero Registrado con Éxito</p>,
      icon: "success",
      position: "center",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
    });
  };

  const mostrarAlerta2 = (errorMessage) => {
    MySwal.fire({
      title: <p>Error al registrar al Pasajero.</p>,
      html: <p>{errorMessage}</p>,
      icon: "error",
      position: "center",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
    });
  };

  const handleClick = () => {
    const newCedulaValue = !cedula;
    setCedula(newCedulaValue);
    setCedula(newCedulaValue); // Cambia el estado entre true y false
    setFormDataUsuarioCedula({
      activation_id: "",
      vista_activacion: "",
      sexo: "",
      first_name: "",
      last_name: "",
      nacionalidad: "",
      identificacion_numero: "",
      fecha_nacimiento: "",
      correo_electronico: "",
      condicion_usuario: "NINGUNA",
      ninos: TieneNinos,
      cedula: newCedulaValue,
      pdf_url: "",
      codigo: "",
      destino: "",
      destino_code: "",
      code_boleto: "",
      pdf_url_boleto: "",
      destinos_disponibles: [],
      fecha_de_viaje: "",
      hora_de_salida: "",
      nombre_empresa: "",
      rif_empresa: "",
      placa: "",
      numero_de_unidad: "",
      taquilla: "",
      km_distancia: "",
      ruta: "",
    });
    setBuscarUsuario({
      nacionalidad: "V",
      identificacion_numero: "",
      cedula: newCedulaValue,
    });
    setNinos([]);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString); // Asegúrate de que `dateString` esté en formato válido
    const day = String(date.getUTCDate()).padStart(2, "0"); // Usa UTC para evitar problemas de zonas horarias
    const month = String(date.getUTCMonth() + 1).padStart(2, "0"); // Meses son 0 indexados
    const year = date.getUTCFullYear();
    return `${day}-${month}-${year}`;
  };

  return (
    <div
      className="container-fluid contarinerGeneralUserHomejs"
      style={{
        display: "flex",
        paddingBottom: "40px",
      }}
    >
      {cedula ? (
        <div className="container asklnduiashndaijsnxansxsaas m-0 p-0">
          <div className="sdfdfgrt54w98e7we9contewe w-100">
            <div style={{ margin: "0", marginTop: "20px" }}>
              <i
                className="fa-solid seraUnMarinsupre fa-person-circle-plus"
                id="icon-tittle-general"
              ></i>
            </div>
            <p style={{ fontSize: "13px", margin: "0" }}>
              Registra el pasajero que tenga cédula. En caso contrario marca la
              opción de pasaporte.
            </p>
          </div>
          <div
            className="container asklnduiashndaijsnxansxsaas3"
            style={{ marginBottom: "20px" }}
          >
            <div
              className="boton-para-transicion-registro-pasajero"
              style={{ marginBottom: "20px" }}
            >
              <div className="boton-para-transicion-registro-pasajero-contenedor">
                <button
                  className={`boton-para-transicion-registro-pasajero-boton ${cedula ? "active" : ""}`}
                  onClick={handleClick}
                >
                  {console.log(formDataUsuarioCedula)}
                  Cédula
                </button>
                <button
                  className={`boton-para-transicion-registro-pasajero-boton ${!cedula ? "active" : ""}`}
                  onClick={handleClick}
                >
                  Pasaporte
                </button>
                <div
                  className={`boton-para-transicion-registro-pasajero-sombra ${
                    cedula ? "sombra-cedula" : "sombra-pasaporte"
                  }`}
                ></div>
              </div>
            </div>
            <form
              className="aoshduiahsdkasnmdasdas"
              style={{
                backgroundColor: "#77777729",
                borderRadius: "7px",
                padding: "10px",
              }}
            >
              <div className="input-group-login2">
                <select
                  className="inputDeLogin"
                  name="nacionalidad"
                  onChange={handleChange2}
                  value={buscarUsuario.nacionalidad}
                  required
                >
                  <option value="V">VENEZOLANO (V-)</option>
                  <option value="E">EXTRANJERO (E-)</option>
                </select>
                <label>Nacionalidad</label>
              </div>
              <div className="input-group-login2" style={{ maxWidth: "350px" }}>
                <input
                  className="inputDeLogin"
                  type="number"
                  name="identificacion_numero"
                  onChange={handleChange2}
                  value={buscarUsuario.identificacion_numero}
                  placeholder="Ejemplo: 12345678"
                  required
                />
                <label>Cédula</label>
                {buscarUsuario.identificacion_numero && (
                  <p
                    className="small-text"
                    style={{
                      fontSize: "0.8em",
                      marginTop: "5px",
                      marginBottom: 0,
                      textAlign: "start",
                      color: mensajeBusquedaColor,
                    }}
                  >
                    {mensajeBusqueda}
                  </p>
                )}
              </div>
              {/* <button
                className="btn btn-secondary"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "white",
                  fontSize: "16px",
                  height: "80%",
                }}
                type="submit"
                onClick={fetchUser}
                disabled={isSubmitting}
              >
                <i className="fa-solid fa-magnifying-glass"></i>
              </button> */}
            </form>
            <form
              onSubmit={handleSubmit}
              className="formularioDeLogin sd98f4e8wf4cwe987cwe984cwe w-100"
            >
              <div className="aoshduiahsdkasnmdasdas">
                <div className="input-group-login2">
                  <input
                    className="inputDeLogin"
                    type="text"
                    name="vista_activacion"
                    onClick={openActivationSelection}
                    value={formDataUsuarioCedula.vista_activacion}
                    required
                    readOnly
                  />
                  <label>Activación</label>
                </div>
                <div className="input-group-login2">
                  <select
                    className="inputDeLogin"
                    name="sexo"
                    onChange={handleChange}
                    value={formDataUsuarioCedula.sexo}
                    required
                  >
                    <option value="" disabled>
                      Selecciona
                    </option>
                    <option value="M">Masculino</option>
                    <option value="F">Femenino</option>
                  </select>
                  <label>Sexo</label>
                </div>
              </div>
              <div className="aoshduiahsdkasnmdasdas">
                <div className="input-group-login2">
                  <input
                    className="inputDeLogin"
                    type="text"
                    name="first_name"
                    required
                    onChange={handleChange}
                    value={formDataUsuarioCedula.first_name}
                    maxLength={50}
                    minLength={2}
                    style={{ textTransform: "uppercase" }}
                  />
                  <label>Primer Nombre</label>
                </div>
                <div className="input-group-login2">
                  <input
                    className="inputDeLogin"
                    type="text"
                    name="last_name"
                    required
                    maxLength={50}
                    onChange={handleChange}
                    minLength={2}
                    value={formDataUsuarioCedula.last_name}
                    style={{ textTransform: "uppercase" }}
                  />
                  <label>Primer Apellido</label>
                </div>
              </div>
              {/* <div className="aoshduiahsdkasnmdasdas">
                <div className="input-group-login2">
                  <select
                    className="inputDeLogin"
                    name="nacionalidad"
                    readOnly
                    onChange={handleChange}
                    value={formDataUsuarioCedula.nacionalidad}
                    required
                  >
                    <option value="" disabled>
                      Selecciona
                    </option>
                    <option value="V">VENEZOLANO</option>
                    <option value="E">EXTRANJERO</option>
                  </select>
                  <label>Nacionalidad</label>
                </div>
                <div className="input-group-login2">
                  <input
                    className="inputDeLogin"
                    type="text"
                    name="identificacion_numero"
                    onChange={handleChange}
                    value={formDataUsuarioCedula.identificacion_numero}
                    required
                    placeholder="Número"
                    readOnly
                  />
                  <label>Número de Cédula</label>
                </div>
              </div> */}
              <div className="aoshduiahsdkasnmdasdas">
                <div className="input-group-login2">
                  <input
                    className="inputDeLogin"
                    type="date"
                    name="fecha_nacimiento"
                    onChange={handleChange}
                    value={formDataUsuarioCedula.fecha_nacimiento}
                    max={new Date().toISOString().split("T")[0]}
                    required
                  />
                  <label>Fecha de Nacimiento</label>
                </div>
                <div className="input-group-login2">
                  <input
                    className="inputDeLogin"
                    type="email"
                    name="correo_electronico"
                    required
                    onChange={handleChange}
                    placeholder="Ejemplo: example@gmail.com"
                    value={formDataUsuarioCedula.correo_electronico}
                  />
                  <label>Correo Electrónico</label>
                </div>
              </div>
              <div className="aoshduiahsdkasnmdasdas">
                <div className="input-group-login2">
                  <select
                    className="inputDeLogin"
                    name="destino"
                    onChange={handleChange}
                    value={formDataUsuarioCedula.destino_code || ""} // Ahora el usuario ve el destino correcto
                    required
                  >
                    <option value="" disabled>
                      Selecciona
                    </option>
                    {formDataUsuarioCedula.destinos_disponibles.map(
                      (destino) => (
                        <option key={destino.code} value={destino.code}>
                          {destino.destino}
                        </option>
                      )
                    )}
                  </select>
                  <label>Destino</label>
                </div>
                <div className="input-group-login2">
                  <select
                    className="inputDeLogin"
                    name="condicion_usuario"
                    onChange={handleChange}
                    value={formDataUsuarioCedula.condicion_usuario}
                  >
                    <option value="NINGUNA">NINGUNA</option>
                    <option value="DISCAPACITADO">DISCAPACITADO</option>
                  </select>
                  <label>Discapacidad (con carnet)</label>
                </div>
              </div>
              <div className="aoshduiahsdkasnmdasdas">
                <div className="input-group-login2">
                  <select
                    className="inputDeLogin"
                    name="TieneNinos"
                    value={TieneNinos}
                    onChange={handleChange69}
                  >
                    <option value="true">SI</option>
                    <option value="false">NO</option>
                  </select>
                  <label>Niños no cedulados</label>
                </div>
              </div>
              {TieneNinos && (
                <div
                  className="aoshduiahsdkasnmdasdas"
                  style={{ flexDirection: "column" }}
                >
                  {ninos.length > 0 ? (
                    <div
                      style={{
                        width: "100%",
                        maxHeight: "400px",
                        overflow: "auto",
                      }}
                    >
                      <table
                        className="custom-table rounded-table"
                        style={{ minWidth: "400px" }}
                      >
                        <thead>
                          <tr>
                            <th
                              className="table-header"
                              style={{ fontSize: "11px" }}
                            >
                              {/* Checkbox para seleccionar/deseleccionar todos */}
                              <input
                                type="checkbox"
                                checked={ninos.every(
                                  (nino) => nino.va_a_viajar
                                )}
                                onChange={handleSeleccionarTodos}
                              />
                            </th>
                            <th
                              className="table-header"
                              style={{ fontSize: "11px" }}
                            >
                              Nombre
                            </th>
                            <th
                              className="table-header"
                              style={{ fontSize: "11px" }}
                            >
                              Sexo
                            </th>
                            <th
                              className="table-header"
                              style={{ fontSize: "11px" }}
                            >
                              Nacimiento
                            </th>
                            <th
                              className="table-header"
                              style={{ fontSize: "11px" }}
                            >
                              Condición
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {ninos.map((nino, index) => (
                            <tr key={index}>
                              <td className="table-cell">
                                <input
                                  type="checkbox"
                                  checked={nino.va_a_viajar}
                                  onChange={() => handleSeleccionNino(index)}
                                />
                              </td>
                              <td className="table-cell">{`${nino.first_name} ${nino.last_name}`}</td>
                              <td className="table-cell">{nino.sexo}</td>
                              <td className="table-cell">
                                {formatDate(nino.fecha_nacimiento)}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      <p
                        style={{
                          fontSize: "13px",
                          color: "red",
                          textAlign: "start",
                        }}
                      >
                        Marca unicamente los Niños que van a viajar.
                      </p>
                    </div>
                  ) : (
                    <p>No hay niños registrados.</p>
                  )}

                  <div
                    className="btn btn-secondary"
                    onClick={openAgregarNinoModal}
                  >
                    Agregar Niño
                  </div>
                </div>
              )}
              <button
                className="buttonCargadeDatosDeLoginregistromovimiento"
                type="submit"
                disabled={isSubmitting}
              >
                {isSubmitting ? "Registrando..." : "Registrar"}{" "}
                <i className="fa-solid fa-arrow-right"></i>
              </button>
            </form>
          </div>
          <hr style={{ width: "90%" }}></hr>
          <div className="d-flex" style={{ marginTop: "12px" }}>
            <p>¿Alguna duda?,</p>
            <Link
              style={{
                marginLeft: "5px",
                textDecoration: "none",
                color: "#24827C",
              }}
              to="/AyudaYSoporte"
            >
              <strong>Contáctanos</strong>
            </Link>
          </div>
        </div>
      ) : (
        <div className="container asklnduiashndaijsnxansxsaas m-0 p-0">
          <div className="sdfdfgrt54w98e7we9contewe w-100">
            <div style={{ margin: "0", marginTop: "20px" }}>
              <i
                className="fa-solid seraUnMarinsupre fa-person-circle-plus"
                id="icon-tittle-general"
              ></i>
            </div>
            <p style={{ fontSize: "13px", margin: "0" }}>
              Registra el pasajero que tenga Pasaporte. En caso contrario
              selecciona la opción Cédula.
            </p>
          </div>
          <div
            className="container asklnduiashndaijsnxansxsaas3"
            style={{ marginBottom: "20px" }}
          >
            <div
              className="boton-para-transicion-registro-pasajero"
              style={{ marginBottom: "20px" }}
            >
              <div className="boton-para-transicion-registro-pasajero-contenedor">
                <button
                  className={`boton-para-transicion-registro-pasajero-boton ${cedula ? "active" : ""}`}
                  onClick={handleClick}
                >
                  Cédula
                </button>
                <button
                  className={`boton-para-transicion-registro-pasajero-boton ${!cedula ? "active" : ""}`}
                  onClick={handleClick}
                >
                  Pasaporte
                </button>
                <div
                  className={`boton-para-transicion-registro-pasajero-sombra ${
                    cedula ? "sombra-cedula" : "sombra-pasaporte"
                  }`}
                ></div>
              </div>
            </div>
            <form
              className="aoshduiahsdkasnmdasdas"
              style={{
                alignItems: "center",
                backgroundColor: "#77777729",
                borderRadius: "7px",
                padding: "10px",
              }}
            >
              <div className="input-group-login2">
                <select
                  className="inputDeLogin"
                  name="nacionalidad"
                  onChange={handleChange2}
                  value={buscarUsuario.nacionalidad}
                  required
                >
                  <option value="">Selecciona</option>
                  {nacionalidad.map((nacionalidad, index) => {
                    return (
                      <option value={nacionalidad.id} key={nacionalidad.id}>
                        {nacionalidad.pais}
                      </option>
                    );
                  })}
                </select>
                <label>Nacionalidad</label>
              </div>
              <div className="input-group-login2" style={{ maxWidth: "350px" }}>
                <input
                  className="inputDeLogin"
                  type="text"
                  name="identificacion_numero"
                  onChange={handleChange2}
                  value={buscarUsuario.identificacion_numero}
                  placeholder="Ejemplo: A12345678"
                  required
                />
                <label>Número de Pasaporte</label>
                {buscarUsuario.identificacion_numero && (
                  <p
                    className="small-text"
                    style={{
                      fontSize: "0.8em",
                      marginTop: "5px",
                      marginBottom: 0,
                      textAlign: "start",
                      color: mensajeBusquedaColor,
                    }}
                  >
                    {mensajeBusqueda}
                  </p>
                )}
              </div>
            </form>
            <form
              onSubmit={handleSubmit}
              className="formularioDeLogin sd98f4e8wf4cwe987cwe984cwe w-100"
            >
              <div className="aoshduiahsdkasnmdasdas">
                <div className="input-group-login2">
                  <input
                    className="inputDeLogin"
                    type="text"
                    name="vista_activacion"
                    onClick={openActivationSelection}
                    value={formDataUsuarioCedula.vista_activacion}
                    required
                    readOnly
                  />
                  <label>Activación</label>
                </div>
                <div className="input-group-login2">
                  <select
                    className="inputDeLogin"
                    name="sexo"
                    onChange={handleChange}
                    value={formDataUsuarioCedula.sexo}
                    required
                  >
                    <option value="" disabled>
                      Selecciona
                    </option>
                    <option value="M">Masculino</option>
                    <option value="F">Femenino</option>
                  </select>
                  <label>Sexo</label>
                </div>
              </div>
              <div className="aoshduiahsdkasnmdasdas">
                <div className="input-group-login2">
                  <input
                    className="inputDeLogin"
                    type="text"
                    name="first_name"
                    required
                    onChange={handleChange}
                    value={formDataUsuarioCedula.first_name}
                    maxLength={50}
                    minLength={2}
                    style={{ textTransform: "uppercase" }}
                  />
                  <label>Primer Nombre</label>
                </div>
                <div className="input-group-login2">
                  <input
                    className="inputDeLogin"
                    type="text"
                    name="last_name"
                    required
                    maxLength={50}
                    onChange={handleChange}
                    minLength={2}
                    value={formDataUsuarioCedula.last_name}
                    style={{ textTransform: "uppercase" }}
                  />
                  <label>Primer Apellido</label>
                </div>
              </div>
              <div className="aoshduiahsdkasnmdasdas">
                <div className="input-group-login2">
                  <input
                    className="inputDeLogin"
                    type="date"
                    name="fecha_nacimiento"
                    onChange={handleChange}
                    value={formDataUsuarioCedula.fecha_nacimiento}
                    max={new Date().toISOString().split("T")[0]}
                    required
                  />
                  <label>Fecha de Nacimiento</label>
                </div>
                <div className="input-group-login2">
                  <input
                    className="inputDeLogin"
                    type="email"
                    name="correo_electronico"
                    maxLength={50}
                    required
                    onChange={handleChange}
                    placeholder="Ejemplo: example@gmail.com"
                    value={formDataUsuarioCedula.correo_electronico}
                  />
                  <label>Correo Electrónico</label>
                </div>
              </div>
              <div className="aoshduiahsdkasnmdasdas">
                <div className="input-group-login2">
                  <select
                    className="inputDeLogin"
                    name="destino"
                    onChange={handleChange}
                    value={formDataUsuarioCedula.destino_code || ""} // Ahora el usuario ve el destino correcto
                    required
                  >
                    <option value="" disabled>
                      Selecciona
                    </option>
                    {formDataUsuarioCedula.destinos_disponibles.map(
                      (destino) => (
                        <option key={destino.code} value={destino.code}>
                          {destino.destino}
                        </option>
                      )
                    )}
                  </select>
                  <label>Destino</label>
                </div>
                <div className="input-group-login2">
                  <select
                    className="inputDeLogin"
                    name="condicion_usuario"
                    onChange={handleChange}
                    value={formDataUsuarioCedula.condicion_usuario}
                  >
                    <option value="NINGUNA">NINGUNA</option>
                    <option value="DISCAPACITADO">DISCAPACITADO</option>
                  </select>
                  <label>Discapacidad (con carnet)</label>
                </div>
              </div>
              <div className="aoshduiahsdkasnmdasdas">
                <div className="input-group-login2">
                  <select
                    className="inputDeLogin"
                    name="TieneNinos"
                    value={TieneNinos}
                    onChange={handleChange69}
                  >
                    <option value="true">SI</option>
                    <option value="false">NO</option>
                  </select>
                  <label>Niños no cedulados</label>
                </div>
              </div>
              {TieneNinos && (
                <div
                  className="aoshduiahsdkasnmdasdas"
                  style={{ flexDirection: "column" }}
                >
                  {ninos.length > 0 ? (
                    <div
                      style={{
                        width: "100%",
                        maxHeight: "400px",
                        overflow: "auto",
                      }}
                    >
                      <table
                        className="custom-table rounded-table"
                        style={{ minWidth: "400px" }}
                      >
                        <thead>
                          <tr>
                            <th
                              className="table-header"
                              style={{ fontSize: "11px" }}
                            >
                              {/* Checkbox para seleccionar/deseleccionar todos */}
                              <input
                                type="checkbox"
                                checked={ninos.every(
                                  (nino) => nino.va_a_viajar
                                )}
                                onChange={handleSeleccionarTodos}
                              />
                            </th>
                            <th
                              className="table-header"
                              style={{ fontSize: "11px" }}
                            >
                              Nombre
                            </th>
                            <th
                              className="table-header"
                              style={{ fontSize: "11px" }}
                            >
                              Sexo
                            </th>
                            <th
                              className="table-header"
                              style={{ fontSize: "11px" }}
                            >
                              Nacimiento
                            </th>
                            <th
                              className="table-header"
                              style={{ fontSize: "11px" }}
                            >
                              Condición
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {ninos.map((nino, index) => (
                            <tr key={index}>
                              <td className="table-cell">
                                <input
                                  type="checkbox"
                                  checked={nino.va_a_viajar}
                                  onChange={() => handleSeleccionNino(index)}
                                />
                              </td>
                              <td className="table-cell">{`${nino.first_name} ${nino.last_name}`}</td>
                              <td className="table-cell">{nino.sexo}</td>
                              <td className="table-cell">
                                {formatDate(nino.fecha_nacimiento)}
                              </td>
                              <td className="table-cell">
                                {nino.condicion_usuario}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      <p
                        style={{
                          fontSize: "13px",
                          color: "red",
                          textAlign: "start",
                        }}
                      >
                        Marca unicamente los Niños que van a viajar.
                      </p>
                    </div>
                  ) : (
                    <p>No hay niños registrados.</p>
                  )}

                  <div
                    className="btn btn-secondary"
                    onClick={openAgregarNinoModal}
                  >
                    Agregar Niño
                  </div>
                </div>
              )}
              <button
                className="buttonCargadeDatosDeLoginregistromovimiento"
                type="submit"
                disabled={isSubmitting}
              >
                {isSubmitting ? "Registrando..." : "Registrar"}{" "}
                <i className="fa-solid fa-arrow-right"></i>
              </button>
            </form>
          </div>
          <hr style={{ width: "90%" }}></hr>
          <div className="d-flex" style={{ marginTop: "12px" }}>
            <p>¿Alguna duda?,</p>
            <Link
              style={{
                marginLeft: "5px",
                textDecoration: "none",
                color: "#24827C",
              }}
              to="/AyudaYSoporte"
            >
              <strong>Contáctanos</strong>
            </Link>
          </div>
        </div>
      )}
    </div>
  );
};
