import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import moment from "moment-timezone";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import "../../../../styles/RegistroActivacionesHoy.css";
import { Context } from "../../../store/appContext.js";
import { useNavigate, useParams } from "react-router-dom";

const MySwal = withReactContent(Swal);

const EstatusIcon = {
  ACTIVO: {
    icon: "fa-check",
    name: "ACTIVO",
    color: "linear-gradient(to right, #28a745, #31c353)", // Verde
  },
  INACTIVO: {
    icon: "fa-ban",
    name: "CANCELADO",
    color: "linear-gradient(to right, #dc3545, #f24d5e)", // Rojo
  },
};

export const RegistrosCpsEditRegistroDeCpsEditNuevo = () => {
  const [records, setRecords] = useState([]);
  const navigate = useNavigate();
  const [filteredRecords, setFilteredRecords] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const { store } = useContext(Context);
  const [loading, setLoading] = useState(true);

  const fetchTodayRecords = async () => {
    try {
      const response = await axios.get(
        process.env.BACKEND_URL + "/api/ver_empresas_por_terminal_con_cps",
        {
          headers: {
            Authorization: "Bearer " + store.token,
          },
        }
      );
      setRecords(response.data.empresas);
      setFilteredRecords(response.data.empresas); // Inicializa el filtrado
    } catch (error) {
      console.error("Error al obtener registros de hoy", error);
    } finally {
      setLoading(false); // Cambia a `false` al terminar la carga
    }
  };

  useEffect(() => {
    fetchTodayRecords();
  }, []);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    filterAndSortRecords(e.target.value, statusFilter, sortOrder);
  };

  const handleStatusChange = (e) => {
    setStatusFilter(e.target.value);
    filterAndSortRecords(searchTerm, e.target.value, sortOrder);
  };

  const handleSortOrderChange = (e) => {
    setSortOrder(e.target.value);
    filterAndSortRecords(searchTerm, statusFilter, e.target.value);
  };

  const filterAndSortRecords = (search, status, order) => {
    let filtered = records.filter((record) => {
      return (
        (!status || record.empresa.status === status) &&
        (!search ||
          record.empresa.nombre.toLowerCase().includes(search.toLowerCase()) ||
          record.empresa.rif.toLowerCase().includes(search.toLowerCase()))
      );
    });

    setFilteredRecords(filtered);
  };

  const handleChangeStatus = () => {
    MySwal.fire({
      title:
        "Este número representa el número de rutas totales que tiene la empresa en tu Terminal",
    });
  };

  const showRouteDetails = (empresa) => {
    // Verifica si la empresa tiene rutas
    const navigateToAgregarHoras = (rutaId) => {
      navigate(`/ruta/${rutaId}/agregar-horas`);
    };
    if (empresa.rutas.length > 0) {
      MySwal.fire({
        title: `Detalles de Rutas para ${empresa.empresa.nombre}`,
        html: (
          <div className="custom-table-container m-0">
            <table
              className="custom-table asd56vdfg65r49g8er4e8rv6w5ef4we98f4we65f4we89f7r84erf5er4"
              style={{ width: "700px" }}
            >
              <thead>
                <tr>
                  <th
                    className="table-header"
                    style={{ fontSize: "14px", minWidth: "350px" }}
                  >
                    Ruta
                  </th>
                  <th
                    className="table-header"
                    style={{ minWidth: "110px", fontSize: "14px" }}
                  >
                    Destino Final
                  </th>
                  <th
                    className="table-header"
                    style={{ minWidth: "80px", fontSize: "14px" }}
                  >
                    Parada
                  </th>
                  <th
                    className="table-header"
                    style={{ minWidth: "90px", fontSize: "14px" }}
                  >
                    Horas Terminal
                  </th>
                  <th
                    className="table-header as564w8e9f48w9ef4er98f7we89f4we9f4we"
                    style={{ minWidth: "90px", fontSize: "14px" }}
                  >
                    Horas CPS
                  </th>
                  <th
                    className="table-header"
                    style={{ minWidth: "90px", fontSize: "14px" }}
                  >
                    Estatus
                  </th>
                  <th
                    className="table-header"
                    style={{ minWidth: "180px", fontSize: "14px" }}
                  >
                    Acciones
                  </th>
                </tr>
              </thead>
              <tbody>
                {empresa.rutas.map((ruta, index) => (
                  <tr key={index} className="table-row">
                    <td className="table-cell" style={{ fontSize: "12px" }}>
                      {ruta.ruta}
                    </td>
                    <td className="table-cell" style={{ fontSize: "12px" }}>
                      {ruta.destino_final}
                    </td>
                    <td className="table-cell" style={{ fontSize: "12px" }}>
                      {ruta.parada}
                    </td>
                    <td className="table-cell" style={{ fontSize: "12px" }}>
                      {ruta.turnos && ruta.turnos.length > 0
                        ? ruta.turnos.map((turno, turnoIndex) => (
                            <div key={turnoIndex}>{turno.turno_hora_real}</div>
                          ))
                        : "No hay turnos disponibles"}
                    </td>
                    <td
                      className="table-cell as564w8e9f48w9ef4er98f7we89f4we9f4we"
                      style={{ fontSize: "12px" }}
                    >
                      {ruta.turnos_cps && ruta.turnos_cps.length > 0
                        ? ruta.turnos_cps.map((turno, turnoIndex) => (
                            <div key={turnoIndex}>{turno.hora}</div>
                          ))
                        : "No hay turnos disponibles"}
                    </td>
                    <td className="table-cell" style={{ fontSize: "12px" }}>
                      <span
                        style={{
                          color: ruta.status === "ACTIVE" ? "green" : "red",
                        }}
                      >
                        {ruta.status === "ACTIVE" ? "ACTIVO" : "INACTIVO"}
                      </span>
                    </td>
                    <td className="table-cell" style={{ fontSize: "12px" }}>
                      <button
                        className="btn btn-warning text-light"
                        onClick={() => navigateToAgregarHoras(ruta.id)}
                      >
                        Agg/Edit Horas
                      </button>
                      <button
                        className="btn btn-danger"
                        onClick={() => handleDelete(ruta.id)} // Asegúrate de pasar el ID correcto
                      >
                        Eliminar
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ),
        confirmButtonText: "Cerrar",
      });
    } else {
      MySwal.fire({
        title: `No hay rutas disponibles para ${empresa.empresa.nombre}`,
        icon: "info",
        confirmButtonText: "Cerrar",
      });
    }
  };

  const handleDelete = async (id) => {
    // Confirmación antes de eliminar
    const result = await MySwal.fire({
      title: "¿Estás seguro de que quieres eliminar este registro?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sí, eliminar",
      cancelButtonText: "Cancelar",
    });

    if (result.isConfirmed) {
      try {
        // Realizar la solicitud DELETE al backend para eliminar el registro
        await axios.delete(
          `${process.env.BACKEND_URL}/api/eliminar_ruta_directores_terminal/${id}`,
          {
            headers: {
              Authorization: "Bearer " + store.token,
            },
          }
        );

        // Notificar al usuario que el registro fue eliminado
        MySwal.fire(
          "Eliminado",
          "El registro ha sido eliminado correctamente.",
          "success"
        );

        fetchTodayRecords();
      } catch (error) {
        console.error("Error al eliminar el registro", error);
        MySwal.fire(
          "Error",
          "Hubo un problema al eliminar el registro.",
          "error"
        );
      }
    }
  };

  return (
    <div className="container-fluid contarinerGeneralUserHomejs h-auto">
      {/* Filtro y Ordenación */}
      <div className="filter-bar p-3 sd56f4df98g4f8g9hb7er89g4ewr89e7r9we4f98ef9we4v4ds2">
        <div
          style={{ display: "flex", alignItems: "center", color: "white" }}
          className="sddv4er98f4we9cw4edivderegis"
        >
          <i
            className="fa-solid fa-magnifying-glass"
            style={{ fontSize: "20px" }}
          ></i>
          <p style={{ margin: "0", marginLeft: "7px", fontSize: "20px" }}>
            Buscar
          </p>
        </div>
        <input
          type="text"
          placeholder="Buscar por rif o nombre de Empresa"
          value={searchTerm}
          onChange={handleSearchChange}
          className="form-control mb-2"
          style={{ borderRadius: "20px", padding: "10px 15px" }}
        />
      </div>
      {loading ? (
        <div style={{ textAlign: "center", padding: "20px" }}>
          <img
            src="https://res.cloudinary.com/dirpdlfbu/image/upload/v1731494258/1/dvhl0hwbx4adb8uceymr.gif"
            alt="Cargando..."
            style={{ width: "150px" }}
          />
        </div>
      ) : (
        <div className="contianer-fluid p-0 m-0 w-100">
          <div className="container asklnduiashndaijsnxansxsaas mobile-er65g48e9r7we984fwe594 p-0">
            {filteredRecords.map((record) => {
              const statusConfig =
                EstatusIcon[record.status] || EstatusIcon.INACTIVO;

              return (
                <div
                  key={record.id}
                  className="container asklnduiashndaijsnxansxsaas"
                >
                  <div className="container asune-rv56er48v9wec-s2 mgjmnh98-sdf9w8e7f9wewefwes">
                    <div className="container aoshdnuqiwhnd897">
                      <div className="container ds897fq4w9dc9w8c4-we89c7w2">
                        <p
                          style={{
                            margin: "0",
                            fontSize: "10px",
                          }}
                          id="pderegistros"
                        >
                          {record.empresa.nombre}
                        </p>
                        <h5 style={{ marginTop: "15px" }}>
                          {record.empresa.rif}
                        </h5>
                      </div>
                      <div
                        className="container ds9f848dsfwef498we4fwd-57982"
                        onClick={() => handleChangeStatus()}
                      >
                        <div
                          className="sad8f48sd4we4g9sd8fg4ewfg48ds9fe8d"
                          style={{
                            background:
                              "linear-gradient(to right, #28a745, #31c353)",
                            padding: "6px",
                            borderRadius: "15px",
                            color: "white",
                            fontSize: "25px",
                            width: "50px",
                            height: "50px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          {record.numero_de_rutas}
                        </div>
                      </div>
                    </div>

                    <div className="container details-info">
                      <div
                        className="clickable-icon-container"
                        onClick={() => showRouteDetails(record)}
                        style={{
                          marginTop: "5px",
                          cursor: "pointer",
                        }}
                      >
                        <i
                          className="fa-solid fa-info-circle"
                          style={{ fontSize: "20px" }}
                        ></i>
                        <span style={{ marginLeft: "5px" }}>Ver detalles</span>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          {/* Vista Escritorio::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::.. */}
          <div className="custom-table-container desktop-er65g48e9r7we984fwe594 m-0">
            <table className="custom-table">
              <thead>
                <tr>
                  <th className="table-header">Taquilla</th>
                  <th className="table-header">Empresa</th>
                  <th className="table-header">RIF</th>
                  <th className="table-header">Número de Rutas</th>
                  <th className="table-header">Acciones</th>
                </tr>
              </thead>
              <tbody>
                {filteredRecords.map((record) => (
                  <tr key={record.id} className="table-row">
                    <td className="table-cell">{record.taquilla}</td>
                    <td className="table-cell">{record.empresa.nombre}</td>
                    <td className="table-cell">{record.empresa.rif}</td>
                    <td className="table-cell">
                      <span className="route-badge">
                        {record.numero_de_rutas}
                      </span>
                    </td>
                    <td className="table-cell">
                      <button
                        className="btn btn-secondary d-flex"
                        onClick={() => showRouteDetails(record)}
                        style={{
                          color: "white",
                          flexDirection: "row",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <i
                          className="fa-solid fa-circle-info"
                          style={{ fontSize: "17px", marginRight: "7px" }}
                        ></i>
                        <p className="m-0">Ver detalles</p>
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};
