import React, { useEffect, useRef, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import grapesjs from "grapesjs";
import "grapesjs/dist/css/grapes.min.css"; // Estilos de GrapesJS
import axios from "axios";
import { Context } from "../../../../store/appContext.js";
import "grapesjs-preset-newsletter";

export const EditPlantillaCorreo = () => {
  const { store } = useContext(Context);
  const { state } = useLocation(); // Obtener los datos pasados desde la vista principal
  const { template } = state || {};
  const editorRef = useRef(null); // Referencia al contenedor del editor

  const navigate = useNavigate();
  console.log("template: " + template + "\nCodigo: " + template.html_content);

  useEffect(() => {
    if (template) {
      // Inicializar GrapesJS con la plantilla cargada
      const editor = grapesjs.init({
        container: editorRef.current, // Contenedor donde se muestra el editor
        fromElement: true,
        height: "600px",
        width: "auto",
        storageManager: false, // No usar almacenamiento local
        plugins: ["gjs-preset-newsletter"], // Usar plugin para correos
        pluginsOpts: {
          "gjs-preset-newsletter": {
            modalTitleImport: "Importar HTML",
            modalTitleExport: "Exportar HTML",
          },
        },
      });

      // Cargar el contenido HTML de la plantilla
      editor.setComponents(template.html_content);

      const handleSubmit = async (e) => {
        e.preventDefault();

        // Obtener el HTML y CSS desde GrapesJS
        const bodyContent = editor.getHtml();
        const styles = editor.getCss();

        // Reconstruir el documento completo
        const updatedHtml = `
    <!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Strict//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-strict.dtd">
    <html xmlns="http://www.w3.org/1999/xhtml">
    <head>
        <meta http-equiv="Content-Type" content="text/html; charset=utf-8"/>
        <meta name="viewport" content="width=device-width, initial-scale=1, minimum-scale=1, maximum-scale=1"/>
        <meta http-equiv="X-UA-Compatible" content="IE=Edge"/>
        <title>${template.name || "Correo"}</title>
        <style type="text/css">${styles}</style> <!-- Agregamos los estilos obtenidos -->
    </head>

        ${bodyContent} <!-- Insertamos el contenido generado -->

    </html>`;

        console.log("HTML antes de enviar:", updatedHtml);

        try {
          await axios.post(
            process.env.BACKEND_URL + `/api/save_template/${template.id}`,
            { html_content: updatedHtml },
            {
              headers: {
                Authorization: "Bearer " + store.token,
              },
            }
          );
          navigate("/Plantillas");
        } catch (error) {
          console.error("Error al guardar la plantilla:", error);
        }
      };

      const saveButton = document.getElementById("save-template-btn");
      if (saveButton) {
        saveButton.addEventListener("click", handleSubmit);
      }

      return () => {
        if (saveButton) {
          saveButton.removeEventListener("click", handleSubmit);
        }
      };
    }
  }, [template, navigate]);

  return (
    <div style={{ width: "100%" }}>
      <h2>Editar Plantilla</h2>
      {template ? (
        <>
          <div>
            <h3>{template.name}</h3>
            <p>
              <strong>Asunto:</strong> {template.subject}
            </p>
          </div>

          {/* Contenedor del editor */}
          <div
            id="editor"
            ref={editorRef}
            style={{ border: "1px solid #ccc", marginTop: "20px" }}
          ></div>

          <button id="save-template-btn" style={{ marginTop: "20px" }}>
            Guardar Cambios
          </button>
        </>
      ) : (
        <p>Cargando plantilla...</p>
      )}
    </div>
  );
};
