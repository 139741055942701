import React, { useState, useEffect, useContext, Fragment } from "react";
import axios from "axios";
import { Context } from "../store/appContext";
import "../../styles/CierreListinTerminal.css";

export const Front2 = () => {
  return (
    <div className="container-fluid contarinerGeneralUserHomejs">
      <div className="container asklnduiashndaijsnxansxsaas">
        <i
          className="fa-solid fa-circle-exclamation"
          style={{ fontSize: "100px" }}
        ></i>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            textAlign: "center",
            color: "rgba(128, 128, 128, 0.697)",
          }}
        >
          <h1 style={{ color: "rgba(128, 128, 128, 0.697)" }}>Muy pronto!</h1>
          <p style={{ color: "rgba(128, 128, 128, 0.697)" }}>
            Cambia tu contraseña, personaliza tu foto de perfil, tus datos y
            mucho más
          </p>
        </div>
      </div>
    </div>
  );
};
