import React, { useContext, useState, useEffect } from "react";
import axios from "axios";
import { Context } from "../../../store/appContext";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFDownloadLink,
  pdf,
  Image,
  PDFViewer,
} from "@react-pdf/renderer";

const MySwal = withReactContent(Swal);

const tableStyles = StyleSheet.create({
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 0.5,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    borderRadius: 10, // Ajusta el valor según el redondeo deseado
    overflow: "hidden",
    marginTop: 10,
  },
  table2: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 0.5,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    marginTop: 10,
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
    backgroundColor: "transparent",
  },
  tableColHeader: {
    borderStyle: "solid",
    borderWidth: 0.5,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    borderColor: "gray",
    backgroundColor: "#24827c89",
    alignItems: "center",
    justifyContent: "center",
  },
  tableColHeader2: {
    borderStyle: "solid",
    borderWidth: 0.5,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    backgroundColor: "#8c1010",
    alignItems: "center",
    justifyContent: "center",
  },
  tableCol: {
    borderStyle: "solid",
    borderWidth: 0.5,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    alignItems: "center",
    justifyContent: "center",
  },
  tableCellHeader: {
    margin: 5,
    fontSize: 9,
    fontWeight: "bold",
    color: "white",
  },
  tableCell: {
    margin: 4,
    fontSize: 7,
  },
});

tableStyles.tableColHeaderOthertaxi = {
  ...tableStyles.tableColHeader,
  width: "50%", // Mayor ancho para los demás
};

tableStyles.tableColOthertaxi = {
  ...tableStyles.tableCol,
  width: "50%", // Mayor ancho para los demás
};

// Modificar anchos específicos aquí
tableStyles.tableColHeaderNumber = {
  ...tableStyles.tableColHeader,
  width: "5%", // Menor ancho para "N°"
};

tableStyles.tableColHeaderOther = {
  ...tableStyles.tableColHeader,
  width: "13.6%", // Mayor ancho para los demás
};

tableStyles.tableColHeaderOther4 = {
  ...tableStyles.tableColHeader,
  width: "32%", // Mayor ancho para los demás
};

tableStyles.tableColHeaderOtherDT = {
  ...tableStyles.tableColHeader,
  width: "10%", // Mayor ancho para los demás
};
tableStyles.tableColHeaderOtherDT2 = {
  ...tableStyles.tableColHeader,
  width: "5%", // Mayor ancho para los demás
};
tableStyles.tableColHeaderOtherDT3 = {
  ...tableStyles.tableColHeader,
  width: "7%", // Mayor ancho para los demás
};

tableStyles.tableColHeaderOther2 = {
  ...tableStyles.tableColHeader2,
  width: "13.6%", // Mayor ancho para los demás
};

tableStyles.tableColHeaderOther3 = {
  ...tableStyles.tableColHeader2,
  width: "32%", // Mayor ancho para los demás
};

tableStyles.tableColNumber = {
  ...tableStyles.tableCol,
  width: "5%", // Menor ancho para "N°"
};

tableStyles.tableColOther = {
  ...tableStyles.tableCol,
  width: "13.6%", // Mayor ancho para los demás
};
tableStyles.tableColOther2 = {
  ...tableStyles.tableCol,
  width: "32%", // Mayor ancho para los demás
};

tableStyles.tableColOtherDT = {
  ...tableStyles.tableCol,
  width: "10%", // Mayor ancho para los demás
};
tableStyles.tableColOtherDT2 = {
  ...tableStyles.tableCol,
  width: "5%", // Mayor ancho para los demás
};
tableStyles.tableColOtherDT3 = {
  ...tableStyles.tableCol,
  width: "7%", // Mayor ancho para los demás
};

tableStyles.tableColHeaderOthertaxi2 = {
  ...tableStyles.tableColHeader,
  width: "83.33333%",
  color: "white",
};
tableStyles.tableColHeaderOthertaxi22 = {
  ...tableStyles.tableColHeader,
  width: "16.66666%",
  color: "white",
};
tableStyles.tableColHeaderOthertaxi3 = {
  ...tableStyles.tableColHeader,
  width: "100%", // Mayor ancho para los demás
};

tableStyles.tableColOthertaxi2 = {
  ...tableStyles.tableCol,
  width: "33.3%", // Mayor ancho para los demás
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    borderBottomColor: "#000",
    borderBottomWidth: 1,
    alignItems: "center",
    height: 15,
    margin: 0,
    padding: 0,
    justifyContent: "center",
    textAlign: "center",
  },
  cell: {
    fontSize: 9,
    textAlign: "center",
    borderRightColor: "#000",
    borderRightWidth: 1,
    flex: 1,
  },
  cell2: {
    fontSize: 9,
    textAlign: "center",
    borderRightColor: "#000",
    borderRightWidth: 1,
    width: 20,
    borderLeftColor: "#000",
    borderLeftWidth: 1,
  },
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    textAlign: "center",
  },
  logo: {
    width: 90, // Ajusta el tamaño según tus necesidades
    height: 30, // Ajusta el tamaño según tus necesidades
  },
  logo2: {
    width: 100,
    height: 20,
  },
  logo22: {
    width: 100,
    height: 20,
    opacity: 0.2,
  },
  page: {
    padding: "30 30 50 30", // Ajusta el valor del margen según tus necesidades
  },
  letter: {
    fontSize: 10,
    padding: 5,
  },
  letter2: {
    fontSize: 10,
  },
  ruta: {
    fontSize: 7,
    padding: 4,
  },
  bottomText: {
    position: "absolute",
    bottom: 0,
    left: 20, // Ajusta según sea necesario
    right: 20, // Ajusta según sea necesario
    textAlign: "center",
    bottom: 30,
    fontSize: 8,
  },
  footer: {
    position: "absolute",
    bottom: 10,
    left: 0,
    right: 0,
    textAlign: "center",
    fontSize: 10,
    paddingTop: 5,
    marginTop: 5,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  bottomText2: {
    position: "absolute",
    bottom: 0,
    left: 20, // Ajusta según sea necesario
    right: 20, // Ajusta según sea necesario
    textAlign: "center",
    bottom: 50,
    fontSize: 11,
    fontWeight: "bold",
  },
  backgroundImage: {
    minwidth: "3508px", // Hace que la imagen ocupe todo el ancho de la página
    height: "auto", // Mantiene la proporción de la imagen, ajustando la altura proporcional al ancho
    position: "absolute", // Asegura que la imagen se quede en el fondo
    top: 0, // Se pega a la parte superior
    left: 0, // Se pega a la parte izquierda
    right: 0,
  },
});

const formatDate = (dateString) => {
  const date = new Date(dateString); // Asegúrate de que `dateString` esté en formato válido
  const day = String(date.getUTCDate()).padStart(2, "0"); // Usa UTC para evitar problemas de zonas horarias
  const month = String(date.getUTCMonth() + 1).padStart(2, "0"); // Meses son 0 indexados
  const year = date.getUTCFullYear();
  return `${day}-${month}-${year}`;
};

const AvisosDeCobroPendientes = ({ avisos }) => (
  <Document>
    <Page size="A4" orientation="landscape" style={styles.page}>
      <Image
        src="https://res.cloudinary.com/dqt0d66h7/image/upload/v1738763378/Fondos%20PDF/dyofo0usmdsq0rxvh4du.png"
        style={styles.backgroundImage}
      />
      <View style={styles.header} fixed>
        <Image
          src="https://res.cloudinary.com/dqt0d66h7/image/upload/v1738835212/Fondos%20PDF/pjtuji0yr4bqvzh9sj0z.png"
          style={styles.logo}
        />
        <Image
          src="https://res.cloudinary.com/dqt0d66h7/image/upload/v1738835293/Fondos%20PDF/d9fqduuf6i7lely21fnx.png"
          style={styles.logo2}
        />
      </View>
      <View>
        <View>
          <Text style={{ textAlign: "center" }}>AVISOS DE COBRO</Text>
          <Text
            style={{ textAlign: "center", fontSize: "12px", color: "gray" }}
          >
            Consulta histórico
          </Text>
        </View>
      </View>
      {/* Rutas CPS */}
      <View style={styles.section}>
        <View style={{ marginTop: 10 }}></View>
        <View style={tableStyles.table}>
          <View style={tableStyles.tableRow} fixed wrap={false}>
            <View style={tableStyles.tableColHeaderOtherDT}>
              <Text style={tableStyles.tableCellHeader}>Nº Listín</Text>
            </View>
            <View style={tableStyles.tableColHeaderOtherDT}>
              <Text style={tableStyles.tableCellHeader}>Empresa</Text>
            </View>
            <View style={tableStyles.tableColHeaderOtherDT2}>
              <Text style={tableStyles.tableCellHeader}>Unidad</Text>
            </View>
            <View style={tableStyles.tableColHeaderOtherDT}>
              <Text style={tableStyles.tableCellHeader}>Destino</Text>
            </View>
            <View style={tableStyles.tableColHeaderOtherDT3}>
              <Text style={tableStyles.tableCellHeader}>Viaje</Text>
            </View>
            <View style={tableStyles.tableColHeaderOtherDT2}>
              <Text style={tableStyles.tableCellHeader}>Bs / $</Text>
            </View>
            <View style={tableStyles.tableColHeaderOtherDT2}>
              <Text style={tableStyles.tableCellHeader}>Adulto</Text>
            </View>
            <View style={tableStyles.tableColHeaderOtherDT2}>
              <Text style={tableStyles.tableCellHeader}>3ra Edad</Text>
            </View>
            <View style={tableStyles.tableColHeaderOtherDT2}>
              <Text style={tableStyles.tableCellHeader}>Discap</Text>
            </View>
            <View style={tableStyles.tableColHeaderOtherDT2}>
              <Text style={tableStyles.tableCellHeader}>Niños</Text>
            </View>
            <View style={tableStyles.tableColHeaderOtherDT2}>
              <Text style={tableStyles.tableCellHeader}>Cortesías</Text>
            </View>
            <View style={tableStyles.tableColHeaderOtherDT3}>
              <Text style={tableStyles.tableCellHeader}>Venta(Bs)</Text>
            </View>
            <View style={tableStyles.tableColHeaderOtherDT3}>
              <Text style={tableStyles.tableCellHeader}>Listin(Bs.)</Text>
            </View>
            <View style={tableStyles.tableColHeaderOtherDT3}>
              <Text style={tableStyles.tableCellHeader}>T.Salida(Bs)</Text>
            </View>
            <View style={tableStyles.tableColHeaderOtherDT3}>
              <Text style={tableStyles.tableCellHeader}>Total(Bs)</Text>
            </View>
          </View>
          {avisos.avisos.map((avisos, index) => (
            <View
              style={[
                tableStyles.tableRow,
                {
                  backgroundColor: avisos.status_pago
                    ? "#d4edda89"
                    : "#f8d7da92",
                }, // Verde claro si pagado, rojo claro si no
              ]}
              key={index}
              wrap={false}
            >
              <View style={tableStyles.tableColOtherDT}>
                <Text style={tableStyles.tableCell}>
                  {avisos.listin.n_listin_terminal}
                </Text>
              </View>
              <View style={tableStyles.tableColOtherDT}>
                <Text style={tableStyles.tableCell}>
                  {avisos.listin.activation.empresa.nombre}
                </Text>
              </View>
              <View style={tableStyles.tableColOtherDT2}>
                <Text style={tableStyles.tableCell}>
                  {avisos.listin.activation.numero_de_unidad}
                </Text>
              </View>
              <View style={tableStyles.tableColOtherDT}>
                <Text style={tableStyles.tableCell}>
                  {
                    avisos.listin.activation.ruta.destino_final
                      .destino_final_detalle.descripcion
                  }
                </Text>
              </View>
              <View style={tableStyles.tableColOtherDT3}>
                <Text style={tableStyles.tableCell}>
                  {(avisos.total_bolivares / avisos.valor_dolar).toFixed(2)}
                </Text>
              </View>
              <View style={tableStyles.tableColOtherDT2}>
                <Text style={tableStyles.tableCell}>{avisos.valor_dolar}</Text>
              </View>
              <View style={tableStyles.tableColOtherDT2}>
                <Text style={tableStyles.tableCell}>{avisos.adultos}</Text>
              </View>
              <View style={tableStyles.tableColOtherDT2}>
                <Text style={tableStyles.tableCell}>{avisos.tercera_edad}</Text>
              </View>
              <View style={tableStyles.tableColOtherDT2}>
                <Text style={tableStyles.tableCell}>
                  {avisos.discapacitados}
                </Text>
              </View>
              <View style={tableStyles.tableColOtherDT2}>
                <Text style={tableStyles.tableCell}>
                  {avisos.ninos_4_menos}
                </Text>
              </View>
              <View style={tableStyles.tableColOtherDT2}>
                <Text style={tableStyles.tableCell}>{avisos.cortesias}</Text>
              </View>
              <View style={tableStyles.tableColOtherDT3}>
                <Text style={tableStyles.tableCell}>
                  {avisos.total_venta_bolivares}
                </Text>
              </View>
              <View style={tableStyles.tableColOtherDT3}>
                <Text style={tableStyles.tableCell}>
                  {avisos.precio_listin_bolivares}
                </Text>
              </View>
              <View style={tableStyles.tableColOtherDT3}>
                <Text style={tableStyles.tableCell}>
                  {avisos.precio_tasa_bolivares}
                </Text>
              </View>
              <View style={tableStyles.tableColOtherDT3}>
                <Text style={tableStyles.tableCell}>
                  {avisos.total_bolivares}
                </Text>
              </View>
            </View>
          ))}
          <View style={tableStyles.tableRow}>
            <View style={tableStyles.tableColHeaderOthertaxi2}>
              <Text style={tableStyles.tableCell}>TOTAL</Text>
            </View>
            <View style={tableStyles.tableColHeaderOthertaxi22}>
              <Text style={tableStyles.tableCell}>{avisos.deuda} Bs.</Text>
            </View>
          </View>
        </View>
      </View>
      <View style={styles.footer} fixed>
        <Image
          src="https://res.cloudinary.com/dqt0d66h7/image/upload/v1738835293/Fondos%20PDF/d9fqduuf6i7lely21fnx.png"
          style={styles.logo22}
        />
        <View style={{ display: "flex", flexDirection: "row" }}>
          <Text style={{ opacity: 0.2 }}>
            www.intravialca-ve.com - Página:{" "}
          </Text>
          <Text
            render={({ pageNumber, totalPages }) =>
              `${pageNumber} / ${totalPages}`
            }
            fixed
          />
        </View>
      </View>
    </Page>
  </Document>
);

export const ConsultaAvisosEntreFechas = () => {
  const { store } = useContext(Context);
  const [fechaInicio, setFechaInicio] = useState("");
  const [fechaFinal, setFechaFinal] = useState("");
  const [reporte, setReporte] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [empresas, setEmpresas] = useState([]);
  const [avisos, setAvisos] = useState([]);
  const [formData, setFormData] = useState({
    empresa_terminal_id: "",
    empresa: "",
    fecha_inicio: "",
    fecha_final: "",
    filter: "",
  });

  const fetchempresasysuscps = async () => {
    try {
      const response = await axios.get(
        process.env.BACKEND_URL + "/api/ver_empresas_terminal_solo_cps",
        {
          headers: {
            Authorization: `Bearer ${store.token}`,
          },
        }
      );
      setEmpresas(response.data.empresas_terminal);
    } catch (error) {
      MySwal.fire({
        title: "Error",
        text: "Error al cargar las empresas disponibles.",
        icon: "error",
        confirmButtonText: "Cerrar",
      });
    }
  };

  useEffect(() => {
    fetchempresasysuscps();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const openCompanySelection = () => {
    MySwal.fire({
      title: (
        <p>
          <i
            className="fa-solid fa-user-tie"
            style={{ marginRight: "7px", fontSize: "20px" }}
          ></i>
          Empresas de Transporte
        </p>
      ),
      html: `
        <input
          type="text"
          id="companySearch"
          placeholder="Buscar empresa"
          class="swal2-input"
          style="width: 100%"
        />
        <div id="companyList" class="company-list">
          ${empresas
            .map(
              (company) => `
            <button 
              class="btn company-option company-list-activation-list" 
              data-id="${company.id}"
              data-name="${company.empresa.nombre}">  <!-- Añadimos data-name con el nombre -->
              ${company.empresa.nombre} <br> ${company.empresa.rif}
            </button>`
            )
            .join("")}
        </div>
      `,
      showConfirmButton: false,
      showCancelButton: true,
      cancelButtonText: "Cerrar",
      didOpen: () => {
        const companySearch = document.getElementById("companySearch");
        const companyList = document.getElementById("companyList");

        // Manejador de filtro para el input de búsqueda
        companySearch.addEventListener("input", (e) => {
          const query = e.target.value.toLowerCase();
          const filteredEmpresas = empresas.filter(
            (company) =>
              company.empresa.nombre.toLowerCase().includes(query) ||
              company.empresa.rif.toLowerCase().includes(query)
          );
          companyList.innerHTML = filteredEmpresas
            .map(
              (company) => `
                <button 
                  class="btn company-option company-list-activation-list" 
                  data-id="${company.id}"
                  data-name="${company.empresa.nombre}">  <!-- Añadimos data-name con el nombre -->
                  ${company.empresa.nombre} <br> ${company.empresa.rif}
                </button>`
            )
            .join("");

          // Agregar listeners a cada opción filtrada
          const buttons = companyList.querySelectorAll(".company-option");
          buttons.forEach((button) => {
            button.addEventListener("click", () => {
              const companyId = button.dataset.id;
              const companyName = button.dataset.name; // Usamos el nombre de la empresa
              selectCompany(companyId, companyName); // Pasamos el ID y el nombre
            });
          });
        });

        // Listener para opciones de empresa inicialmente
        const buttons = companyList.querySelectorAll(".company-option");
        buttons.forEach((button) => {
          button.addEventListener("click", () => {
            const companyId = button.dataset.id;
            const companyName = button.dataset.name;
            selectCompany(companyId, companyName); // Pasamos el ID y el nombre
          });
        });
      },
    });
  };

  const deleteAviso = async (activationId) => {
    try {
      const result = await MySwal.fire({
        title: "Confirmar eliminación",
        text: "¿Estás seguro de que deseas eliminar este registro?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Eliminar",
      });

      if (result.isConfirmed) {
        const response = await axios.delete(
          `${process.env.BACKEND_URL}/api/eliminar_aviso_de_cobro/${activationId}`,
          {
            headers: { Authorization: `Bearer ${store.token}` },
          }
        );
        await MySwal.fire("Eliminado", response.data.message, "success");

        // Simular un evento para buscarRegistros
        const simulatedEvent = { preventDefault: () => {} };
        buscarRegistros(simulatedEvent); // Llamar la función tal como estaba definida
        return true;
      }
    } catch (error) {
      console.error("Error en la eliminación:", error);
      const errorMsg =
        error.response?.data?.message || "Error al eliminar el registro.";
      await MySwal.fire("Error", errorMsg, "error");
    }
    return false;
  };

  const selectCompany = (companyId, companyName) => {
    // Encuentra la empresa seleccionada
    const empresaSeleccionada = empresas.find(
      (empresa) => empresa.id === parseInt(companyId)
    );

    setFormData({
      ...formData,
      empresa: companyName, // Nombre de la empresa
      empresa_terminal_id: companyId, // ID de la empresa
    });

    MySwal.close(); // Cierra el modal
  };

  const buscarRegistros = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      const response = await axios.get(
        process.env.BACKEND_URL + "/api/get_avisos_cobro",
        {
          headers: { Authorization: "Bearer " + store.token },
          params: formData, // Este es el cambio, 'params' pasa los datos como query parameters
        }
      );
      setAvisos(response.data);

      Swal.fire("Éxito", "Enviado Exitosamente", "success");
      setReporte(response.data.avisos);
    } catch (error) {
      Swal.fire(
        "Error",
        error.response?.data?.message || "Error interno",
        "error"
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  const handlePreviewPDF = async () => {
    try {
      // Mostrar mensaje de carga
      Swal.fire({
        title: "Generando vista previa...",
        html: "<p>Por favor, espera mientras preparamos el PDF.</p>",
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      //   Solicitar los datos necesarios al backend
      // const response = await axios.post(
      //   process.env.BACKEND_URL +
      //     "/api/generar_firma_cloudinary_activaciones_cierre_diario",
      //   formData2,
      //   {
      //     headers: {
      //       Authorization: `Bearer ${localStorage.getItem("token")}`,
      //     },
      //   }
      // );

      const todo = avisos;

      //   Generar PDF con los datos obtenidos
      const pdfBlob = await pdf(
        <AvisosDeCobroPendientes avisos={todo} />
      ).toBlob();

      // Crear URL temporal para el PDF
      const pdfUrl = URL.createObjectURL(pdfBlob);
      window.open(pdfUrl, "_blank");

      Swal.close(); // Cerrar la ventana de carga
    } catch (error) {
      console.error("Error al generar PDF:", error);
      Swal.fire({
        title: "Error",
        text: "Ocurrió un error al generar la vista previa del PDF. Inténtalo nuevamente.",
        icon: "error",
      });
    }
  };

  const showRecordsPdfDownload = async (records) => {
    try {
      setIsLoading(true);

      Swal.fire({
        title: "Cargando...",
        html: `<p style="text-align:center; margin:20px;">Elaborando PDF...</p>`,
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      const detalles = records.detalles_por_dia;
      const totales = records.totales_generales;

      if (Array.isArray(detalles) && detalles.length > 0) {
        Swal.close();

        const formatDate = (dateString) => {
          const date = new Date(dateString); // Asegúrate de que `dateString` esté en formato válido
          const day = String(date.getUTCDate()).padStart(2, "0"); // Usa UTC para evitar problemas de zonas horarias
          const month = String(date.getUTCMonth() + 1).padStart(2, "0"); // Meses son 0 indexados
          const year = date.getUTCFullYear();
          return `${day}-${month}-${year}`;
        };

        // Generar el PDF
        const pdfBlob = await pdf(
          <EntreFechasDetailPDF
            records={detalles}
            fechaInicio={formatDate(fechaInicio)}
            fechaFinal={formatDate(fechaFinal)}
            formatDate={formatDate}
            totales={totales}
          />
        ).toBlob();

        // Crear un enlace de descarga programático
        const link = document.createElement("a");
        link.href = URL.createObjectURL(pdfBlob);
        link.download =
          formatDate(fechaInicio) +
          "-" +
          formatDate(fechaFinal) +
          "-reporteRL.pdf";
        document.body.appendChild(link);
        link.click(); // Simular clic para descargar el archivo
        document.body.removeChild(link);
      } else {
        Swal.fire({
          title: `No datos suficientes para poder generar un reporte`,
          icon: "info",
          confirmButtonText: "Cerrar",
        });
      }
    } catch (error) {
      console.error("Error al obtener datos:", error);
      Swal.fire({
        title: "Error",
        html: `<p>Error al obtener los datos. Por favor, intentelo de nuevo.</p>`,
        icon: "error",
        confirmButtonText: "Cerrar",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString); // Asegúrate de que `dateString` esté en formato válido
    const day = String(date.getUTCDate()).padStart(2, "0"); // Usa UTC para evitar problemas de zonas horarias
    const month = String(date.getUTCMonth() + 1).padStart(2, "0"); // Meses son 0 indexados
    const year = date.getUTCFullYear();
    return `${day}-${month}-${year}`;
  };

  return (
    <div
      className="container-fluid contarinerGeneralUserHomejs"
      style={{
        display: "flex",
        paddingBottom: "40px",
      }}
    >
      <div className="container asklnduiashndaijsnxansxsaas m-0 p-0">
        <div className="sdfdfgrt54w98e7we9contewe w-100">
          <div style={{ margin: "0", marginTop: "20px" }}>
            <i
              className="fa-solid seraUnMarinsupre fa-file"
              id="icon-tittle-general"
            ></i>
          </div>
          <p>Busca los avisos de cobro entre fechas.</p>
        </div>
        <div className="formularioDeLogin sd98f4e8wf4cwe987cwe984cwe w-100">
          <div
            className="container asklnduiashndaijsnxansxsaas3"
            style={{ marginBottom: "20px" }}
          >
            <form
              onSubmit={buscarRegistros}
              className="formularioDeLogin s6d54fsd89f7sd98f4we98f7wr89 w-100"
            >
              <div className="aoshduiahsdkasnmdasdas">
                <div className="input-group-login2">
                  <input
                    className="inputDeLogin"
                    type="date"
                    required
                    name="fecha_inicio"
                    value={formData.fecha_inicio}
                    onChange={handleChange}
                  />
                  <label>Fecha Inicio</label>
                </div>
                <div className="input-group-login2">
                  <input
                    className="inputDeLogin"
                    type="date"
                    required
                    name="fecha_final"
                    value={formData.fecha_final}
                    onChange={handleChange}
                  />
                  <label>Fecha Final</label>
                </div>
              </div>
              <div className="aoshduiahsdkasnmdasdas">
                <div className="input-group-login2">
                  <input
                    className="inputDeLogin"
                    type="text"
                    name="empresa"
                    onClick={openCompanySelection}
                    readOnly
                    onChange={handleChange}
                    value={formData.empresa}
                    required
                    style={{ textTransform: "uppercase" }}
                  />
                  <label>Línea de Transporte (opcional)</label>
                </div>
                <div className="input-group-login2">
                  <select
                    className="inputDeLogin"
                    name="filter"
                    value={formData.filter}
                    onChange={handleChange}
                    style={{ cursor: "pointer" }}
                  >
                    <option value="">Todos</option>
                    <option value="PAGADOS">PAGADOS</option>
                    <option value="NO_PAGOS">NO PAGADOS</option>
                  </select>
                  <label>Filtro (Opcional)</label>
                </div>
              </div>
              <button
                className="buttonCargadeDatosDeLoginregistromovimiento"
                disabled={isSubmitting}
                type="submit"
                style={{ width: "200px" }}
              >
                {isSubmitting ? "Buscando..." : "Buscar Avisos"}{" "}
                <i className="fa-solid fa-arrow-right"></i>
              </button>
            </form>
          </div>
        </div>
        <div className="d-flex" style={{ marginTop: "12px" }}>
          <p>¿Alguna duda?,</p>
          <Link
            style={{
              marginLeft: "5px",
              textDecoration: "none",
              color: "#24827C",
            }}
            to="/AyudaYSoporte"
          >
            <strong>Contáctanos</strong>
          </Link>
        </div>
        <hr style={{ width: "90%" }}></hr>
        {reporte && (
          <div style={{ padding: "10px", width: "100%" }}>
            <div
              className="text-center mt-3 d-flex"
              style={{ justifyContent: "center", alignItems: "center" }}
            >
              <button
                onClick={handlePreviewPDF}
                className="btn btn-secondary"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <i
                  className="fa-solid fa-file-pdf"
                  style={{ marginRight: "10px" }}
                ></i>
                Descargar
              </button>
            </div>
            <div
              className="custom-table-container m-0"
              style={{
                maxWidth: "100%",
                padding: "10px",
                overflow: "auto",
                maxHeight: "80vh",
              }}
            >
              <table
                className="custom-table rounded-table"
                style={{ minWidth: "600px" }}
              >
                <thead>
                  {/* Pendiente colocar background segun el estatus del aviso de cobro */}
                  <tr>
                    <th
                      className="table-header"
                      style={{ minWidth: "80px", fontSize: "11px" }}
                    >
                      Pago
                    </th>
                    <th
                      className="table-header"
                      style={{ minWidth: "80px", fontSize: "11px" }}
                    >
                      Fecha Viaje
                    </th>
                    <th
                      className="table-header"
                      style={{ minWidth: "80px", fontSize: "11px" }}
                    >
                      Valor BCV
                    </th>
                    <th
                      className="table-header"
                      style={{ minWidth: "100px", fontSize: "11px" }}
                    >
                      Número Listín
                    </th>
                    <th
                      className="table-header"
                      style={{ minWidth: "100px", fontSize: "11px" }}
                    >
                      Empresa
                    </th>
                    <th
                      className="table-header"
                      style={{ minWidth: "100px", fontSize: "11px" }}
                    >
                      Destino Final
                    </th>
                    <th className="table-header" style={{ fontSize: "11px" }}>
                      Adultos
                    </th>
                    <th className="table-header" style={{ fontSize: "11px" }}>
                      Tercera Edad
                    </th>
                    <th className="table-header" style={{ fontSize: "11px" }}>
                      Discapa-citados
                    </th>
                    <th className="table-header" style={{ fontSize: "11px" }}>
                      Niños 4 o menos
                    </th>
                    <th className="table-header" style={{ fontSize: "11px" }}>
                      Cortesias
                    </th>
                    <th className="table-header" style={{ fontSize: "11px" }}>
                      Total Pasajeros
                    </th>
                    <th
                      className="table-header"
                      style={{ minWidth: "100px", fontSize: "11px" }}
                    >
                      Total Venta (Bs.)
                    </th>
                    <th
                      className="table-header"
                      style={{ minWidth: "80px", fontSize: "11px" }}
                    >
                      Listín (Bs.)
                    </th>
                    <th
                      className="table-header"
                      style={{ minWidth: "80px", fontSize: "11px" }}
                    >
                      Tasa Salida (Bs.)
                    </th>
                    <th
                      className="table-header"
                      style={{ minWidth: "100px", fontSize: "11px" }}
                    >
                      Total (Bs.)
                    </th>
                    <th
                      className="table-header"
                      style={{ minWidth: "100px", fontSize: "11px" }}
                    >
                      Acciones
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {reporte.map((aviso) => {
                    return (
                      <tr key={aviso.id} className="table-row">
                        <td className="table-cell" style={{ fontSize: "11px" }}>
                          {aviso.status_pago ? (
                            // Mostrar cuando esté pago en verde
                            <div className="btn btn-success"></div>
                          ) : (
                            // Mostrar cuando NO esté pago en rojo
                            <div className="btn btn-danger"></div>
                          )}
                        </td>
                        <td className="table-cell" style={{ fontSize: "11px" }}>
                          {aviso.listin.activation.fecha_de_viaje}
                        </td>
                        <td className="table-cell" style={{ fontSize: "11px" }}>
                          {aviso.valor_dolar} Bs.
                        </td>
                        <td className="table-cell" style={{ fontSize: "11px" }}>
                          {aviso.listin.n_listin_terminal}
                        </td>
                        <td className="table-cell" style={{ fontSize: "11px" }}>
                          {aviso.listin.activation.empresa.nombre}
                        </td>
                        <td className="table-cell" style={{ fontSize: "11px" }}>
                          {
                            aviso.listin.activation.ruta.destino_final
                              .destino_final_detalle.descripcion
                          }
                        </td>
                        <td className="table-cell" style={{ fontSize: "11px" }}>
                          {aviso.adultos}
                        </td>
                        <td className="table-cell" style={{ fontSize: "11px" }}>
                          {aviso.tercera_edad}
                        </td>
                        <td className="table-cell" style={{ fontSize: "11px" }}>
                          {aviso.discapacitados}
                        </td>
                        <td className="table-cell" style={{ fontSize: "11px" }}>
                          {aviso.ninos_4_menos}
                        </td>
                        <td className="table-cell" style={{ fontSize: "11px" }}>
                          {aviso.cortesias}
                        </td>
                        <td className="table-cell" style={{ fontSize: "11px" }}>
                          {aviso.adultos +
                            aviso.tercera_edad +
                            aviso.discapacitados +
                            aviso.ninos_4_menos +
                            aviso.cortesias}
                        </td>
                        <td className="table-cell" style={{ fontSize: "11px" }}>
                          {aviso.total_venta_bolivares.toFixed(2)} Bs.
                        </td>
                        <td className="table-cell" style={{ fontSize: "11px" }}>
                          {aviso.precio_listin_bolivares.toFixed(2)} Bs.
                        </td>
                        <td className="table-cell" style={{ fontSize: "11px" }}>
                          {aviso.precio_tasa_bolivares.toFixed(2)} Bs.
                        </td>
                        <td className="table-cell" style={{ fontSize: "11px" }}>
                          {aviso.total_bolivares.toFixed(2)} Bs.
                        </td>
                        <td className="table-cell" style={{ fontSize: "11px" }}>
                          <button
                            className="btn btn-danger d-flex"
                            onClick={() => deleteAviso(aviso.id)}
                            style={{
                              color: "white",
                              flexDirection: "row",
                              justifyContent: "center",
                              alignItems: "center",
                              marginRight: "10px",
                            }}
                          >
                            <i
                              className="fa-solid fa-trash"
                              style={{ fontSize: "17px" }}
                            ></i>
                          </button>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
