const getState = ({ getStore, getActions, setStore }) => {
  return {
    store: {
      message: null,
      demo: [
        {
          title: "FIRST",
          background: "white",
          initial: "white",
        },
        {
          title: "SECOND",
          background: "white",
          initial: "white",
        },
      ],
      token: localStorage.getItem("token") ?? undefined,
    },
    actions: {
      changePassword: async (currentPassword, newPassword) => {
        try {
          const response = await axios.post(
            process.env.BACKEND_URL + "/api/change-password",
            { current_password: currentPassword, new_password: newPassword },
            {
              headers: {
                Authorization: "Bearer " + store.token,
              },
            }
          );

          if (response.status === 200) {
            // Contraseña cambiada exitosamente
            return true;
          } else {
            throw new Error("No se pudo cambiar la contraseña");
          }
        } catch (error) {
          throw new Error(
            error.response?.data?.message || "Error al cambiar la contraseña"
          );
        }
      },
      checkCurrentPassword: async (currentPassword) => {
        try {
          const response = await axios.post(
            process.env.BACKEND_URL + "/api/check-current-password",
            { current_password: currentPassword },
            {
              headers: {
                Authorization: "Bearer " + store.token,
              },
            }
          );

          if (response.status !== 200) {
            throw new Error(
              response.data?.message ||
                "Error al verificar la contraseña actual"
            );
          }
        } catch (error) {
          throw new Error(
            error.response?.data?.message ||
              "Error al verificar la contraseña actual"
          );
        }
      },

      savetoken: (token) => {
        setStore({ token });
        localStorage.setItem("token", token);
        getActions().fetchUserData();
      },

      fetchUserData: async () => {
        try {
          const token = localStorage.getItem("token");
          if (token) {
            const resp = await fetch(
              process.env.BACKEND_URL + "/api/protected",
              {
                method: "GET",
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            );

            if (resp.status === 200) {
              const userData = await resp.json();
              setStore({ userData });
              // Save user data to localStorage
              //   localStorage.setItem("userData", JSON.stringify(userData));
            } else {
              console.log(
                "Error fetching user data. Status code:",
                resp.status
              );
            }
          }
        } catch (error) {
          console.log("Error fetching user data", error);
        }
      },
      checkLogin: (redirect) => {
        const token = localStorage.getItem("token");
        const store = getStore();
        if (token == null || !store.token) {
          redirect("/Login");
        }
      },
    },
  };
};

export default getState;
