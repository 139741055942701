import React, { useContext, useState, useEffect } from "react";
import axios from "axios";
import { Context } from "../../../store/appContext";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import {
  Document,
  Page,
  Text,
  View,
  Image,
  StyleSheet,
  pdf,
} from "@react-pdf/renderer";
import { Link } from "react-router-dom";

const MySwal = withReactContent(Swal);

export const Elab_listin_rl = () => {
  const { store } = useContext(Context);
  const [formData, setFormData] = useState({
    numero_listin: "",
    fecha: "",
    tipo_servicio: "",
    unidad: "",
    placa: "",
    numero_puestos: "",
    destino: "",
    anden: "",
    hora_salida: "",
    ruta: "",
    empresa: "",
    rif: "",
    conductor_1: "",
    cedula_1: "",
    grado_licencia_1: "",
    fecha_vencimiento_licencia_1: "",
    conductor_2: "",
    cedula_2: "",
    grado_licencia_2: "",
    fecha_vencimiento_licencia_2: "",
    vista: "",
    activationId: "",
  });
  const [activaciones, setActivaciones] = useState([]);
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [records, setRecords] = useState([]);

  const fetchTodayRecords = async () => {
    try {
      // Configurar el token de autorización en los headers
      const response = await axios.get(
        process.env.BACKEND_URL + "/api/activations-status-recau",
        {
          headers: {
            Authorization: `Bearer ${store.token}`, // Corrige el uso del token
          },
        }
      );
      setRecords(response.data.activations); // Guarda los datos obtenidos
    } catch (error) {
      console.error("Error al obtener registros de hoy:", error);
    } finally {
      setLoading(false); // Cambia a `false` al terminar la carga
    }
  };

  useEffect(() => {
    fetchTodayRecords();

    // Limpiar efectos al desmontar el componente (si tienes intervalos o eventos)
    return () => setLoading(false);
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const openActivationSelection = () => {
    MySwal.fire({
      title: (
        <p>
          <i
            className="fa-solid fa-bus"
            style={{ marginRight: "7px", fontSize: "20px" }}
          ></i>
          Activaciones de hoy
        </p>
      ),
      html: `
        <input
          type="text"
          id="companySearch"
          placeholder="Buscar activación"
          class="swal2-input"
          style="width: 100%"
        />
        <div id="companyList" class="company-list">
          ${records
            .map(
              (activation) => `
            <button 
              class="btn company-option company-list-activation-list" 
              data-id="${activation.id}"
              data-name="${activation.empresa}"
              data-unidad="${activation.numero_de_unidad}"
              data-rif="${activation.rif}"
              data-placa="${activation.placa}"
              data-salida="${activation.hora_de_salida}"
              data-destino="${activation.destino_final}"
              data-ruta="${activation.ruta}"
              >
              ${activation.empresa} <br> Unidad: ${activation.numero_de_unidad}
            </button>`
            )
            .join("")}
        </div>
      `,
      showConfirmButton: false,
      showCancelButton: true,
      cancelButtonText: "Cerrar",
      didOpen: () => {
        const companySearch = document.getElementById("companySearch");
        const companyList = document.getElementById("companyList");

        // Manejador de filtro para el input de búsqueda
        companySearch.addEventListener("input", (e) => {
          const query = e.target.value.toLowerCase();
          const filteredEmpresas = records.filter(
            (activation) =>
              activation.empresa.toLowerCase().includes(query) ||
              activation.numero_de_unidad.toLowerCase().includes(query)
          );
          companyList.innerHTML = filteredEmpresas
            .map(
              (activation) => `
                <button 
                  class="btn company-option company-list-activation-list" 
                  data-id="${activation.id}"
                  data-name="${activation.empresa}"
                  data-unidad="${activation.numero_de_unidad}"
                  data-rif="${activation.rif}"
                  data-placa="${activation.placa}"
                  data-salida="${activation.hora_de_salida}"
                  data-destino="${activation.destino_final}"
                  data-ruta="${activation.ruta}"
                  >
                  ${activation.empresa} <br> Unidad: ${activation.numero_de_unidad}
                </button>`
            )
            .join("");

          // Agregar listeners a cada opción filtrada
          const buttons = companyList.querySelectorAll(".company-option");
          buttons.forEach((button) => {
            button.addEventListener("click", () => {
              const activationId = button.dataset.id;
              const empresa = button.dataset.name;
              const numero_unidad = button.dataset.unidad;
              const rif = button.dataset.rif;
              const placa = button.dataset.placa;
              const hora_de_salida = button.dataset.salida;
              const destino_final = button.dataset.destino;
              const ruta = button.dataset.ruta;
              selectCompany(
                activationId,
                empresa,
                numero_unidad,
                rif,
                placa,
                hora_de_salida,
                destino_final,
                ruta
              ); // Pasamos el ID y el nombre
            });
          });
        });

        // Listener para opciones de empresa inicialmente
        const buttons = companyList.querySelectorAll(".company-option");
        buttons.forEach((button) => {
          button.addEventListener("click", () => {
            const activationId = button.dataset.id;
            const empresa = button.dataset.name;
            const numero_unidad = button.dataset.unidad;
            const rif = button.dataset.rif;
            const placa = button.dataset.placa;
            const hora_de_salida = button.dataset.salida;
            const destino_final = button.dataset.destino;
            const ruta = button.dataset.ruta;
            selectCompany(
              activationId,
              empresa,
              numero_unidad,
              rif,
              placa,
              hora_de_salida,
              destino_final,
              ruta
            ); // Pasamos el ID y el nombre
          });
        });
      },
    });
  };

  const selectCompany = (
    activationId,
    empresa,
    numero_de_unidad,
    rif,
    placa,
    hora_de_salida,
    destino_final,
    ruta
  ) => {
    setFormData({
      ...formData,
      activationId: activationId,
      vista: empresa + " - " + numero_de_unidad,
      empresa: empresa,
      rif: rif,
      placa: placa,
      hora_salida: hora_de_salida,
      unidad: numero_de_unidad,
      destino: destino_final,
      ruta: ruta,
    });
    MySwal.close(); // Cerrar la ventana de SweetAlert
  };

  return (
    <div
      className="container-fluid contarinerGeneralUserHomejs"
      style={{
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
        paddingBottom: "40px",
      }}
    >
      <div className="container asklnduiashndaijsnxansxsaas m-0 p-0">
        <div style={{ margin: "0", marginTop: "20px" }}>
          <i
            className="fa-solid seraUnMarinsupre fa-file-invoice"
            id="icon-tittle-general"
          ></i>
        </div>
        <p style={{ fontSize: "13px" }}>Elaboración de Listines Rutas Largas</p>
        <div
          className="container asklnduiashndaijsnxansxsaas3"
          style={{ marginBottom: "20px" }}
        >
          <form className="formularioDeLogin sd98f4e8wf4cwe987cwe984cwe w-100">
            <div className="aoshduiahsdkasnmdasdas">
              <div className="input-group-login2">
                <input
                  className="inputDeLogin"
                  type="text"
                  name="activacion"
                  value={formData.vista}
                  onClick={openActivationSelection}
                  readOnly
                  required
                />
                <label>Programación de hoy</label>
              </div>
              <div className="input-group-login2">
                <select
                  className="inputDeLogin"
                  name="tipo_servicio"
                  onChange={handleChange}
                  value={formData.tipo_servicio}
                  required
                >
                  <option value="" disabled>
                    Selecciona un Servicio
                  </option>
                  <option value="BUSCAMA">BUSCAMA</option>
                  <option value="EJECUTIVO">EJECUTIVO</option>
                  <option value="YUTONG">YUTONG</option>
                </select>
                <label>Tipo de Servicio</label>
              </div>
            </div>

            <div className="aoshduiahsdkasnmdasdas">
              <div className="input-group-login2">
                <input
                  className="inputDeLogin"
                  type="text"
                  name="unidad"
                  value={formData.unidad}
                  onChange={handleChange}
                  required
                  readOnly
                />
                <label>Unidad</label>
              </div>
              <div className="input-group-login2">
                <input
                  className="inputDeLogin"
                  type="text"
                  name="placa"
                  value={formData.placa}
                  onChange={handleChange}
                  required
                  readOnly
                  style={{ textTransform: "uppercase" }}
                />
                <label>Placa</label>
              </div>
              <div className="input-group-login2">
                <input
                  className="inputDeLogin"
                  type="text"
                  name="destino"
                  value={formData.destino}
                  onChange={handleChange}
                  required
                  readOnly
                />
                <label>Destino Final</label>
              </div>
            </div>
            <div className="aoshduiahsdkasnmdasdas">
              <div className="input-group-login2">
                <textarea
                  className="inputDeLogin"
                  type="text"
                  name="ruta"
                  value={formData.ruta}
                  onChange={handleChange}
                  required
                  readOnly
                  style={{ minHeight: "62px" }}
                />
                <label>Ruta</label>
              </div>
            </div>

            <div className="aoshduiahsdkasnmdasdas">
              <div className="input-group-login2">
                <input
                  className="inputDeLogin"
                  type="time"
                  name="hora_salida"
                  value={formData.hora_salida}
                  onChange={handleChange}
                  required
                  readOnly
                />
                <label>Hora de Salida</label>
              </div>

              <div className="input-group-login2">
                <input
                  className="inputDeLogin"
                  type="text"
                  name="empresa"
                  value={formData.empresa}
                  onChange={handleChange}
                  required
                  readOnly
                />
                <label>Empresa</label>
              </div>
              <div className="input-group-login2">
                <input
                  className="inputDeLogin"
                  type="text"
                  name="rif"
                  value={formData.rif}
                  onChange={handleChange}
                  required
                  readOnly
                />
                <label>RIF</label>
              </div>
            </div>

            <div className="aoshduiahsdkasnmdasdas">
              <div className="input-group-login2">
                <input
                  className="inputDeLogin"
                  type="text"
                  name="conductor_1"
                  value={formData.conductor_1}
                  onChange={handleChange}
                  required
                />
                <label>Cédula 1</label>
              </div>
              <div className="input-group-login2">
                <input
                  className="inputDeLogin"
                  type="text"
                  name="nombre_1"
                  value={formData.nombre_1}
                  onChange={handleChange}
                  required
                  readOnly
                />
                <label>Nombre completo 1</label>
              </div>
            </div>

            <div className="aoshduiahsdkasnmdasdas">
              <div className="input-group-login2">
                <input
                  className="inputDeLogin"
                  type="text"
                  name="conductor_2"
                  value={formData.conductor_2}
                  onChange={handleChange}
                />
                <label>Cédula 2</label>
              </div>
              <div className="input-group-login2">
                <input
                  className="inputDeLogin"
                  type="text"
                  name="nombre_2"
                  value={formData.nombre_2}
                  onChange={handleChange}
                  readOnly
                />
                <label>Nombre Completo 2</label>
              </div>
            </div>

            <button
              className="buttonCargadeDatosDeLoginregistromovimiento"
              type="submit"
              // disabled={isSubmitting}
            >
              Elaborar
              {/* {isSubmitting ? "Registrando..." : "Registrarse"}{" "} */}
              <i className="fa-solid fa-arrow-right"></i>
            </button>
          </form>
        </div>
        <div className="d-flex" style={{ marginTop: "12px" }}>
          <p>¿Alguna duda?,</p>
          <Link
            style={{
              marginLeft: "5px",
              textDecoration: "none",
              color: "#24827C",
            }}
            to="/AyudaYSoporte"
          >
            <strong>Contáctanos</strong>
          </Link>
        </div>
      </div>
    </div>
  );
};
