import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { Context } from "../../../store/appContext.js";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFDownloadLink,
} from "@react-pdf/renderer";

const MySwal = withReactContent(Swal);

export const EmpresasTerminal = () => {
  const navigate = useNavigate();
  const { store } = useContext(Context);
  const [formData, setFormData] = useState({
    empresaId: "",
    taquilla: "",
    empresa: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false); // Nuevo estado para controlar el estado de envío
  const [empresas, setEmpresas] = useState([]); // Nuevo estado para controlar el estado de envío

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true); // Desactiva el botón

    try {
      await axios.post(
        process.env.BACKEND_URL + "/api/empresa_terminal",
        formData,
        {
          headers: {
            Authorization: "Bearer " + store.token,
          },
        }
      );
      setFormData({
        empresa: "",
        empresaId: "",
        taquilla: "",
      });
      mostrarAlerta1();
    } catch (error) {
      const errorMessage =
        error.response?.data?.message ||
        "Error desconocido al registrar al usuario";
      mostrarAlerta2(errorMessage);
      console.error("Error al registrar", error);
    } finally {
      setIsSubmitting(false); // Reactiva el botón después del envío
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const mostrarAlerta1 = () => {
    MySwal.fire({
      title: <p>Empresa Registrada con éxito</p>,
      icon: "success",
      position: "center",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
    });
  };

  const mostrarAlerta2 = (errorMessage) => {
    MySwal.fire({
      title: <p>Error al Registrar la Empresa</p>,
      html: <p>{errorMessage}</p>,
      icon: "error",
      position: "center",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
    });
  };

  const fetchEmpresasDisponibles = async () => {
    try {
      const response = await axios.get(
        process.env.BACKEND_URL + "/api/empresas",
        {
          headers: {
            Authorization: `Bearer ${store.token}`,
          },
        }
      );
      setEmpresas(response.data);
    } catch (error) {
      MySwal.fire({
        title: "Error",
        text: "Error al cargar las empresas disponibles.",
        icon: "error",
        confirmButtonText: "Cerrar",
      });
    }
  };

  useEffect(() => {
    fetchEmpresasDisponibles();
  }, []);

  const openCompanySelection = () => {
    MySwal.fire({
      title: (
        <p>
          <i
            className="fa-solid fa-user-tie"
            style={{ marginRight: "7px", fontSize: "20px" }}
          ></i>
          Empresas de Transporte
        </p>
      ),
      html: `
        <input
          type="text"
          id="companySearch"
          placeholder="Buscar empresa"
          class="swal2-input"
          style="width: 100%"
        />
        <div id="companyList" class="company-list">
          ${empresas
            .map(
              (company) => `
            <button 
              class="btn company-option company-list-activation-list" 
              data-id="${company.id}"
              data-name="${company.nombre}">  <!-- Añadimos data-name con el nombre -->
              ${company.nombre} <br> ${company.rif}
            </button>`
            )
            .join("")}
        </div>
      `,
      showConfirmButton: false,
      showCancelButton: true,
      cancelButtonText: "Cerrar",
      didOpen: () => {
        const companySearch = document.getElementById("companySearch");
        const companyList = document.getElementById("companyList");

        // Manejador de filtro para el input de búsqueda
        companySearch.addEventListener("input", (e) => {
          const query = e.target.value.toLowerCase();
          const filteredEmpresas = empresas.filter(
            (company) =>
              company.nombre.toLowerCase().includes(query) ||
              company.rif.toLowerCase().includes(query)
          );
          companyList.innerHTML = filteredEmpresas
            .map(
              (company) => `
                <button 
                class="btn company-option company-list-activation-list" 
                data-id="${company.id}"
                data-name="${company.nombre}">
                ${company.nombre} <br> ${company.rif}
                </button>`
            )
            .join("");

          // Agregar listeners a cada opción filtrada
          const buttons = companyList.querySelectorAll(".company-option");
          buttons.forEach((button) => {
            button.addEventListener("click", () => {
              const companyId = button.dataset.id;
              const companyName = button.dataset.name; // Usamos el nombre de la empresa
              selectCompany(companyId, companyName); // Pasamos el ID y el nombre
            });
          });
        });

        // Listener para opciones de empresa inicialmente
        const buttons = companyList.querySelectorAll(".company-option");
        buttons.forEach((button) => {
          button.addEventListener("click", () => {
            const companyId = button.dataset.id;
            const companyName = button.dataset.name;
            selectCompany(companyId, companyName); // Pasamos el ID y el nombre
          });
        });
      },
    });
  };

  const selectCompany = (companyId, companyName) => {
    setFormData({
      ...formData,
      empresa: companyName, // Guardamos el nombre de la empresa para mostrarlo en el input
      empresaId: companyId, // Guardamos el ID de la empresa para enviarlo al backend
    });
    MySwal.close(); // Cerrar la ventana de SweetAlert
  };

  const fetchTerminal = async () => {
    MySwal.fire({
      title: "Cargando...",
      html: `<p style="text-align:center; margin:20px;">Cargando Empresas...</p>`,
      allowOutsideClick: false,
      didOpen: () => {
        MySwal.showLoading();
      },
    });

    try {
      const response = await axios.get(
        process.env.BACKEND_URL + "/api/ver_empresas_por_terminal",
        {
          headers: {
            Authorization: `Bearer ${store.token}`,
          },
        }
      );

      const data = response.data.empresas;

      MySwal.close();

      if (!data || data.length === 0) {
        MySwal.fire({
          title: "Empresas",
          html: `<p style="text-align:center; margin:20px;">No se encontraron Empresas.</p>`,
          confirmButtonText: "Cerrar",
        });
      } else {
        const tableHeaders = `
          <tr>
            <th style="text-align: start">EMPRESA</th>
            <th style="text-align: start">RIF</th>
            <th style="text-align: start">ESTATUS</th>
          </tr>
        `;
        const statusMap = {
          ACTIVE: "ACTIVO",
          NO_ACTIVE: "BLOQUEADO",
        };

        const tableRows = data
          .map(
            (item) => `
              <tr>
                <td>${item.empresa.nombre}</td>
                <td>${item.empresa.rif}</td>
                <td>
                  <button class="change-status" data-id="${item.id}" data-status="${item.status}" style="color: white; background-color: ${item.status == "ACTIVE" ? "green" : "red"}; border: none; padding: 5px; cursor: pointer;">
                    ${statusMap[item.status] || item.status}
                  </button>
                </td>
              </tr>
            `
          )
          .join("");

        const table = `
          <input type="text" id="search-listines" placeholder="Buscar..." class="swal2-input" style="margin-bottom: 10px;">
          <div style="max-height: 400px; overflow-y: auto;">
            <table style="width: 450px; border-collapse: collapse; font-size: 12px; min-width: 100%">
              <thead>
                ${tableHeaders}
              </thead>
              <tbody id="listines-list" style="text-align: start">
                ${tableRows}
              </tbody>
            </table>
          </div>
        `;

        MySwal.fire({
          title: "Empresas",
          html: table,
          showConfirmButton: true,
          confirmButtonText: "Cerrar",
          didRender: () => {
            const searchInput = document.getElementById("search-listines");
            searchInput.addEventListener("input", (event) => {
              const searchTerm = event.target.value.toLowerCase();
              const listinesList = document.getElementById("listines-list");
              const filteredRows = data
                .filter(
                  (item) =>
                    item.empresa.nombre.toLowerCase().includes(searchTerm) ||
                    item.empresa.rif.toLowerCase().includes(searchTerm)
                )
                .map(
                  (item) => `
                    <tr>
                        <td>${item.empresa.nombre}</td>
                        <td>${item.empresa.rif}</td>
                        <td>
                        <button class="change-status" data-id="${item.id}" data-status="${item.status}" style="color: white; background-color: ${item.status == "ACTIVE" ? "green" : "red"}; border: none; padding: 5px; cursor: pointer;">
                            ${statusMap[item.status] || item.status}
                        </button>
                        </td>
                    </tr>
                  `
                )
                .join("");
              listinesList.innerHTML = filteredRows;
            });

            // Delegación de eventos para los botones de cambio de estado
            document
              .getElementById("listines-list")
              .addEventListener("click", async (event) => {
                if (event.target.classList.contains("change-status")) {
                  const button = event.target;
                  const userId = button.getAttribute("data-id");
                  const userStatus = button.getAttribute("data-status");

                  const actionText =
                    userStatus === "ACTIVE" ? "bloquear" : "activar";
                  const confirmationText =
                    userStatus === "ACTIVE"
                      ? "¡Si haces esto el usuario no podrá usar más su cuenta!"
                      : "¡El usuario podrá usar su cuenta nuevamente!";

                  const confirmation = await MySwal.fire({
                    title: `¿Estás seguro de que quieres ${actionText} este usuario?`,
                    text: confirmationText,
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#d33",
                    cancelButtonColor: "#3085d6",
                    confirmButtonText: `Sí, ${actionText}`,
                    cancelButtonText: "Cancelar",
                  });

                  if (confirmation.isConfirmed) {
                    MySwal.fire({
                      title: `${actionText.charAt(0).toUpperCase() + actionText.slice(1)}...`,
                      html: `<p style="text-align:center; margin:20px;">Actualizando estado del usuario...</p>`,
                      allowOutsideClick: false,
                      didOpen: () => {
                        MySwal.showLoading();
                      },
                    });

                    try {
                      await axios.put(
                        `${process.env.BACKEND_URL}/api/empresadeterminal/${userId}/updateStatus`,
                        {},
                        {
                          headers: {
                            Authorization: `Bearer ${store.token}`,
                          },
                        }
                      );

                      MySwal.fire({
                        title: `Usuario ${actionText === "bloquear" ? "bloqueado" : "activado"}`,
                        text: `El usuario ha sido ${actionText === "bloquear" ? "bloqueado" : "activado"} exitosamente.`,
                        icon: "success",
                        confirmButtonText: "Cerrar",
                      });

                      fetchTerminal();
                    } catch (error) {
                      MySwal.fire({
                        title: "Error",
                        text: "Hubo un error al intentar actualizar el estado del usuario",
                        icon: "error",
                        confirmButtonText: "Cerrar",
                      });
                      console.error(
                        "Error al cambiar el estado del usuario:",
                        error
                      );
                    }
                  }
                }
              });
          },
        });
      }
    } catch (error) {
      console.error("Error al obtener usuarios", error);
      MySwal.fire({
        title: "Error",
        html: `<p>Error al obtener usuarios. Por favor, inténtelo de nuevo.</p>`,
        icon: "error",
        confirmButtonText: "Cerrar",
      });
    }
  };

  return (
    <div
      className="container-fluid contarinerGeneralUserHomejs"
      style={{
        display: "flex",
        paddingBottom: "40px",
      }}
    >
      <div className="container asklnduiashndaijsnxansxsaas m-0 p-0">
        <div className="sdfdfgrt54w98e7we9contewe w-100">
          <div
            className="i-fewf5c9ew5ce9wdc1w4c1s sd56f4e8r97g8yrh4be8g9e4rf89er45s4"
            style={{
              marginTop: "30px",
            }}
          ></div>
          <p style={{ fontSize: "13px" }}>
            Agrega solo las empresas autorizadas en tu Terminal. Si falta
            alguna, contáctanos para agregarla.
          </p>
        </div>
        <div
          className="container asklnduiashndaijsnxansxsaas3"
          style={{ marginBottom: "20px" }}
        >
          <form
            onSubmit={handleSubmit}
            className="formularioDeLogin sd98f4e8wf4cwe987cwe984cwe w-100"
          >
            <div className="aoshduiahsdkasnmdasdas">
              <div className="input-group-login2">
                <input
                  className="inputDeLogin"
                  type="text"
                  name="empresa"
                  onClick={openCompanySelection}
                  required
                  readOnly
                  onChange={handleChange}
                  value={formData.empresa}
                  style={{ textTransform: "uppercase" }}
                />
                <label>Línea de Transporte</label>
              </div>
              <div className="input-group-login2">
                <input
                  className="inputDeLogin"
                  type="text"
                  name="taquilla"
                  required
                  onChange={handleChange}
                  value={formData.taquilla}
                  style={{ textTransform: "uppercase" }}
                />
                <label>Taquilla</label>
              </div>
            </div>
            <button
              className="buttonCargadeDatosDeLoginregistromovimiento"
              type="submit"
              disabled={isSubmitting}
            >
              {isSubmitting ? "Registrando..." : "Registrar"}{" "}
              <i className="fa-solid fa-arrow-right"></i>
            </button>
          </form>
        </div>
        <div className="w-100 asd9as-da9s84dx9a8qdw-qdw asdqw98d4qv895y4n9r84bvr98ebtnbtn poja65s4d8a9s7d89errg98er7fqwd9849iu9ykj7asj">
          <button
            className="asd98q7wd65q1wd8xqw49x8e4wc"
            onClick={fetchTerminal}
          >
            <i
              className="fa-solid fa-list"
              style={{ marginRight: "7px", fontSize: "20px" }}
            ></i>
            Ver Mis Empresas
          </button>
        </div>
        <div className="d-flex" style={{ marginTop: "12px" }}>
          <p>¿Alguna duda?,</p>
          <Link
            style={{
              marginLeft: "5px",
              textDecoration: "none",
              color: "#24827C",
            }}
            to="/AyudaYSoporte"
          >
            <strong>Contáctanos</strong>
          </Link>
        </div>
      </div>
    </div>
  );
};
