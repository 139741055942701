// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.as56d489wdq98wd4q9w4d98qw7dw89e4dwe98f7we98 {
  background-image: linear-gradient(to right, #0a306a, #1e509b);
  color: white;
  padding: 10px 15px;
  border-radius: 10px;
}
.as56d489wdq98wd4q9w4d98qw7dw89e4dwe98f7we98:hover {
  background-image: linear-gradient(to right, #2557a1, #4272bb);
  color: white;
}
.as564d9q8wd789qw4ddqw98d7qw894dqw898qw9d7 {
  background-color: white;
  color: #0a306a;
  border: 2px solid #0a306a;
  border-radius: 10px;
  padding: 10px 15px;
}
.as564d9q8wd789qw4ddqw98d7qw894dqw898qw9d7:hover {
  background-color: white;
  color: #0a306a;
  border: 2px solid #0a306a;
  border-radius: 10px;
}
.sd4f9e8wf4we98fw98ef4we98f4we98f7we98 {
  width: 100%;
  max-width: 500px;
  flex-direction: column;
  margin-top: 40px;
  justify-content: center;
  align-items: center;
}
`, "",{"version":3,"sources":["webpack://./src/front/styles/CierreListinTerminal.css"],"names":[],"mappings":"AAAA;EACE,6DAA6D;EAC7D,YAAY;EACZ,kBAAkB;EAClB,mBAAmB;AACrB;AACA;EACE,6DAA6D;EAC7D,YAAY;AACd;AACA;EACE,uBAAuB;EACvB,cAAc;EACd,yBAAyB;EACzB,mBAAmB;EACnB,kBAAkB;AACpB;AACA;EACE,uBAAuB;EACvB,cAAc;EACd,yBAAyB;EACzB,mBAAmB;AACrB;AACA;EACE,WAAW;EACX,gBAAgB;EAChB,sBAAsB;EACtB,gBAAgB;EAChB,uBAAuB;EACvB,mBAAmB;AACrB","sourcesContent":[".as56d489wdq98wd4q9w4d98qw7dw89e4dwe98f7we98 {\n  background-image: linear-gradient(to right, #0a306a, #1e509b);\n  color: white;\n  padding: 10px 15px;\n  border-radius: 10px;\n}\n.as56d489wdq98wd4q9w4d98qw7dw89e4dwe98f7we98:hover {\n  background-image: linear-gradient(to right, #2557a1, #4272bb);\n  color: white;\n}\n.as564d9q8wd789qw4ddqw98d7qw894dqw898qw9d7 {\n  background-color: white;\n  color: #0a306a;\n  border: 2px solid #0a306a;\n  border-radius: 10px;\n  padding: 10px 15px;\n}\n.as564d9q8wd789qw4ddqw98d7qw894dqw898qw9d7:hover {\n  background-color: white;\n  color: #0a306a;\n  border: 2px solid #0a306a;\n  border-radius: 10px;\n}\n.sd4f9e8wf4we98fw98ef4we98f4we98f7we98 {\n  width: 100%;\n  max-width: 500px;\n  flex-direction: column;\n  margin-top: 40px;\n  justify-content: center;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
