import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import moment from "moment-timezone";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import "../../../../styles/RegistroActivacionesHoy.css";
import { Context } from "../../../store/appContext.js";
import { useNavigate, useParams } from "react-router-dom";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFDownloadLink,
  pdf,
  Image,
  PDFViewer,
} from "@react-pdf/renderer";

const tableStyles = StyleSheet.create({
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 0.5,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    borderRadius: 10, // Ajusta el valor según el redondeo deseado
    overflow: "hidden",
    marginTop: 10,
  },
  table2: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 0.5,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    marginTop: 10,
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
    backgroundColor: "transparent",
  },
  tableColHeader: {
    borderStyle: "solid",
    borderWidth: 0.5,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    borderColor: "gray",
    backgroundColor: "#24827c89",
    alignItems: "center",
    justifyContent: "center",
  },
  tableColHeader2: {
    borderStyle: "solid",
    borderWidth: 0.5,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    backgroundColor: "#8c10106e",
    alignItems: "center",
    justifyContent: "center",
  },
  tableCol: {
    borderStyle: "solid",
    borderWidth: 0.5,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    alignItems: "center",
    justifyContent: "center",
  },
  tableCellHeader: {
    margin: 5,
    fontSize: 9,
    fontWeight: "bold",
    color: "white",
  },
  tableCell: {
    margin: 4,
    fontSize: 7,
  },
});

tableStyles.tableColHeaderOthertaxi = {
  ...tableStyles.tableColHeader,
  width: "50%", // Mayor ancho para los demás
};

tableStyles.tableColOthertaxi = {
  ...tableStyles.tableCol,
  width: "50%", // Mayor ancho para los demás
};

// Modificar anchos específicos aquí
tableStyles.tableColHeaderNumber = {
  ...tableStyles.tableColHeader,
  width: "5%", // Menor ancho para "N°"
};

tableStyles.tableColHeaderOther = {
  ...tableStyles.tableColHeader,
  width: "13.6%", // Mayor ancho para los demás
};

tableStyles.tableColHeaderOther4 = {
  ...tableStyles.tableColHeader,
  width: "32%", // Mayor ancho para los demás
};

tableStyles.tableColHeaderOtherDT = {
  ...tableStyles.tableColHeader,
  width: "50%", // Mayor ancho para los demás
};

tableStyles.tableColHeaderOther2 = {
  ...tableStyles.tableColHeader2,
  width: "13.6%", // Mayor ancho para los demás
};

tableStyles.tableColHeaderOther3 = {
  ...tableStyles.tableColHeader2,
  width: "32%", // Mayor ancho para los demás
};

tableStyles.tableColNumber = {
  ...tableStyles.tableCol,
  width: "5%", // Menor ancho para "N°"
};

tableStyles.tableColOther = {
  ...tableStyles.tableCol,
  width: "13.6%", // Mayor ancho para los demás
};
tableStyles.tableColOther2 = {
  ...tableStyles.tableCol,
  width: "32%", // Mayor ancho para los demás
};

tableStyles.tableColOtherDT = {
  ...tableStyles.tableCol,
  width: "50%", // Mayor ancho para los demás
};

tableStyles.tableColHeaderOthertaxi2 = {
  ...tableStyles.tableColHeader,
  width: "33.3%", // Mayor ancho para los demás
  backgroundColor: "#8c1010",
};
tableStyles.tableColHeaderOthertaxi3 = {
  ...tableStyles.tableColHeader,
  width: "100%", // Mayor ancho para los demás
};

tableStyles.tableColOthertaxi2 = {
  ...tableStyles.tableCol,
  width: "33.3%", // Mayor ancho para los demás
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    borderBottomColor: "#000",
    borderBottomWidth: 1,
    alignItems: "center",
    height: 15,
    margin: 0,
    padding: 0,
    justifyContent: "center",
    textAlign: "center",
  },
  cell: {
    fontSize: 9,
    textAlign: "center",
    borderRightColor: "#000",
    borderRightWidth: 1,
    flex: 1,
  },
  cell2: {
    fontSize: 9,
    textAlign: "center",
    borderRightColor: "#000",
    borderRightWidth: 1,
    width: 20,
    borderLeftColor: "#000",
    borderLeftWidth: 1,
  },
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    textAlign: "center",
  },
  logo: {
    width: 90, // Ajusta el tamaño según tus necesidades
    height: 30, // Ajusta el tamaño según tus necesidades
  },
  logo2: {
    width: 100,
    height: 20,
  },
  page: {
    padding: "30 30 50 30", // Ajusta el valor del margen según tus necesidades
    height: "100%",
  },
  letter: {
    fontSize: 10,
    padding: 5,
  },
  letter2: {
    fontSize: 10,
  },
  ruta: {
    fontSize: 7,
    padding: 4,
  },
  bottomText: {
    position: "absolute",
    bottom: 0,
    left: 20, // Ajusta según sea necesario
    right: 20, // Ajusta según sea necesario
    textAlign: "center",
    bottom: 30,
    fontSize: 8,
  },
  footer: {
    position: "absolute",
    bottom: 10,
    left: 0,
    right: 0,
    textAlign: "center",
    fontSize: 10,
    paddingTop: 5,
    marginTop: 5,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 10,
  },
  bottomText2: {
    position: "absolute",
    bottom: 0,
    left: 20, // Ajusta según sea necesario
    right: 20, // Ajusta según sea necesario
    textAlign: "center",
    bottom: 50,
    fontSize: 11,
    fontWeight: "bold",
  },
  logo22: {
    width: 100,
    height: 20,
    opacity: 0.2,
  },
  backgroundImage: {
    minwidth: "2480px", // Hace que la imagen ocupe todo el ancho de la página
    height: "auto", // Mantiene la proporción de la imagen, ajustando la altura proporcional al ancho
    position: "absolute", // Asegura que la imagen se quede en el fondo
    top: 0, // Se pega a la parte superior
    left: 0, // Se pega a la parte izquierda
    right: 0,
  },
});

const RouteDetailsPDF = ({
  rutas,
  rutasContingencia,
  empresaDetalle,
  terminal,
}) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <Image
        src="https://res.cloudinary.com/dqt0d66h7/image/upload/v1738848407/Fondos%20PDF/k8k5amtzu6r8ubnrnhmx.png"
        style={styles.backgroundImage}
        fixed
      />
      <View style={styles.header} fixed>
        <Image
          src="https://res.cloudinary.com/dqt0d66h7/image/upload/v1738835212/Fondos%20PDF/pjtuji0yr4bqvzh9sj0z.png"
          style={styles.logo}
        />
        <Image
          src="https://res.cloudinary.com/dqt0d66h7/image/upload/v1738835293/Fondos%20PDF/d9fqduuf6i7lely21fnx.png"
          style={styles.logo2}
        />
      </View>
      {/* <View style={styles.header}>
        <Text style={styles.letter2}>
          Rutas de {empresaDetalle.empresa.empresa.nombre}
        </Text>
      </View> */}
      {empresaDetalle && (
        <View>
          {/* Primera columna */}
          <View>
            <Text
              style={{ textAlign: "center" }}
            >{`${empresaDetalle.empresa.nombre} - ${empresaDetalle.empresa.rif}`}</Text>

            <Text
              style={{ textAlign: "center", fontSize: "12px", color: "gray" }}
            >{`TERMINAL: ${terminal}`}</Text>
          </View>
        </View>
      )}
      {/* Rutas CPS */}
      <View style={{ marginTop: 10 }}></View>
      <View style={styles.section}>
        <Text style={styles.header}>Rutas CPS</Text>
        <View style={{ marginTop: 10 }}></View>
        <View style={tableStyles.table}>
          <View style={tableStyles.tableRow} wrap={false} fixed>
            <View style={tableStyles.tableColHeaderOther4}>
              <Text style={tableStyles.tableCellHeader}>Ruta</Text>
            </View>
            <View style={tableStyles.tableColHeaderOther}>
              <Text style={tableStyles.tableCellHeader}>Destino Final</Text>
            </View>
            <View style={tableStyles.tableColHeaderOther}>
              <Text style={tableStyles.tableCellHeader}>Parada</Text>
            </View>
            <View style={tableStyles.tableColHeaderOther}>
              <Text style={tableStyles.tableCellHeader}>Horas Terminal</Text>
            </View>
            <View style={tableStyles.tableColHeaderOther}>
              <Text style={tableStyles.tableCellHeader}>Horas CPS</Text>
            </View>
            <View style={tableStyles.tableColHeaderOther}>
              <Text style={tableStyles.tableCellHeader}>Estatus</Text>
            </View>
          </View>
          {rutas.map((ruta, index) => (
            <View style={tableStyles.tableRow} wrap={false} key={index}>
              <View style={tableStyles.tableColOther2}>
                <Text style={tableStyles.tableCell}>{ruta.ruta}</Text>
              </View>
              <View style={tableStyles.tableColOther}>
                <Text style={tableStyles.tableCell}>
                  {ruta.destino_final.destino_final_detalle.descripcion}
                </Text>
              </View>
              <View style={tableStyles.tableColOther}>
                <Text style={tableStyles.tableCell}>{ruta.parada}</Text>
              </View>
              <View style={tableStyles.tableColOther}>
                <Text style={tableStyles.tableCell}>
                  {ruta.turnos
                    ? ruta.turnos
                        .map((turno) => turno.turno_hora_real)
                        .join(", ")
                    : "No hay turnos"}
                </Text>
              </View>
              <View style={tableStyles.tableColOther}>
                <Text style={tableStyles.tableCell}>
                  {ruta.turnos_cps
                    ? ruta.turnos_cps.map((turno) => turno.hora).join(", ")
                    : "No hay turnos"}
                </Text>
              </View>
              <View style={tableStyles.tableColOther}>
                <Text style={tableStyles.tableCell}>
                  {ruta.status === "ACTIVE" ? "ACTIVO" : "INACTIVO"}
                </Text>
              </View>
            </View>
          ))}
        </View>
      </View>
      <View style={{ marginTop: 20 }}></View>
      {/* Rutas Contingencia */}
      <View style={styles.section}>
        <Text style={styles.header}>Rutas Contingencia</Text>
        <View style={{ marginTop: 10 }}></View>
        <View style={tableStyles.table}>
          <View style={tableStyles.tableRow} wrap={false} fixed>
            <View style={tableStyles.tableColHeaderOther3}>
              <Text style={tableStyles.tableCellHeader}>Ruta</Text>
            </View>
            <View style={tableStyles.tableColHeaderOther2}>
              <Text style={tableStyles.tableCellHeader}>Destino Final</Text>
            </View>
            <View style={tableStyles.tableColHeaderOther2}>
              <Text style={tableStyles.tableCellHeader}>Parada</Text>
            </View>
            <View style={tableStyles.tableColHeaderOther2}>
              <Text style={tableStyles.tableCellHeader}>Horas Terminal</Text>
            </View>
            <View style={tableStyles.tableColHeaderOther2}>
              <Text style={tableStyles.tableCellHeader}>Horas CPS</Text>
            </View>
            <View style={tableStyles.tableColHeaderOther2}>
              <Text style={tableStyles.tableCellHeader}>Estatus</Text>
            </View>
          </View>
          {rutasContingencia.map((ruta, index) => (
            <View style={tableStyles.tableRow} wrap={false} key={index}>
              <View style={tableStyles.tableColOther2}>
                <Text style={tableStyles.tableCell}>{ruta.ruta}</Text>
              </View>
              <View style={tableStyles.tableColOther}>
                <Text style={tableStyles.tableCell}>
                  {ruta.destino_final.destino_final_detalle.descripcion}
                </Text>
              </View>
              <View style={tableStyles.tableColOther}>
                <Text style={tableStyles.tableCell}>{ruta.parada}</Text>
              </View>
              <View style={tableStyles.tableColOther}>
                <Text style={tableStyles.tableCell}>
                  {ruta.turnos
                    ? ruta.turnos
                        .map((turno) => turno.turno_hora_real)
                        .join(", ")
                    : "No hay turnos"}
                </Text>
              </View>
              <View style={tableStyles.tableColOther}>
                <Text style={tableStyles.tableCell}>
                  {ruta.turnos_cps
                    ? ruta.turnos_cps.map((turno) => turno.hora).join(", ")
                    : "No hay turnos"}
                </Text>
              </View>
              <View style={tableStyles.tableColOther}>
                <Text style={tableStyles.tableCell}>
                  {ruta.status === "ACTIVE" ? "ACTIVO" : "INACTIVO"}
                </Text>
              </View>
            </View>
          ))}
        </View>
      </View>
      <View style={styles.footer} fixed>
        <Image
          src="https://res.cloudinary.com/dqt0d66h7/image/upload/v1738835293/Fondos%20PDF/d9fqduuf6i7lely21fnx.png"
          style={styles.logo22}
        />
        <View style={{ display: "flex", flexDirection: "row" }}>
          <Text style={{ opacity: 0.2 }}>
            www.intravialca-ve.com - Página:{" "}
          </Text>
          <Text
            render={({ pageNumber, totalPages }) =>
              `${pageNumber} / ${totalPages}`
            }
            fixed
          />
        </View>
      </View>
    </Page>
  </Document>
);

const DTDetailsPDF = ({ dt, dtContingencia, empresaDetalle, terminal }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <Image
        src="https://res.cloudinary.com/dqt0d66h7/image/upload/v1738848407/Fondos%20PDF/k8k5amtzu6r8ubnrnhmx.png"
        style={styles.backgroundImage}
        fixed
      />
      <View style={styles.header} fixed>
        <Image
          src="https://res.cloudinary.com/dqt0d66h7/image/upload/v1738835212/Fondos%20PDF/pjtuji0yr4bqvzh9sj0z.png"
          style={styles.logo}
        />
        <Image
          src="https://res.cloudinary.com/dqt0d66h7/image/upload/v1738835293/Fondos%20PDF/d9fqduuf6i7lely21fnx.png"
          style={styles.logo2}
        />
      </View>
      {empresaDetalle && (
        <View>
          <View>
            <Text
              style={{ textAlign: "center" }}
            >{`${empresaDetalle.empresa.nombre} - ${empresaDetalle.empresa.rif}`}</Text>

            <Text
              style={{ textAlign: "center", fontSize: "12px", color: "gray" }}
            >{`TERMINAL: ${terminal}`}</Text>
          </View>
        </View>
      )}
      {/* Rutas CPS */}
      <View style={{ marginTop: 10 }}></View>
      <View style={styles.section}>
        <Text style={styles.header}>DT</Text>
        <View style={{ marginTop: 10 }}></View>
        <View style={tableStyles.table}>
          <View style={tableStyles.tableRow} wrap={false} fixed>
            <View style={tableStyles.tableColHeaderOtherDT}>
              <Text style={tableStyles.tableCellHeader}>Placa</Text>
            </View>
            <View style={tableStyles.tableColHeaderOtherDT}>
              <Text style={tableStyles.tableCellHeader}>Estatus</Text>
            </View>
          </View>
          {dt.map((dt, index) => (
            <View style={tableStyles.tableRow} wrap={false} key={index}>
              <View style={tableStyles.tableColOtherDT}>
                <Text style={tableStyles.tableCell}>{dt.placa}</Text>
              </View>
              <View style={tableStyles.tableColOtherDT}>
                <Text style={tableStyles.tableCell}>
                  {dt.status === "ACTIVE" ? "ACTIVO" : "INACTIVO"}
                </Text>
              </View>
            </View>
          ))}
        </View>
      </View>
      <View style={{ marginTop: 20 }}></View>
      <View style={styles.section}>
        <Text style={styles.header}>DT Contingencia</Text>
        <View style={{ marginTop: 10 }}></View>
        <View style={tableStyles.table}>
          <View style={tableStyles.tableRow} wrap={false} fixed>
            <View style={tableStyles.tableColHeaderOthertaxi2}>
              <Text style={tableStyles.tableCellHeader}>Placa</Text>
            </View>
            <View style={tableStyles.tableColHeaderOthertaxi2}>
              <Text style={tableStyles.tableCellHeader}>Motivo</Text>
            </View>
            <View style={tableStyles.tableColHeaderOthertaxi2}>
              <Text style={tableStyles.tableCellHeader}>Estatus</Text>
            </View>
          </View>
          {dtContingencia.map((dt, index) => (
            <View style={tableStyles.tableRow} wrap={false} key={index}>
              <View style={tableStyles.tableColOthertaxi2}>
                <Text style={tableStyles.tableCell}>{dt.placa}</Text>
              </View>
              <View style={tableStyles.tableColOthertaxi2}>
                <Text style={tableStyles.tableCell}>{dt.razon}</Text>
              </View>
              <View style={tableStyles.tableColOthertaxi2}>
                <Text style={tableStyles.tableCell}>
                  {dt.status === "ACTIVE" ? "ACTIVO" : "INACTIVO"}
                </Text>
              </View>
            </View>
          ))}
        </View>
      </View>
      <View style={styles.footer} fixed>
        <Image
          src="https://res.cloudinary.com/dqt0d66h7/image/upload/v1738835293/Fondos%20PDF/d9fqduuf6i7lely21fnx.png"
          style={styles.logo22}
        />
        <View style={{ display: "flex", flexDirection: "row" }}>
          <Text style={{ opacity: 0.2 }}>
            www.intravialca-ve.com - Página:{" "}
          </Text>
          <Text
            render={({ pageNumber, totalPages }) =>
              `${pageNumber} / ${totalPages}`
            }
            fixed
          />
        </View>
      </View>
    </Page>
  </Document>
);

const formatDate = (dateString) => {
  const date = new Date(dateString); // Asegúrate de que `dateString` esté en formato válido
  const day = String(date.getUTCDate()).padStart(2, "0"); // Usa UTC para evitar problemas de zonas horarias
  const month = String(date.getUTCMonth() + 1).padStart(2, "0"); // Meses son 0 indexados
  const year = date.getUTCFullYear();
  return `${day}-${month}-${year}`;
};

const DriverDetailsPDF = ({ driver, empresaDetalle, terminal }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <Image
        src="https://res.cloudinary.com/dqt0d66h7/image/upload/v1738848407/Fondos%20PDF/k8k5amtzu6r8ubnrnhmx.png"
        style={styles.backgroundImage}
        fixed
      />
      <View style={styles.header} fixed>
        <Image
          src="https://res.cloudinary.com/dqt0d66h7/image/upload/v1738835212/Fondos%20PDF/pjtuji0yr4bqvzh9sj0z.png"
          style={styles.logo}
        />
        <Image
          src="https://res.cloudinary.com/dqt0d66h7/image/upload/v1738835293/Fondos%20PDF/d9fqduuf6i7lely21fnx.png"
          style={styles.logo2}
        />
      </View>
      {/* <View style={styles.header}>
        <Text style={styles.letter2}>
          Rutas de {empresaDetalle.empresa.empresa.nombre}
        </Text>
      </View> */}
      {empresaDetalle && (
        <View>
          {/* Primera columna */}
          <View>
            <Text
              style={{ textAlign: "center" }}
            >{`${empresaDetalle.empresa.nombre} - ${empresaDetalle.empresa.rif}`}</Text>
            <Text
              style={{ textAlign: "center", fontSize: "12px", color: "gray" }}
            >{`TERMINAL: ${terminal}`}</Text>
          </View>
        </View>
      )}
      {/* Rutas CPS */}
      <View style={{ marginTop: 10 }}></View>
      <View style={styles.section}>
        <Text style={styles.header}>Conductores</Text>
        <View style={{ marginTop: 10 }}></View>
        <View style={tableStyles.table}>
          <View style={tableStyles.tableRow} wrap={false} fixed>
            <View style={tableStyles.tableColHeaderOther4}>
              <Text style={tableStyles.tableCellHeader}>Nombre y Apellido</Text>
            </View>
            <View style={tableStyles.tableColHeaderOther}>
              <Text style={tableStyles.tableCellHeader}>Cédula</Text>
            </View>
            <View style={tableStyles.tableColHeaderOther}>
              <Text style={tableStyles.tableCellHeader}>Nacimiento</Text>
            </View>
            <View style={tableStyles.tableColHeaderOther}>
              <Text style={tableStyles.tableCellHeader}>
                <Text style={{ textAlign: "center" }}>
                  Vencimiento {"     "} licencia
                </Text>
              </Text>
            </View>
            <View style={tableStyles.tableColHeaderOther}>
              <Text style={tableStyles.tableCellHeader}>Grado</Text>
            </View>
            <View style={tableStyles.tableColHeaderOther}>
              <Text style={tableStyles.tableCellHeader}>Estatus</Text>
            </View>
          </View>
          {driver.map((driver, index) => (
            <View style={tableStyles.tableRow} wrap={false} key={index}>
              <View style={tableStyles.tableColOther2}>
                <Text style={tableStyles.tableCell}>
                  {driver.first_name} {driver.last_name}
                </Text>
              </View>
              <View style={tableStyles.tableColOther}>
                <Text style={tableStyles.tableCell}>
                  {driver.nacionalidad}-{driver.cedula}
                </Text>
              </View>
              <View style={tableStyles.tableColOther}>
                <Text style={tableStyles.tableCell}>
                  {formatDate(driver.fecha_nacimiento)}
                </Text>
              </View>
              <View style={tableStyles.tableColOther}>
                <Text style={tableStyles.tableCell}>
                  {formatDate(driver.fecha_vencimiento_licencia)}
                </Text>
              </View>
              <View style={tableStyles.tableColOther}>
                <Text style={tableStyles.tableCell}>
                  {driver.grado_licencia}
                </Text>
              </View>
              <View style={tableStyles.tableColOther}>
                <Text style={tableStyles.tableCell}>
                  {driver.status === "ACTIVE" ? "ACTIVO" : "INACTIVO"}
                </Text>
              </View>
            </View>
          ))}
        </View>
      </View>
      <View style={styles.footer} fixed>
        <Image
          src="https://res.cloudinary.com/dqt0d66h7/image/upload/v1738835293/Fondos%20PDF/d9fqduuf6i7lely21fnx.png"
          style={styles.logo22}
        />
        <View style={{ display: "flex", flexDirection: "row" }}>
          <Text style={{ opacity: 0.2 }}>
            www.intravialca-ve.com - Página:{" "}
          </Text>
          <Text
            render={({ pageNumber, totalPages }) =>
              `${pageNumber} / ${totalPages}`
            }
            fixed
          />
        </View>
      </View>
    </Page>
  </Document>
);

const MySwal = withReactContent(Swal);

const EstatusIcon = {
  ACTIVO: {
    icon: "fa-check",
    name: "ACTIVO",
    color: "linear-gradient(to right, #28a745, #31c353)", // Verde
  },
  INACTIVO: {
    icon: "fa-ban",
    name: "CANCELADO",
    color: "linear-gradient(to right, #dc3545, #f24d5e)", // Rojo
  },
};

export const RegistrosCpsEditRegistroDeCpsEditNuevo = () => {
  const [records, setRecords] = useState([]);
  const navigate = useNavigate();
  const [filteredRecords, setFilteredRecords] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const { store } = useContext(Context);
  const [loading, setLoading] = useState(true);
  const [rutas, setRutas] = useState([]);
  const [rutasContingencia, setRutasContingencia] = useState([]);
  const [dtEmpresaTerminal, setDtEmpresaTerminal] = useState([]);
  const [dtEmpresaTerminalContingencia, setDtEmpresaTerminalContingencia] =
    useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [driver, setDriver] = useState(false);

  const fetchTodayRecords = async () => {
    try {
      const response = await axios.get(
        process.env.BACKEND_URL + "/api/ver_empresas_terminal_solo_cps",
        {
          headers: {
            Authorization: "Bearer " + store.token,
          },
        }
      );
      setRecords(response.data.empresas_terminal);
      setFilteredRecords(response.data.empresas_terminal); // Inicializa el filtrado
    } catch (error) {
      console.error("Error al obtener registros de hoy", error);
    } finally {
      setLoading(false); // Cambia a `false` al terminar la carga
    }
  };

  useEffect(() => {
    fetchTodayRecords();
  }, []);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    filterAndSortRecords(e.target.value, statusFilter, sortOrder);
  };

  const handleStatusChange = (e) => {
    setStatusFilter(e.target.value);
    filterAndSortRecords(searchTerm, e.target.value, sortOrder);
  };

  const handleSortOrderChange = (e) => {
    setSortOrder(e.target.value);
    filterAndSortRecords(searchTerm, statusFilter, e.target.value);
  };

  const filterAndSortRecords = (search, status, order) => {
    let filtered = records.filter((record) => {
      return (
        (!status || record.empresa.status === status) &&
        (!search ||
          record.empresa.nombre.toLowerCase().includes(search.toLowerCase()) ||
          record.empresa.rif.toLowerCase().includes(search.toLowerCase()))
      );
    });

    setFilteredRecords(filtered);
  };

  const handleChangeStatusRoute = async (id, currentStatus) => {
    try {
      // Mostrar confirmación al usuario
      const result = await MySwal.fire({
        title: "¿Estás seguro?",
        text: `La ruta está actualmente ${currentStatus === "ACTIVE" ? "ACTIVA" : "INACTIVA"}. ¿Quieres cambiar su estado?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Sí, cambiar estado",
        cancelButtonText: "Cancelar",
      });

      // Si el usuario confirma, procede con la solicitud
      if (result.isConfirmed) {
        // Mostrar ventana de carga
        MySwal.fire({
          title: "Procesando...",
          text: "Actualizando estado de la ruta. Por favor, espera.",
          allowOutsideClick: false,
          didOpen: () => {
            MySwal.showLoading();
          },
        });

        // Solicitar cambio de estado al backend
        const response = await axios.put(
          `${process.env.BACKEND_URL}/api/ruta/${id}/updateStatus`,
          {}, // Sin cuerpo de datos
          {
            headers: {
              Authorization: `Bearer ${store.token}`,
            },
          }
        );

        // Cerrar ventana de carga y mostrar resultado
        MySwal.fire({
          title: "¡Éxito!",
          text: response.data.message,
          icon: "success",
        });

        fetchTodayRecords();
      }
    } catch (error) {
      // Manejo de errores
      MySwal.fire({
        title: "Error",
        text:
          error.response?.data?.message ||
          "Ocurrió un error al intentar actualizar el estado de la ruta.",
        icon: "error",
      });
    }
  };

  const showRouteDetails = async (id) => {
    try {
      // Mostrar ventana de carga
      MySwal.fire({
        title: "Cargando...",
        html: `<p style="text-align:center; margin:20px;">Cargando Rutas de la Empresa Seleccionada...</p>`,
        allowOutsideClick: false,
        didOpen: () => {
          MySwal.showLoading();
        },
      });

      // Solicitar datos al backend
      const response = await axios.get(
        `${process.env.BACKEND_URL}/api/empresa-terminal/${id}`,
        {
          headers: {
            Authorization: `Bearer ${store.token}`,
          },
        }
      );

      // Extraer datos de la empresa
      const empresa = response.data.empresa;
      const terminal = response.data.terminal;
      const rutas = empresa.rutas || []; // Asegurarse de que rutas sea un arreglo
      const rutasContingencia2 = empresa.rutas_contingencia || []; // Asegurarse de que rutas sea un arreglo
      setRutas(rutas); // Guardar rutas en el estado
      setRutasContingencia(rutasContingencia2); // Guardar rutas en el estado

      // Cerrar la ventana de carga
      MySwal.close();

      // Declarar la función en el contexto global
      window.navigateToAgregarHoras = (rutaId) => {
        navigate(`/ruta/${rutaId}/agregar-horas`);
      };

      // Verificar si la empresa tiene rutas
      if (rutas.length > 0 || rutasContingencia2.length > 0) {
        MySwal.fire({
          title: `Detalles de Rutas para ${empresa.empresa.nombre}`,
          html: `<div class="custom-table-container m-0 d-flex" style="flex-direction: column">
          <div class="d-flex" style="width: 100%; position: absolute">
              <button
                id="showRouteDetailsPdfButton"
                class="btn btn-info text-light desktop-er65g48e9r7we984fwe594"
                style="font-size: 15px; margin-right: 10px"
              >
                <i class="fa-solid fa-file-pdf m-0" style="color: white; font-size: 12px"></i>
              </button>
              <button
                id="showRouteDetailsPdfButtonMobile"
                class="btn btn-info text-light mobile-er65g48e9r7we984fwe594"
                style="font-size: 15px; margin-right: 10px"
              >
                <i class="fa-solid fa-file-pdf m-0" style="color: white; font-size: 12px"></i>
              </button>
          </div>
          <h4 style="text-align: center"> Rutas CPS </h4>
            <table class="custom-table asd56vdfg65r49g8er4e8rv6w5ef4we98f4we65f4we89f7r84erf5er4" style="width: 700px;">
              <thead>
                <tr>
                  <th class="table-header" style="font-size: 14px; min-width: 350px;">Ruta</th>
                  <th class="table-header" style="font-size: 14px; min-width: 110px;">Destino Final</th>
                  <th class="table-header" style="font-size: 14px; min-width: 90px;">Parada</th>
                  <th class="table-header" style="font-size: 14px; min-width: 90px;">Horas Terminal</th>
                  <th class="table-header" style="font-size: 14px; min-width: 90px;">Horas CPS</th>
                  <th class="table-header" style="font-size: 14px; min-width: 90px;">Estatus</th>
                  <th class="table-header" style="font-size: 14px; min-width: 180px;">Acciones</th>
                </tr>
              </thead>
              <tbody>
                ${rutas
                  .map(
                    (ruta, index) => `
                  <tr key="${index}" class="table-row">
                    <td class="table-cell" style="font-size: 12px;">${ruta.ruta_n} : ${ruta.ruta}</td>
                    <td class="table-cell" style="font-size: 12px;">${ruta.destino_final?.destino_final_detalle?.descripcion || "Sin destino"}</td>
                    <td class="table-cell" style="font-size: 12px;">${ruta.parada || "Sin parada"}</td>
                    <td class="table-cell" style="font-size: 12px;">
                      ${
                        ruta.turnos && ruta.turnos.length > 0
                          ? ruta.turnos
                              .map(
                                (turno) => `<div>${turno.turno_hora_real}</div>`
                              )
                              .join("")
                          : "No hay turnos disponibles"
                      }
                    </td>
                    <td class="table-cell" style="font-size: 12px;">
                      ${
                        ruta.turnos_cps && ruta.turnos_cps.length > 0
                          ? ruta.turnos_cps
                              .map((turno) => `<div>${turno.hora}</div>`)
                              .join("")
                          : "No hay turnos disponibles"
                      }
                    </td>
                    <td class="table-cell" style="font-size: 12px;">
                      <span style="color: ${
                        ruta.status === "ACTIVE" ? "green" : "red"
                      };">
                        ${ruta.status === "ACTIVE" ? "ACTIVO" : "INACTIVO"}
                      </span>
                    </td>
                    <td class="table-cell" style="font-size: 12px;">
                    <div class="d-flex">
                      <button
                        class="btn btn-secondary text-light navigate-to-horas"
                        style="font-size: 12px; margin-right: 10px"
                        data-id="${ruta.id}"
                      >
                        <i class="fa-solid fa-stopwatch m-0" style="color: white; font-size: 12px"></i>
                      </button>
                      <button
                            class="btn d-flex btn-warning route-edit"
                            data-id="${ruta.id}""
                            style="margin-right: 5px"
                          >
                          <i
                            class="fa-solid fa-pencil text-light"
                            style="font-size: 17px"
                          ></i>
                        </button>
                      <button
                          class="btn d-flex btn-${ruta.status == "ACTIVE" ? "success" : "danger"} update-status"
                          data-id="${ruta.id}" data-status="${ruta.status}"
                        >
                        <i
                          class="fa-solid fa-lock${ruta.status == "ACTIVE" ? "-open" : ""}"
                          style="font-size: 17px"
                        ></i>
                      </button>
                    </div>
                    </td>
                  </tr>`
                  )
                  .join("")}
              </tbody>
            </table>
            <h4 style="text-align: center"> Rutas Contingencia </h4>
            <table class="custom-table asd56vdfg65r49g8er4e8rv6w5ef4we98f4we65f4we89f7r84erf5er4" style="width: 700px;">
              <thead>
                <tr>
                  <th class="table-header2" style="font-size: 14px; min-width: 350px;">Ruta</th>
                  <th class="table-header2" style="font-size: 14px; min-width: 110px;">Destino Final</th>
                  <th class="table-header2" style="font-size: 14px; min-width: 90px;">Parada</th>
                  <th class="table-header2" style="font-size: 14px; min-width: 90px;">Horas Terminal</th>
                  <th class="table-header2" style="font-size: 14px; min-width: 90px;">Horas CPS</th>
                  <th class="table-header2" style="font-size: 14px; min-width: 90px;">Estatus</th>
                  <th class="table-header2" style="font-size: 14px; min-width: 180px;">Acciones</th>
                </tr>
              </thead>
              <tbody>
                ${rutasContingencia2
                  .map(
                    (ruta, index) => `
                  <tr key="${index}" class="table-row">
                    <td class="table-cell" style="font-size: 12px;">CONTINGENCIA 00${index + 1}: ${ruta.ruta}</td>
                    <td class="table-cell" style="font-size: 12px;">${ruta.destino_final?.destino_final_detalle?.descripcion || "Sin destino"}</td>
                    <td class="table-cell" style="font-size: 12px;">${ruta.parada || "Sin parada"}</td>
                    <td class="table-cell" style="font-size: 12px;">
                      ${
                        ruta.turnos && ruta.turnos.length > 0
                          ? ruta.turnos
                              .map(
                                (turno) => `<div>${turno.turno_hora_real}</div>`
                              )
                              .join("")
                          : "No hay turnos disponibles"
                      }
                    </td>
                    <td class="table-cell" style="font-size: 12px;">
                      ${
                        ruta.turnos_cps && ruta.turnos_cps.length > 0
                          ? ruta.turnos_cps
                              .map((turno) => `<div>${turno.hora}</div>`)
                              .join("")
                          : "No hay turnos disponibles"
                      }
                    </td>
                    <td class="table-cell" style="font-size: 12px;">
                      <span style="color: ${
                        ruta.status === "ACTIVE" ? "green" : "red"
                      };">
                        ${ruta.status === "ACTIVE" ? "ACTIVO" : "INACTIVO"}
                      </span>
                    </td>
                    <td>
                      <div class="d-flex">
                        <button
                          class="btn btn-secondary text-light contingenciaNavigate-to-horas"
                          style="font-size: 12px; margin-right: 10px"
                          data-idd="${ruta.id}"
                        >
                          <i class="fa-solid fa-stopwatch m-0" style="color: white; font-size: 12px"></i>
                        </button>
                        <button
                            class="btn d-flex btn-warning route-contingencia-edit"
                            data-id="${ruta.id}""
                            style="margin-right: 5px"
                          >
                          <i
                            class="fa-solid fa-pencil text-light"
                            style="font-size: 17px"
                          ></i>
                        </button>
                        <button
                            class="btn d-flex btn-${ruta.status == "ACTIVE" ? "success" : "danger"} contingenciaUpdate-status"
                            data-idd="${ruta.id}" data-statuss="${ruta.status}"
                          >
                          <i
                            class="fa-solid fa-lock${ruta.status == "ACTIVE" ? "-open" : ""}"
                            style="font-size: 17px"
                          ></i>
                        </button>
                      </div>
                    </td>
                  </tr>`
                  )
                  .join("")}
              </tbody>
            </table>
          </div>`,
          confirmButtonText: "Cerrar",
          didRender: () => {
            // CPS
            document
              .querySelectorAll(".navigate-to-horas")
              .forEach((button) => {
                button.addEventListener("click", () => {
                  const rutaId = button.dataset.id; // Obtener el ID desde el atributo data-id
                  navigate(`/ruta/${rutaId}/agregar-horas`);
                  MySwal.close();
                });
              });
            const buttonRoute = document.getElementById(
              "showRouteDetailsPdfButton"
            );
            if (buttonRoute) {
              buttonRoute.addEventListener("click", () => {
                showRouteDetailsPdf(id);
              });
            }
            const buttonRouteMobile = document.getElementById(
              "showRouteDetailsPdfButtonMobile"
            );
            if (buttonRouteMobile) {
              buttonRouteMobile.addEventListener("click", () => {
                showRouteDetailsPdfDownload(id);
              });
            }
            const buttonDT = document.getElementById("showDTDetailsPdfButton");
            if (buttonDT) {
              buttonDT.addEventListener("click", () => {
                showDTDetailsPdf(id);
              });
            }
            document.querySelectorAll(".route-edit").forEach((button) => {
              button.addEventListener("click", () => {
                const conductorId = button.dataset.id; // Asegúrate de añadir el ID en el botón
                window.location.href = `/EditRoute/${conductorId}`;
              });
            });
            document
              .querySelectorAll(".route-contingencia-edit")
              .forEach((button) => {
                button.addEventListener("click", () => {
                  const conductorId = button.dataset.id; // Asegúrate de añadir el ID en el botón
                  window.location.href = `/EditRouteContingencia/${conductorId}`;
                });
              });
            const buttonDTMobile = document.getElementById(
              "showDTDetailsPdfButtonMobile"
            );
            if (buttonDTMobile) {
              buttonDT.addEventListener("click", () => {
                showDTDetailsPdfDownload(id);
              });
            }
            const buttonDriver = document.getElementById(
              "showDriverDetailsPdfButton"
            );
            if (buttonDriver) {
              buttonDriver.addEventListener("click", () => {
                showDriverDetailsPdf(id);
              });
            }
            const buttonDriverMobile = document.getElementById(
              "showDriverDetailsPdfButtonMobile"
            );
            if (buttonDriverMobile) {
              buttonDriverMobile.addEventListener("click", () => {
                showDriverDetailsPdfDownload(id);
              });
            }
            document.querySelectorAll(".update-status").forEach((button) => {
              button.addEventListener("click", async () => {
                const rutaId = button.dataset.id; // Obtener el ID de la ruta
                const currentStatus = button.dataset.status; // Obtener el estado actual
                const newStatus =
                  currentStatus === "ACTIVE" ? "INACTIVE" : "ACTIVE"; // Alternar el estado

                try {
                  MySwal.fire({
                    title: "Actualizando estado",
                    text: `La ruta está siendo marcada como ${newStatus === "ACTIVE" ? "ACTIVA" : "INACTIVA"}.`,
                    didOpen: () => {
                      MySwal.showLoading();
                    },
                  });
                  // Llamada a la API para actualizar el estado
                  const response = await axios.put(
                    `${process.env.BACKEND_URL}/api/ruta/${rutaId}/updateStatus`,
                    { status: newStatus },
                    {
                      headers: {
                        Authorization: `Bearer ${store.token}`,
                      },
                    }
                  );

                  if (response.status === 200) {
                    MySwal.fire({
                      title: "Estado actualizado",
                      text: `La ruta ha sido marcada como ${newStatus === "ACTIVE" ? "ACTIVA" : "INACTIVA"}.`,
                      icon: "success",
                    });
                    // Recargar la tabla o actualizar dinámicamente
                    showRouteDetails(id); // Vuelve a cargar los detalles
                  } else {
                    throw new Error("No se pudo actualizar el estado.");
                  }
                } catch (error) {
                  console.error("Error al actualizar el estado:", error);
                  MySwal.fire({
                    title: "Error",
                    text: "No se pudo actualizar el estado de la ruta. Inténtelo nuevamente.",
                    icon: "error",
                  });
                }
              });
            });
            // Contingencia
            document
              .querySelectorAll(".contingenciaNavigate-to-horas")
              .forEach((button) => {
                button.addEventListener("click", () => {
                  const rutaIdd = button.dataset.idd; // Obtener el ID desde el atributo data-id
                  navigate(`/rutaContingencia/${rutaIdd}/agregar-horas`);
                });
              });
            document
              .querySelectorAll(".contingenciaUpdate-status")
              .forEach((button) => {
                button.addEventListener("click", async () => {
                  const rutaIdd = button.dataset.idd; // Obtener el ID de la ruta
                  const currentStatuss = button.dataset.statuss; // Obtener el estado actual
                  const newStatus =
                    currentStatuss === "ACTIVE" ? "INACTIVE" : "ACTIVE"; // Alternar el estado

                  try {
                    MySwal.fire({
                      title: "Actualizando estado",
                      text: `La ruta está siendo marcada como ${newStatus === "ACTIVE" ? "ACTIVA" : "INACTIVA"}.`,
                      didOpen: () => {
                        MySwal.showLoading();
                      },
                    });
                    // Llamada a la API para actualizar el estado
                    const response = await axios.put(
                      `${process.env.BACKEND_URL}/api/rutaContingencia/${rutaIdd}/updateStatus`,
                      { status: newStatus },
                      {
                        headers: {
                          Authorization: `Bearer ${store.token}`,
                        },
                      }
                    );

                    if (response.status === 200) {
                      MySwal.fire({
                        title: "Estado actualizado",
                        text: `La ruta ha sido marcada como ${newStatus === "ACTIVE" ? "ACTIVA" : "INACTIVA"}.`,
                        icon: "success",
                      });
                      // Recargar la tabla o actualizar dinámicamente
                      showRouteDetails(id); // Vuelve a cargar los detalles
                    } else {
                      throw new Error("No se pudo actualizar el estado.");
                    }
                  } catch (error) {
                    console.error("Error al actualizar el estado:", error);
                    MySwal.fire({
                      title: "Error",
                      text: "No se pudo actualizar el estado de la ruta. Inténtelo nuevamente.",
                      icon: "error",
                    });
                  }
                });
              });
          },
        });
      } else {
        MySwal.fire({
          title: `No hay rutas disponibles para ${empresa.empresa.nombre}`,
          icon: "info",
          confirmButtonText: "Cerrar",
        });
      }
    } catch (error) {
      console.error("Error al obtener datos:", error);
      MySwal.fire({
        title: "Error",
        html: `<p>Error al obtener los datos de la empresa. Por favor, inténtelo de nuevo.</p>`,
        icon: "error",
        confirmButtonText: "Cerrar",
      });
    }
  };

  const showDTDetails = async (id) => {
    try {
      // Mostrar ventana de carga
      MySwal.fire({
        title: "Cargando...",
        html: `<p style="text-align:center; margin:20px;">Cargando DT de la Empresa Seleccionada...</p>`,
        allowOutsideClick: false,
        didOpen: () => {
          MySwal.showLoading();
        },
      });

      // Solicitar datos al backend
      const response = await axios.get(
        `${process.env.BACKEND_URL}/api/empresa-terminal/${id}`,
        {
          headers: {
            Authorization: `Bearer ${store.token}`,
          },
        }
      );

      // Extraer datos de la empresa
      const empresa = response.data.empresa;
      const rutas = empresa.rutas || []; // Asegurarse de que rutas sea un arreglo
      const rutasContingencia2 = empresa.rutas_contingencia || [];
      const dt = empresa.dt_terminal_empresa || [];
      const dtContingencia = empresa.dt_terminal_empresa_contingencia || [];
      setRutas(rutas); // Guardar rutas en el estado
      setRutasContingencia(rutasContingencia2); // Guardar rutas en el estado
      setDtEmpresaTerminal(dt);
      setDtEmpresaTerminalContingencia(dtContingencia);
      // Cerrar la ventana de carga
      MySwal.close();

      // Verificar si la empresa tiene rutas
      if (dt.length > 0 || dtContingencia.length > 0) {
        MySwal.fire({
          title: `Detalles de DT para ${empresa.empresa.nombre}`,
          html: `<div class="custom-table-container m-0 d-flex" style="flex-direction: column">
          <div class="d-flex" style="width: 100%; position: absolute">
              <button
                id="showDTDetailsPdfButton"
                class="btn btn-info text-light desktop-er65g48e9r7we984fwe594"
                style="font-size: 15px; margin-right: 10px"
              >
                <i class="fa-solid fa-file-pdf m-0" style="color: white; font-size: 12px"></i>
              </button>
              <button
                id="showDTDetailsPdfButtonMobile"
                class="btn btn-info text-light mobile-er65g48e9r7we984fwe594"
                style="font-size: 15px; margin-right: 10px"
              >
                <i class="fa-solid fa-file-pdf m-0" style="color: white; font-size: 12px"></i>
              </button>
          </div>
          <h4 style="text-align: center"> DT </h4>
            <table class="custom-table asd56vdfg65r49g8er4e8rv6w5ef4we98f4we65f4we89f7r84erf5er4" style="width: 100%;">
              <thead>
                <tr>
                  <th class="table-header" style="font-size: 14px;">Placa</th>
                  <th class="table-header" style="font-size: 14px;">Estatus</th>
                  <th class="table-header" style="font-size: 14px;">Acciones</th>
                </tr>
              </thead>
              <tbody>
                ${dt
                  .map(
                    (dt, index) => `
                  <tr key="${index}" class="table-row">
                    <td class="table-cell" style="font-size: 12px;">${dt.placa}</td>
                    <td class="table-cell" style="font-size: 12px;">
                      <span style="color: ${
                        dt.status === "ACTIVE" ? "green" : "red"
                      };">
                        ${dt.status === "ACTIVE" ? "ACTIVO" : "INACTIVO"}
                      </span>
                    </td>
                    <td>
                      <div class="d-flex justify-content-center">
                        <button
                            class="btn d-flex btn-${dt.status == "ACTIVE" ? "success" : "danger"} dtupdate-status-dt"
                            data-id="${dt.id}" data-status="${dt.status}"
                          >
                          <i
                            class="fa-solid fa-lock${dt.status == "ACTIVE" ? "-open" : ""}"
                            style="font-size: 17px"
                          ></i>
                        </button>
                      </div>
                    </td>
                  </tr>`
                  )
                  .join("")}
              </tbody>
            </table>
            <h4 style="text-align: center"> DT Contingencia </h4>
            <table class="custom-table asd56vdfg65r49g8er4e8rv6w5ef4we98f4we65f4we89f7r84erf5er4" style="width: 100%">
              <thead>
                <tr>
                  <th class="table-header2" style="font-size: 14px;">Placa</th>
                  <th class="table-header2" style="font-size: 14px;">Estatus</th>
                  <th class="table-header2" style="font-size: 14px;">Acciones</th>
                </tr>
              </thead>
              <tbody>
              ${dtContingencia
                .map(
                  (dt, index) => `
                <tr key="${index}" class="table-row">
                  <td class="table-cell" style="font-size: 12px;">${dt.placa}</td>
                  <td class="table-cell" style="font-size: 12px;">
                    <span style="color: ${
                      dt.status === "ACTIVE" ? "green" : "red"
                    };">
                      ${dt.status === "ACTIVE" ? "ACTIVO" : "INACTIVO"}
                    </span>
                  </td>
                  <td>
                    <div class="d-flex justify-content-center">
                      <button
                          class="btn d-flex btn-${dt.status == "ACTIVE" ? "success" : "danger"} dtContingenciaUpdate-status-dt"
                          data-id="${dt.id}" data-status="${dt.status}"
                        >
                        <i
                          class="fa-solid fa-lock${dt.status == "ACTIVE" ? "-open" : ""}"
                          style="font-size: 17px"
                        ></i>
                      </button>
                    </div>
                  </td>
                </tr>`
                )
                .join("")}
              </tbody>
            </table>
          </div>`,
          confirmButtonText: "Cerrar",
          didRender: () => {
            const buttonRoute = document.getElementById(
              "showRouteDetailsPdfButton"
            );
            if (buttonRoute) {
              buttonRoute.addEventListener("click", () => {
                showRouteDetailsPdf(id);
              });
            }
            const buttonRouteMobile = document.getElementById(
              "showRouteDetailsPdfButtonMobile"
            );
            if (buttonRouteMobile) {
              buttonRouteMobile.addEventListener("click", () => {
                showRouteDetailsPdfDownload(id);
              });
            }
            const buttonDT = document.getElementById("showDTDetailsPdfButton");
            if (buttonDT) {
              buttonDT.addEventListener("click", () => {
                showDTDetailsPdf(id);
              });
            }
            const buttonDTMobile = document.getElementById(
              "showDTDetailsPdfButtonMobile"
            );
            if (buttonDTMobile) {
              buttonDTMobile.addEventListener("click", () => {
                showDTDetailsPdfDownload(id);
              });
            }
            const buttonDriver = document.getElementById(
              "showDriverDetailsPdfButton"
            );
            if (buttonDriver) {
              buttonDriver.addEventListener("click", () => {
                showDriverDetailsPdf(id);
              });
            }
            const buttonDriverMobile = document.getElementById(
              "showDriverDetailsPdfButtonMobile"
            );
            if (buttonDriverMobile) {
              buttonDriverMobile.addEventListener("click", () => {
                showDriverDetailsPdfDownload(id);
              });
            }
            document
              .querySelectorAll(".dtupdate-status-dt")
              .forEach((button) => {
                button.addEventListener("click", async () => {
                  const dt_id = button.dataset.id; // Obtener el ID de la ruta
                  const currentStatus = button.dataset.status; // Obtener el estado actual
                  const newStatus =
                    currentStatus === "ACTIVE" ? "INACTIVE" : "ACTIVE"; // Alternar el estado

                  try {
                    MySwal.fire({
                      title: "Actualizando estado",
                      text: `La DT seleccionada está siendo marcada como ${newStatus === "ACTIVE" ? "ACTIVA" : "INACTIVA"}.`,
                      didOpen: () => {
                        MySwal.showLoading();
                      },
                    });
                    // Llamada a la API para actualizar el estado
                    const response = await axios.put(
                      `${process.env.BACKEND_URL}/api/dt_status/${dt_id}/updateStatus`,
                      { status: newStatus },
                      {
                        headers: {
                          Authorization: `Bearer ${store.token}`,
                        },
                      }
                    );

                    if (response.status === 200) {
                      MySwal.fire({
                        title: "Estado actualizado",
                        text: `La DT ha sido marcada como ${newStatus === "ACTIVE" ? "ACTIVA" : "INACTIVA"}.`,
                        icon: "success",
                      });
                      // Recargar la tabla o actualizar dinámicamente
                      showDTDetails(id); // Vuelve a cargar los detalles
                    } else {
                      throw new Error("No se pudo actualizar el estado.");
                    }
                  } catch (error) {
                    console.error("Error al actualizar el estado:", error);
                    MySwal.fire({
                      title: "Error",
                      text: "No se pudo actualizar el estado de la DT. Inténtelo nuevamente.",
                      icon: "error",
                    });
                  }
                });
              });
            document
              .querySelectorAll(".dtContingenciaUpdate-status-dt")
              .forEach((button) => {
                button.addEventListener("click", async () => {
                  const dt_id = button.dataset.id; // Obtener el ID de la ruta
                  const currentStatus = button.dataset.status; // Obtener el estado actual
                  const newStatus =
                    currentStatus === "ACTIVE" ? "INACTIVE" : "ACTIVE"; // Alternar el estado

                  try {
                    MySwal.fire({
                      title: "Actualizando estado",
                      text: `La DT seleccionada está siendo marcada como ${newStatus === "ACTIVE" ? "ACTIVA" : "INACTIVA"}.`,
                      didOpen: () => {
                        MySwal.showLoading();
                      },
                    });
                    // Llamada a la API para actualizar el estado
                    const response = await axios.put(
                      `${process.env.BACKEND_URL}/api/dt_status_contingencia/${dt_id}/updateStatus`,
                      { status: newStatus },
                      {
                        headers: {
                          Authorization: `Bearer ${store.token}`,
                        },
                      }
                    );

                    if (response.status === 200) {
                      MySwal.fire({
                        title: "Estado actualizado",
                        text: `La DT ha sido marcada como ${newStatus === "ACTIVE" ? "ACTIVA" : "INACTIVA"}.`,
                        icon: "success",
                      });
                      // Recargar la tabla o actualizar dinámicamente
                      showDTDetails(id); // Vuelve a cargar los detalles
                    } else {
                      throw new Error("No se pudo actualizar el estado.");
                    }
                  } catch (error) {
                    console.error("Error al actualizar el estado:", error);
                    MySwal.fire({
                      title: "Error",
                      text: "No se pudo actualizar el estado de la DT. Inténtelo nuevamente.",
                      icon: "error",
                    });
                  }
                });
              });
          },
        });
      } else {
        MySwal.fire({
          title: `No hay DTs disponibles para ${empresa.empresa.nombre}`,
          icon: "info",
          confirmButtonText: "Cerrar",
        });
      }
    } catch (error) {
      console.error("Error al obtener datos:", error);
      MySwal.fire({
        title: "Error",
        html: `<p>Error al obtener los datos de la empresa. Por favor, inténtelo de nuevo.</p>`,
        icon: "error",
        confirmButtonText: "Cerrar",
      });
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString); // Asegúrate de que `dateString` esté en formato válido
    const day = String(date.getUTCDate()).padStart(2, "0"); // Usa UTC para evitar problemas de zonas horarias
    const month = String(date.getUTCMonth() + 1).padStart(2, "0"); // Meses son 0 indexados
    const year = date.getUTCFullYear();
    return `${day}-${month}-${year}`;
  };
  const showDriverDetails = async (id) => {
    try {
      // Mostrar ventana de carga
      MySwal.fire({
        title: "Cargando...",
        html: `<p style="text-align:center; margin:20px;">Cargando Conductores de la Empresa Seleccionada...</p>`,
        allowOutsideClick: false,
        didOpen: () => {
          MySwal.showLoading();
        },
      });

      // Solicitar datos al backend
      const response = await axios.get(
        `${process.env.BACKEND_URL}/api/empresa-terminal/${id}`,
        {
          headers: {
            Authorization: `Bearer ${store.token}`,
          },
        }
      );

      // Extraer datos de la empresa
      const empresa = response.data.empresa;
      const rutas = empresa.rutas || []; // Asegurarse de que rutas sea un arreglo
      const rutasContingencia2 = empresa.rutas_contingencia || [];
      const dt = empresa.dt_terminal_empresa || [];
      const dtContingencia = empresa.dt_terminal_empresa_contingencia || [];
      const conductor = empresa.conductores_empresa_terminal || [];
      setRutas(rutas); // Guardar rutas en el estado
      setRutasContingencia(rutasContingencia2); // Guardar rutas en el estado
      setDtEmpresaTerminal(dt);
      setDtEmpresaTerminalContingencia(dtContingencia);
      setDriver(conductor);
      console.log(conductor);
      // Cerrar la ventana de carga
      MySwal.close();

      // Verificar si la empresa tiene rutas
      if (conductor.length > 0) {
        MySwal.fire({
          title: `Detalles de Conductores para ${empresa.empresa.nombre}`,
          html: `<div class="custom-table-container m-0 d-flex" style="flex-direction: column">
          <div class="d-flex" style="width: 100%; position: absolute">
              <button
                id="showDriverDetailsPdfButton"
                class="btn btn-info text-light desktop-er65g48e9r7we984fwe594"
                style="font-size: 15px; margin-right: 10px"
              >
                <i class="fa-solid fa-file-pdf m-0" style="color: white; font-size: 12px"></i>
              </button>
              <button
                id="showDriverDetailsPdfButtonMobile"
                class="btn btn-info text-light mobile-er65g48e9r7we984fwe594"
                style="font-size: 15px; margin-right: 10px"
              >
                <i class="fa-solid fa-file-pdf m-0" style="color: white; font-size: 12px"></i>
              </button>
          </div>
          <h4 style="text-align: center"> Conductores </h4>
            <table class="custom-table asd56vdfg65r49g8er4e8rv6w5ef4we98f4we65f4we89f7r84erf5er4" style="width: 800px;">
              <thead>
                <tr>
                  <th class="table-header" style="font-size: 14px;">Foto</th>
                  <th class="table-header" style="font-size: 14px;">Nombre y Apellido</th>
                  <th class="table-header" style="font-size: 14px;">Nacimiento</th>
                  <th class="table-header" style="font-size: 14px;">Cédula</th>
                  <th class="table-header" style="font-size: 14px;">Vencimiento</th>
                  <th class="table-header" style="font-size: 14px;">Estatus</th>
                  <th class="table-header" style="font-size: 14px;">Acciones</th>
                </tr>
              </thead>
              <tbody>
                ${conductor
                  .map(
                    (conductor, index) => `
                  <tr key="${index}" class="table-row">
                    <td class="table-cell" style="font-size: 12px;">
                      <div class="photo-driver-list" style="background-image: url(${conductor.photo_cara_link})">
                      </div>
                    </td>
                    <td class="table-cell" style="font-size: 12px;">
                      ${conductor.first_name + " " + conductor.last_name}
                    </td>
                    <td class="table-cell" style="font-size: 12px;">
                      ${formatDate(conductor.fecha_nacimiento)}
                    </td>
                    <td class="table-cell" style="font-size: 12px;">
                      ${conductor.nacionalidad + "-" + conductor.cedula}
                    </td>
                    <td class="table-cell" style="font-size: 12px;">
                      ${formatDate(conductor.fecha_vencimiento_licencia)}
                    </td>
                    <td class="table-cell" style="font-size: 12px;">
                      <span style="color: ${
                        conductor.status === "ACTIVE" ? "green" : "red"
                      };">
                        ${conductor.status === "ACTIVE" ? "ACTIVO" : "INACTIVO"}
                      </span>
                    </td>
                    <td>
                      <div class="d-flex justify-content-center">
                        <button
                            class="btn d-flex btn-${conductor.status == "ACTIVE" ? "success" : "danger"} driver-change-status"
                            data-id="${conductor.id}" data-status="${conductor.status}"
                            style="margin-right: 5px"
                          >
                          <i
                            class="fa-solid fa-lock${conductor.status == "ACTIVE" ? "-open" : ""}"
                            style="font-size: 17px"
                          ></i>
                        </button>
                        <button
                            class="btn d-flex btn-warning driver-edit"
                            data-id="${conductor.id}""
                            style="margin-right: 5px"
                          >
                          <i
                            class="fa-solid fa-pencil text-light"
                            style="font-size: 17px"
                          ></i>
                        </button>
                        <button
                            class="btn d-flex btn-danger driver-delete"
                            data-id="${conductor.id}""
                            data-empresa="${conductor.empresa_terminal_id}""
                          >
                          <i
                            class="fa-solid fa-trash text-light"
                            style="font-size: 17px"
                          ></i>
                        </button>
                      </div>
                    </td>
                  </tr>`
                  )
                  .join("")}
              </tbody>
            </table>
          </div>`,
          confirmButtonText: "Cerrar",
          didRender: () => {
            const buttonRoute = document.getElementById(
              "showRouteDetailsPdfButton"
            );
            if (buttonRoute) {
              buttonRoute.addEventListener("click", () => {
                showRouteDetailsPdf(id);
              });
            }
            const buttonRouteMobile = document.getElementById(
              "showRouteDetailsPdfButtonMobile"
            );
            if (buttonRouteMobile) {
              buttonRouteMobile.addEventListener("click", () => {
                showRouteDetailsPdfDownload(id);
              });
            }
            const buttonDT = document.getElementById("showDTDetailsPdfButton");
            if (buttonDT) {
              buttonDT.addEventListener("click", () => {
                showDTDetailsPdf(id);
              });
            }
            const buttonDTMobile = document.getElementById(
              "showDTDetailsPdfButtonMobile"
            );
            if (buttonDTMobile) {
              buttonDT.addEventListener("click", () => {
                showDTDetailsPdfDownload(id);
              });
            }
            const buttonDriver = document.getElementById(
              "showDriverDetailsPdfButton"
            );
            if (buttonDriver) {
              buttonDriver.addEventListener("click", () => {
                showDriverDetailsPdf(id);
              });
            }
            const buttonDriverMobile = document.getElementById(
              "showDriverDetailsPdfButtonMobile"
            );
            if (buttonDriverMobile) {
              buttonDriverMobile.addEventListener("click", () => {
                showDriverDetailsPdfDownload(id);
              });
            }
            document.querySelectorAll(".driver-edit").forEach((button) => {
              button.addEventListener("click", () => {
                const conductorId = button.dataset.id; // Asegúrate de añadir el ID en el botón
                window.location.href = `/EditConductor/${conductorId}`;
              });
            });
            document.querySelectorAll(".driver-delete").forEach((button) => {
              button.addEventListener("click", async () => {
                const dt_id = button.dataset.id; // Obtener el ID del conductor
                const empresaId = button.dataset.empresa; // Obtener el ID del conductor

                try {
                  // Mostrar un cuadro de confirmación antes de proceder
                  const result = await MySwal.fire({
                    title: "¿Estás seguro?",
                    text: "Esta acción eliminará permanentemente al conductor. ¿Deseas continuar?",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Sí, eliminar",
                    cancelButtonText: "Cancelar",
                  });

                  if (result.isConfirmed) {
                    // Mostrar un mensaje de carga mientras se realiza la eliminación
                    MySwal.fire({
                      title: "Eliminando Conductor",
                      text: "Por favor espera...",
                      didOpen: () => {
                        MySwal.showLoading();
                      },
                    });

                    // Llamada a la API para eliminar el conductor
                    const response = await axios.delete(
                      `${process.env.BACKEND_URL}/api/ConductorEmpresasTerminal/${dt_id}`,
                      {
                        headers: {
                          Authorization: `Bearer ${store.token}`,
                        },
                      }
                    );

                    if (response.status === 200) {
                      // Mostrar mensaje de éxito
                      MySwal.fire({
                        title: "Conductor eliminado",
                        text:
                          response.data.message ||
                          "El conductor ha sido eliminado correctamente.",
                        icon: "success",
                      });
                      // Actualizar la tabla o recargar la página si es necesario
                      showDriverDetails(empresaId); // Ajusta según tu lógica de recarga o actualización
                    } else {
                      throw new Error("No se pudo eliminar el conductor.");
                    }
                  }
                } catch (error) {
                  console.error("Error al eliminar el conductor:", error);

                  // Obtener mensaje del backend si está disponible
                  const errorMessage =
                    error.response?.data?.message ||
                    "No se pudo eliminar el conductor. Inténtelo nuevamente.";

                  // Mostrar mensaje de error
                  MySwal.fire({
                    title: "Error",
                    text: errorMessage,
                    icon: "error",
                  });
                }
              });
            });

            document
              .querySelectorAll(".driver-change-status")
              .forEach((button) => {
                button.addEventListener("click", async () => {
                  const dt_id = button.dataset.id; // Obtener el ID de la ruta
                  const currentStatus = button.dataset.status; // Obtener el estado actual
                  const newStatus =
                    currentStatus === "ACTIVE" ? "INACTIVE" : "ACTIVE"; // Alternar el estado

                  try {
                    MySwal.fire({
                      title: "Actualizando estado",
                      text: `El conductor seleccionado está siendo marcada como ${newStatus === "ACTIVE" ? "ACTIVA" : "INACTIVA"}.`,
                      didOpen: () => {
                        MySwal.showLoading();
                      },
                    });
                    // Llamada a la API para actualizar el estado
                    const response = await axios.put(
                      `${process.env.BACKEND_URL}/api/change_driver/${dt_id}/updateStatus`,
                      { status: newStatus },
                      {
                        headers: {
                          Authorization: `Bearer ${store.token}`,
                        },
                      }
                    );

                    if (response.status === 200) {
                      MySwal.fire({
                        title: "Estado actualizado",
                        text: `El Conductor ha sido marcado como ${newStatus === "ACTIVE" ? "ACTIVA" : "INACTIVA"}.`,
                        icon: "success",
                      });
                      // Recargar la tabla o actualizar dinámicamente
                      showDriverDetails(id); // Vuelve a cargar los detalles
                    } else {
                      throw new Error("No se pudo actualizar el estado.");
                    }
                  } catch (error) {
                    console.error("Error al actualizar el estado:", error);
                    MySwal.fire({
                      title: "Error",
                      text: "No se pudo actualizar el estado del Conductor. Inténtelo nuevamente.",
                      icon: "error",
                    });
                  }
                });
              });
            document
              .querySelectorAll(".dtContingenciaUpdate-status-dt")
              .forEach((button) => {
                button.addEventListener("click", async () => {
                  const dt_id = button.dataset.id; // Obtener el ID de la ruta
                  const currentStatus = button.dataset.status; // Obtener el estado actual
                  const newStatus =
                    currentStatus === "ACTIVE" ? "INACTIVE" : "ACTIVE"; // Alternar el estado

                  try {
                    MySwal.fire({
                      title: "Actualizando estado",
                      text: `La DT seleccionada está siendo marcada como ${newStatus === "ACTIVE" ? "ACTIVA" : "INACTIVA"}.`,
                      didOpen: () => {
                        MySwal.showLoading();
                      },
                    });
                    // Llamada a la API para actualizar el estado
                    const response = await axios.put(
                      `${process.env.BACKEND_URL}/api/dt_status_contingencia/${dt_id}/updateStatus`,
                      { status: newStatus },
                      {
                        headers: {
                          Authorization: `Bearer ${store.token}`,
                        },
                      }
                    );

                    if (response.status === 200) {
                      MySwal.fire({
                        title: "Estado actualizado",
                        text: `La DT ha sido marcada como ${newStatus === "ACTIVE" ? "ACTIVA" : "INACTIVA"}.`,
                        icon: "success",
                      });
                      // Recargar la tabla o actualizar dinámicamente
                      showDTDetails(id); // Vuelve a cargar los detalles
                    } else {
                      throw new Error("No se pudo actualizar el estado.");
                    }
                  } catch (error) {
                    console.error("Error al actualizar el estado:", error);
                    MySwal.fire({
                      title: "Error",
                      text: "No se pudo actualizar el estado de la DT. Inténtelo nuevamente.",
                      icon: "error",
                    });
                  }
                });
              });
          },
        });
      } else {
        MySwal.fire({
          title: `No hay Conductores disponibles para ${empresa.empresa.nombre}`,
          icon: "info",
          confirmButtonText: "Cerrar",
        });
      }
    } catch (error) {
      console.error("Error al obtener datos:", error);
      MySwal.fire({
        title: "Error",
        html: `<p>Error al obtener los datos de la empresa. Por favor, inténtelo de nuevo.</p>`,
        icon: "error",
        confirmButtonText: "Cerrar",
      });
    }
  };

  const showRouteDetailsPdf = async (id) => {
    try {
      setIsLoading(true);

      // Mostrar ventana de carga
      Swal.fire({
        title: "Cargando...",
        html: `<p style="text-align:center; margin:20px;">Cargando Rutas de la Empresa Seleccionada...</p>`,
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      // Solicitar datos al backend
      const response = await axios.get(
        `${process.env.BACKEND_URL}/api/empresa-terminal/${id}`,
        {
          headers: {
            Authorization: `Bearer ${store.token}`,
          },
        }
      );

      // Extraer datos de la empresa
      const empresa = response.data.empresa || [];
      const terminal = response.data.terminal;
      const rutas = empresa.rutas || [];
      const rutasContingencia2 = empresa.rutas_contingencia || [];

      // Verificar si hay rutas disponibles
      if (rutas.length > 0 || rutasContingencia2.length > 0) {
        Swal.close(); // Cerrar la ventana de carga

        // Generar el PDF
        const pdfBlob = await pdf(
          <RouteDetailsPDF
            rutas={rutas}
            rutasContingencia={rutasContingencia2}
            empresaDetalle={empresa}
            terminal={terminal}
          />
        ).toBlob();

        // Crear una URL temporal para el Blob y abrirla en una nueva ventana
        const pdfUrl = URL.createObjectURL(pdfBlob);
        window.open(pdfUrl, "_blank");
      } else {
        Swal.fire({
          title: `No hay rutas disponibles para ${empresa.empresa.nombre}`,
          icon: "info",
          confirmButtonText: "Cerrar",
        });
      }
    } catch (error) {
      console.error("Error al obtener datos:", error);
      Swal.fire({
        title: "Error",
        html: `<p>Error al obtener los datos de la empresa. Por favor, inténtelo de nuevo.</p>`,
        icon: "error",
        confirmButtonText: "Cerrar",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const showDTDetailsPdf = async (id) => {
    try {
      setIsLoading(true);

      // Mostrar ventana de carga
      Swal.fire({
        title: "Cargando...",
        html: `<p style="text-align:center; margin:20px;">Cargando Rutas de la Empresa Seleccionada...</p>`,
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      // Solicitar datos al backend
      const response = await axios.get(
        `${process.env.BACKEND_URL}/api/empresa-terminal/${id}`,
        {
          headers: {
            Authorization: `Bearer ${store.token}`,
          },
        }
      );

      // Extraer datos de la empresa
      const empresa = response.data.empresa || [];
      const terminal = response.data.terminal;
      const rutas = empresa.rutas || [];
      const rutasContingencia2 = empresa.rutas_contingencia || [];
      const dt = empresa.dt_terminal_empresa || [];
      const dtContingencia = empresa.dt_terminal_empresa_contingencia || [];

      // Verificar si hay rutas disponibles
      if (dt.length > 0 || dtContingencia.length > 0) {
        Swal.close(); // Cerrar la ventana de carga

        // Generar el PDF
        const pdfBlob = await pdf(
          <DTDetailsPDF
            dt={dt}
            dtContingencia={dtContingencia}
            empresaDetalle={empresa}
            terminal={terminal}
          />
        ).toBlob();

        // Crear una URL temporal para el Blob y abrirla en una nueva ventana
        const pdfUrl = URL.createObjectURL(pdfBlob);
        window.open(pdfUrl, "_blank");
      } else {
        Swal.fire({
          title: `No hay placas disponibles para ${empresa.empresa.nombre}`,
          icon: "info",
          confirmButtonText: "Cerrar",
        });
      }
    } catch (error) {
      console.error("Error al obtener datos:", error);
      Swal.fire({
        title: "Error",
        html: `<p>Error al obtener los datos de la empresa. Por favor, inténtelo de nuevo.</p>`,
        icon: "error",
        confirmButtonText: "Cerrar",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const showDriverDetailsPdf = async (id) => {
    try {
      setIsLoading(true);

      // Mostrar ventana de carga
      Swal.fire({
        title: "Cargando...",
        html: `<p style="text-align:center; margin:20px;">Cargando Rutas de la Empresa Seleccionada...</p>`,
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      // Solicitar datos al backend
      const response = await axios.get(
        `${process.env.BACKEND_URL}/api/empresa-terminal/${id}`,
        {
          headers: {
            Authorization: `Bearer ${store.token}`,
          },
        }
      );

      // Extraer datos de la empresa
      const empresa = response.data.empresa || [];
      const terminal = response.data.terminal;
      const rutas = empresa.rutas || [];
      const rutasContingencia2 = empresa.rutas_contingencia || [];
      const dt = empresa.dt_terminal_empresa || [];
      const dtContingencia = empresa.dt_terminal_empresa_contingencia || [];
      const driver = empresa.conductores_empresa_terminal || [];

      // Verificar si hay rutas disponibles
      if (driver.length > 0) {
        Swal.close(); // Cerrar la ventana de carga

        // Generar el PDF
        const pdfBlob = await pdf(
          <DriverDetailsPDF
            driver={driver}
            empresaDetalle={empresa}
            terminal={terminal}
          />
        ).toBlob();

        // Crear una URL temporal para el Blob y abrirla en una nueva ventana
        const pdfUrl = URL.createObjectURL(pdfBlob);
        window.open(pdfUrl, "_blank");
      } else {
        Swal.fire({
          title: `No hay Conductores disponibles para ${empresa.empresa.nombre}`,
          icon: "info",
          confirmButtonText: "Cerrar",
        });
      }
    } catch (error) {
      console.error("Error al obtener datos:", error);
      Swal.fire({
        title: "Error",
        html: `<p>Error al obtener los datos de la empresa. Por favor, inténtelo de nuevo.</p>`,
        icon: "error",
        confirmButtonText: "Cerrar",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const showRouteDetailsPdfDownload = async (id) => {
    try {
      setIsLoading(true);

      // Mostrar ventana de carga
      Swal.fire({
        title: "Cargando...",
        html: `<p style="text-align:center; margin:20px;">Cargando Rutas de la Empresa Seleccionada...</p>`,
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      // Solicitar datos al backend
      const response = await axios.get(
        `${process.env.BACKEND_URL}/api/empresa-terminal/${id}`,
        {
          headers: {
            Authorization: `Bearer ${store.token}`,
          },
        }
      );

      // Extraer datos de la empresa
      const empresa = response.data.empresa;
      const terminal = response.data.terminal;
      const rutas = empresa.rutas || [];
      const rutasContingencia2 = empresa.rutas_contingencia || [];
      const empresaDetalle = empresa || [];

      // Verificar si hay rutas disponibles
      if (rutas.length > 0 || rutasContingencia2.length > 0) {
        Swal.close(); // Cerrar la ventana de carga

        // Generar el PDF
        const pdfBlob = await pdf(
          <RouteDetailsPDF
            rutas={rutas}
            rutasContingencia={rutasContingencia2}
            empresaDetalle={empresaDetalle}
            terminal={terminal}
          />
        ).toBlob();

        // Crear un enlace de descarga programático
        const link = document.createElement("a");
        link.href = URL.createObjectURL(pdfBlob);
        link.download = "ruta-details.pdf";
        document.body.appendChild(link);
        link.click(); // Simular clic para descargar el archivo
        document.body.removeChild(link);
      } else {
        Swal.fire({
          title: `No hay rutas disponibles para ${empresa.empresa.nombre}`,
          icon: "info",
          confirmButtonText: "Cerrar",
        });
      }
    } catch (error) {
      console.error("Error al obtener datos:", error);
      Swal.fire({
        title: "Error",
        html: `<p>Error al obtener los datos de la empresa. Por favor, inténtelo de nuevo.</p>`,
        icon: "error",
        confirmButtonText: "Cerrar",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const showDTDetailsPdfDownload = async (id) => {
    try {
      setIsLoading(true);

      // Mostrar ventana de carga
      Swal.fire({
        title: "Cargando...",
        html: `<p style="text-align:center; margin:20px;">Cargando DT de la Empresa Seleccionada...</p>`,
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      // Solicitar datos al backend
      const response = await axios.get(
        `${process.env.BACKEND_URL}/api/empresa-terminal/${id}`,
        {
          headers: {
            Authorization: `Bearer ${store.token}`,
          },
        }
      );

      // Extraer datos de la empresa
      const empresa = response.data.empresa;
      const terminal = response.data.terminal;
      const rutas = empresa.rutas || [];
      const rutasContingencia2 = empresa.rutas_contingencia || [];
      const empresaDetalle = empresa || [];
      const dt = empresa.dt_terminal_empresa || [];
      const dtContingencia = empresa.dt_terminal_empresa_contingencia || [];

      // Verificar si hay rutas disponibles
      if (dt.length > 0) {
        Swal.close(); // Cerrar la ventana de carga

        // Generar el PDF
        const pdfBlob = await pdf(
          <DTDetailsPDF
            dt={dt}
            dtContingencia={dtContingencia}
            empresaDetalle={empresaDetalle}
            terminal={terminal}
          />
        ).toBlob();

        // Crear un enlace de descarga programático
        const link = document.createElement("a");
        link.href = URL.createObjectURL(pdfBlob);
        link.download = "dt-details.pdf";
        document.body.appendChild(link);
        link.click(); // Simular clic para descargar el archivo
        document.body.removeChild(link);
      } else {
        Swal.fire({
          title: `No hay DT disponibles para ${empresa.empresa.nombre}`,
          icon: "info",
          confirmButtonText: "Cerrar",
        });
      }
    } catch (error) {
      console.error("Error al obtener datos:", error);
      Swal.fire({
        title: "Error",
        html: `<p>Error al obtener los datos de la empresa. Por favor, inténtelo de nuevo.</p>`,
        icon: "error",
        confirmButtonText: "Cerrar",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const showDriverDetailsPdfDownload = async (id) => {
    try {
      setIsLoading(true);

      // Mostrar ventana de carga
      Swal.fire({
        title: "Cargando...",
        html: `<p style="text-align:center; margin:20px;">Cargando Conductores de la Empresa Seleccionada...</p>`,
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      // Solicitar datos al backend
      const response = await axios.get(
        `${process.env.BACKEND_URL}/api/empresa-terminal/${id}`,
        {
          headers: {
            Authorization: `Bearer ${store.token}`,
          },
        }
      );

      // Extraer datos de la empresa
      const empresa = response.data.empresa;
      const terminal = response.data.terminal;
      const rutas = empresa.rutas || [];
      const rutasContingencia2 = empresa.rutas_contingencia || [];
      const empresaDetalle = empresa || [];
      const dt = empresa.dt_terminal_empresa || [];
      const dtContingencia = empresa.dt_terminal_empresa_contingencia || [];
      const driver = empresa.conductores_empresa_terminal || [];

      // Verificar si hay rutas disponibles
      if (dt.length > 0) {
        Swal.close(); // Cerrar la ventana de carga

        // Generar el PDF
        const pdfBlob = await pdf(
          <DriverDetailsPDF
            driver={driver}
            empresaDetalle={empresaDetalle}
            terminal={terminal}
          />
        ).toBlob();

        // Crear un enlace de descarga programático
        const link = document.createElement("a");
        link.href = URL.createObjectURL(pdfBlob);
        link.download = "conductores-details.pdf";
        document.body.appendChild(link);
        link.click(); // Simular clic para descargar el archivo
        document.body.removeChild(link);
      } else {
        Swal.fire({
          title: `No hay Conductores disponibles para ${empresa.empresa.nombre}`,
          icon: "info",
          confirmButtonText: "Cerrar",
        });
      }
    } catch (error) {
      console.error("Error al obtener datos:", error);
      Swal.fire({
        title: "Error",
        html: `<p>Error al obtener los datos de la empresa. Por favor, inténtelo de nuevo.</p>`,
        icon: "error",
        confirmButtonText: "Cerrar",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdateStatus = async (id, status) => {
    try {
      // Variable para almacenar el resultado de la confirmación
      let result;

      // Confirmación antes de realizar la acción
      if (status === "ACTIVE") {
        // Si el estado es ACTIVE, mostrar la confirmación para bloquear
        result = await MySwal.fire({
          title: "¿Estás seguro de que quieres bloquear esta Empresa?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Sí, bloquear",
          cancelButtonText: "Cancelar",
        });
      } else {
        // Si el estado no es ACTIVE, mostrar la confirmación para eliminar
        result = await MySwal.fire({
          title: "¿Estás seguro de que quieres desbloquear esta Empresa?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Sí, desbloquear",
          cancelButtonText: "Cancelar",
        });
      }

      // Si el usuario confirma la acción
      if (result.isConfirmed) {
        try {
          await axios.put(
            `${process.env.BACKEND_URL}/api/empresadeterminal/${id}/updateStatus`,
            { status: status }, // Enviar la información necesaria en el cuerpo de la solicitud
            {
              headers: {
                Authorization: `Bearer ${store.token}`, // Enviar el token en los encabezados
              },
            }
          );

          // Notificar al usuario que el registro fue eliminado o bloqueado
          MySwal.fire(
            "Éxito",
            status === "ACTIVE"
              ? "La Empresa ha sido bloqueada."
              : "La Empresa ha sido desbloqueada correctamente.",
            "success"
          );

          // Refrescar los registros
          fetchTodayRecords();
        } catch (error) {
          console.error("Error al actualizar el registro", error);
          MySwal.fire(
            "Error",
            "Hubo un problema al realizar la acción.",
            "error"
          );
        }
      } else {
        console.log("Acción cancelada");
      }
    } catch (error) {
      console.error("Error al mostrar la confirmación", error);
      MySwal.fire(
        "Error",
        "Hubo un problema al mostrar la confirmación.",
        "error"
      );
    }
  };

  return (
    <div
      className="container-fluid contarinerGeneralUserHomejs h-auto"
      style={{ paddingBottom: "40px" }}
    >
      {/* Filtro y Ordenación */}
      <div className="container asklnduiashndaijsnxansxsaas6548 m-0 p-0">
        <div className="filter-bar p-3 sd56f4df98g4f8g9hb7er89g4ewr89e7r9we4f98ef9we4v4ds2">
          <div
            style={{ display: "flex", alignItems: "center", color: "white" }}
            className="sddv4er98f4we9cw4edivderegis"
          >
            <i
              className="fa-solid fa-magnifying-glass"
              style={{ fontSize: "20px" }}
            ></i>
            <p style={{ margin: "0", marginLeft: "7px", fontSize: "20px" }}>
              Buscar
            </p>
          </div>
          <div className="input-group-login2">
            <input
              type="text"
              value={searchTerm}
              onChange={handleSearchChange}
              className="inputDeLogin inputDeLoginEspecialMovil"
              name="buscador"
              style={{ borderRadius: "20px", padding: "10px 15px" }}
            />
            <label for="buscador">Buscar por rif o nombre de Empresa</label>
          </div>
        </div>
        {loading ? (
          <div style={{ textAlign: "center", padding: "20px" }}>
            <img
              src="https://res.cloudinary.com/dirpdlfbu/image/upload/v1731494258/1/dvhl0hwbx4adb8uceymr.gif"
              alt="Cargando..."
              style={{ width: "150px" }}
            />
          </div>
        ) : (
          <div className="contianer-fluid p-0 m-0 w-100">
            <div className="container asklnduiashndaijsnxansxsaas mobile-er65g48e9r7we984fwe594 p-0">
              {filteredRecords.map((record) => {
                const statusConfig =
                  EstatusIcon[record.status] || EstatusIcon.INACTIVO;

                return (
                  <div
                    key={record.id}
                    className="container asklnduiashndaijsnxansxsaas"
                  >
                    <div
                      className="container asune-rv56er48v9wec-s2 mgjmnh98-sdf9w8e7f9wewefwes"
                      style={{ height: "auto" }}
                    >
                      <div
                        className="container aoshdnuqiwhnd897"
                        style={{ flexDirection: "column" }}
                      >
                        <div
                          className="container ds897fq4w9dc9w8c4-we89c7w2 w-100"
                          style={{ width: "100%" }}
                        >
                          <p
                            style={{
                              margin: "0",
                              fontSize: "10px",
                            }}
                            id="pderegistros"
                          >
                            {record.empresa.rif} -{" "}
                            <strong>{record.taquilla}</strong>
                          </p>
                          <h5 style={{ marginTop: "15px" }}>
                            {record.empresa.nombre}
                          </h5>
                        </div>
                        <div
                          className="container ds9f848dsfwef498we4fwd-57982 w-20"
                          style={{
                            width: "100%",
                            height: "100% !important",
                            display: "flex",
                            justifyContent: "end",
                            alignItems: "center",
                          }}
                        >
                          <button
                            className={`btn d-flex btn-${record.status === "ACTIVE" ? "success" : "danger"}`}
                            onClick={() =>
                              handleUpdateStatus(record.id, record.status)
                            }
                            style={{
                              color: "white",
                              flexDirection: "row",
                              justifyContent: "center",
                              alignItems: "center",
                              marginRight: "10px",
                            }}
                          >
                            <i
                              className={`fa-solid fa-lock${record.status === "ACTIVE" ? "-open" : ""}`}
                              style={{ fontSize: "17px" }}
                            ></i>
                          </button>
                          <button
                            className="btn btn-info d-flex"
                            onClick={() => showDriverDetails(record.id)}
                            style={{
                              color: "white",
                              flexDirection: "row",
                              justifyContent: "center",
                              alignItems: "center",
                              marginRight: "10px",
                            }}
                          >
                            <i
                              className="fa-solid fa-id-card"
                              style={{ fontSize: "17px" }}
                            ></i>
                          </button>
                          <button
                            className="btn btn-warning d-flex"
                            onClick={() => showDTDetails(record.id)}
                            style={{
                              color: "white",
                              flexDirection: "row",
                              justifyContent: "center",
                              alignItems: "center",
                              marginRight: "10px",
                            }}
                          >
                            <i
                              className="fa-solid fa-bus"
                              style={{ fontSize: "17px" }}
                            ></i>
                          </button>
                          <button
                            className="btn btn-secondary d-flex"
                            onClick={() => showRouteDetails(record.id)}
                            style={{
                              color: "white",
                              flexDirection: "row",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <i
                              className="fa-solid fa-route"
                              style={{ fontSize: "17px" }}
                            ></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            {/* Vista Escritorio::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::.. */}
            <div className="custom-table-container desktop-er65g48e9r7we984fwe594 m-0">
              <table className="custom-table rounded-table">
                <thead>
                  <tr>
                    <th className="table-header">Taquilla</th>
                    <th className="table-header">Empresa</th>
                    <th className="table-header">RIF</th>
                    <th className="table-header">Estatus</th>
                    <th className="table-header">Acciones</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredRecords.map((record) => (
                    <tr key={record.id} className="table-row">
                      <td className="table-cell">{record.taquilla}</td>
                      <td className="table-cell">{record.empresa.nombre}</td>
                      <td className="table-cell">{record.empresa.rif}</td>
                      <td className="table-cell">
                        <span
                          className="route-badge"
                          style={{
                            color: record.status === "ACTIVE" ? "green" : "red",
                          }}
                        >
                          {record.status === "ACTIVE" ? "ACTIVO" : "INACTIVO"}
                        </span>
                      </td>
                      <td className="table-cell d-flex w-100">
                        <button
                          className="btn btn-secondary d-flex"
                          onClick={() => showRouteDetails(record.id)}
                          style={{
                            color: "white",
                            flexDirection: "row",
                            justifyContent: "center",
                            alignItems: "center",
                            marginRight: "10px",
                          }}
                        >
                          <i
                            className="fa-solid fa-route"
                            style={{ fontSize: "17px" }}
                          ></i>
                        </button>
                        <button
                          className="btn btn-warning d-flex"
                          onClick={() => showDTDetails(record.id)}
                          style={{
                            color: "white",
                            flexDirection: "row",
                            justifyContent: "center",
                            alignItems: "center",
                            marginRight: "10px",
                          }}
                        >
                          <i
                            className="fa-solid fa-bus"
                            style={{ fontSize: "17px" }}
                          ></i>
                        </button>
                        <button
                          className="btn btn-info d-flex"
                          onClick={() => showDriverDetails(record.id)}
                          style={{
                            color: "white",
                            flexDirection: "row",
                            justifyContent: "center",
                            alignItems: "center",
                            marginRight: "10px",
                          }}
                        >
                          <i
                            className="fa-solid fa-id-card"
                            style={{ fontSize: "17px" }}
                          ></i>
                        </button>
                        <button
                          className={`btn d-flex btn-${record.status === "ACTIVE" ? "success" : "danger"}`}
                          onClick={() =>
                            handleUpdateStatus(record.id, record.status)
                          }
                          style={{
                            color: "white",
                            flexDirection: "row",
                            justifyContent: "center",
                            alignItems: "center",
                            marginRight: "10px",
                          }}
                        >
                          <i
                            className={`fa-solid fa-lock${record.status === "ACTIVE" ? "-open" : ""}`}
                            style={{ fontSize: "17px" }}
                          ></i>
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
