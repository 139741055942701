import React, { useState, useEffect, useContext, Fragment } from "react";
import axios from "axios";
import { Context } from "../store/appContext";
import "../../styles/CierreListinTerminal.css";
import { useParams } from "react-router-dom";
import * as XLSX from "xlsx";
import Swal from "sweetalert2";

export const EmpresasConsulta = () => {
  const { id } = useParams(); // Captura el código único desde la URL
  const [empresa, setEmpresa] = useState(null);
  const [activaciones, setActivaciones] = useState([]);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (activaciones.length > 0) {
      setLoading(false);
    }
  }, [activaciones]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          process.env.BACKEND_URL + `/api/consulta_empresa/${id}`
        );
        setEmpresa(response.data.empresa);
        setActivaciones(response.data.activaciones);
      } catch (error) {
        console.error("Error obteniendo datos:", error);
      }
    };
    fetchData();
  }, [id]);

  const formatDate = (dateString) => {
    const date = new Date(dateString); // Asegúrate de que `dateString` esté en formato válido
    const day = String(date.getUTCDate()).padStart(2, "0"); // Usa UTC para evitar problemas de zonas horarias
    const month = String(date.getUTCMonth() + 1).padStart(2, "0"); // Meses son 0 indexados
    const year = date.getUTCFullYear();
    return `${day}-${month}-${year}`;
  };

  const descargarExcel = () => {
    if (!activaciones || activaciones.length === 0) {
      Swal.fire("Error", "No hay datos para exportar", "error");
      return;
    }

    // Crear una hoja de cálculo a partir de los datos
    const ws = XLSX.utils.json_to_sheet(activaciones);

    // Crear un libro de trabajo
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Reporte");

    // Generar el archivo Excel y descargarlo
    XLSX.writeFile(wb, "Reporte_Empresas.xlsx");
  };

  return (
    <div className="sdf54e98g4g86se9f8er4g98e4t98hrt98h4etr98g798yr4bva8s4">
      <div className="container-fluid" style={{ paddingTop: "20px" }}>
        <div style={{ textAlign: "center", marginBottom: "10px" }}>
          {empresa && (
            <div style={{ textAlign: "center", marginBottom: "10px" }}>
              {empresa.nombre}
            </div>
          )}
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            margin: "0",
          }}
          className="container"
        >
          <button
            className="btn btn-success"
            style={{
              backgroundImage:
                "linear-gradient(to right, rgb(24, 92, 55), rgb(33, 128, 76))",
            }}
            onClick={descargarExcel}
            disabled={loading} // Botón deshabilitado mientras loading sea true
          >
            <i className="fa-solid fa-file-xls"></i> Descargar Excel
          </button>
        </div>
        <div style={{ width: "100%", height: "90vh", overflow: "auto" }}>
          <table
            className="custom-table rounded-table"
            style={{
              minWidth: "800px",
              marginBottom: "20px",
              marginTop: "5px",
            }}
          >
            <thead>
              <tr>
                <th
                  className="table-header"
                  style={{ fontSize: "12px", textAlign: "center" }}
                >
                  N°
                </th>
                <th
                  className="table-header"
                  style={{ fontSize: "12px", textAlign: "center" }}
                >
                  Fecha Viaje
                </th>
                <th
                  className="table-header"
                  style={{ fontSize: "12px", textAlign: "center" }}
                >
                  Destino
                </th>
                <th
                  className="table-header"
                  style={{ fontSize: "12px", textAlign: "center" }}
                >
                  Unidad
                </th>
                <th
                  className="table-header"
                  style={{ fontSize: "12px", textAlign: "center" }}
                >
                  Hora Programada
                </th>
                <th
                  className="table-header"
                  style={{ fontSize: "12px", textAlign: "center" }}
                >
                  Estatus Final
                </th>
                <th
                  className="table-header"
                  style={{ fontSize: "12px", textAlign: "center" }}
                >
                  N° Listín
                </th>

                <th
                  className="table-header"
                  style={{ fontSize: "12px", textAlign: "center" }}
                >
                  Tiempo Retraso
                </th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td
                    colSpan="8"
                    style={{
                      textAlign: "start",
                      padding: "10px",
                      alignItems: "center",
                      display: "flex",
                    }}
                  >
                    <i
                      className="fa-solid fa-spinner-scale fa-spin-pulse"
                      style={{ fontSize: "60px" }}
                    ></i>{" "}
                    <span style={{ marginLeft: "7px" }}>Cargando...</span>
                  </td>
                </tr>
              ) : (
                activaciones.map((act, index) => (
                  <tr key={index} style={{ fontSize: "11px" }}>
                    <td className="table-cell" style={{ fontSize: "11px" }}>
                      {index + 1}
                    </td>
                    <td className="table-cell" style={{ fontSize: "11px" }}>
                      {formatDate(act.fecha_de_viaje)}
                    </td>
                    <td className="table-cell" style={{ fontSize: "11px" }}>
                      {act.destino}
                    </td>
                    <td className="table-cell" style={{ fontSize: "11px" }}>
                      {`${act.numero_unidad} - ${act.placa || "N/A"}`}
                    </td>
                    <td className="table-cell" style={{ fontSize: "11px" }}>
                      {act.hora_salida}
                    </td>
                    <td className="table-cell" style={{ fontSize: "11px" }}>
                      {act.estatus_final}
                    </td>
                    <td className="table-cell" style={{ fontSize: "11px" }}>
                      {act.numero_listin}
                    </td>
                    <td className="table-cell" style={{ fontSize: "11px" }}>
                      {act.retraso_minutos}
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
