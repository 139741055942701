import React, { useContext, useState, useEffect } from "react";
import { Context } from "../../store/appContext";
import { Link, Navigate } from "react-router-dom";
import "../../../styles/CompanyAdminNavbar.css";

export const Navbar = () => {
  const { store, actions } = useContext(Context);
  const [selectedMenu, setSelectedMenu] = useState(null);

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("userData");
    window.location.reload();
  };
  const handleClick = (menu) => {
    setSelectedMenu(menu);
  };

  useEffect(() => {
    // Obtén el pathname de la ubicación actual
    const pathname = location.pathname;

    // Mapea el pathname a un nombre de menú y establece el menú seleccionado
    const menuMapping = {
      "/": "/",
      "": "",
      "/RegistroCps": "RegistroCps",
      "/EmpresasDeTransporte": "EmpresasDeTransporte",
      "/Configuraciones": "Configuraciones",
      "/RegistroUsuarios": "RegistroUsuarios",
      "/Destinos": "Destinos",
      "/Programacion": "Programacion",
      "/ActivacionCalendario": "ActivacionCalendario",
      "/PrecioDestinoFinalRC": "PrecioDestinoFinalRC",
      "/PrecioDestinoFinalRL": "PrecioDestinoFinalRL",
      "/KmDistancia": "KmDistancia",
      "/RegistrosCpsEdit": "RegistrosCpsEdit",
      "/Autorizacionporpuesto": "Autorizacionporpuesto",
      "/CierresRutasCortas": "CierresRutasCortas",
      "/RegistroActivacionesHoy": "RegistroActivacionesHoy",
      "/AyudaYSoporte": "AyudaYSoporte",
    };

    const menu = menuMapping[pathname];
    if (menu) {
      setSelectedMenu(menu);
    }
  }, [location.pathname]);

  return (
    <div className="ideaAdminCompany-container2">
      <div className="ideaAdminCompany-container" style={{ position: "fixed" }}>
        <div className="as56d4s9f87er98fwe4fwr987fwee98d4qw987">
          <img
            src="https://res.cloudinary.com/dirpdlfbu/image/upload/v1713119110/INTRAVIALCA/truxqodtlg1vzishxhot.png"
            alt="Logo"
            style={{ height: "35px" }}
            className="d65f4sd9f8w7ef98we7qw654er987fw"
          />
          <i
            className="fa-solid a6s54d89f7v98wedq89wre98we87icneassa9d87 fa-bars"
            style={{ color: "white" }}
          ></i>
        </div>
        <div className="sdsdf98u7yt9htg7wfqe987879467434">
          {/* A partir de aqui coloca el nuevo menu aqui dentro de este div */}
          <Link
            className={`subtitulosMenuUserHomeCompany ${
              selectedMenu === "/" ? "selected-menu" : ""
            }`}
            to="/"
            onClick={() => handleClick("/")}
          >
            <i className="fa-solid seraUnMarinsupre fa-globe"></i>
            <div className="LetterInvisible2" style={{ fontSize: "12px" }}>
              Inicio
            </div>
          </Link>
          <Link
            className={`subtitulosMenuUserHomeCompany ${
              selectedMenu === "/RegistroActivacionesHoy" ? "selected-menu" : ""
            }`}
            to="/RegistroActivacionesHoy"
            onClick={() => handleClick("/RegistroActivacionesHoy")}
          >
            <i className="fa-solid seraUnMarinsupre fa-file-circle-check"></i>
            <div className="LetterInvisible2" style={{ fontSize: "12px" }}>
              Activaciones Hoy
            </div>
          </Link>
          {/* <Link
            className={`subtitulosMenuUserHomeCompany ${
              selectedMenu === "EmpresasDeTransporte" ? "selected-menu" : ""
            }`}
            onClick={() => handleClick("EmpresasDeTransporte")}
            to="/EmpresasDeTransporte"
          >
            <i className="fa-solid seraUnMarinsupre fa-user-tie"></i>
            <div className="LetterInvisible2" style={{ fontSize: "12px" }}>
              Empresas de Transporte
            </div>
          </Link>
          <Link
            className={`subtitulosMenuUserHomeCompany ${
              selectedMenu === "Destinos" ? "selected-menu" : ""
            }`}
            onClick={() => handleClick("Destinos")}
            to="/Destinos"
          >
            <i className="fa-solid seraUnMarinsupre fa-location-dot"></i>
            <div className="LetterInvisible2" style={{ fontSize: "12px" }}>
              Destinos
            </div>
          </Link> */}
          {/* <Link
            className={`subtitulosMenuUserHomeCompany ${
              selectedMenu === "Programacion" ? "selected-menu" : ""
            }`}
            onClick={() => handleClick("Programacion")}
            to="/Programacion"
          >
            <i className="fa-solid seraUnMarinsupre fa-calendar-check"></i>
            <div className="LetterInvisible2" style={{ fontSize: "12px" }}>
              Programación
            </div>
          </Link>
          <Link
            className={`subtitulosMenuUserHomeCompany ${
              selectedMenu === "ActivacionCalendario" ? "selected-menu" : ""
            }`}
            onClick={() => handleClick("ActivacionCalendario")}
            to="/ActivacionCalendario"
          >
            <i className="fa-solid seraUnMarinsupre fa-calendar-days"></i>
            <div className="LetterInvisible2" style={{ fontSize: "12px" }}>
              Calendario de Salidas
            </div>
          </Link>
          <Link
            className={`subtitulosMenuUserHomeCompany ${
              selectedMenu === "PrecioDestinoFinalRC" ? "selected-menu" : ""
            }`}
            onClick={() => handleClick("PrecioDestinoFinalRC")}
            to="/PrecioDestinoFinalRC"
          >
            <i className="fa-solid seraUnMarinsupre fa-tags"></i>
            <div className="LetterInvisible2" style={{ fontSize: "12px" }}>
              Rutas Cortas Precios
            </div>
          </Link>
          <Link
            className={`subtitulosMenuUserHomeCompany ${
              selectedMenu === "PrecioDestinoFinalRL" ? "selected-menu" : ""
            }`}
            onClick={() => handleClick("PrecioDestinoFinalRL")}
            to="/PrecioDestinoFinalRL"
          >
            <i className="fa-solid seraUnMarinsupre fa-tags"></i>
            <div className="LetterInvisible2" style={{ fontSize: "12px" }}>
              Rutas Largas Precios
            </div>
          </Link>
          <Link
            className={`subtitulosMenuUserHomeCompany ${
              selectedMenu === "KmDistancia" ? "selected-menu" : ""
            }`}
            onClick={() => handleClick("KmDistancia")}
            to="/KmDistancia"
          >
            <i className="fa-solid seraUnMarinsupre fa-route"></i>
            <div className="LetterInvisible2" style={{ fontSize: "12px" }}>
              Destino Final Km
            </div>
          </Link>
          <Link
            className={`subtitulosMenuUserHomeCompany ${
              selectedMenu === "Autorizacionporpuesto" ? "selected-menu" : ""
            }`}
            onClick={() => handleClick("Autorizacionporpuesto")}
            to="/Autorizacionporpuesto"
          >
            <i className="fa-solid seraUnMarinsupre fa-road-circle-check"></i>
            <div className="LetterInvisible2" style={{ fontSize: "12px" }}>
              Autorización Por Puesto
            </div>
          </Link>
          <Link
            className={`subtitulosMenuUserHomeCompany ${
              selectedMenu === "CierresRutasCortas" ? "selected-menu" : ""
            }`}
            onClick={() => handleClick("CierresRutasCortas")}
            to="/CierresRutasCortas"
          >
            <i className="fa-solid seraUnMarinsupre fa-bus"></i>
            <div className="LetterInvisible2" style={{ fontSize: "12px" }}>
              Cierres Rutas Cortas
            </div>
          </Link> */}
          <Link
            className={`subtitulosMenuUserHomeCompany ${
              selectedMenu === "AyudaYSoporte" ? "selected-menu" : ""
            }`}
            onClick={() => handleClick("AyudaYSoporte")}
            to="/AyudaYSoporte"
          >
            <i className="fa-solid seraUnMarinsupre fa-headset"></i>
            <div className="LetterInvisible2" style={{ fontSize: "12px" }}>
              Ayuda Y Soporte
            </div>
          </Link>

          <div className="s547"></div>
        </div>
        <div className="d56f4d89g7wfq98d7et98re4f6t5er1eq56w64">
          <Link
            className={`subtitulosMenuUserHomeCompany ${
              selectedMenu === "Configuraciones" ? "selected-menu" : ""
            }`}
            onClick={() => handleClick("Configuraciones")}
            to="/Configuraciones"
          >
            <i className="fa-solid seraUnMarinsupre fa-gear"></i>
            <div className="LetterInvisible2" style={{ fontSize: "12px" }}>
              Configuraciones
            </div>
          </Link>
        </div>
        <div className="d56f4d89g7wfq98d7et98re4f6t5er1eq56w64">
          <a
            className="subtitulosMenuUserHomeCompany logoutbuttonCompany"
            onClick={handleLogout}
            href="/"
          >
            <i className="fa-solid seraUnMarinsupre fa-right-from-bracket"></i>
            <div
              href="#"
              className="LetterInvisible2"
              style={{ fontSize: "12px" }}
            >
              Cerrar sesión
            </div>
          </a>
        </div>
      </div>
    </div>
  );
};
