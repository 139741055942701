import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "../../styles/login.css";
import { Context } from "./../store/appContext";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

export const Login = () => {
  const { actions } = useContext(Context);
  const navigate = useNavigate();
  const [showIntro, setShowIntro] = useState(true);
  const [isLoading, setIsLoading] = useState(false); // Estado para controlar el loading
  const [rememberMe, setRememberMe] = useState(false);
  const [email, setEmail] = useState(localStorage.getItem("email") || "");
  const [password, setPassword] = useState(
    localStorage.getItem("password") || ""
  );

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowIntro(false);
    }, 7000);
    return () => clearTimeout(timer);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Mostrar el loading al comenzar la solicitud
    setIsLoading(true);

    const formData = {
      email: e.target.email.value,
      password: e.target.password.value,
    };

    try {
      const response = await axios.post(
        process.env.BACKEND_URL + "/api/AccesIntravialca",
        formData,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );

      mostrarAlerta1();
      if (rememberMe) {
        localStorage.setItem("email", formData.email);
        localStorage.setItem("password", formData.password);
      } else {
        localStorage.removeItem("email");
        localStorage.removeItem("password");
      }

      if (response.status === 200) {
        const userData = response.data;
        actions.savetoken(response.data.token, userData);

        // Redireccionar a la página de inicio después de iniciar sesión
        navigate("/");
      } else {
        console.error("Error al iniciar sesión", response.data);
      }
    } catch (error) {
      mostrarAlerta2();
    } finally {
      // Ocultar el loading cuando la solicitud termine
      setIsLoading(false);
    }
  };

  const mostrarAlerta1 = () => {
    MySwal.fire({
      title: <p>Inicio de Sesión exitoso</p>,
      icon: "success",
      position: "center",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
    });
  };

  const mostrarAlerta2 = (errorMessage) => {
    MySwal.fire({
      title: <p>Correo o Contraseña Inválido</p>,
      html: <p>{errorMessage}</p>,
      icon: "error",
      position: "center",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
    });
  };

  return (
    <div>
      {showIntro ? (
        <div className="intro-container">
          <div className="video-background"></div>
          <div className="intro-text">
            <div
              className="i-fewf5c9ew5ce9wdc1w4c1s123"
              style={{ marginBottom: "30px" }}
            ></div>
            <h1>Bienvenido a INTRAVIALCA</h1>
            <h4>Optimizando tu experiencia de transporte</h4>
            <button className="skip-button" onClick={() => setShowIntro(false)}>
              Omitir{" "}
              <i
                className="fa-solid fa-angles-right"
                style={{ marginLeft: "8px" }}
              ></i>
            </button>
          </div>
        </div>
      ) : (
        <div className="aq4-asd56sa54s">
          <div className="sa65d489as7f98re7f98q489re7w9d8q4wd9q8w7d98rwe74we w-100">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 1440 320"
              className="sd45erge98h88r48ter9q4w9q87wd9q8wdqw98"
            >
              <path
                fill="#ffffff"
                fillOpacity="1"
                d="M0,160L80,154.7C160,149,320,139,480,138.7C640,139,800,149,960,165.3C1120,181,1280,203,1360,213.3L1440,224L1440,320L1360,320C1280,320,1120,320,960,320C800,320,640,320,480,320C320,320,160,320,80,320L0,320Z"
              ></path>
            </svg>
          </div>
          <div className="asd564er987ty98r79t8ut7yt9rv8wc4q9d8e4r98we4">
            <div className="container containerDeLoginjssegundo123">
              <div className="i-fewf5c9ew5ce9wdc1w4c1s"></div>
              <p style={{ color: "#0a306a" }}>Inicia Sesión en Intravialca</p>
              <form onSubmit={handleSubmit} className="formularioDeLogin">
                <div className="input-group-login">
                  <input
                    className="inputDeLogin392"
                    type="text"
                    name="email"
                    value={email}
                    required
                    onChange={(e) => setEmail(e.target.value)}
                    style={{ backgroundColor: "#0a306a2b" }}
                  />
                  <label htmlFor="email">Correo electrónico</label>
                </div>
                <div className="input-group-login">
                  <input
                    className="inputDeLogin392"
                    type="password"
                    name="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                    style={{ backgroundColor: "#0a306a2b" }}
                  />
                  <label htmlFor="text">Contraseña</label>
                </div>
                <div
                  className="input-group-logi checkbox-container"
                  style={{
                    textAlign: "start",
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <input
                    type="checkbox"
                    id="rememberMe"
                    checked={rememberMe}
                    onChange={() => setRememberMe(!rememberMe)}
                  />
                  <label htmlFor="rememberMe" style={{ paddingLeft: "7px" }}>
                    Recordarme
                  </label>
                </div>

                <button className="buttonCargadeDatosDeLogin" type="submit">
                  Iniciar sesión <i className="fa-solid fa-arrow-right"></i>
                </button>
              </form>
            </div>
          </div>
          <div className="d65f4fg98ry7j98er7ge6t9r8g7w8e94ew">
            <div className="sd65f4r9y8u7utytr98red98ut7ytb98vqw897erwe"></div>
          </div>
        </div>
      )}
    </div>
  );
};
