import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import moment from "moment-timezone";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import "../../../../styles/RegistroActivacionesHoy.css";
import { Context } from "../../../store/appContext.js";
import { useNavigate, useParams } from "react-router-dom";

const MySwal = withReactContent(Swal);

export const VerCierres = () => {
  const [records, setRecords] = useState([]);
  const navigate = useNavigate();
  const [filteredRecords, setFilteredRecords] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const { store } = useContext(Context);
  const [loading, setLoading] = useState(true);
  const [avisos, setAvisos] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchTodayRecords = async () => {
    try {
      const response = await axios.get(
        process.env.BACKEND_URL + "/api/cierres_todos_recau",
        {
          headers: {
            Authorization: "Bearer " + store.token,
          },
        }
      );
      setRecords(response.data.pagos_no_cerrados);
      setFilteredRecords(response.data.pagos_no_cerrados);
    } catch (error) {
      console.error("Error al obtener registros de hoy", error);
    } finally {
      setLoading(false); // Cambia a `false` al terminar la carga
    }
  };

  const handleUpdateStatus = async (id, currentReview) => {
    try {
      // Confirmación antes de realizar la acción
      let result = await MySwal.fire({
        title: currentReview
          ? "¿Marcar como no revisado?"
          : "¿Marcar como revisado?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: currentReview
          ? "Sí, marcar como no revisado"
          : "Sí, marcar como revisado",
        cancelButtonText: "Cancelar",
      });

      // Si el usuario confirma la acción
      if (result.isConfirmed) {
        await axios.put(
          `${process.env.BACKEND_URL}/api/cierre-caja/${id}/toggle-review`,
          {}, // No se necesita enviar un body
          {
            headers: {
              Authorization: `Bearer ${store.token}`,
            },
          }
        );

        // Notificar al usuario del cambio
        MySwal.fire(
          "Éxito",
          currentReview
            ? "El estado ha sido cambiado a no revisado."
            : "El estado ha sido cambiado a revisado.",
          "success"
        );

        // Refrescar los registros
        fetchTodayRecords();
      } else {
        console.log("Acción cancelada");
      }
    } catch (error) {
      console.error("Error al actualizar el estado de revisión", error);
      MySwal.fire("Error", "Hubo un problema al realizar la acción.", "error");
    }
  };

  const formatDate = (timestamp) => {
    // Dividir fecha y zona horaria
    const [datePart, timePart] = timestamp.split("T");
    const [year, month, day] = datePart.split("-");
    const [time, offset] = timePart.split("-");

    // Extraer la hora, minutos y segundos
    const [hours, minutes] = time.split(":");

    // Formatear en dd/mm/yyyy hh:mm
    return `${day}/${month}/${year} ${hours}:${minutes}`;
  };

  useEffect(() => {
    fetchTodayRecords();
  }, []);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    filterAndSortRecords(e.target.value, statusFilter, sortOrder);
  };

  const filterAndSortRecords = (search, status, order) => {
    let filtered = records.filter((record) => {
      return (
        (!status || record.empresa.status === status) &&
        (!search || record.code.toLowerCase().includes(search.toLowerCase()))
      );
    });

    setFilteredRecords(filtered);
  };

  return (
    <div className="container-fluid contarinerGeneralUserHomejs h-auto">
      <div className="container asklnduiashndaijsnxansxsaas6548 m-0 p-0">
        {/* Filtro y Ordenación */}
        <div className="filter-bar p-3 sd56f4df98g4f8g9hb7er89g4ewr89e7r9we4f98ef9we4v4ds2">
          <div
            style={{ display: "flex", alignItems: "center", color: "white" }}
            className="sddv4er98f4we9cw4edivderegis"
          >
            <i
              className="fa-solid fa-magnifying-glass"
              style={{ fontSize: "20px" }}
            ></i>
            <p style={{ margin: "0", marginLeft: "7px", fontSize: "20px" }}>
              Buscar
            </p>
          </div>
          <input
            type="text"
            placeholder="Buscar por rif o nombre de Empresa"
            value={searchTerm}
            onChange={handleSearchChange}
            className="form-control mb-2"
            style={{ borderRadius: "20px", padding: "10px 15px" }}
          />
        </div>
        {loading ? (
          <div style={{ textAlign: "center", padding: "20px" }}>
            <img
              src="https://res.cloudinary.com/dirpdlfbu/image/upload/v1731494258/1/dvhl0hwbx4adb8uceymr.gif"
              alt="Cargando..."
              style={{ width: "150px" }}
            />
          </div>
        ) : (
          <div className="contianer-fluid p-0 m-0 w-100">
            <div className="container asklnduiashndaijsnxansxsaas mobile-er65g48e9r7we984fwe594 p-0">
              {filteredRecords.map((record) => {
                return (
                  <div
                    key={record.id}
                    className="container asklnduiashndaijsnxansxsaas"
                  >
                    <div
                      className="container asune-rv56er48v9wec-s2 mgjmnh98-sdf9w8e7f9wewefwes"
                      style={{ height: "auto" }}
                    >
                      <div
                        className="container aoshdnuqiwhnd897"
                        style={{ flexDirection: "column" }}
                      >
                        <div
                          className="container ds897fq4w9dc9w8c4-we89c7w2 w-100"
                          style={{ width: "100%" }}
                        >
                          <div
                            style={{
                              display: "flex",
                              width: "100%",
                              justifyContent: "space-between",
                            }}
                          >
                            <p
                              style={{
                                margin: "0",
                                fontSize: "10px",
                              }}
                              id="pderegistros"
                            >
                              <strong>{formatDate(record.timestamp)}</strong>
                            </p>
                            <p
                              style={{
                                margin: "0",
                                fontSize: "12px",
                              }}
                              id="pderegistros"
                            >
                              {record.registered_by}
                            </p>
                          </div>
                          <h5 style={{ marginTop: "15px" }}>
                            {record.fecha_cierre}
                          </h5>
                        </div>
                        <div
                          className="asdw-w9d84c9w8e4dcd"
                          style={{ marginBottom: "5px" }}
                        ></div>

                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%",
                          }}
                        >
                          <div style={{ minWidth: "200px" }}>
                            <strong>{record.code}</strong> -{" "}
                            {record.total_pagado_bs} Bs.
                          </div>
                          <div
                            className="container ds9f848dsfwef498we4fwd-57982 w-20"
                            style={{
                              width: "100%",
                              height: "100% !important",
                              display: "flex",
                              justifyContent: "end",
                              alignItems: "center",
                            }}
                          >
                            {record.review ? (
                              <button
                                className="btn btn-success d-flex"
                                onClick={() =>
                                  handleUpdateStatus(record.id, record.status)
                                }
                                style={{
                                  color: "white",
                                  flexDirection: "row",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  marginRight: "10px",
                                }}
                              >
                                <i
                                  className="fa-solid fa-eye"
                                  style={{ fontSize: "17px" }}
                                ></i>
                              </button>
                            ) : (
                              <button
                                className="btn btn-danger d-flex"
                                onClick={() =>
                                  handleUpdateStatus(record.id, record.status)
                                }
                                style={{
                                  color: "white",
                                  flexDirection: "row",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  marginRight: "10px",
                                }}
                              >
                                <i
                                  className="fa-solid fa-eye-slash"
                                  style={{ fontSize: "17px" }}
                                ></i>
                              </button>
                            )}
                            <a
                              className="btn btn-info d-flex"
                              href={record.pdf_url}
                              target="_blank"
                              rel="noopener noreferrer"
                              style={{
                                color: "white",
                                flexDirection: "row",
                                justifyContent: "center",
                                alignItems: "center",
                                marginRight: "10px",
                              }}
                            >
                              <i
                                className="fa-solid fa-file-pdf"
                                style={{ fontSize: "17px" }}
                              ></i>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            {/* Vista Escritorio::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::.. */}
            <div className="custom-table-container desktop-er65g48e9r7we984fwe594 m-0">
              <table className="custom-table rounded-table">
                <thead>
                  <tr>
                    <th className="table-header">Fecha y Hora</th>
                    <th className="table-header">Fecha de Cierre</th>
                    <th className="table-header">Code</th>
                    <th className="table-header">Registrado</th>
                    <th className="table-header">Total Bs</th>
                    <th className="table-header">Acciones</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredRecords.map((record) => (
                    <tr key={record.id} className="table-row">
                      <td className="table-cell">
                        {formatDate(record.timestamp)}
                      </td>
                      <td className="table-cell">{record.fecha_cierre}</td>
                      <td className="table-cell">{record.code}</td>
                      <td className="table-cell">{record.registered_by}</td>
                      <td className="table-cell">{record.total_pagado_bs}</td>
                      <td className="table-cell d-flex w-100">
                        {record.review ? (
                          <button
                            className="btn btn-success d-flex"
                            onClick={() =>
                              handleUpdateStatus(record.id, record.status)
                            }
                            style={{
                              color: "white",
                              flexDirection: "row",
                              justifyContent: "center",
                              alignItems: "center",
                              marginRight: "10px",
                            }}
                          >
                            <i
                              className="fa-solid fa-eye"
                              style={{ fontSize: "17px" }}
                            ></i>
                          </button>
                        ) : (
                          <button
                            className="btn btn-danger d-flex"
                            onClick={() =>
                              handleUpdateStatus(record.id, record.status)
                            }
                            style={{
                              color: "white",
                              flexDirection: "row",
                              justifyContent: "center",
                              alignItems: "center",
                              marginRight: "10px",
                            }}
                          >
                            <i
                              className="fa-solid fa-eye-slash"
                              style={{ fontSize: "17px" }}
                            ></i>
                          </button>
                        )}
                        <a
                          className="btn btn-info d-flex"
                          href={record.pdf_url}
                          rel="noopener noreferrer"
                          target="_blank"
                          style={{
                            color: "white",
                            flexDirection: "row",
                            justifyContent: "center",
                            alignItems: "center",
                            marginRight: "10px",
                          }}
                        >
                          <i
                            className="fa-solid fa-file-pdf"
                            style={{ fontSize: "17px" }}
                          ></i>
                        </a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
