// Importa React y createRoot
import React from "react";
import { createRoot } from "react-dom/client";

// Importa el archivo CSS
import "../styles/index.css";

// Importa tu layout principal
import Layout from "./layout";

// Importa el Service Worker
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

// Encuentra el elemento en el que quieres montar tu aplicación de React
const container = document.querySelector("#app");

// Crea una raíz para tu aplicación React
const root = createRoot(container);

// Renderiza tu aplicación React
root.render(<Layout />);

// Registra el Service Worker para que la aplicación funcione como una PWA
serviceWorkerRegistration.register();

if ("serviceWorker" in navigator) {
  window.addEventListener("load", () => {
    navigator.serviceWorker
      .register("/service-worker.js")
      .then((reg) => console.log("Service Worker registrado"))
      .catch((err) => console.error("Error al registrar Service Worker:", err));
  });
}
