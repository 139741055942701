import React, { useContext, useState, useEffect } from "react";
import { Context } from "../../../store/appContext";
import { useNavigate, useLocation, Link } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import axios from "axios";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFDownloadLink,
  pdf,
  Image,
  PDFViewer,
} from "@react-pdf/renderer";

const MySwal = withReactContent(Swal);

const formatDate = (timestamp) => {
  // Dividir fecha y zona horaria
  const [datePart, timePart] = timestamp.split("T");
  const [year, month, day] = datePart.split("-");
  const [time, offset] = timePart.split("-");

  // Extraer la hora, minutos y segundos
  const [hours, minutes] = time.split(":");

  // Formatear en dd/mm/yyyy hh:mm
  return `${day}/${month}/${year} ${hours}:${minutes}`;
};

const AvisoPDF = ({
  avisos,
  empresaName,
  formasPago,
  totalAPagarBs,
  timestamp,
  nombre_apellido_user,
  email_user,
  paquete_code,
  empresa_rif,
}) => {
  const formattedDate = formatDate(timestamp);

  return (
    <Document>
      <Page style={styles.page} size="A4">
        <Image
          src="https://res.cloudinary.com/dqt0d66h7/image/upload/v1738848407/Fondos%20PDF/k8k5amtzu6r8ubnrnhmx.png"
          style={styles.backgroundImage}
          fixed
        />
        {/* Encabezado */}
        <View style={styles.header} fixed>
          <Image
            src="https://res.cloudinary.com/dqt0d66h7/image/upload/v1738835212/Fondos%20PDF/pjtuji0yr4bqvzh9sj0z.png"
            style={styles.logo}
          />
          <Image
            src="https://res.cloudinary.com/dqt0d66h7/image/upload/v1738835293/Fondos%20PDF/d9fqduuf6i7lely21fnx.png"
            style={styles.logo2}
          />
        </View>

        <View style={styles.titleSection}>
          <Text style={styles.title}>RECIBO DE PAGO</Text>
          <Text style={styles.subtitle}>
            Código Único del Pago: {paquete_code}
          </Text>
        </View>
        <View style={styles.header}>
          <Text style={styles.companyName}>
            {empresaName} - {empresa_rif}
          </Text>
        </View>

        {/* Información general */}
        <View style={styles.infoSection}>
          <Text style={styles.infoText}>
            <Text style={styles.infoLabel}>Fecha y Hora de Pago: </Text>
            {formattedDate}
          </Text>
          <Text style={styles.infoText}>
            <Text style={styles.infoLabel}>Recibido por: </Text>
            {nombre_apellido_user} ({email_user})
          </Text>
        </View>

        {/* Detalle del pago */}
        <View style={styles.detailSection}>
          <Text style={styles.sectionTitle}>Detalle del Pago</Text>
          <View style={styles.table}>
            {/* Encabezados de la tabla */}
            <View style={tableStyles.tableRow} wrap={false} fixed>
              <View style={tableStyles.tableColHeaderOther}>
                <Text style={tableStyles.tableCellHeader}>Listín</Text>
              </View>
              <View style={tableStyles.tableColHeaderOther}>
                <Text style={tableStyles.tableCellHeader}>Fecha Viaje</Text>
              </View>
              <View style={tableStyles.tableColHeaderOther}>
                <Text style={tableStyles.tableCellHeader}>Unidad</Text>
              </View>
              <View style={tableStyles.tableColHeaderOther}>
                <Text style={tableStyles.tableCellHeader}>Destino</Text>
              </View>
              <View style={tableStyles.tableColHeaderOther}>
                <Text style={tableStyles.tableCellHeader}>Monto</Text>
              </View>
            </View>
            {/* <View style={styles.tableRowHeader}>
              <Text style={styles.tableCellHeader}>Listín</Text>
              <Text style={styles.tableCellHeader}>Fecha Viaje</Text>
              <Text style={styles.tableCellHeader}>Unidad</Text>
              <Text style={styles.tableCellHeader}>Destino</Text>
              <Text style={styles.tableCellHeader}>Monto</Text>
            </View> */}

            {/* Filas de la tabla */}
            {avisos.map((aviso, index) => (
              // <View key={index} style={styles.tableRow}>
              //   <Text style={styles.tableCell}>{aviso.listin_terminal}</Text>
              //   <Text style={styles.tableCell}>{aviso.fecha_viaje}</Text>
              //   <Text style={styles.tableCell}>{aviso.numero_de_unidad}</Text>
              //   <Text style={styles.tableCell}>{aviso.destino_final}</Text>
              //   <Text style={styles.tableCell}>
              //     {aviso.total_bolivares.toFixed(2)} Bs.
              //   </Text>
              // </View>
              <View style={tableStyles.tableRow} wrap={false} key={index}>
                <View style={tableStyles.tableColOther}>
                  <Text style={tableStyles.tableCell}>
                    {aviso.listin_terminal}
                  </Text>
                </View>
                <View style={tableStyles.tableColOther}>
                  <Text style={tableStyles.tableCell}>{aviso.fecha_viaje}</Text>
                </View>
                <View style={tableStyles.tableColOther}>
                  <Text style={tableStyles.tableCell}>
                    {aviso.numero_de_unidad}
                  </Text>
                </View>
                <View style={tableStyles.tableColOther}>
                  <Text style={tableStyles.tableCell}>
                    {aviso.destino_final}
                  </Text>
                </View>
                <View style={tableStyles.tableColOther}>
                  <Text style={tableStyles.tableCell}>
                    {aviso.total_bolivares.toFixed(2)} Bs.
                  </Text>
                </View>
              </View>
            ))}
          </View>
        </View>

        {/* Formas de pago */}
        <View style={styles.paymentMethods}>
          <Text style={styles.subtitle}>Formas de Pago:</Text>
          {formasPago.map((forma, index) => (
            <Text key={index}>
              {index + 1}. {forma.formaPago}: {forma.montoBs.toFixed(2)} Bs.{" "}
              {["PUNTO_DE_VENTA", "TRANSFERENCIA"].includes(forma.formaPago)
                ? " (Ref:" + forma.ref_transaccion + ")"
                : ""}
            </Text>
          ))}
        </View>

        {/* Total */}
        <View style={styles.totalSection}>
          <Text style={styles.totalText}>
            Total Pagado: {totalAPagarBs.toFixed(2)} Bs.
          </Text>
        </View>
        <View style={styles.footer} fixed>
          <Image
            src="https://res.cloudinary.com/dqt0d66h7/image/upload/v1738835293/Fondos%20PDF/d9fqduuf6i7lely21fnx.png"
            style={styles.logo22}
          />
          <View style={{ display: "flex", flexDirection: "row" }}>
            <Text style={{ opacity: 0.2 }}>
              www.intravialca-ve.com - Página:{" "}
            </Text>
            <Text
              render={({ pageNumber, totalPages }) =>
                `${pageNumber} / ${totalPages}`
              }
              fixed
            />
          </View>
        </View>
      </Page>
    </Document>
  );
};

const tableStyles = StyleSheet.create({
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 0.5,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    borderRadius: 10, // Ajusta el valor según el redondeo deseado
    overflow: "hidden",
    marginTop: 10,
  },
  table2: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 0.5,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    marginTop: 10,
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
    backgroundColor: "transparent",
  },
  tableColHeader: {
    borderStyle: "solid",
    borderWidth: 0.5,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    borderColor: "gray",
    backgroundColor: "#24827c89",
    alignItems: "center",
    justifyContent: "center",
  },
  tableColHeader2: {
    borderStyle: "solid",
    borderWidth: 0.5,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    backgroundColor: "#8c10106e",
    alignItems: "center",
    justifyContent: "center",
  },
  tableCol: {
    borderStyle: "solid",
    borderWidth: 0.5,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    alignItems: "center",
    justifyContent: "center",
  },
  tableCellHeader: {
    margin: 5,
    fontSize: 12,
    fontWeight: "bold",
    color: "white",
  },
  tableCell: {
    margin: 4,
    fontSize: 11,
  },
});

// Estilos
const styles = StyleSheet.create({
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 0.5,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    borderRadius: 10, // Ajusta el valor según el redondeo deseado
    overflow: "hidden",
    marginBottom: 10,
  },
  tableColHeader: {
    borderStyle: "solid",
    borderWidth: 0.5,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    borderColor: "gray",
    backgroundColor: "#24827c89",
    alignItems: "center",
    justifyContent: "center",
  },
  tableCol: {
    borderStyle: "solid",
    borderWidth: 0.5,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    alignItems: "center",
    justifyContent: "center",
  },
  page: {
    padding: "30 30 50 30", // Ajusta el valor del margen según tus necesidades
    height: "100%",
    fontSize: 12,
    fontFamily: "Times-Roman",
  },
  backgroundImage: {
    minwidth: "2480px", // Hace que la imagen ocupe todo el ancho de la página
    height: "auto", // Mantiene la proporción de la imagen, ajustando la altura proporcional al ancho
    position: "absolute", // Asegura que la imagen se quede en el fondo
    top: 0, // Se pega a la parte superior
    left: 0, // Se pega a la parte izquierda
    right: 0,
  },
  logo22: {
    width: 100,
    height: 20,
    opacity: 0.2,
  },
  footer: {
    position: "absolute",
    bottom: 10,
    left: 0,
    right: 0,
    textAlign: "center",
    fontSize: 10,
    paddingTop: 5,
    marginTop: 5,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  logo: { width: 90, height: 30 },
  logo2: { width: 100, height: 20 },
  companyName: {
    fontSize: 16,
    fontWeight: "bold",
    marginTop: 10,
  },
  titleSection: {
    textAlign: "center",
    marginBottom: 20,
  },
  title: {
    fontSize: 18,
    fontWeight: "bold",
  },
  subtitle: {
    fontSize: 14,
    color: "#555",
    marginTop: 5,
  },
  infoSection: {
    marginBottom: 20,
    borderBottom: "1px solid #ddd",
    paddingBottom: 10,
    marginTop: 10,
  },
  infoText: {
    marginBottom: 5,
  },
  infoLabel: {
    fontWeight: "bold",
  },
  detailSection: {
    marginTop: 20,
  },
  sectionTitle: {
    fontSize: 14,
    fontWeight: "bold",
    marginBottom: 10,
  },
  tableRowHeader: {
    flexDirection: "row",
    backgroundColor: "#24827c",
    borderBottomWidth: 1,
    borderColor: "#ddd",
  },
  tableRow: {
    flexDirection: "row",
    borderBottomWidth: 1,
    borderColor: "#ddd",
  },
  tableCellHeader: {
    margin: 5,
    fontSize: 12,
    fontWeight: "bold",
    color: "white",
  },
  tableCell: {
    margin: 4,
    fontSize: 11,
  },
  totalSection: {
    textAlign: "right",
    marginTop: 10,
    borderTop: "1px solid #ddd",
    paddingTop: 10,
  },
  totalText: {
    fontSize: 14,
    fontWeight: "bold",
  },
});

tableStyles.tableColHeaderOther = {
  ...tableStyles.tableColHeader,
  width: "20%", // Mayor ancho para los demás
};

tableStyles.tableColOther = {
  ...tableStyles.tableCol,
  width: "20%", // Mayor ancho para los demás
};

export const RegistroDePago3Definitivo = () => {
  const { store } = useContext(Context);
  const { state } = useLocation();
  const { empresaId, avisos, empresaName, companyRif } = state || {};
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [totalBolivares, setTotalBolivares] = useState(0);
  const [totalDolares, setTotalDolares] = useState(0);
  const [paqueteCode, setPaqueteCode] = useState("");
  const navigate = useNavigate();
  useEffect(() => {
    if (avisos && avisos.length > 0) {
      const totalBs = avisos.reduce(
        (acc, aviso) => acc + aviso.total_bolivares,
        0
      );
      const totalUsd = avisos.reduce(
        (acc, aviso) => acc + aviso.total_bolivares / aviso.valor_tasa,
        0
      );

      setTotalBolivares(totalBs);
      setTotalDolares(totalUsd);
      setTotalAPagarBs(totalBs); // Actualiza totalAPagarBs cuando totalBolivares cambie
    }
  }, [avisos]);

  const handleCancelClick = () => {
    Swal.fire({
      title: "¿Estás seguro?",
      text: "Si cancelas, los datos ingresados se perderán.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Sí, cancelar",
      cancelButtonText: "No, volver",
    }).then((result) => {
      if (result.isConfirmed) {
        navigate("/RegistroPago");
      }
    });
  };

  const handlePreviewPDF = async () => {
    try {
      // Mostrar mensaje de carga
      Swal.fire({
        title: "Generando vista previa...",
        html: "<p>Por favor, espera mientras preparamos el PDF.</p>",
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      // Solicitar los datos necesarios al backend
      const response = await axios.post(
        process.env.BACKEND_URL + "/api/generar_firma_cloudinary_recibo_pago",
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      const {
        email_user,
        nombre_apellido_user,
        paquete_code,
        timestamp_ve,
        timestamp,
      } = response.data;

      // Generar PDF con los datos obtenidos
      const pdfBlob = await pdf(
        <AvisoPDF
          avisos={avisos}
          empresaName={empresaName}
          formasPago={formasPago}
          totalAPagarBs={totalAPagarBs}
          timestamp={timestamp_ve}
          nombre_apellido_user={nombre_apellido_user}
          email_user={email_user}
          paquete_code={paquete_code}
          empresa_rif={companyRif}
        />
      ).toBlob();

      // Crear URL temporal para el PDF
      const pdfUrl = URL.createObjectURL(pdfBlob);
      window.open(pdfUrl, "_blank");

      Swal.close(); // Cerrar la ventana de carga
    } catch (error) {
      console.error("Error al generar PDF:", error);
      Swal.fire({
        title: "Error",
        text: "Ocurrió un error al generar la vista previa del PDF. Inténtalo nuevamente.",
        icon: "error",
      });
    }
  };

  const handleAddFormaPago = (selectedFormaPago, montoBs, ref_transaccion) => {
    if (!selectedFormaPago || !montoBs) {
      return MySwal.fire(
        "Error",
        "Selecciona una forma de pago y un monto válido.",
        "error"
      );
    }

    // Redondeo para evitar problemas de precisión
    const precision = 2; // Número de decimales
    const montoRestante = totalAPagarBs - totalPagado;
    const montoRestanteRedondeado = parseFloat(
      montoRestante.toFixed(precision)
    );
    const montoBsRedondeado = parseFloat(montoBs.toFixed(precision));

    // Verificar si el pago ya está completo
    if (montoRestanteRedondeado <= 0) {
      return MySwal.fire(
        "Pago Completo",
        "El pago ya está completo. No puedes agregar más formas de pago.",
        "info"
      );
    }

    // Verificar si el monto a agregar excede el monto restante
    if (montoBsRedondeado > montoRestanteRedondeado) {
      return MySwal.fire(
        "Exceso de Pago",
        `El monto ingresado (${montoBsRedondeado} Bs.) excede el monto restante (${montoRestanteRedondeado} Bs.).`,
        "warning"
      );
    }

    // Agregar la nueva forma de pago a la lista
    const nuevaFormaPago = {
      formaPago: selectedFormaPago,
      montoBs: montoBsRedondeado, // Usamos el monto redondeado
      ref_transaccion: ref_transaccion,
    };
    const nuevoTotalPagado = totalPagado + nuevaFormaPago.montoBs;

    // Actualizar el estado
    setFormasPago([...formasPago, nuevaFormaPago]);
    setTotalPagado(nuevoTotalPagado);
  };

  // Función para abrir el modal
  const openFormaPagoModal = () => {
    const montoRestante = totalAPagarBs - totalPagado;

    MySwal.fire({
      title: "Agregar Forma de Pago",
      html: (
        <div>
          <select
            id="formaPago"
            className="form-select mb-2"
            style={{ width: "100%" }}
          >
            <option value="">Selecciona una forma de pago</option>
            <option value="PUNTO_DE_VENTA">Punto de Venta</option>
            <option value="EFECTIVO">Efectivo</option>
            <option value="ME">Moneda Extranjera</option>
            <option value="TRANSFERENCIA">Transferencia</option>
          </select>
          <input
            id="montoBs"
            type="number"
            className="form-control mb-2"
            placeholder={`Monto en Bolívares (Restante: ${montoRestante.toFixed(
              2
            )} Bs.)`}
            style={{ width: "100%" }}
            defaultValue={montoRestante.toFixed(2)}
            min="0"
            max={montoRestante.toFixed(2)} // Límite máximo del monto restante
          />
          <input
            id="ref_transaccion"
            type="text"
            className="form-control mb-2"
            placeholder="Referencia Transacción"
            style={{ width: "100%" }}
          />
        </div>
      ),
      preConfirm: () => {
        const selectedFormaPago = document.getElementById("formaPago").value;
        const montoBs = parseFloat(document.getElementById("montoBs").value);
        const ref_transaccion =
          document.getElementById("ref_transaccion").value;
        return { selectedFormaPago, montoBs, ref_transaccion };
      },
      confirmButtonText: "Agregar Pago",
      showCancelButton: true,
      cancelButtonText: "Cancelar",
      cancelButtonColor: "#d33",
    }).then((result) => {
      if (result.isConfirmed) {
        const { selectedFormaPago, montoBs, ref_transaccion } = result.value;
        handleAddFormaPago(selectedFormaPago, montoBs, ref_transaccion);
      }
    });
  };

  const [formasPago, setFormasPago] = useState([]);
  const [totalPagado, setTotalPagado] = useState(0);
  const [totalAPagarBs, setTotalAPagarBs] = useState(totalBolivares);

  const handleRemoveFormaPago = (index) => {
    const updatedFormasPago = [...formasPago];
    const montoEliminado = updatedFormasPago.splice(index, 1)[0].montoBs;

    // Actualizar el total pagado después de eliminar la forma de pago
    setFormasPago(updatedFormasPago);
    setTotalPagado(totalPagado - montoEliminado);
  };

  useEffect(() => {
    if (avisos && avisos.length > 0) {
      const totalBs = avisos.reduce(
        (acc, aviso) => acc + aviso.total_bolivares,
        0
      );
      const totalUsd = avisos.reduce(
        (acc, aviso) => acc + aviso.total_bolivares / aviso.valor_tasa,
        0
      );

      setTotalBolivares(totalBs);
      setTotalDolares(totalUsd);
    }
  }, [avisos]);

  const generatePDF = async (
    timestamp_ve,
    nombre_apellido_user,
    email_user,
    paquete_code
  ) => {
    try {
      // Generar el PDF con el número de listín incluido
      const pdfBlob = await pdf(
        <AvisoPDF
          avisos={avisos}
          empresaName={empresaName}
          formasPago={formasPago}
          totalAPagarBs={totalAPagarBs}
          timestamp={timestamp_ve}
          nombre_apellido_user={nombre_apellido_user}
          email_user={email_user}
          paquete_code={paquete_code}
          empresa_rif={companyRif}
        />
      ).toBlob();

      // Convertir el PDF Blob a File para subirlo
      return new File([pdfBlob], "RECIBO_DE_PAGO_" + { empresaName } + ".pdf", {
        type: "application/pdf",
      });
    } catch (error) {
      console.error("Error al generar el PDF:", error);
      throw error;
    }
  };

  const handlePDFUpload = async () => {
    try {
      // Solicitar firma
      const response = await axios.post(
        process.env.BACKEND_URL + "/api/generar_firma_cloudinary_recibo_pago",
        {},
        {
          headers: { Authorization: "Bearer " + store.token },
        }
      );

      const {
        signature,
        timestamp_ve,
        timestamp,
        api_key,
        cloud_name,
        folder,
        email_user,
        nombre_apellido_user,
        paquete_code, // Aquí obtenemos el paquete_code
      } = response.data;

      // Generar el PDF
      const pdfFile = await generatePDF(
        timestamp_ve,
        nombre_apellido_user,
        email_user,
        paquete_code // Asegúrate de que el paquete_code se pase a la generación del PDF
      );

      // Crear FormData para subir el PDF
      const formData = new FormData();
      formData.append("file", pdfFile);
      formData.append("timestamp", timestamp);
      formData.append("signature", signature);
      formData.append("api_key", api_key);
      formData.append("folder", folder);

      // Subir el PDF a Cloudinary
      const uploadResponse = await axios.post(
        `https://api.cloudinary.com/v1_1/${cloud_name}/auto/upload`,
        formData
      );

      // Validar respuesta de Cloudinary
      if (!uploadResponse.data || !uploadResponse.data.secure_url) {
        throw new Error("Cloudinary no devolvió una URL válida del PDF.");
      }

      // Retornar la URL del PDF y el paquete_code
      return {
        pdfUrl: uploadResponse.data.secure_url,
        paqueteCode: paquete_code,
      };
    } catch (error) {
      console.error("Error al subir el PDF:", error);
      throw new Error(
        error.response?.data?.message ||
          "Error al generar o subir el PDF. Intenta nuevamente."
      );
    }
  };

  const handlePayment = async () => {
    try {
      // Cambiar estado a "submitting"
      setIsSubmitting(true);

      // 1. Generar y subir el PDF
      const { pdfUrl, paqueteCode } = await handlePDFUpload(); // Asegúrate de que handlePDFUpload devuelva ambos valores

      if (!pdfUrl || !paqueteCode) {
        throw new Error(
          "No se pudo generar o subir el PDF. Intenta nuevamente."
        );
      }

      // 2. Procesar el pago después de subir el PDF
      const response = await axios.post(
        `${process.env.BACKEND_URL}/api/register_payment`,
        {
          avisos,
          empresaId,
          formasPago,
          paqueteCode,
          pdfUrl,
          empresaName,
        },
        {
          headers: { Authorization: `Bearer ${store.token}` },
        }
      );

      // Mostrar mensaje de éxito
      MySwal.fire({
        title: "Pago exitoso",
        text: "El pago ha sido procesado correctamente.",
        icon: "success",
      }).then(() => {
        navigate("/RegistroPago");
      });
    } catch (error) {
      console.error("Error al procesar el pago:", error);

      // Mostrar mensaje de error
      MySwal.fire({
        title: "Error",
        text: `Hubo un problema al procesar el pago: ${
          error.response?.data?.message ||
          error.message ||
          "Intenta nuevamente."
        }`,
        icon: "error",
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div
      className="container-fluid contarinerGeneralUserHomejs"
      style={{ display: "flex", paddingBottom: "40px" }}
    >
      <div className="container asklnduiashndaijsnxansxsaas m-0 p-0">
        <div className="sdfdfgrt54w98e7we9contewe w-100">
          <div style={{ margin: "0", marginTop: "20px" }}>
            <i
              className="fa-solid seraUnMarinsupre fa-3"
              id="icon-tittle-general"
            ></i>
          </div>
          <p>Confirmación de Pago</p>
        </div>
        <div className="formularioDeLogin sd98f4e8wf4cwe987cwe984cwe w-100">
          <div
            className="container asklnduiashndaijsnxansxsaas3"
            style={{ marginBottom: "20px" }}
          >
            <h3 style={{ textAlign: "center" }}>{empresaName}</h3>
            {/* Resumen del pago */}
            <div className="text-center mb-3 w-100">
              <h6>Resumen a pagar</h6>
              <table
                className="custom-table rounded-table"
                style={{ width: "100%", margin: "0" }}
              >
                <thead>
                  <tr>
                    <th className="table-header" style={{ fontSize: "11px" }}>
                      Total (Bs.)
                    </th>
                    <th className="table-header" style={{ fontSize: "11px" }}>
                      Total ($.)
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="table-row">
                    <td className="table-cell" style={{ fontSize: "11px" }}>
                      {totalBolivares.toFixed(2)} Bs.
                    </td>
                    <td className="table-cell" style={{ fontSize: "11px" }}>
                      {totalDolares.toFixed(2)} $.
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div
              className="aoshduiahsdkasnmdasdas"
              style={{
                marginBottom: "20px",
                flexDirection: "column",
                textAlign: "center",
              }}
            >
              <h6 className="m-0">Detalle Avisos seleccionados</h6>
              <div
                className="custom-table-container m-0"
                style={{
                  maxWidth: "100%",
                  overflow: "auto",
                  maxHeight: "80vh",
                }}
              >
                <table
                  className="custom-table rounded-table"
                  style={{ width: "100%", minWidth: "450px", margin: "0" }}
                >
                  <thead>
                    <tr>
                      <th className="table-header" style={{ fontSize: "11px" }}>
                        N° Listín
                      </th>
                      <th className="table-header" style={{ fontSize: "11px" }}>
                        Fecha Viaje
                      </th>
                      <th className="table-header" style={{ fontSize: "11px" }}>
                        Valor Tasa
                      </th>
                      <th className="table-header" style={{ fontSize: "11px" }}>
                        Total (Bs.)
                      </th>
                      <th className="table-header" style={{ fontSize: "11px" }}>
                        Total ($.)
                      </th>
                    </tr>
                  </thead>
                  {avisos && avisos.length > 0 ? (
                    avisos.map((aviso, index) => (
                      <tbody key={index}>
                        <tr className="table-row">
                          <td
                            className="table-cell"
                            style={{ fontSize: "11px" }}
                          >
                            {aviso?.listin_terminal || "N/A"}
                          </td>
                          <td
                            className="table-cell"
                            style={{ fontSize: "11px" }}
                          >
                            {aviso?.fecha_viaje}
                          </td>
                          <td
                            className="table-cell"
                            style={{ fontSize: "11px" }}
                          >
                            {aviso?.valor_tasa} Bs.
                          </td>
                          <td
                            className="table-cell"
                            style={{ fontSize: "11px" }}
                          >
                            {aviso?.total_bolivares || "0"} Bs.
                          </td>
                          <td
                            className="table-cell"
                            style={{ fontSize: "11px" }}
                          >
                            {(
                              aviso?.total_bolivares / aviso?.valor_tasa
                            )?.toFixed(2) || "0.00"}{" "}
                            $.
                          </td>
                        </tr>
                      </tbody>
                    ))
                  ) : (
                    <p>No hay avisos seleccionados.</p>
                  )}
                </table>
              </div>

              {/* Listado de formas de pago agregadas */}
              <div className="mt-3">
                <h6>Formas de Pago Agregadas</h6>
                <table
                  className="custom-table rounded-table"
                  style={{ width: "100%", margin: "0" }}
                >
                  <thead>
                    <tr>
                      <th
                        className="table-header3"
                        style={{ fontSize: "11px" }}
                      >
                        Forma de Pago
                      </th>
                      <th
                        className="table-header3"
                        style={{ fontSize: "11px" }}
                      >
                        Monto (Bs.)
                      </th>
                      <th
                        className="table-header3"
                        style={{ fontSize: "11px" }}
                      >
                        Ref Transacción
                      </th>
                      <th
                        className="table-header3"
                        style={{ fontSize: "11px" }}
                      >
                        Acciones
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {formasPago.length > 0 ? (
                      formasPago.map((pago, index) => (
                        <tr className="table-row" key={index}>
                          <td
                            className="table-cell"
                            style={{ fontSize: "11px" }}
                          >
                            {pago.formaPago}
                          </td>
                          <td
                            className="table-cell"
                            style={{ fontSize: "11px" }}
                          >
                            {pago.montoBs.toFixed(2)} Bs.
                          </td>
                          <td
                            className="table-cell"
                            style={{ fontSize: "11px" }}
                          >
                            {pago.ref_transaccion}
                          </td>
                          <td
                            className="table-cell"
                            style={{
                              fontSize: "11px",
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            <button
                              type="button"
                              className="btn btn-danger btn-sm"
                              onClick={() => handleRemoveFormaPago(index)}
                            >
                              <i className="fa-solid fa-trash"></i>
                            </button>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td
                          colSpan="3"
                          className="text-center"
                          style={{ fontSize: "11px" }}
                        >
                          Aún no hay ninguna forma de pago registrada
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>

                <div className="form-group mt-4 d-flex justify-content-center">
                  <button
                    type="button"
                    className="btn btn-secondary d-flex"
                    style={{ alignItems: "center" }}
                    onClick={openFormaPagoModal}
                  >
                    <i
                      className="fa-solid fa-plus"
                      style={{ color: "white", marginRight: "7px" }}
                    ></i>{" "}
                    Nueva Forma de Pago
                  </button>
                </div>
              </div>

              {/* Información del total pagado */}
              <div className="mt-4">
                <table
                  className="custom-table rounded-table"
                  style={{ width: "100%", margin: "0" }}
                >
                  <thead>
                    <tr>
                      <th
                        className="table-header2"
                        style={{ fontSize: "11px" }}
                      >
                        Total Pagado
                      </th>
                      <th
                        className="table-header2"
                        style={{ fontSize: "11px" }}
                      >
                        Estatus del pago
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="table-row">
                      <td className="table-cell" style={{ fontSize: "11px" }}>
                        {totalPagado.toFixed(2)} Bs.
                      </td>
                      <td className="table-cell" style={{ fontSize: "11px" }}>
                        {totalPagado.toFixed(2) > totalAPagarBs.toFixed(2) ? (
                          <span className="text-danger">¡Exceso de Pago!</span>
                        ) : totalPagado.toFixed(2) ==
                          totalAPagarBs.toFixed(2) ? (
                          <span className="text-success">Pago Completo</span>
                        ) : (
                          <span>
                            {(totalAPagarBs.toFixed(2) - totalPagado).toFixed(
                              2
                            )}{" "}
                            Bs. restantes
                          </span>
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>

                {/* Mostrar mensajes adicionales según el estatus del pago */}
                {totalPagado.toFixed(2) == totalAPagarBs.toFixed(2) && (
                  <div className="mt-3 text-success">
                    <strong>¡Pago Completado con Éxito!</strong>
                  </div>
                )}
                {totalPagado.toFixed(2) > totalAPagarBs.toFixed(2) && (
                  <div className="mt-3 text-danger">
                    <strong>¡Exceso de Pago!</strong>
                  </div>
                )}
              </div>
            </div>
            <div className="d-flex justify-content-center">
              <button
                type="button"
                className="btn text-light"
                style={{ backgroundColor: "#24827c" }}
                onClick={handlePayment}
                disabled={isSubmitting}
              >
                {isSubmitting ? "Procesando..." : "Confirmar Pago"}
              </button>
            </div>
          </div>
          <div className="text-center mt-3">
            <button onClick={handlePreviewPDF} className="btn btn-primary">
              Vista Previa del Recibo
            </button>
          </div>
          <div
            className="w-100"
            style={{
              padding: "20px",
              width: "100%",
              flexDirection: "column",
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
            }}
          >
            <hr style={{ width: "90%" }}></hr>
            <button
              onClick={handleCancelClick}
              className="btn btn-secondary"
              style={{ color: "white" }}
            >
              Cancelar
            </button>
          </div>
        </div>
      </div>
      <div className="d-flex" style={{ marginTop: "12px" }}>
        <p>¿Alguna duda?,</p>
        <Link
          style={{
            marginLeft: "5px",
            textDecoration: "none",
            color: "#24827C",
          }}
          to="/AyudaYSoporte"
        >
          <strong>Contáctanos</strong>
        </Link>
      </div>
      <hr style={{ width: "90%" }} />
    </div>
  );
};
