import React, { useContext, useState, useEffect } from "react";
import axios from "axios";
import "../../../../styles/SolicitudActivaciones.css";
import { Context } from "../../../store/appContext";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Link } from "react-router-dom";

const MySwal = withReactContent(Swal);

export const VistaRetornosAgregados = () => {
  const { store } = useContext(Context);
  const [records, setRecords] = useState({ control_entrada_vehicular: [] });

  const [selectedIds, setSelectedIds] = useState([]);

  const handleCheckboxChange = (id) => {
    setSelectedIds((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((selectedId) => selectedId !== id)
        : [...prevSelected, id]
    );
  };

  const handleDeleteSelected = async () => {
    if (selectedIds.length === 0) {
      Swal.fire(
        "Atención",
        "No hay registros seleccionados para eliminar.",
        "warning"
      );
      return;
    }

    Swal.fire({
      title: "¿Estás seguro?",
      text: "Esta acción eliminará permanentemente los registros seleccionados.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Sí, eliminar",
      cancelButtonText: "Cancelar",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await axios.post(
            process.env.BACKEND_URL + "/api/control_entrada/eliminar",
            { ids: selectedIds },
            {
              headers: {
                Authorization: `Bearer ${store.token}`,
              },
            }
          );
          Swal.fire(
            "Eliminado",
            "Los registros han sido eliminados.",
            "success"
          );
          setSelectedIds([]);
          fetchTodayRecords(); // Recargar datos después de eliminar
        } catch (error) {
          console.error("Error al eliminar registros:", error);
          Swal.fire(
            "Error",
            "Hubo un problema al eliminar los registros.",
            "error"
          );
        }
      }
    });
  };

  const fetchTodayRecords = async () => {
    try {
      // Configurar el token de autorización en los headers
      const response = await axios.get(
        process.env.BACKEND_URL + "/api/control_entrada_vehicular_get",
        {
          headers: {
            Authorization: `Bearer ${store.token}`, // Corrige el uso del token
          },
        }
      );
      setRecords(response.data); // Guarda los datos obtenidos
    } catch (error) {
      console.error("Error al obtener registros de hoy:", error);
    }
  };

  useEffect(() => {
    fetchTodayRecords();
  }, []);

  const formatDate = (dateString) => {
    const date = new Date(dateString); // Asegúrate de que `dateString` esté en formato válido
    const day = String(date.getUTCDate()).padStart(2, "0"); // Usa UTC para evitar problemas de zonas horarias
    const month = String(date.getUTCMonth() + 1).padStart(2, "0"); // Meses son 0 indexados
    const year = date.getUTCFullYear();
    return `${day}-${month}-${year}`;
  };

  return (
    <div
      className="container-fluid contarinerGeneralUserHomejs"
      style={{
        display: "flex",
        paddingBottom: "40px",
      }}
    >
      <div className="container asklnduiashndaijsnxansxsaas m-0 p-0">
        <div
          style={{
            textAlign: "center",
            padding: "10px",
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
          className="sdfdfgrt54w98e7we9contewe"
        >
          <div style={{ margin: "0" }}>
            <i
              className="fa-solid seraUnMarinsupre fa-rectangle-list"
              id="icon-tittle-general"
            ></i>
          </div>
          <p
            style={{ fontSize: "13px", margin: "0" }}
            className="asdjhfnerer4pdeterminal"
          >
            Por defecto, la tabla traerá los últimos 40 registros.
          </p>
        </div>
        <div className="container-table-pds-padre">
          <div className="container-table-pds">
            <div className="container-table-pds-hijo-1">
              <div
                className="input-group-login69"
                style={{ fontSize: "12px", maxWidth: "130px" }}
              >
                <input
                  className="inputDeLogin"
                  style={{ height: "33px", fontSize: "12px" }}
                  type="date"
                  required
                  // value={fechaInicio}
                  // onChange={(e) => setFechaInicio(e.target.value)}
                />
                <label>Fecha Inicio:</label>
              </div>
              <div
                className="input-group-login69"
                style={{ fontSize: "12px", maxWidth: "130px" }}
              >
                <input
                  className="inputDeLogin"
                  style={{ height: "33px", fontSize: "12px" }}
                  type="date"
                  required
                  // value={fechaFinal}
                  // onChange={(e) => setFechaFinal(e.target.value)}
                />
                <label>Fecha Final:</label>
              </div>
              <button
                className="btn btn-secondary"
                style={{
                  height: "76%",
                  marginRight: "100px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: "12px",
                }}
              >
                Buscar
              </button>
              <button
                className="btn btn-danger"
                style={{
                  height: "76%",
                  marginRight: "5px",
                  marginLeft: "auto",
                  color: "white",
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                  fontSize: "12px",
                }}
                onClick={handleDeleteSelected}
              >
                <i className="fa-solid fa-trash-can"></i>
              </button>
              <Link
                className="btn btn-info"
                to="/RegistroRetornosEst"
                style={{
                  height: "76%",
                  marginRight: "5px",
                  color: "white",
                  alignItems: "center",
                  justifyContent: "center",
                  display: "flex",
                  fontSize: "12px",
                }}
              >
                <i className="fa-solid fa-plus"></i>
              </Link>
            </div>
            <div className="w-100 h-100">
              <div style={{ width: "100%" }}>
                <div
                  className="custom-table-container m-0"
                  style={{
                    maxWidth: "100%",
                    padding: "10px",
                    overflow: "auto",
                    maxHeight: "50vh",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <table className="custom-table rounded-table m-0">
                    <thead>
                      <tr>
                        <th className="table-header69">
                          <input
                            type="checkbox"
                            onChange={(e) =>
                              setSelectedIds(
                                e.target.checked
                                  ? records.control_entrada_vehicular.map(
                                      (d) => d.id
                                    )
                                  : []
                              )
                            }
                            checked={
                              selectedIds.length ===
                              records.control_entrada_vehicular.length
                            }
                          />
                        </th>
                        <th className="table-header69">Fecha y Hora Entrada</th>
                        <th className="table-header69">Empresa</th>
                        <th className="table-header69">Destino</th>
                        <th className="table-header69">Parada</th>
                        <th className="table-header69">Unidad</th>
                        <th className="table-header69">Usuarios</th>
                      </tr>
                    </thead>

                    {records && (
                      <tbody>
                        {records.control_entrada_vehicular.map(
                          (detalle, index) => (
                            <tr className="table-row" key={index}>
                              <td className="table-cell69">
                                <input
                                  type="checkbox"
                                  checked={selectedIds.includes(detalle.id)}
                                  onChange={() =>
                                    handleCheckboxChange(detalle.id)
                                  }
                                />
                              </td>
                              <td className="table-cell69">
                                {formatDate(detalle.fecha_entrada)} -{" "}
                                {detalle.hora_entrada}
                              </td>
                              <td className="table-cell69">
                                {detalle.empresa}
                              </td>
                              <td className="table-cell69">
                                {detalle.destino}
                              </td>
                              <td className="table-cell69">
                                {detalle.token_o_final}
                              </td>
                              <td className="table-cell69">
                                {detalle.placa} - {detalle.n_unidad}
                              </td>
                              <td className="table-cell69">
                                {detalle.pasajeros}
                              </td>
                            </tr>
                          )
                        )}
                      </tbody>
                    )}
                  </table>
                  {console.log(selectedIds)}
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr style={{ width: "90%" }}></hr>

        <div className="d-flex" style={{ marginTop: "12px" }}>
          <p>¿Alguna duda?,</p>
          <Link
            style={{
              marginLeft: "5px",
              textDecoration: "none",
              color: "#24827C",
            }}
            to="/AyudaYSoporte"
          >
            <strong>Contáctanos</strong>
          </Link>
        </div>
      </div>
    </div>
  );
};
