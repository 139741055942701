import React, { useContext, useState, useEffect } from "react";
import { Context } from "../../store/appContext";
import { Link, Navigate } from "react-router-dom";
import "../../../styles/CompanyAdminNavbar.css";

export const Navbar = () => {
  const { store, actions } = useContext(Context);
  const [selectedMenu, setSelectedMenu] = useState(null);

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("userData");
    window.location.reload();
  };
  const handleClick = (menu) => {
    setSelectedMenu(menu);
  };

  useEffect(() => {
    // Obtén el pathname de la ubicación actual
    const pathname = location.pathname;

    // Mapea el pathname a un nombre de menú y establece el menú seleccionado
    const menuMapping = {
      "/": "/",
      "": "",
      "/RegistroCps": "RegistroCps",
      "/EmpresasDeTransporte": "EmpresasDeTransporte",
      "/Configuraciones": "Configuraciones",
      "/RegistroUsuarios": "RegistroUsuarios",
      "/Destinos": "Destinos",
      "/Programacion": "Programacion",
      "/ActivacionCalendario": "ActivacionCalendario",
      "/PrecioDestinoFinalRC": "PrecioDestinoFinalRC",
      "/PrecioDestinoFinalRL": "PrecioDestinoFinalRL",
      "/KmDistancia": "KmDistancia",
      "/RegistrosCpsEdit": "RegistrosCpsEdit",
      "/Autorizacionporpuesto": "Autorizacionporpuesto",
      "/CierresRutasCortas": "CierresRutasCortas",
      "/RegistroActivacionesHoy": "RegistroActivacionesHoy",
      "/AyudaYSoporte": "AyudaYSoporte",
      "/EmpresaTerminal": "EmpresaTerminal",
      "/RegistrosCpsVision": "RegistrosCpsVision",
      "/DestinoFinal": "DestinoFinal",
      "/DTplacaTerminalEmpresa": "DTplacaTerminalEmpresa",
      "/RegistroContingenciaRuta": "RegistroContingenciaRuta",
      "/DTContingenciaPlaca": "DTContingenciaPlaca",
      "/RegistroConductorEmpresa": "RegistroConductorEmpresa",
      "/ActivacionNuevo": "ActivacionNuevo",
      "/ActivacionesHoyNuevo": "ActivacionesHoyNuevo",
    };

    const menu = menuMapping[pathname];
    if (menu) {
      setSelectedMenu(menu);
    }
  }, [location.pathname]);

  const [isOpen, setIsOpen] = useState(true);
  const [isOpen1, setIsOpen1] = useState(true);
  const [isOpen2, setIsOpen2] = useState(true);

  return (
    <div className="ideaAdminCompany-container2">
      <div className="ideaAdminCompany-container" style={{ position: "fixed" }}>
        <div className="as56d4s9f87er98fwe4fwr987fwee98d4qw987">
          <img
            src="https://res.cloudinary.com/dirpdlfbu/image/upload/v1713119110/INTRAVIALCA/truxqodtlg1vzishxhot.png"
            alt="Logo"
            style={{ height: "35px" }}
            className="d65f4sd9f8w7ef98we7qw654er987fw"
          />
          <i
            className="fa-solid a6s54d89f7v98wedq89wre98we87icneassa9d87 fa-bars"
            style={{ color: "white" }}
          ></i>
        </div>
        <div className="sdsdf98u7yt9htg7wfqe987879467434">
          {/* A partir de aqui coloca el nuevo menu aqui dentro de este div */}
          <Link
            className={`subtitulosMenuUserHomeCompany ${
              selectedMenu === "/" ? "selected-menu" : ""
            }`}
            to="/"
            onClick={() => handleClick("/")}
          >
            <i className="fa-solid seraUnMarinsupre fa-globe"></i>
            <div className="LetterInvisible2" style={{ fontSize: "12px" }}>
              Inicio
            </div>
          </Link>
          <div
            style={{
              width: "100%",
              background: "#8080804a",
              height: ".07px",
              margin: "4px 0 4px 0",
            }}
          ></div>
          <div className="menu-container">
            <fieldset className={`menu-fieldset ${isOpen ? "open" : ""}`}>
              <legend
                onClick={() => setIsOpen(!isOpen)}
                className={`subtitulosMenuUserHomeCompany2 menu-legend m-0`}
                style={{
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "row",
                  fontSize: "12px",
                  padding: "15px 10px 10px 24px",
                  cursor: "pointer",
                }}
              >
                <i
                  style={{
                    marginRight: "10px",
                    alignItems: "center",
                    display: "flex",
                  }}
                  className="fa-solid fa-bolt-lightning"
                ></i>
                EMPRESAS{" "}
                <i
                  style={{ marginLeft: "auto" }}
                  className="fa-solid fa-chevron-down"
                ></i>
              </legend>
              {isOpen && (
                <div className="menu-links">
                  <Link
                    className={`subtitulosMenuUserHomeCompany ${selectedMenu === "/EmpresaTerminal" ? "selected-menu" : ""}`}
                    to="/EmpresaTerminal"
                    onClick={() => handleClick("/EmpresaTerminal")}
                  >
                    <i className="fa-solid seraUnMarinsupre fa-user-tie"></i>
                    <div
                      className="LetterInvisible2"
                      style={{ fontSize: "12px" }}
                    >
                      Empresas Terminal
                    </div>
                  </Link>
                  <Link
                    className={`subtitulosMenuUserHomeCompany ${
                      selectedMenu === "RegistroCps" ? "selected-menu" : ""
                    }`}
                    onClick={() => handleClick("RegistroCps")}
                    to="/RegistroCps"
                  >
                    <i className="fa-solid seraUnMarinsupre fa-file-circle-plus"></i>
                    <div
                      className="LetterInvisible2"
                      style={{ fontSize: "12px" }}
                    >
                      Registro Ruta CPS
                    </div>
                  </Link>
                  <Link
                    className={`subtitulosMenuUserHomeCompany ${
                      selectedMenu === "RegistroContingenciaRuta"
                        ? "selected-menu"
                        : ""
                    }`}
                    onClick={() => handleClick("RegistroContingenciaRuta")}
                    to="/RegistroContingenciaRuta"
                  >
                    <i className="fa-solid seraUnMarinsupre fa-triangle-exclamation"></i>
                    <div
                      className="LetterInvisible2"
                      style={{ fontSize: "12px" }}
                    >
                      Registro Ruta Contingencia
                    </div>
                  </Link>
                  <Link
                    className={`subtitulosMenuUserHomeCompany ${
                      selectedMenu === "Listado Rutas Empresas"
                        ? "selected-menu"
                        : ""
                    }`}
                    onClick={() => handleClick("RegistrosCpsVision")}
                    to="/RegistrosCpsVision"
                  >
                    <i className="fa-solid seraUnMarinsupre fa-list-check"></i>
                    <div
                      className="LetterInvisible2"
                      style={{ fontSize: "12px" }}
                    >
                      Listado Rutas Empresas
                    </div>
                  </Link>
                  <Link
                    className={`subtitulosMenuUserHomeCompany ${selectedMenu === "/DTplacaTerminalEmpresa" ? "selected-menu" : ""}`}
                    to="/DTplacaTerminalEmpresa"
                    onClick={() => handleClick("/DTplacaTerminalEmpresa")}
                  >
                    <i className="fa-solid seraUnMarinsupre fa-bus"></i>
                    <div
                      className="LetterInvisible2"
                      style={{ fontSize: "12px" }}
                    >
                      DT Empresas
                    </div>
                  </Link>
                  <Link
                    className={`subtitulosMenuUserHomeCompany ${selectedMenu === "/DTContingenciaPlaca" ? "selected-menu" : ""}`}
                    to="/DTContingenciaPlaca"
                    onClick={() => handleClick("/DTContingenciaPlaca")}
                  >
                    <i className="fa-solid seraUnMarinsupre fa-triangle-exclamation"></i>
                    <div
                      className="LetterInvisible2"
                      style={{ fontSize: "12px" }}
                    >
                      DT Contingencia Empresa
                    </div>
                  </Link>
                  <Link
                    className={`subtitulosMenuUserHomeCompany ${selectedMenu === "/RegistroConductorEmpresa" ? "selected-menu" : ""}`}
                    to="/RegistroConductorEmpresa"
                    onClick={() => handleClick("/RegistroConductorEmpresa")}
                  >
                    <i className="fa-solid seraUnMarinsupre fa-id-card"></i>
                    <div
                      className="LetterInvisible2"
                      style={{ fontSize: "12px" }}
                    >
                      Registro Conductor Empresa
                    </div>
                  </Link>
                </div>
              )}
            </fieldset>
          </div>
          <div
            style={{
              width: "100%",
              background: "#8080804a",
              height: ".07px",
              margin: "4px 0 4px 0",
            }}
          ></div>

          <div className="menu-container">
            <fieldset className={`menu-fieldset ${isOpen1 ? "open" : ""}`}>
              <legend
                onClick={() => setIsOpen1(!isOpen1)}
                className={`subtitulosMenuUserHomeCompany2 menu-legend m-0`}
                style={{
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "row",
                  fontSize: "12px",
                  padding: "15px 10px 10px 24px",
                  cursor: "pointer",
                }}
              >
                <i
                  style={{
                    marginRight: "10px",
                    alignItems: "center",
                    display: "flex",
                  }}
                  className="fa-solid fa-plus"
                ></i>
                ACTIVACIONES
                <i
                  style={{ marginLeft: "auto" }}
                  className="fa-solid fa-chevron-down"
                ></i>
              </legend>
              {isOpen1 && (
                <div className="menu-links">
                  <Link
                    className={`subtitulosMenuUserHomeCompany ${selectedMenu === "/ActivacionNuevo" ? "selected-menu" : ""}`}
                    to="/ActivacionNuevo"
                    onClick={() => handleClick("/ActivacionNuevo")}
                  >
                    <i className="fa-solid seraUnMarinsupre fa-bullhorn"></i>
                    <div
                      className="LetterInvisible2"
                      style={{ fontSize: "12px" }}
                    >
                      Registro Activación Nuevo
                    </div>
                  </Link>
                  <Link
                    className={`subtitulosMenuUserHomeCompany ${selectedMenu === "/ActivacionParaBillBoard" ? "selected-menu" : ""}`}
                    to="/ActivacionParaBillBoard"
                    onClick={() => handleClick("/ActivacionParaBillBoard")}
                  >
                    <i className="fa-solid seraUnMarinsupre fa-bullhorn"></i>
                    <div
                      className="LetterInvisible2"
                      style={{ fontSize: "12px" }}
                    >
                      Registro Activación
                    </div>
                  </Link>
                  <Link
                    className={`subtitulosMenuUserHomeCompany ${selectedMenu === "/RegistroActivacionesHoy" ? "selected-menu" : ""}`}
                    to="/RegistroActivacionesHoy"
                    onClick={() => handleClick("/RegistroActivacionesHoy")}
                  >
                    <i className="fa-solid seraUnMarinsupre fa-file-circle-check"></i>
                    <div
                      className="LetterInvisible2"
                      style={{ fontSize: "12px" }}
                    >
                      Activaciones Hoy
                    </div>
                  </Link>
                  <Link
                    className={`subtitulosMenuUserHomeCompany ${selectedMenu === "/ActivacionesHoyNuevo" ? "selected-menu" : ""}`}
                    to="/ActivacionesHoyNuevo"
                    onClick={() => handleClick("/ActivacionesHoyNuevo")}
                  >
                    <i className="fa-solid seraUnMarinsupre fa-file-circle-check"></i>
                    <div
                      className="LetterInvisible2"
                      style={{ fontSize: "12px" }}
                    >
                      Activaciones Hoy Nuevo
                    </div>
                  </Link>
                </div>
              )}
            </fieldset>
          </div>
          <div
            style={{
              width: "100%",
              background: "#8080804a",
              height: ".07px",
              margin: "4px 0 4px 0",
            }}
          ></div>

          <div className="menu-container">
            <fieldset className={`menu-fieldset ${isOpen2 ? "open" : ""}`}>
              <legend
                onClick={() => setIsOpen2(!isOpen2)}
                className={`subtitulosMenuUserHomeCompany2 menu-legend m-0`}
                style={{
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "row",
                  fontSize: "12px",
                  padding: "15px 10px 10px 24px",
                  cursor: "pointer",
                }}
              >
                <i
                  style={{
                    marginRight: "10px",
                    alignItems: "center",
                    display: "flex",
                  }}
                  className="fa-solid fa-users"
                ></i>
                USUARIOS
                <i
                  style={{ marginLeft: "auto" }}
                  className="fa-solid fa-chevron-down"
                ></i>
              </legend>
              {isOpen2 && (
                <div className="menu-links">
                  <Link
                    className={`subtitulosMenuUserHomeCompany ${
                      selectedMenu === "RegistroUsuarios" ? "selected-menu" : ""
                    }`}
                    onClick={() => handleClick("RegistroUsuarios")}
                    to="/RegistroUsuarios"
                  >
                    <i className="fa-solid seraUnMarinsupre fa-users"></i>
                    <div
                      className="LetterInvisible2"
                      style={{ fontSize: "12px" }}
                    >
                      Registro Usuarios
                    </div>
                  </Link>
                </div>
              )}
            </fieldset>
          </div>
          <div
            style={{
              width: "100%",
              background: "#8080804a",
              height: ".07px",
              margin: "4px 0 4px 0",
            }}
          ></div>

          <Link
            className={`subtitulosMenuUserHomeCompany ${
              selectedMenu === "DestinoFinal" ? "selected-menu" : ""
            }`}
            onClick={() => handleClick("DestinoFinal")}
            to="/DestinoFinal"
          >
            <i className="fa-solid seraUnMarinsupre fa-route"></i>
            <div className="LetterInvisible2" style={{ fontSize: "12px" }}>
              Destino Final
            </div>
          </Link>

          {/* <Link
            className={`subtitulosMenuUserHomeCompany ${
              selectedMenu === "EmpresasDeTransporte" ? "selected-menu" : ""
            }`}
            onClick={() => handleClick("EmpresasDeTransporte")}
            to="/EmpresasDeTransporte"
          >
            <i className="fa-solid seraUnMarinsupre fa-user-tie"></i>
            <div className="LetterInvisible2" style={{ fontSize: "12px" }}>
              Empresas de Transporte
            </div>
          </Link>
          <Link
            className={`subtitulosMenuUserHomeCompany ${
              selectedMenu === "Destinos" ? "selected-menu" : ""
            }`}
            onClick={() => handleClick("Destinos")}
            to="/Destinos"
          >
            <i className="fa-solid seraUnMarinsupre fa-location-dot"></i>
            <div className="LetterInvisible2" style={{ fontSize: "12px" }}>
              Destinos
            </div>
          </Link> */}

          {/* <Link
            className={`subtitulosMenuUserHomeCompany ${
              selectedMenu === "Programacion" ? "selected-menu" : ""
            }`}
            onClick={() => handleClick("Programacion")}
            to="/Programacion"
          >
            <i className="fa-solid seraUnMarinsupre fa-calendar-check"></i>
            <div className="LetterInvisible2" style={{ fontSize: "12px" }}>
              Programación
            </div>
          </Link>
          <Link
            className={`subtitulosMenuUserHomeCompany ${
              selectedMenu === "ActivacionCalendario" ? "selected-menu" : ""
            }`}
            onClick={() => handleClick("ActivacionCalendario")}
            to="/ActivacionCalendario"
          >
            <i className="fa-solid seraUnMarinsupre fa-calendar-days"></i>
            <div className="LetterInvisible2" style={{ fontSize: "12px" }}>
              Calendario de Salidas
            </div>
          </Link>
          <Link
            className={`subtitulosMenuUserHomeCompany ${
              selectedMenu === "PrecioDestinoFinalRC" ? "selected-menu" : ""
            }`}
            onClick={() => handleClick("PrecioDestinoFinalRC")}
            to="/PrecioDestinoFinalRC"
          >
            <i className="fa-solid seraUnMarinsupre fa-tags"></i>
            <div className="LetterInvisible2" style={{ fontSize: "12px" }}>
              Rutas Cortas Precios
            </div>
          </Link>
          <Link
            className={`subtitulosMenuUserHomeCompany ${
              selectedMenu === "PrecioDestinoFinalRL" ? "selected-menu" : ""
            }`}
            onClick={() => handleClick("PrecioDestinoFinalRL")}
            to="/PrecioDestinoFinalRL"
          >
            <i className="fa-solid seraUnMarinsupre fa-tags"></i>
            <div className="LetterInvisible2" style={{ fontSize: "12px" }}>
              Rutas Largas Precios
            </div>
          </Link>
          <Link
            className={`subtitulosMenuUserHomeCompany ${
              selectedMenu === "KmDistancia" ? "selected-menu" : ""
            }`}
            onClick={() => handleClick("KmDistancia")}
            to="/KmDistancia"
          >
            <i className="fa-solid seraUnMarinsupre fa-route"></i>
            <div className="LetterInvisible2" style={{ fontSize: "12px" }}>
              Destino Final Km
            </div>
          </Link>
          <Link
            className={`subtitulosMenuUserHomeCompany ${
              selectedMenu === "Autorizacionporpuesto" ? "selected-menu" : ""
            }`}
            onClick={() => handleClick("Autorizacionporpuesto")}
            to="/Autorizacionporpuesto"
          >
            <i className="fa-solid seraUnMarinsupre fa-road-circle-check"></i>
            <div className="LetterInvisible2" style={{ fontSize: "12px" }}>
              Autorización Por Puesto
            </div>
          </Link>
          <Link
            className={`subtitulosMenuUserHomeCompany ${
              selectedMenu === "CierresRutasCortas" ? "selected-menu" : ""
            }`}
            onClick={() => handleClick("CierresRutasCortas")}
            to="/CierresRutasCortas"
          >
            <i className="fa-solid seraUnMarinsupre fa-bus"></i>
            <div className="LetterInvisible2" style={{ fontSize: "12px" }}>
              Cierres Rutas Cortas
            </div>
          </Link> */}
          <Link
            className={`subtitulosMenuUserHomeCompany ${
              selectedMenu === "AyudaYSoporte" ? "selected-menu" : ""
            }`}
            onClick={() => handleClick("AyudaYSoporte")}
            to="/AyudaYSoporte"
          >
            <i className="fa-solid seraUnMarinsupre fa-headset"></i>
            <div className="LetterInvisible2" style={{ fontSize: "12px" }}>
              Ayuda Y Soporte
            </div>
          </Link>

          <div className="s547"></div>
        </div>
        <div className="d56f4d89g7wfq98d7et98re4f6t5er1eq56w64">
          <Link
            className={`subtitulosMenuUserHomeCompany ${
              selectedMenu === "Configuraciones" ? "selected-menu" : ""
            }`}
            onClick={() => handleClick("Configuraciones")}
            to="/Configuraciones"
          >
            <i className="fa-solid seraUnMarinsupre fa-gear"></i>
            <div className="LetterInvisible2" style={{ fontSize: "12px" }}>
              Configuraciones
            </div>
          </Link>
        </div>
        <div className="d56f4d89g7wfq98d7et98re4f6t5er1eq56w64">
          <a
            className="subtitulosMenuUserHomeCompany logoutbuttonCompany"
            onClick={handleLogout}
            href="/"
          >
            <i className="fa-solid seraUnMarinsupre fa-right-from-bracket"></i>
            <div
              href="#"
              className="LetterInvisible2"
              style={{ fontSize: "12px" }}
            >
              Cerrar sesión
            </div>
          </a>
        </div>
      </div>
    </div>
  );
};
