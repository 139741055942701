import React from "react";

const LoadingOverlay = () => {
  return (
    <div className="loading-overlay">
      <div className="loading-spinner"></div>
      <img
        style={{ width: "240px", marginTop: "20px" }}
        src="https://res.cloudinary.com/dirpdlfbu/image/upload/v1712419654/INTRAVIALCA/h3w410oozis3kujgakfm.png"
      />
    </div>
  );
};

export default LoadingOverlay;
