import React, { useContext, useState, useEffect } from "react";
import axios from "axios";
import "../../../../styles/SolicitudActivaciones.css";
import { Context } from "../../../store/appContext";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const MySwal = withReactContent(Swal);

export const RegistroRetornosEst = () => {
  const { store } = useContext(Context);
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    empresa_terminal_id: "",
    empresa: "",
    dt: "",
    fecha_de_entrada: "",
    hora_de_entrada: "",
    destino_nombre: "",
    destino_id: "",
    numero_de_unidad: "",
    pasajeros: 0,
    parada: "",
    destino_proveniente: false,
  });
  const [cpsData, setCpsData] = useState([]); // Data completa del CPS
  const [empresas, setEmpresas] = useState([]);
  const [destino, setDestino] = useState([]);
  const [filteredParadas, setFilteredParadas] = useState([]); // Paradas filtradas por empresa
  const [filteredRutas, setFilteredRutas] = useState([]); // Rutas filtradas por parada
  const [referencia, setReferencia] = useState([]); // Referencias filtradas por parada
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [records, setRecords] = useState([]);
  const [filteredHorarios, setFilteredHorarios] = useState([]);

  useEffect(() => {
    fetchempresasysuscps();
    fetchDestinoProveniente();
  }, []);

  const fetchempresasysuscps = async () => {
    try {
      const response = await axios.get(
        process.env.BACKEND_URL + "/api/empresas_combinadas",
        {
          headers: {
            Authorization: `Bearer ${store.token}`,
          },
        }
      );
      setEmpresas(response.data.empresas_combinadas);
    } catch (error) {
      MySwal.fire({
        title: "Error",
        text: "Error al cargar las empresas disponibles.",
        icon: "error",
        confirmButtonText: "Cerrar",
      });
    }
  };

  const fetchDestinoProveniente = async () => {
    try {
      const response = await axios.get(
        process.env.BACKEND_URL + "/api/destinos_combinados",
        {
          headers: {
            Authorization: `Bearer ${store.token}`,
          },
        }
      );
      setDestino(response.data.destinos_combinados);
    } catch (error) {
      MySwal.fire({
        title: "Error",
        text: "Error al cargar los destinos disponibles.",
        icon: "error",
        confirmButtonText: "Cerrar",
      });
    }
  };

  const fetchTodayRecords2 = async () => {
    MySwal.fire({
      title: "Cargando...",
      html: `<p style="text-align:center; margin:20px;">Cargando Activaciones...</p>`,
      allowOutsideClick: false,
      didOpen: () => {
        MySwal.showLoading();
      },
    });

    try {
      const response = await axios.get(
        process.env.BACKEND_URL + "/api/get_activations_new",
        {
          headers: {
            Authorization: `Bearer ${store.token}`,
          },
        }
      );

      const data = response.data.activations;

      MySwal.close();

      if (!data || data.length === 0) {
        MySwal.fire({
          title: "Activaciones de Hoy",
          html: `<p style="text-align:center; margin:20px;">No se encontraron Activaciones.</p>`,
          confirmButtonText: "Cerrar",
        });
      } else {
        const tableHeaders = `
          <tr>
            <th class="table-header" style="text-align: start; font-size: 12px">EMPRESA</th>
            <th class="table-header" style="text-align: start; font-size: 12px">DESTINO FINAL</th>
            <th class="table-header" style="text-align: start; font-size: 12px; width: 90px">UNIDAD</th>
            <th class="table-header" style="text-align: start; font-size: 12px; width: 100px">PLACA</th>
            <th class="table-header" style="text-align: start; font-size: 12px; width: 100px">ACCIONES</th>
          </tr>
        `;
        const statusMap = {
          ACTIVE: "ACTIVO",
          NO_ACTIVE: "BLOQUEADO",
        };

        const tableRows = data
          .map(
            (item) => `
            <tr>
              <td>${item.empresa?.nombre || "N/A"}</td>
              <td>${item.ruta?.destino_final?.destino_final_detalle?.descripcion || "No disponible"}</td>
              <td>${item.numero_de_unidad}</td>
              <td>${item.dt_terminal_empresa?.placa || "No disponible"}</td>
              <td><button class="btn btn-danger" id="delete-${item.id}" style="font-size: 10px; padding: 5px 10px 5px 10px"><i class="fa-solid fa-trash"></i></button></td>
            </tr>
          `
          )
          .join("");

        const table = `
          <input type="text" id="search-listines" placeholder="Buscar..." class="swal2-input" style="margin-bottom: 10px;">
          <div style="max-height: 400px; overflow-y: auto;">
            <table class="custom-table rounded-table asd56vdfg65r49g8er4e8rv6w5ef4we98f4we65f4we89f7r84erf5er4" style="width: 600px; border-collapse: collapse; font-size: 12px; min-width: 100%">
              <thead>
                ${tableHeaders}
              </thead>
              <tbody id="listines-list" style="text-align: start">
                ${tableRows}
              </tbody>
            </table>
          </div>
        `;

        MySwal.fire({
          title: "Empresas",
          html: table,
          showConfirmButton: true,
          confirmButtonText: "Cerrar",
          didRender: () => {
            data.forEach((item) => {
              const deleteButton = document.getElementById(`delete-${item.id}`);
              deleteButton.addEventListener("click", () =>
                deleteActivation(item.id)
              );
            });

            const searchInput = document.getElementById("search-listines");
            searchInput.addEventListener("input", (event) => {
              const searchTerm = event.target.value.toLowerCase();
              const listinesList = document.getElementById("listines-list");
              const filteredRows = data
                .filter(
                  (item) =>
                    item.empresa.nombre.toLowerCase().includes(searchTerm) ||
                    item.ruta.destino_final.destino_final_detalle.descripcion
                      .toLowerCase()
                      .includes(searchTerm) ||
                    item.numero_de_unidad.toLowerCase().includes(searchTerm) ||
                    item.dt_terminal_empresa.placa
                      .toLowerCase()
                      .includes(searchTerm)
                )
                .map(
                  (item) => `
                    <tr>
                      <td>${item.empresa.nombre}</td>
                      <td>${item.ruta.destino_final.destino_final_detalle.descripcion}</td>
                      <td>${item.numero_de_unidad}</td>
                      <td>${item.dt_terminal_empresa.placa}</td>
                      <td><button class="btn btn-danger" id="delete-${item.id}" style="font-size: 10px; padding: 5px 10px 5px 10px"><i class="fa-solid fa-trash"></i></button></td>
                    </tr>
                  `
                )
                .join("");
              listinesList.innerHTML = filteredRows;
            });

            // Delegación de eventos para los botones de cambio de estado
            document
              .getElementById("listines-list")
              .addEventListener("click", async (event) => {
                if (event.target.classList.contains("change-status")) {
                  const button = event.target;
                  const userId = button.getAttribute("data-id");
                  const userStatus = button.getAttribute("data-status");

                  const actionText =
                    userStatus === "ACTIVE" ? "bloquear" : "activar";
                  const confirmationText =
                    userStatus === "ACTIVE"
                      ? "¡Si haces esto la Empresa será bloqueada!"
                      : "¡La Empresa podrá usar su cuenta nuevamente!";

                  const confirmation = await MySwal.fire({
                    title: `¿Estás seguro de que quieres ${actionText} esta empresa?`,
                    text: confirmationText,
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#d33",
                    cancelButtonColor: "#3085d6",
                    confirmButtonText: `Sí, ${actionText}`,
                    cancelButtonText: "Cancelar",
                  });

                  if (confirmation.isConfirmed) {
                    MySwal.fire({
                      title: `${actionText.charAt(0).toUpperCase() + actionText.slice(1)}...`,
                      html: `<p style="text-align:center; margin:20px;">Actualizando estado de la empresa...</p>`,
                      allowOutsideClick: false,
                      didOpen: () => {
                        MySwal.showLoading();
                      },
                    });

                    try {
                      await axios.put(
                        `${process.env.BACKEND_URL}/api/empresadeterminal/${userId}/updateStatus`,
                        {},
                        {
                          headers: {
                            Authorization: `Bearer ${store.token}`,
                          },
                        }
                      );

                      MySwal.fire({
                        title: `Empresa ${actionText === "bloquear" ? "bloqueado" : "activado"}`,
                        text: `La Empresa ha sido ${actionText === "bloquear" ? "bloqueada" : "activada"} exitosamente.`,
                        icon: "success",
                        confirmButtonText: "Cerrar",
                      });

                      fetchTerminal();
                    } catch (error) {
                      MySwal.fire({
                        title: "Error",
                        text: "Hubo un error al intentar actualizar el estado de la empresa",
                        icon: "error",
                        confirmButtonText: "Cerrar",
                      });
                      console.error(
                        "Error al cambiar el estado de la empresa",
                        error
                      );
                    }
                  }
                }
              });
          },
        });
      }
    } catch (error) {
      console.error("Error al obtener usuarios", error);
      MySwal.fire({
        title: "Error",
        html: `<p>Error al obtener usuarios. Por favor, inténtelo de nuevo.</p>`,
        icon: "error",
        confirmButtonText: "Cerrar",
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      await axios.post(
        process.env.BACKEND_URL + "/api/retorno_registro",
        formData,
        {
          headers: {
            Authorization: `Bearer ${store.token}`,
          },
        }
      );

      setFormData({
        empresa_terminal_id: "",
        empresa: "",
        dt: "",
        fecha_de_entrada: "",
        hora_de_entrada: "",
        destino_nombre: "",
        destino_id: "",
        numero_de_unidad: "",
        pasajeros: 0,
        parada: "",
        destino_proveniente: false,
      });

      await MySwal.fire({
        title: "Retorno Registrado Exitosamente",
        text: "El retorno ha sido registrado exitosamente.",
        icon: "success",
        confirmButtonText: "Cerrar",
      });

      fetchempresasysuscps();

      // Redirigir a VistaRetornosAgregados
      navigate("/VistaRetornosAgregados");
    } catch (error) {
      const errorMessage =
        error.response?.data?.message ||
        "Error desconocido al registrar el retorno.";
      MySwal.fire({
        title: "Error",
        text: errorMessage,
        icon: "error",
        confirmButtonText: "Cerrar",
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const openCompanySelection = () => {
    setTimeout(() => {
      MySwal.fire({
        title: (
          <p>
            <i
              className="fa-solid fa-user-tie"
              style={{ marginRight: "7px", fontSize: "20px" }}
            ></i>
            Empresas de Transporte
          </p>
        ),
        html: `
        <input
          type="text"
          id="companySearch"
          placeholder="Buscar empresa"
          class="swal2-input"
          style="width: 100%"
        />
        <div id="companyList" class="company-list">
          ${empresas
            .map(
              (company) => `
            <button 
              class="btn company-option company-list-activation-list" 
              data-id="${company.id}"
              data-name="${company.empresa.nombre}"
              >  <!-- Añadimos data-name con el nombre -->
              ${company.empresa.nombre}
            </button>`
            )
            .join("")}
        </div>
      `,
        showConfirmButton: false,
        showCancelButton: true,
        cancelButtonText: "Cerrar",
        didOpen: () => {
          const companySearch = document.getElementById("companySearch");
          const companyList = document.getElementById("companyList");

          // Manejador de filtro para el input de búsqueda
          companySearch.addEventListener("input", (e) => {
            const query = e.target.value.toLowerCase();
            const filteredEmpresas = empresas.filter((company) =>
              company.empresa.nombre.toLowerCase().includes(query)
            );
            companyList.innerHTML = filteredEmpresas
              .map(
                (company) => `
                <button 
                  class="btn company-option company-list-activation-list" 
                  data-id="${company.id}"
                  data-name="${company.empresa.nombre}"
                  >  <!-- Añadimos data-name con el nombre -->
                  ${company.empresa.nombre}
                </button>`
              )
              .join("");

            // Agregar listeners a cada opción filtrada
            const buttons = companyList.querySelectorAll(".company-option");
            buttons.forEach((button) => {
              button.addEventListener("click", () => {
                const companyId = button.dataset.id;
                const companyName = button.dataset.name; // Usamos el nombre de la empresa

                selectCompany(companyId, companyName); // Pasamos el ID y el nombre
              });
            });
          });

          // Listener para opciones de empresa inicialmente
          const buttons = companyList.querySelectorAll(".company-option");
          buttons.forEach((button) => {
            button.addEventListener("click", () => {
              const companyId = button.dataset.id;
              const companyName = button.dataset.name;

              selectCompany(companyId, companyName); // Pasamos el ID y el nombre
            });
          });
        },
      });
    }, 100);
  };

  const destinoProveniente = () => {
    setTimeout(() => {
      MySwal.fire({
        title: (
          <p>
            <i
              className="fa-solid fa-route"
              style={{ marginRight: "7px", fontSize: "20px" }}
            ></i>
            Destino Proveniente
          </p>
        ),
        html: `
        <input
          type="text"
          id="companySearch"
          placeholder="Buscar empresa"
          class="swal2-input"
          style="width: 100%"
        />
        <div id="companyList" class="company-list">
          ${destino
            .map(
              (company) => `
            <button 
              class="btn company-option company-list-activation-list" 
              data-id="${company.id}"
              data-name="${company.destino_final_detalle.descripcion}"
              data-proveniente="${company.destino_proveniente}"
              >  <!-- Añadimos data-name con el nombre -->
              ${company.destino_final_detalle.descripcion}
            </button>`
            )
            .join("")}
        </div>
      `,
        showConfirmButton: false,
        showCancelButton: true,
        cancelButtonText: "Cerrar",
        didOpen: () => {
          const companySearch = document.getElementById("companySearch");
          const companyList = document.getElementById("companyList");

          // Manejador de filtro para el input de búsqueda
          companySearch.addEventListener("input", (e) => {
            const query = e.target.value.toLowerCase();
            const filteredEmpresas = destino.filter((company) =>
              company.destino_final_detalle.descripcion
                .toLowerCase()
                .includes(query)
            );
            companyList.innerHTML = filteredEmpresas
              .map(
                (company) => `
                <button 
                  class="btn company-option company-list-activation-list" 
                  data-id="${company.id}"
                  data-name="${company.destino_final_detalle.descripcion}"
                  data-proveniente="${company.destino_proveniente}"
                  >  <!-- Añadimos data-name con el nombre -->
                  ${company.destino_final_detalle.descripcion}
                </button>`
              )
              .join("");

            // Agregar listeners a cada opción filtrada
            const buttons = companyList.querySelectorAll(".company-option");
            buttons.forEach((button) => {
              button.addEventListener("click", () => {
                const companyId = button.dataset.id;
                const companyName = button.dataset.name; // Usamos el nombre de la empresa
                const proveniente = button.dataset.proveniente;

                selectDestino(companyId, companyName, proveniente); // Pasamos el ID y el nombre
              });
            });
          });

          // Listener para opciones de empresa inicialmente
          const buttons = companyList.querySelectorAll(".company-option");
          buttons.forEach((button) => {
            button.addEventListener("click", () => {
              const companyId = button.dataset.id;
              const companyName = button.dataset.name;
              const proveniente = button.dataset.proveniente;

              selectDestino(companyId, companyName, proveniente); // Pasamos el ID y el nombre
            });
          });
        },
      });
    }, 100);
  };

  const filterCompanies = (query) => {
    const filteredCompanies = companies.filter((company) =>
      company.toLowerCase().includes(query.toLowerCase())
    );
    // Actualiza el HTML del Swal para mostrar solo los resultados filtrados
    document.getElementById("companyList").innerHTML = filteredCompanies
      .map((company) => `<button class="company-option">${company}</button>`)
      .join("");
  };

  const selectCompany = (companyId, companyName) => {
    setFormData({
      ...formData,
      empresa: companyName,
      empresa_terminal_id: companyId,
    });

    MySwal.close(); // Cierra el modal
  };

  const selectDestino = (companyId, companyName, proveniente) => {
    setFormData({
      ...formData,
      destino_nombre: companyName,
      destino_id: companyId,
      destino_proveniente: proveniente,
    });

    MySwal.close(); // Cierra el modal
  };

  useEffect(() => {
    if (records && records.rutas) {
      // Obtener paradas únicas de las rutas
      const paradasUnicas = Array.from(
        new Set(records.rutas.map((ruta) => ruta.parada))
      );

      setFilteredParadas(paradasUnicas);
    }
  }, [records]); // Se ejecuta solo cuando `records` cambia

  return (
    <div
      className="container-fluid contarinerGeneralUserHomejs"
      style={{
        display: "flex",
        paddingBottom: "40px",
      }}
    >
      <div className="container asklnduiashndaijsnxansxsaas m-0 p-0">
        <div
          style={{
            textAlign: "center",
            padding: "10px",
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
          className="sdfdfgrt54w98e7we9contewe"
        >
          <div style={{ margin: "0", marginTop: "20px" }}>
            <i
              className="fa-solid seraUnMarinsupre fa-rotate-left"
              id="icon-tittle-general"
            ></i>
          </div>
          <p
            style={{ fontSize: "13px", margin: "0" }}
            className="asdjhfnerer4pdeterminal"
          >
            Registra los buses que descarguen pasajeros en tu terminal.
          </p>
        </div>
        <div className="container asklnduiashndaijsnxansxsaas3">
          <form
            className="d-flex sd98f4e8wf4cwe987cwe984cwe"
            onSubmit={handleSubmit}
          >
            <div className="aoshduiahsdkasnmdasdas">
              <div className="input-group-login2">
                <input
                  className="inputDeLogin"
                  type="date"
                  name="fecha_de_entrada"
                  value={formData.fecha_de_entrada}
                  onChange={handleChange}
                  required
                  style={{ cursor: "pointer" }}
                />
                <label>Fecha de Entrada</label>
              </div>
              <div className="input-group-login2">
                <input
                  className="inputDeLogin"
                  type="time"
                  name="hora_de_entrada"
                  value={formData.hora_de_entrada}
                  onChange={handleChange}
                  required
                  style={{ cursor: "pointer" }}
                />
                <label>Hora de Entrada</label>
              </div>
              <div className="input-group-login2">
                <input
                  className="inputDeLogin"
                  type="text"
                  name="empresa"
                  onClick={openCompanySelection}
                  readOnly
                  onChange={handleChange}
                  value={formData.empresa}
                  style={{ textTransform: "uppercase" }}
                />
                <label>Línea de Transporte</label>
              </div>
            </div>

            <div className="aoshduiahsdkasnmdasdas">
              <div className="input-group-login2">
                <select
                  className="inputDeLogin"
                  name="parada"
                  value={formData.parada}
                  onChange={handleChange}
                  required
                  style={{ cursor: "pointer" }}
                >
                  <option value="" hidden>
                    Selecciona Parada
                  </option>
                  <option value="TOKEN">TOKEN</option>
                  <option value="FINAL">PARADA FINAL</option>
                </select>
                <label>Parada</label>
              </div>
              <div className="input-group-login2">
                <input
                  className="inputDeLogin"
                  type="text"
                  name="numero_de_unidad"
                  value={formData.numero_de_unidad}
                  onChange={handleChange}
                  required
                  maxLength={12}
                  style={{ textTransform: "uppercase" }}
                />
                <label>Número de Unidad</label>
              </div>
              <div className="input-group-login2">
                <input
                  className="inputDeLogin"
                  type="text"
                  name="dt"
                  value={formData.dt}
                  required
                  style={{ textTransform: "uppercase" }}
                  onChange={handleChange}
                  maxLength={12}
                />
                <label>Placa</label>
              </div>
              <div className="input-group-login2">
                <input
                  className="inputDeLogin"
                  type="number"
                  name="pasajeros"
                  value={formData.pasajeros}
                  onChange={handleChange}
                  required
                  max={99}
                  min={1}
                />
                <label>Pasajeros</label>
              </div>
            </div>

            <div className="aoshduiahsdkasnmdasdas">
              <div className="input-group-login2">
                <input
                  className="inputDeLogin"
                  type="text"
                  name="destino_nombre"
                  value={formData.destino_nombre}
                  onClick={destinoProveniente}
                  required
                  onChange={handleChange}
                  readOnly
                />
                <label>Proveniente</label>
              </div>
            </div>

            <div
              style={{
                flexDirection: "column",
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
                alignItems: "center",
              }}
            >
              <button
                className="buttonCargadeDatosDeLoginregistromovimiento"
                type="submit"
                disabled={isSubmitting}
              >
                {isSubmitting ? "Registrando..." : "Registrar"}{" "}
                <i className="fa-solid fa-arrow-right"></i>
              </button>
            </div>
          </form>
        </div>
        <hr style={{ width: "90%" }}></hr>

        <div className="d-flex" style={{ marginTop: "12px" }}>
          <p>¿Alguna duda?,</p>
          <Link
            style={{
              marginLeft: "5px",
              textDecoration: "none",
              color: "#24827C",
            }}
            to="/AyudaYSoporte"
          >
            <strong>Contáctanos</strong>
          </Link>
        </div>
      </div>
    </div>
  );
};
