import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import moment from "moment-timezone";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import "../../../../styles/RegistroActivacionesHoy.css";
import { Context } from "../../../store/appContext.js";

const MySwal = withReactContent(Swal);

const EstatusIcon = {
  ACTIVO: {
    icon: "fa-check",
    name: "ACTIVO",
    color: "linear-gradient(to right, #28a745, #31c353)", // Verde
  },
  INACTIVO: {
    icon: "fa-ban",
    name: "CANCELADO",
    color: "linear-gradient(to right, #dc3545, #f24d5e)", // Rojo
  },
  "RUTAS CORTAS": {
    icon: "fa-van-shuttle",
    name: "RUTAS CORTAS",
    color: "linear-gradient(to right, #dc3545, #f24d5e)", // Rojo
  },
  ESCALAFON: {
    icon: "fa-check",
    name: "ESCALAFON",
    color: "linear-gradient(to right, #28a745, #31c353)", // Verde
  },
};

export const RegistrosCpsEdit = () => {
  const [records, setRecords] = useState([]);
  const [filteredRecords, setFilteredRecords] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const { store } = useContext(Context);
  const [loading, setLoading] = useState(true);

  const fetchTodayRecords = async () => {
    try {
      const response = await axios.get(
        process.env.BACKEND_URL + "/api/todas_cps",
        {
          headers: {
            Authorization: "Bearer " + store.token,
          },
        }
      );
      setRecords(response.data);
      setFilteredRecords(response.data); // Inicializa el filtrado
    } catch (error) {
      console.error("Error al obtener registros de hoy", error);
    } finally {
      setLoading(false); // Cambia a `false` al terminar la carga
    }
  };

  useEffect(() => {
    fetchTodayRecords();
  }, []);

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    filterAndSortRecords(e.target.value, statusFilter, sortOrder);
  };

  const handleStatusChange = (e) => {
    setStatusFilter(e.target.value);
    filterAndSortRecords(searchTerm, e.target.value, sortOrder);
  };

  const handleSortOrderChange = (e) => {
    setSortOrder(e.target.value);
    filterAndSortRecords(searchTerm, statusFilter, e.target.value);
  };

  const filterAndSortRecords = (search, status, order) => {
    let filtered = records.filter((record) => {
      return (
        (!status || record.status === status) &&
        (!search ||
          record.linea_transporte
            .toLowerCase()
            .includes(search.toLowerCase()) ||
          record.destino_final.toLowerCase().includes(search.toLowerCase()))
      );
    });

    if (order === "asc") {
      filtered = filtered.sort((a, b) =>
        a.destino_final.localeCompare(b.destino_final)
      );
    } else {
      filtered = filtered.sort((a, b) =>
        b.destino_final.localeCompare(a.destino_final)
      );
    }

    setFilteredRecords(filtered);
  };

  const handleChangeStatus = (record) => {
    MySwal.fire({
      title: "Cambiar el estatus",
      input: "select",
      inputOptions: {
        ACTIVO: "ACTIVO",
        INACTIVO: "INACTIVO",
        "RUTAS CORTAS": "RUTAS CORTAS",
        ESCALAFON: "ESCALAFON",
      },
      inputPlaceholder: "Selecciona un estatus",
      showCancelButton: true,
      confirmButtonText: "Cambiar",
      inputValidator: (value) => {
        if (!value) {
          return "Debes seleccionar un estatus!";
        }
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await axios.put(
            `${process.env.BACKEND_URL}/api/cps/${record.id}/updateStatus`,
            { status: result.value },
            {
              headers: {
                Authorization: `Bearer ${store.token}`,
              },
            }
          );

          Swal.fire({
            icon: "success",
            title: response.data.message,
          });
          fetchTodayRecords(); // Recargar registros después de actualizar
        } catch (error) {
          Swal.fire({
            icon: "error",
            title: "Error al cambiar el estatus",
            text: error.response?.data?.message || "Error interno",
          });
        }
      }
    });
  };

  return (
    <div className="container-fluid contarinerGeneralUserHomejs">
      {/* Filtro y Ordenación */}
      <div className="filter-bar mb-3 p-3 sd56f4df98g4f8g9hb7er89g4ewr89e7r9we4f98ef9we4v4ds">
        <div
          style={{ display: "flex", alignItems: "center", color: "white" }}
          className="sddv4er98f4we9cw4edivderegis"
        >
          <i
            class="fa-solid fa-magnifying-glass"
            style={{ fontSize: "20px" }}
          ></i>
          <p style={{ margin: "0", marginLeft: "7px", fontSize: "20px" }}>
            Buscar
          </p>
        </div>
        <input
          type="text"
          placeholder="Buscar por destino o línea de transporte"
          value={searchTerm}
          onChange={handleSearchChange}
          className="form-control mb-2"
          style={{ borderRadius: "20px", padding: "10px 15px" }}
        />
        <select
          value={statusFilter}
          onChange={handleStatusChange}
          className="form-select mb-2"
          style={{ borderRadius: "20px", padding: "10px" }}
        >
          <option value="">Filtrar por estatus</option>
          <option value="ACTIVO">ACTIVO</option>
          <option value="INACTIVO">INACTIVO</option>
          <option value="RUTAS CORTAS">RUTAS CORTAS</option>
          <option value="ESCALAFON">ESCALAFON</option>
        </select>
      </div>
      {loading ? (
        <div style={{ textAlign: "center", padding: "20px" }}>
          <img
            src="https://res.cloudinary.com/dirpdlfbu/image/upload/v1731494258/1/dvhl0hwbx4adb8uceymr.gif"
            alt="Cargando..."
            style={{ width: "150px" }}
          />
        </div>
      ) : (
        <div className="container asklnduiashndaijsnxansxsaas p-0">
          {filteredRecords.map((record) => {
            const statusConfig =
              EstatusIcon[record.status] || EstatusIcon.INACTIVO;

            return (
              <div
                key={record.id}
                className="container asklnduiashndaijsnxansxsaas"
              >
                <div className="container asune-rv56er48v9wec-s2 mgjmnh98-sdf9w8e7f9wewefwes">
                  <div className="container aoshdnuqiwhnd897">
                    <div className="container ds897fq4w9dc9w8c4-we89c7w2">
                      <p
                        style={{
                          margin: "0",
                          fontSize: "10px",
                        }}
                        id="pderegistros"
                      >
                        {record.linea_transporte}
                      </p>
                      <h5 style={{ marginTop: "15px" }}>
                        {record.destino_final}
                      </h5>
                    </div>
                    <div
                      className="container ds9f848dsfwef498we4fwd-57982"
                      onClick={() => handleChangeStatus(record)}
                    >
                      <div
                        className="sad8qw9d4asas6x4a89s42"
                        style={{ background: statusConfig.color }}
                      >
                        <i
                          className={`fa-solid asd98asdas894xsa ${statusConfig.icon}`}
                        ></i>
                      </div>
                    </div>
                  </div>
                  <div className="asdw-w9d84c9w8e4dcd"></div>
                  <div className="container aoshdnuqiwhnd8972 p-2">
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        width: "85%",
                      }}
                    >
                      <p
                        style={{
                          margin: "0",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          maxWidth: "90%",
                          fontSize: "12px",
                        }}
                      >
                        {record.ruta}
                      </p>
                    </div>
                    <div style={{ width: "15%" }}>{record.turno_hora_cps}</div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};
