import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { Context } from "../../../store/appContext.js";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFDownloadLink,
} from "@react-pdf/renderer";

const MySwal = withReactContent(Swal);

export const RegistroCps = () => {
  const navigate = useNavigate();
  const { store } = useContext(Context);
  const [formData, setFormData] = useState({
    empresa_terminal_id: "",
    empresa: "",
    numero_cps: "",
    ruta_n: "",
    ruta: "",
    parada: "",
    destino_final: "",
    status: "",
    horas: [], // Nuevo campo para manejar las horas
  });
  const [isSubmitting, setIsSubmitting] = useState(false); // Nuevo estado para controlar el estado de envío
  const [empresas, setEmpresas] = useState([]); // Nuevo estado para controlar el estado de envío
  const [cpsOptions, setCpsOptions] = useState([]); // Opciones de CPS para el <select>
  const [destinos, setDestinos] = useState([]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true); // Desactiva el botón

    try {
      // Enviar los datos con las horas
      await axios.post(
        process.env.BACKEND_URL + "/api/registro_ruta_directores_terminal",
        formData,
        {
          headers: {
            Authorization: "Bearer " + store.token,
          },
        }
      );
      setFormData({
        empresa_terminal_id: "",
        empresa: "",
        numero_cps: "",
        ruta_n: "",
        ruta: "",
        destino_final: "",
        status: "",
        horas: [], // Limpiar las horas después de enviar
      });
      mostrarAlerta1();
    } catch (error) {
      const errorMessage =
        error.response?.data?.message ||
        "Error desconocido al registrar la Cps";
      mostrarAlerta2(errorMessage);
      console.error("Error al registrar", error);
    } finally {
      setIsSubmitting(false); // Reactiva el botón después del envío
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const mostrarAlerta1 = () => {
    MySwal.fire({
      title: <p>CPS Registrada con éxito</p>,
      icon: "success",
      position: "center",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
    });
  };

  const mostrarAlerta2 = (errorMessage) => {
    MySwal.fire({
      title: <p>Error al Registrar la CPS</p>,
      html: <p>{errorMessage}</p>,
      icon: "error",
      position: "center",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
    });
  };

  const fetchempresasysuscps = async () => {
    try {
      const response = await axios.get(
        process.env.BACKEND_URL + "/api/ver_empresas_terminal_solo_cps",
        {
          headers: {
            Authorization: `Bearer ${store.token}`,
          },
        }
      );
      setEmpresas(response.data.empresas_terminal);
    } catch (error) {
      MySwal.fire({
        title: "Error",
        text: "Error al cargar las empresas disponibles.",
        icon: "error",
        confirmButtonText: "Cerrar",
      });
    }
  };

  const fetchDestinos = async () => {
    try {
      const response = await axios.get(
        process.env.BACKEND_URL + "/api/ver_destino_final_por_terminal",
        {
          headers: {
            Authorization: `Bearer ${store.token}`,
          },
        }
      );
      setDestinos(response.data.destinos_terminal);
    } catch (error) {
      MySwal.fire({
        title: "Error",
        text: "Error al cargar los destinos disponibles.",
        icon: "error",
        confirmButtonText: "Cerrar",
      });
    }
  };

  useEffect(() => {
    fetchempresasysuscps();
    fetchDestinos();
  }, []);

  const openCompanySelection = () => {
    MySwal.fire({
      title: (
        <p>
          <i
            className="fa-solid fa-user-tie"
            style={{ marginRight: "7px", fontSize: "20px" }}
          ></i>
          Empresas de Transporte
        </p>
      ),
      html: `
        <input
          type="text"
          id="companySearch"
          placeholder="Buscar empresa"
          class="swal2-input"
          style="width: 100%"
        />
        <div id="companyList" class="company-list">
          ${empresas
            .map(
              (company) => `
            <button 
              class="btn company-option company-list-activation-list" 
              data-id="${company.id}"
              data-name="${company.empresa.nombre}">  <!-- Añadimos data-name con el nombre -->
              ${company.empresa.nombre} <br> ${company.empresa.rif}
            </button>`
            )
            .join("")}
        </div>
      `,
      showConfirmButton: false,
      showCancelButton: true,
      cancelButtonText: "Cerrar",
      didOpen: () => {
        const companySearch = document.getElementById("companySearch");
        const companyList = document.getElementById("companyList");

        // Manejador de filtro para el input de búsqueda
        companySearch.addEventListener("input", (e) => {
          const query = e.target.value.toLowerCase();
          const filteredEmpresas = empresas.filter(
            (company) =>
              company.empresa.nombre.toLowerCase().includes(query) ||
              company.empresa.rif.toLowerCase().includes(query)
          );
          companyList.innerHTML = filteredEmpresas
            .map(
              (company) => `
                <button 
                  class="btn company-option company-list-activation-list" 
                  data-id="${company.id}"
                  data-name="${company.empresa.nombre}">  <!-- Añadimos data-name con el nombre -->
                  ${company.empresa.nombre} <br> ${company.empresa.rif}
                </button>`
            )
            .join("");

          // Agregar listeners a cada opción filtrada
          const buttons = companyList.querySelectorAll(".company-option");
          buttons.forEach((button) => {
            button.addEventListener("click", () => {
              const companyId = button.dataset.id;
              const companyName = button.dataset.name; // Usamos el nombre de la empresa
              selectCompany(companyId, companyName); // Pasamos el ID y el nombre
            });
          });
        });

        // Listener para opciones de empresa inicialmente
        const buttons = companyList.querySelectorAll(".company-option");
        buttons.forEach((button) => {
          button.addEventListener("click", () => {
            const companyId = button.dataset.id;
            const companyName = button.dataset.name;
            selectCompany(companyId, companyName); // Pasamos el ID y el nombre
          });
        });
      },
    });
  };

  const selectCompany = (companyId, companyName) => {
    // Encuentra la empresa seleccionada
    const empresaSeleccionada = empresas.find(
      (empresa) => empresa.id === parseInt(companyId)
    );

    // Extrae los CPS de la empresa seleccionada
    const cpsOptions = empresaSeleccionada.empresa.cps.map((cps) => ({
      id: cps.id,
      numero_cps: cps.numero_cps,
    }));

    setFormData({
      ...formData,
      empresa: companyName, // Nombre de la empresa
      empresa_terminal_id: companyId, // ID de la empresa
      numero_cps: "", // Resetea el campo de CPS
    });

    setCpsOptions(cpsOptions); // Guarda las opciones de CPS para el <select>
    MySwal.close(); // Cierra el modal
  };

  const addHora = () => {
    setFormData((prevData) => ({
      ...prevData,
      horas: [...prevData.horas, ""], // Agregar un nuevo campo vacío para la hora
    }));
  };

  const removeHora = (index) => {
    setFormData((prevData) => ({
      ...prevData,
      horas: prevData.horas.filter((_, i) => i !== index), // Eliminar la hora por índice
    }));
  };

  return (
    <div
      className="container-fluid contarinerGeneralUserHomejs"
      style={{
        display: "flex",
        paddingBottom: "40px",
      }}
    >
      <div className="container asklnduiashndaijsnxansxsaas m-0 p-0">
        <div className="sdfdfgrt54w98e7we9contewe w-100">
          <div style={{ margin: "0", marginTop: "20px" }}>
            <i
              className="fa-solid seraUnMarinsupre fa-file-circle-plus"
              id="icon-tittle-general"
            ></i>
          </div>
          <p style={{ fontSize: "13px" }}>
            Agrega unicamente las Rutas que están en la CPS de la Empresa
          </p>
        </div>
        <div
          className="container asklnduiashndaijsnxansxsaas3"
          style={{ marginBottom: "20px" }}
        >
          <form
            onSubmit={handleSubmit}
            className="formularioDeLogin sd98f4e8wf4cwe987cwe984cwe w-100"
          >
            <div className="aoshduiahsdkasnmdasdas">
              <div className="input-group-login2">
                <input
                  className="inputDeLogin"
                  type="text"
                  name="empresa"
                  onClick={openCompanySelection}
                  readOnly
                  onChange={handleChange}
                  value={formData.empresa}
                  style={{ textTransform: "uppercase" }}
                />
                <label>Línea de Transporte</label>
              </div>
              <div className="input-group-login2">
                <select
                  className="inputDeLogin"
                  name="numero_cps"
                  onChange={handleChange}
                  value={formData.numero_cps}
                >
                  <option value="" disabled>
                    Selecciona el número de CPS
                  </option>
                  {cpsOptions.map((cps) => (
                    <option key={cps.id} value={cps.id}>
                      {cps.numero_cps}
                    </option>
                  ))}
                </select>
                <label>Número de CPS</label>
              </div>
              <div className="input-group-login2">
                <input
                  className="inputDeLogin"
                  type="number"
                  name="ruta_n"
                  onChange={handleChange}
                  placeholder="12"
                  value={formData.ruta_n}
                  style={{ textTransform: "uppercase" }}
                />
                <label>Número de Ruta</label>
              </div>
            </div>
            <div className="aoshduiahsdkasnmdasdas">
              <div className="input-group-login2">
                <textarea
                  className="inputDeLogin"
                  type="text"
                  name="ruta"
                  placeholder="TERMINAL LA BANDERA, MERIDA"
                  onChange={handleChange}
                  value={formData.ruta}
                  style={{ textTransform: "uppercase" }}
                />
                <label>Ruta</label>
              </div>
            </div>
            <div className="aoshduiahsdkasnmdasdas">
              <div className="input-group-login2">
                <select
                  className="inputDeLogin"
                  name="destino_final"
                  onChange={handleChange}
                  value={formData.destino_final}
                >
                  <option value="" disabled>
                    Selecciona el destino final
                  </option>
                  {destinos.map((destino) => (
                    <option key={destino.id} value={destino.id}>
                      <strong>{destino.destino_final_detalle.code} -</strong>{" "}
                      {destino.destino_final_detalle.descripcion}
                    </option>
                  ))}
                </select>
                <label>Destino Final</label>
              </div>
              <div className="input-group-login2">
                <select
                  className="inputDeLogin"
                  name="parada"
                  onChange={handleChange}
                  value={formData.parada}
                >
                  <option value="" disabled>
                    Selecciona
                  </option>
                  <option value="IDA TERMINAL LA BANDERA">
                    SALIDA DESDE EL TERMINAL
                  </option>
                  <option value="TOKEN">TOKEN</option>
                </select>
                <label>Parada</label>
              </div>
              <div className="input-group-login2">
                <select
                  className="inputDeLogin"
                  name="status"
                  onChange={handleChange}
                  value={formData.status}
                >
                  <option value="" disabled>
                    Selecciona
                  </option>
                  <option value="ACTIVE">ACTIVO</option>
                  <option value="NO_ACTIVE">INACTIVO</option>
                </select>
                <label>Estatus de Ruta</label>
              </div>
            </div>
            <div
              className="aoshduiahsdkasnmdasdas"
              style={{ flexDirection: "column" }}
            >
              {formData.horas.map((hora, index) => (
                <div key={index} className="input-group-login2 d-flex">
                  <input
                    className="inputDeLogin w-80"
                    type="time"
                    value={hora}
                    onChange={(e) => {
                      const updatedHoras = [...formData.horas];
                      updatedHoras[index] = e.target.value;
                      setFormData({ ...formData, horas: updatedHoras });
                    }}
                  />
                  <button
                    type="button"
                    onClick={() => removeHora(index)}
                    className="btn btn-danger"
                  >
                    Eliminar
                  </button>
                  <label>Hora CPS</label>
                </div>
              ))}
              <button
                type="button"
                onClick={addHora}
                className="btn btn-secondary"
              >
                Añadir Hora CPS (Opcional)
              </button>
            </div>
            <button
              className="buttonCargadeDatosDeLoginregistromovimiento"
              type="submit"
              disabled={isSubmitting}
            >
              {isSubmitting ? "Registrando..." : "Registrar"}{" "}
              <i className="fa-solid fa-arrow-right"></i>
            </button>
          </form>
        </div>
        <div className="d-flex" style={{ marginTop: "12px" }}>
          <p>¿Alguna duda?,</p>
          <Link
            style={{
              marginLeft: "5px",
              textDecoration: "none",
              color: "#24827C",
            }}
            to="/AyudaYSoporte"
          >
            <strong>Contáctanos</strong>
          </Link>
        </div>
      </div>
    </div>
  );
};
