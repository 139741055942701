import React, { useContext, useState, useEffect } from "react";
import axios from "axios";
import { Context } from "../../../store/appContext";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useNavigate, useParams } from "react-router-dom";

import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFDownloadLink,
  pdf,
  Image,
  PDFViewer,
} from "@react-pdf/renderer";

const formatDate2 = (dateString) => {
  return dateString.split(" ")[1]; // Extrae solo la parte de la hora
};

const tableStyles = StyleSheet.create({
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 0.5,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    borderRadius: 10, // Ajusta el valor según el redondeo deseado
    overflow: "hidden",
    marginTop: 10,
  },
  table2: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 0.5,
    borderRightWidth: 0,
    borderBottomWidth: 0,
    marginTop: 10,
  },
  tableRow: {
    margin: "auto",
    flexDirection: "row",
    backgroundColor: "transparent",
  },
  tableColHeader: {
    borderStyle: "solid",
    borderWidth: 0.5,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    borderColor: "gray",
    backgroundColor: "#24827cbe",
    alignItems: "center",
    justifyContent: "center",
  },
  tableColHeader2: {
    borderStyle: "solid",
    borderWidth: 0.5,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    backgroundColor: "#8c1010",
    alignItems: "center",
    justifyContent: "center",
  },
  tableCol: {
    borderStyle: "solid",
    borderWidth: 0.5,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    alignItems: "center",
    justifyContent: "center",
  },
  tableCellHeader: {
    margin: 5,
    fontSize: 9,
    fontWeight: "bold",
    color: "white",
    textAlign: "center",
  },
  tableCell: {
    margin: 4,
    fontSize: 7,
  },
});

tableStyles.tableColHeaderOthertaxi = {
  ...tableStyles.tableColHeader,
  width: "50%", // Mayor ancho para los demás
};

tableStyles.tableColOthertaxi = {
  ...tableStyles.tableCol,
  width: "50%", // Mayor ancho para los demás
};

// Modificar anchos específicos aquí
tableStyles.tableColHeaderNumber = {
  ...tableStyles.tableColHeader,
  width: "5%", // Menor ancho para "N°"
};

tableStyles.tableColHeaderOther = {
  ...tableStyles.tableColHeader,
  width: "13.6%", // Mayor ancho para los demás
};

tableStyles.tableColHeaderOther4 = {
  ...tableStyles.tableColHeader,
  width: "32%", // Mayor ancho para los demás
};

tableStyles.tableColHeaderOtherDT = {
  ...tableStyles.tableColHeader,
  width: "9%", // Mayor ancho para los demás
};
tableStyles.tableColHeaderOtherDT2 = {
  ...tableStyles.tableColHeader,
  width: "5%", // Mayor ancho para los demás
};
tableStyles.tableColHeaderOtherDT3 = {
  ...tableStyles.tableColHeader,
  width: "7%", // Mayor ancho para los demás
};

tableStyles.tableColHeaderOtherDT4 = {
  ...tableStyles.tableColHeader,
  width: "10%", // Mayor ancho para los demás
};

tableStyles.tableColHeaderOther2 = {
  ...tableStyles.tableColHeader2,
  width: "13.6%", // Mayor ancho para los demás
};

tableStyles.tableColHeaderOther3 = {
  ...tableStyles.tableColHeader2,
  width: "32%", // Mayor ancho para los demás
};

tableStyles.tableColNumber = {
  ...tableStyles.tableCol,
  width: "5%", // Menor ancho para "N°"
};

tableStyles.tableColOther = {
  ...tableStyles.tableCol,
  width: "13.6%", // Mayor ancho para los demás
};
tableStyles.tableColOther2 = {
  ...tableStyles.tableCol,
  width: "32%", // Mayor ancho para los demás
};

tableStyles.tableColOtherDT = {
  ...tableStyles.tableCol,
  width: "9%", // Mayor ancho para los demás
};
tableStyles.tableColOtherDT2 = {
  ...tableStyles.tableCol,
  width: "5%", // Mayor ancho para los demás
};
tableStyles.tableColOtherDT4 = {
  ...tableStyles.tableCol,
  width: "10%", // Mayor ancho para los demás
};

tableStyles.tableColHeaderOthertaxi2 = {
  ...tableStyles.tableColHeader,
  width: "83.33333%",
  color: "white",
};
tableStyles.tableColHeaderOthertaxi22 = {
  ...tableStyles.tableColHeader,
  width: "16.66666%",
  color: "white",
};
tableStyles.tableColHeaderOthertaxi3 = {
  ...tableStyles.tableColHeader,
  width: "100%", // Mayor ancho para los demás
};

tableStyles.tableColOthertaxi2 = {
  ...tableStyles.tableCol,
  width: "33.3%", // Mayor ancho para los demás
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    borderBottomColor: "#000",
    borderBottomWidth: 1,
    alignItems: "center",
    height: 15,
    margin: 0,
    padding: 0,
    justifyContent: "center",
    textAlign: "center",
  },
  cell: {
    fontSize: 9,
    textAlign: "center",
    borderRightColor: "#000",
    borderRightWidth: 1,
    flex: 1,
  },
  cell2: {
    fontSize: 9,
    textAlign: "center",
    borderRightColor: "#000",
    borderRightWidth: 1,
    width: 20,
    borderLeftColor: "#000",
    borderLeftWidth: 1,
  },
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    textAlign: "center",
  },
  logo: {
    width: 90, // Ajusta el tamaño según tus necesidades
    height: 30, // Ajusta el tamaño según tus necesidades
  },
  logo2: {
    width: 100,
    height: 20,
  },
  logo22: {
    width: 100,
    height: 20,
    opacity: 0.2,
  },
  page: {
    padding: "30 30 50 30", // Ajusta el valor del margen según tus necesidades
  },
  letter: {
    fontSize: 10,
    padding: 5,
  },
  letter2: {
    fontSize: 10,
  },
  ruta: {
    fontSize: 7,
    padding: 4,
  },
  bottomText: {
    position: "absolute",
    bottom: 0,
    left: 20, // Ajusta según sea necesario
    right: 20, // Ajusta según sea necesario
    textAlign: "center",
    bottom: 30,
    fontSize: 8,
  },
  footer: {
    position: "absolute",
    bottom: 10,
    left: 0,
    right: 0,
    textAlign: "center",
    fontSize: 10,
    paddingTop: 5,
    marginTop: 5,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  bottomText2: {
    position: "absolute",
    bottom: 0,
    left: 20, // Ajusta según sea necesario
    right: 20, // Ajusta según sea necesario
    textAlign: "center",
    bottom: 50,
    fontSize: 11,
    fontWeight: "bold",
  },
  backgroundImage: {
    minwidth: "3508px", // Hace que la imagen ocupe todo el ancho de la página
    height: "auto", // Mantiene la proporción de la imagen, ajustando la altura proporcional al ancho
    position: "absolute", // Asegura que la imagen se quede en el fondo
    top: 0, // Se pega a la parte superior
    left: 0, // Se pega a la parte izquierda
    right: 0,
  },
});

const formatDate = (dateString) => {
  const date = new Date(dateString); // Asegúrate de que `dateString` esté en formato válido
  const day = String(date.getUTCDate()).padStart(2, "0"); // Usa UTC para evitar problemas de zonas horarias
  const month = String(date.getUTCMonth() + 1).padStart(2, "0"); // Meses son 0 indexados
  const year = date.getUTCFullYear();
  return `${day}-${month}-${year}`;
};

const CierreDiario = ({ datos }) => (
  <Document>
    <Page size="A4" orientation="landscape" style={styles.page}>
      <Image
        src="https://res.cloudinary.com/dqt0d66h7/image/upload/v1738763378/Fondos%20PDF/dyofo0usmdsq0rxvh4du.png"
        style={styles.backgroundImage}
        fixed
      />
      <View style={styles.header} fixed>
        <Image
          src="https://res.cloudinary.com/dqt0d66h7/image/upload/v1738835212/Fondos%20PDF/pjtuji0yr4bqvzh9sj0z.png"
          style={styles.logo}
        />
        <Image
          src="https://res.cloudinary.com/dqt0d66h7/image/upload/v1738835293/Fondos%20PDF/d9fqduuf6i7lely21fnx.png"
          style={styles.logo2}
        />
      </View>
      <View>
        <View>
          <Text style={{ textAlign: "center" }}>
            REPORTE DIARIO OPERACIONES TERMINAL LA BANDERA
          </Text>
          <Text
            style={{ textAlign: "center", fontSize: "12px", color: "gray" }}
          >
            FECHA: {formatDate(datos.fecha_cierre)}
          </Text>
        </View>
      </View>
      <View>
        <View
          style={{
            marginBottom: 5,
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
          }}
        >
          <View
            style={{
              borderRadius: "50%",
              width: 10,
              height: 10,
              backgroundColor: "#98d4a699",
            }}
          ></View>
          <Text style={{ marginLeft: 7, fontSize: 10 }}>A TIEMPO -</Text>
          <Text
            style={{
              marginLeft: 7,
              fontSize: 10,
              color: "green",
              fontWeight: 700,
            }}
          >
            {datos.total_a_tiempo}
          </Text>
        </View>
        <View
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
            marginBottom: 5,
          }}
        >
          <View
            style={{
              borderRadius: "50%",
              width: 10,
              height: 10,
              backgroundColor: "#ffcc8099",
            }}
          ></View>
          <Text style={{ marginLeft: 7, fontSize: 10 }}>CON RETRASO -</Text>
          <Text
            style={{
              marginLeft: 7,
              fontSize: 10,
              color: "yellow",
              fontWeight: 700,
            }}
          >
            {datos.total_retrasados}
          </Text>
        </View>
        <View
          style={{
            marginBottom: 5,
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
            marginTop: 5,
          }}
        >
          <View
            style={{
              borderRadius: "50%",
              width: 10,
              height: 10,
              backgroundColor: "#e08e9b99",
            }}
          ></View>
          <Text style={{ marginLeft: 7, fontSize: 10 }}>CANCELADOS -</Text>
          <Text
            style={{
              marginLeft: 7,
              fontSize: 10,
              color: "red",
              fontWeight: 700,
            }}
          >
            {datos.total_cancelados}
          </Text>
        </View>
      </View>
      {/* Rutas CPS */}
      <View style={styles.section}>
        <View style={tableStyles.table}>
          <View style={tableStyles.tableRow} fixed wrap={false}>
            <View style={tableStyles.tableColHeaderOtherDT2}>
              <Text style={tableStyles.tableCellHeader}>Nº</Text>
            </View>
            <View style={tableStyles.tableColHeaderOtherDT}>
              <Text style={tableStyles.tableCellHeader}>Empresa</Text>
            </View>
            <View style={tableStyles.tableColHeaderOtherDT}>
              <Text style={tableStyles.tableCellHeader}>Destino</Text>
            </View>
            <View style={tableStyles.tableColHeaderOtherDT}>
              <Text style={tableStyles.tableCellHeader}>
                Hora{"\n"}Programada
              </Text>
            </View>
            <View style={tableStyles.tableColHeaderOtherDT}>
              <Text style={tableStyles.tableCellHeader}>
                Estatus{"\n"}Final
              </Text>
            </View>
            <View style={tableStyles.tableColHeaderOtherDT}>
              <Text style={tableStyles.tableCellHeader}>N° Listín</Text>
            </View>
            <View style={tableStyles.tableColHeaderOtherDT4}>
              <Text style={tableStyles.tableCellHeader}>PISTA</Text>
            </View>
            <View style={tableStyles.tableColHeaderOtherDT4}>
              <Text style={tableStyles.tableCellHeader}>ABORDANDO</Text>
            </View>
            <View style={tableStyles.tableColHeaderOtherDT4}>
              <Text style={tableStyles.tableCellHeader}>CANCELADO</Text>
            </View>
            <View style={tableStyles.tableColHeaderOtherDT4}>
              <Text style={tableStyles.tableCellHeader}>SALIÓ</Text>
            </View>
            <View style={tableStyles.tableColHeaderOtherDT4}>
              <Text style={tableStyles.tableCellHeader}>
                Tiempo de{"\n"}Retraso
              </Text>
            </View>
          </View>
          {datos.activaciones.map((activation, index) => (
            <View
              style={[
                tableStyles.tableRow,
                {
                  backgroundColor:
                    activation.retraso_minutos === "A TIEMPO"
                      ? "#98d4a666" // Verde más oscuro con 40% de opacidad
                      : activation.retraso_minutos === "CANCELADO"
                        ? "#e08e9b66" // Rojo más oscuro con 40% de opacidad
                        : "#ffcc8066", // Amarillo anaranjado con 40% de opacidad
                },
              ]}
              key={index}
              wrap={false}
            >
              <View style={tableStyles.tableColOtherDT2}>
                <Text style={tableStyles.tableCell}>{index + 1}</Text>
              </View>
              <View style={tableStyles.tableColOtherDT}>
                <Text style={tableStyles.tableCell}>{activation.empresa}</Text>
              </View>
              <View style={tableStyles.tableColOtherDT}>
                <Text style={tableStyles.tableCell}>{activation.destino}</Text>
              </View>
              <View style={tableStyles.tableColOtherDT}>
                <Text style={tableStyles.tableCell}>
                  {activation.hora_salida}
                </Text>
              </View>
              <View style={tableStyles.tableColOtherDT}>
                <Text style={tableStyles.tableCell}>
                  {activation.estatus_final}
                </Text>
              </View>

              <View style={tableStyles.tableColOtherDT}>
                <Text style={tableStyles.tableCell}>
                  {activation.numero_listin}
                </Text>
              </View>
              <View style={tableStyles.tableColOtherDT4}>
                <Text style={tableStyles.tableCell}>
                  {activation.ultima_hora_pista
                    ? formatDate2(activation.ultima_hora_pista)
                    : "N/A"}
                </Text>
              </View>
              <View style={tableStyles.tableColOtherDT4}>
                <Text style={tableStyles.tableCell}>
                  {activation.ultima_hora_abordando
                    ? formatDate2(activation.ultima_hora_abordando)
                    : "N/A"}
                </Text>
              </View>
              <View style={tableStyles.tableColOtherDT4}>
                <Text style={tableStyles.tableCell}>
                  {activation.ultima_hora_cancelado
                    ? formatDate2(activation.ultima_hora_cancelado)
                    : "N/A"}
                </Text>
              </View>
              <View style={tableStyles.tableColOtherDT4}>
                <Text style={tableStyles.tableCell}>
                  {activation.ultima_hora_salio
                    ? formatDate2(activation.ultima_hora_salio)
                    : "N/A"}
                </Text>
              </View>

              <View style={tableStyles.tableColOtherDT4}>
                <Text style={tableStyles.tableCell}>
                  {activation.retraso_minutos}
                </Text>
              </View>
            </View>
          ))}
        </View>
      </View>
      <View style={styles.footer} fixed>
        <Image
          src="https://res.cloudinary.com/dqt0d66h7/image/upload/v1738835293/Fondos%20PDF/d9fqduuf6i7lely21fnx.png"
          style={styles.logo22}
        />
        <View style={{ display: "flex", flexDirection: "row" }}>
          <Text style={{ opacity: 0.2 }}>
            www.intravialca-ve.com - Página:{" "}
          </Text>
          <Text
            render={({ pageNumber, totalPages }) =>
              `${pageNumber} / ${totalPages}`
            }
            fixed
          />
        </View>
      </View>
    </Page>
  </Document>
);

export const ReporteDiarioActivaciones = () => {
  const { store } = useContext(Context);
  const [reporte, setReporte] = useState(null);
  const navigate = useNavigate();
  const [records, setRecords] = useState(null);
  const [recordsTotals, setRecordsTotals] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [empresas, setEmpresas] = useState([]);
  const [empresaId, setEmpresaId] = useState(0);
  const [formData2, setFormData2] = useState({
    fecha_cierre: "",
  });

  const fetchTodayRecords = async () => {
    try {
      // Configurar el token de autorización en los headers
      const response = await axios.get(
        process.env.BACKEND_URL + "/api/audit_reporte_activaciones",
        {
          headers: {
            Authorization: `Bearer ${store.token}`, // Corrige el uso del token
          },
        }
      );
      setRecords(response.data.audit_reportes); // Guarda los datos obtenidos
      console.log(response.data.audit_reportes);
    } catch (error) {
      console.error("Error al obtener registros de hoy:", error);
    }
  };

  useEffect(() => {
    fetchTodayRecords();
  }, []);

  const handlePreviewPDF = async () => {
    try {
      // Mostrar mensaje de carga
      Swal.fire({
        title: "Generando vista previa...",
        html: "<p>Por favor, espera mientras preparamos el PDF.</p>",
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });

      //   Solicitar los datos necesarios al backend
      const response = await axios.post(
        process.env.BACKEND_URL +
          "/api/generar_firma_cloudinary_activaciones_cierre_diario",
        formData2,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      const todo = response.data;

      Swal.close();

      const pdfBlob = await pdf(<CierreDiario datos={todo} />).toBlob();

      // Crear un enlace de descarga programático
      const link = document.createElement("a");
      link.href = URL.createObjectURL(pdfBlob);
      link.download = "reporteActivaciones.pdf";
      document.body.appendChild(link);
      link.click(); // Simular clic para descargar el archivo
      document.body.removeChild(link);

      fetchTodayRecords();

      Swal.close(); // Cerrar la ventana de carga
    } catch (error) {
      console.error("Error al generar PDF:", error);
      Swal.fire({
        title: "Error",
        text: "Ocurrió un error al generar la vista previa del PDF. Inténtalo nuevamente.",
        icon: "error",
      });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Actualizar el estado del formulario
    setFormData2((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString); // Asegúrate de que `dateString` esté en formato válido
    const day = String(date.getUTCDate()).padStart(2, "0"); // Usa UTC para evitar problemas de zonas horarias
    const month = String(date.getUTCMonth() + 1).padStart(2, "0"); // Meses son 0 indexados
    const year = date.getUTCFullYear();
    return `${day}/${month}/${year}`;
  };

  const formatDate2 = (timestamp) => {
    // Dividir fecha y zona horaria
    const [datePart, timePart] = timestamp.split("T");
    const [year, month, day] = datePart.split("-");
    const [time, offset] = timePart.split("-");

    // Extraer la hora, minutos y segundos
    const [hours, minutes] = time.split(":");

    // Formatear en dd/mm/yyyy hh:mm
    return `${day}/${month}/${year} ${hours}:${minutes}`;
  };
  return (
    <div
      className="container-fluid contarinerGeneralUserHomejs"
      style={{
        display: "flex",
        paddingBottom: "40px",
      }}
    >
      <div className="container asklnduiashndaijsnxansxsaas m-0 p-0">
        <div className="sdfdfgrt54w98e7we9contewe w-100">
          <div style={{ margin: "0", marginTop: "20px" }}>
            <i
              className="fa-solid seraUnMarinsupre fa-check-to-slot"
              id="icon-tittle-general"
            ></i>
          </div>
          <p>Diariamente, haz tu reporte de activaciones.</p>
        </div>
        <div className="formularioDeLogin sd98f4e8wf4cwe987cwe984cwe w-100">
          <div
            className="container asklnduiashndaijsnxansxsaas3"
            style={{ marginBottom: "20px", marginTop: "-40px" }}
          >
            <div className="aoshduiahsdkasnmdasdas">
              <div className="input-group-login2">
                <input
                  className="inputDeLogin"
                  type="date"
                  name="fecha_cierre"
                  onChange={handleChange}
                  value={formData2.fecha_cierre}
                  required
                />
                <label>Fecha de Cierre</label>
              </div>
            </div>
            <button
              className="buttonCargadeDatosDeLoginregistromovimiento m-0"
              onClick={handlePreviewPDF}
              disabled={isSubmitting}
              style={{ width: "200px" }}
            >
              {isSubmitting ? "Espera..." : "Ver Reporte"}{" "}
              <i className="fa-solid fa-arrow-right"></i>
            </button>
          </div>
        </div>
        <div className="d-flex" style={{ marginTop: "12px" }}>
          <p>¿Alguna duda?,</p>
          <Link
            style={{
              marginLeft: "5px",
              textDecoration: "none",
              color: "#24827C",
            }}
            to="/AyudaYSoporte"
          >
            <strong>Contáctanos</strong>
          </Link>
        </div>
        <hr style={{ width: "90%" }}></hr>
        {!isSubmitting ? (
          <div
            className="custom-table-container m-0"
            style={{
              maxWidth: "100%",
              overflow: "auto",
              maxHeight: "60vh",
            }}
          >
            {records ? (
              <div style={{ padding: "10px", width: "100%" }}>
                <table
                  className="custom-table rounded-table"
                  style={{ minWidth: "100%", marginBottom: "20px" }}
                >
                  <thead>
                    <tr>
                      <th className="table-header">Fecha y Hora</th>
                      <th className="table-header">Fecha Reporte</th>
                      <th className="table-header">Usuario</th>
                    </tr>
                  </thead>
                  <tbody>
                    {records.map((reporte, index) => (
                      <tr style={{ fontSize: "11px" }} key={index}>
                        <td>{formatDate2(reporte.timestamp)}</td>
                        <td>{formatDate(reporte.fecha_cierre)}</td>
                        <td>{reporte.generado_por}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <div></div>
            )}
          </div>
        ) : (
          <div style={{ textAlign: "center", padding: "20px" }}>
            <img
              src="https://res.cloudinary.com/dirpdlfbu/image/upload/v1731494258/1/dvhl0hwbx4adb8uceymr.gif"
              alt="Cargando..."
              style={{ width: "150px" }}
            />
          </div>
        )}
      </div>

      {/* <div className="text-center mt-3">
        <button onClick={handlePreviewPDF} className="btn btn-primary">
          Vista Previa del Recibo
        </button>
      </div> */}
    </div>
  );
};

// CARGAR PREVIEW DEL PDFFFFFFFFFFFFFFFFFF___________::::::::::::::::::::::::::::::::::::::::::::::::. OJO
