import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment-timezone"; // Asegúrate de instalar esta librería
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import "../../../../styles/RegistroActivacionesHoy.css";

const MySwal = withReactContent(Swal);

const EstatusIcon = {
  ACTIVO: {
    icon: "fa-check",
    name: "ACTIVO",
    color: "linear-gradient(to right, #28a745, #31c353)", // Verde
  },
  ANULADO: {
    icon: "fa-ban",
    name: "CANCELADO",
    color: "linear-gradient(to right, #dc3545, #f24d5e)", // Rojo
  },
  "EN PISTA": {
    icon: "fa-house",
    name: "EN PISTA",
    color: "linear-gradient(to right, #ffc107, #ffd24a)", // Amarillo
  },
  CARGANDO: {
    icon: "fa-person-walking-luggage",
    name: "ABORDANDO",
    color: "linear-gradient(to right, #176ec6, #2a89e8)", // Blanco
  },
  SALIO: {
    icon: "fa-van-shuttle",
    name: "SALIÓ",
    color: "linear-gradient(to right, #6c757d, #969ca2)", // Gris
  },
  RETRASADO: {
    icon: "fa-hourglass-end",
    name: "CON RETRASO",
    color: "linear-gradient(to right, #ff6347, #ff856f)", // Rojo Claro
  },
};

export const RegistroActivacionesHoy = () => {
  const [records, setRecords] = useState([]);
  const [currentTime, setCurrentTime] = useState(moment.tz("America/Caracas"));
  const [loading, setLoading] = useState(true);

  const fetchTodayRecords = async () => {
    try {
      const response = await axios.get(
        process.env.BACKEND_URL + "/api/public/getTodayActivacionPista2"
      );
      setRecords(response.data);
    } catch (error) {
      console.error("Error al obtener registros de hoy", error);
    } finally {
      setLoading(false); // Cambia a `false` al terminar la carga
    }
  };

  useEffect(() => {
    // Actualizar la hora de Venezuela cada segundo
    const interval = setInterval(() => {
      setCurrentTime(moment.tz("America/Caracas"));
    }, 1000);

    fetchTodayRecords();

    return () => clearInterval(interval); // Limpiar el intervalo al desmontar el componente
  }, []);

  const ViewRoute = (record) => {
    MySwal.fire({
      title: <h6>Ruta de {record.empresa}</h6>,
      html: <p>{record.ruta}</p>, // JSX ahora es aceptado con `withReactContent`
      focusConfirm: false,
    });
  };

  const handleChangeStatus = (record) => {
    MySwal.fire({
      title: (
        <h6>
          <i
            className="fa-solid fa-pen-to-square"
            style={{ marginRight: "7px", fontSize: "20px" }}
          ></i>
          Editar Datos
        </h6>
      ),
      html: `
            <div class="input-group-login2"><input style="width: 100%; margin-bottom: 20px" id="swal-numero-de-unidad" class="inputDeLogin" value="${record.numero_de_unidad || ""}"><label>Número Unidad</label></div>
            <div class="input-group-login2"><input style="width: 100%; margin-bottom: 20px; text-transform: uppercase" id="swal-placa" class="inputDeLogin" value="${record.placa || ""}"><label>Placa</label></div>
            <div class="input-group-login2"><input style="width: 100%; margin-bottom: 20px" id="swal-anden" class="inputDeLogin" value="${record.anden || ""}"><label>Puerta</label></div>
            <div class="input-group-login2">
              <select id="swal-status" class="inputDeLogin" style="width: 100%">
                <option value="" disabled ${!record.estatus ? "selected" : ""}>Selecciona un estatus</option>
                <option value="ACTIVO" ${record.estatus === "ACTIVO" ? "selected" : ""}>ACTIVO</option>
                <option value="ANULADO" ${record.estatus === "ANULADO" ? "selected" : ""}>CANCELADO</option>
                <option value="EN PISTA" ${record.estatus === "EN PISTA" ? "selected" : ""}>EN PISTA</option>
                <option value="CARGANDO" ${record.estatus === "CARGANDO" ? "selected" : ""}>ABORDANDO</option>
                <option value="SALIO" ${record.estatus === "SALIO" ? "selected" : ""}>SALIÓ</option>
                <option value="RETRASADO" ${record.estatus === "RETRASADO" ? "selected" : ""}>CON RETRASO</option>
              </select>
            <label>Estatus</label></div>
            `,
      focusConfirm: false,
      showCancelButton: true,
      confirmButtonText: "Actualizar",
      preConfirm: () => {
        const estatus = document.getElementById("swal-status").value;
        const numero_de_unidad = document.getElementById(
          "swal-numero-de-unidad"
        ).value;
        const placa = document.getElementById("swal-placa").value;
        const anden = document.getElementById("swal-anden").value;

        if (!estatus) {
          Swal.showValidationMessage("Debes seleccionar un estatus!");
          return false;
        }

        return { estatus, numero_de_unidad, placa, anden };
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        const { estatus, numero_de_unidad, placa, anden } = result.value;

        try {
          const response = await axios.put(
            `${process.env.BACKEND_URL}/api/activacion/${record.id}/updateStatus`,
            {
              estatus,
              numero_de_unidad,
              placa,
              anden,
            },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          );

          Swal.fire({
            icon: "success",
            title: response.data.message,
          });

          fetchTodayRecords();
        } catch (error) {
          Swal.fire({
            icon: "error",
            title: "Error al cambiar el estatus",
            text: error.response?.data?.message || "Error interno",
          });
        }
      }
    });
  };

  const calculateTimeDiff = (horaSalida, estatus) => {
    const salidaTime = moment.tz(horaSalida, "HH:mm", "America/Caracas");

    const today = currentTime.clone().startOf("day");
    const fullSalidaTime = today.clone().set({
      hour: salidaTime.hour(),
      minute: salidaTime.minute(),
    });

    const diffInMinutes = fullSalidaTime.diff(currentTime, "minutes");

    // Si el tiempo restante es mayor a 0, mostramos el tiempo restante en verde
    if (diffInMinutes > 0) {
      return { time: `${diffInMinutes} min`, color: "green", estatus: estatus };
    } else {
      // Si el tiempo restante es menor o igual a 0, mostramos el tiempo pasado en rojo
      return {
        time: `${Math.abs(diffInMinutes)} min`,
        color: "red",
        estatus: estatus,
      };
    }
  };

  return (
    <div className="container-fluid contarinerGeneralUserHomejs">
      <div
        style={{
          textAlign: "center",
          padding: "10px",
          display: "flex",
          flexDirection: "column",
        }}
        className="sdfdfgrt54w98e7we9contewe"
      >
        <div style={{ margin: "0", marginTop: "20px" }}>
          <i
            className="fa-solid seraUnMarinsupre fa-file-circle-check"
            id="icon-tittle-general"
          ></i>
        </div>
        <p
          style={{ fontSize: "13px", margin: "0" }}
          className="asdjhfnerer4pdeterminal"
        >
          Consulta y gestiona todos las activaciones realizadas durante el día,
          visualizando información detallada y actualizada para un control
          eficiente y puntual.
        </p>
      </div>
      {loading ? (
        <div style={{ textAlign: "center", padding: "20px" }}>
          <img
            src="https://res.cloudinary.com/dirpdlfbu/image/upload/v1731494258/1/dvhl0hwbx4adb8uceymr.gif"
            alt="Cargando..."
            style={{ width: "150px" }}
          />
        </div>
      ) : (
        <div className="contianer-fluid p-0 m-0 w-100">
          <div className="sdf5erg8e9r7f8weactivac mobile-er65g48e9r7we984fwe594">
            {records
              .sort((a, b) => {
                const estatusOrdenA =
                  a.estatus === "SALIO" || a.estatus === "ANULADO" ? 1 : 0;
                const estatusOrdenB =
                  b.estatus === "SALIO" || b.estatus === "ANULADO" ? 1 : 0;

                if (estatusOrdenA !== estatusOrdenB) {
                  return estatusOrdenA - estatusOrdenB;
                }

                const diffA = moment
                  .tz(a.hora_salida, "HH:mm", "America/Caracas")
                  .diff(currentTime, "minutes");
                const diffB = moment
                  .tz(b.hora_salida, "HH:mm", "America/Caracas")
                  .diff(currentTime, "minutes");

                return diffA - diffB;
              })
              .map((record, index) => {
                const {
                  empresa,
                  destino_final,
                  estatus,
                  hora_salida,
                  numero_de_unidad,
                  placa,
                  anden,
                } = record;
                const {
                  time,
                  color,
                  estatus: updatedStatus,
                } = calculateTimeDiff(hora_salida, estatus);

                // Si el estatus es "ACTIVO" y faltan 30 minutos o menos, cambiar el estatus a "RETRASADO"
                const finalStatus =
                  estatus === "ACTIVO" &&
                  moment
                    .tz(hora_salida, "HH:mm", "America/Caracas")
                    .diff(currentTime, "minutes") <= 30
                    ? "RETRASADO"
                    : updatedStatus;

                // Obtener el ícono y color según el estatus final
                const icono = EstatusIcon[finalStatus];

                return (
                  <div
                    key={index}
                    className="container asklnduiashndaijsnxansxsaas"
                  >
                    <div className="container asune-rv56er48v9wec-s2 mgjmnh98-sdf9w8e7f9wewefwes">
                      <div className="container aoshdnuqiwhnd897">
                        <div className="container ds897fq4w9dc9w8c4-we89c7w2">
                          <p
                            style={{
                              margin: "0",
                              fontSize: "10px",
                            }}
                            id="pderegistros"
                          >
                            {empresa}
                          </p>
                          <h5 style={{ marginTop: "15px" }}>
                            {destino_final}
                            <i
                              className="fa-solid fa-circle-info"
                              onClick={() => ViewRoute(record)} // Evento para cambiar estatus
                              style={{ marginLeft: "7px", color: "gray" }}
                            ></i>
                          </h5>
                        </div>
                        <div
                          className="container ds9f848dsfwef498we4fwd-57982"
                          onClick={() => handleChangeStatus(record)} // Evento para cambiar estatus
                        >
                          <div
                            className="sad8qw9d4asas6x4a89s42"
                            style={{ backgroundImage: icono.color }}
                          >
                            <i
                              className={`fa-solid asd98asdas894xsa ${icono.icon}`}
                            ></i>
                          </div>
                        </div>
                      </div>
                      <div className="asdw-w9d84c9w8e4dcd"></div>
                      <div className="container aoshdnuqiwhnd8972">
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            width: "100%",
                          }}
                        >
                          <div
                            style={{
                              width: "33.33%",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <p style={{ margin: "0", color }}>{time}</p>
                          </div>
                          <div
                            style={{
                              width: "33.33%",
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              fontSize: "14px",
                              textAlign: "center",
                            }}
                          >
                            <p style={{ margin: "0", textAlign: "center" }}>
                              Puerta: {anden}
                            </p>
                            <p style={{ margin: "0", textAlign: "center" }}>
                              Hora: {hora_salida}
                            </p>
                          </div>
                          <div
                            style={{
                              width: "33.33%",
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "end",
                              fontSize: "14px",
                            }}
                          >
                            <p style={{ margin: "0", textAlign: "end" }}>
                              Unidad: {numero_de_unidad}
                            </p>
                            <p style={{ margin: "0", textAlign: "end" }}>
                              {placa}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
          <div className="custom-table-container desktop-er65g48e9r7we984fwe594 m-0">
            <table className="custom-table">
              <thead>
                <tr>
                  <th className="table-header">Empresa</th>
                  <th className="table-header">Destino Final</th>
                  <th className="table-header">Contador</th>
                  <th className="table-header">Estatus</th>
                  <th className="table-header">Puerta</th>
                  <th className="table-header">Hora</th>
                  <th className="table-header">Unidad</th>
                  <th className="table-header">Placa</th>
                  <th className="table-header">Acciones</th>
                </tr>
              </thead>
              <tbody>
                {records
                  .sort((a, b) => {
                    const estatusOrdenA =
                      a.estatus === "SALIO" || a.estatus === "ANULADO" ? 1 : 0;
                    const estatusOrdenB =
                      b.estatus === "SALIO" || b.estatus === "ANULADO" ? 1 : 0;

                    if (estatusOrdenA !== estatusOrdenB) {
                      return estatusOrdenA - estatusOrdenB;
                    }

                    const diffA = moment
                      .tz(a.hora_salida, "HH:mm", "America/Caracas")
                      .diff(currentTime, "minutes");
                    const diffB = moment
                      .tz(b.hora_salida, "HH:mm", "America/Caracas")
                      .diff(currentTime, "minutes");

                    return diffA - diffB;
                  })
                  .map((record) => {
                    const {
                      empresa,
                      destino_final,
                      estatus,
                      hora_salida,
                      numero_de_unidad,
                      placa,
                      anden,
                    } = record;
                    const {
                      time,
                      color,
                      estatus: updatedStatus,
                    } = calculateTimeDiff(hora_salida, estatus);

                    // Si el estatus es "ACTIVO" y faltan 30 minutos o menos, cambiar el estatus a "RETRASADO"
                    const finalStatus =
                      estatus === "ACTIVO" &&
                      moment
                        .tz(hora_salida, "HH:mm", "America/Caracas")
                        .diff(currentTime, "minutes") <= 30
                        ? "RETRASADO"
                        : updatedStatus;

                    // Obtener el ícono y color según el estatus final
                    const icono = EstatusIcon[finalStatus];

                    return (
                      <tr key={record.id} className="table-row">
                        <td className="table-cell">{empresa}</td>
                        <td className="table-cell">{destino_final}</td>
                        <td style={{ color: color }} className="table-cell">
                          {time}
                        </td>
                        <td className="table-cell">
                          <div
                            className="sad8qw9d4asas6x4a89s42"
                            style={{
                              backgroundImage: icono.color,
                              width: "90%",
                              height: "20px",
                            }}
                          >
                            <i
                              className={`fa-solid asd98asdas894xsa ${icono.icon}`}
                              style={{ fontSize: "14px" }}
                            ></i>
                          </div>
                        </td>
                        <td className="table-cell">{anden}</td>
                        <td className="table-cell">{hora_salida}</td>
                        <td className="table-cell">{numero_de_unidad}</td>
                        <td className="table-cell">{placa}</td>
                        <td className="table-cell">
                          <button
                            className="btn btn-secondary d-flex"
                            onClick={() => handleChangeStatus(record)}
                            style={{
                              color: "white",
                              flexDirection: "row",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <i
                              className="fa-solid fa-circle-info"
                              style={{ fontSize: "17px", marginRight: "7px" }}
                            ></i>
                            <p className="m-0">Editar</p>
                          </button>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};
