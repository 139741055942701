import React, { useContext, useState, useEffect } from "react";
import axios from "axios";
import "../../../../styles/SolicitudActivaciones.css";
import { Context } from "../../../store/appContext";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Link } from "react-router-dom";

const MySwal = withReactContent(Swal);

export const TerminalEnVivo = () => {
  const { store } = useContext(Context);
  const [formData, setFormData] = useState({
    fecha_viaje: "",
    empresa: "",
    rif: "",
    parada: "",
    ruta: "",
    destino_final: "",
    referencia: "",
    hora_salida: "",
    numero_unidad: "",
    placa: "",
    anden: "",
  });
  const [cpsData, setCpsData] = useState([]); // Data completa del CPS
  const [companies, setCompanies] = useState([]); // Empresas únicas
  const [filteredParadas, setFilteredParadas] = useState([]); // Paradas filtradas por empresa
  const [filteredRutas, setFilteredRutas] = useState([]); // Rutas filtradas por parada
  const [referencia, setReferencia] = useState([]); // Referencias filtradas por parada
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    fetchTodayRecords();
  }, []);

  const fetchTodayRecords = async () => {
    try {
      const response = await axios.get(
        process.env.BACKEND_URL + "/api/GetCpsDatesTLB",
        {
          headers: {
            Authorization: `Bearer ${store.token}`,
          },
        }
      );
      setCpsData(response.data.cps);

      // Extraer empresas únicas
      const uniqueCompanies = Array.from(
        new Set(response.data.cps.map((item) => item.linea_transporte))
      );
      setCompanies(uniqueCompanies);
    } catch (error) {
      MySwal.fire({
        title: "Error",
        text: "Error al cargar los registros del día.",
        icon: "error",
        confirmButtonText: "Cerrar",
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    const requiredFields = [
      { key: "fecha_viaje", label: "Fecha de Viaje" },
      { key: "empresa", label: "Empresa" },
      { key: "rif", label: "RIF" },
      { key: "parada", label: "Parada" },
      { key: "ruta", label: "Ruta" },
      { key: "destino_final", label: "Destino Final" },
      { key: "referencia", label: "Referencia" },
      { key: "hora_salida", label: "Hora de Salida" },
      { key: "anden", label: "Andén" },
      { key: "placa", label: "Placa" },
    ];

    // Encuentra el primer campo vacío
    const missingField = requiredFields.find((field) => !formData[field.key]);

    if (missingField) {
      MySwal.fire({
        title: "Error",
        text: `Por favor, complete el campo requerido: ${missingField.label}.`,
        icon: "error",
        confirmButtonText: "Cerrar",
      });
      setIsSubmitting(false);
      return;
    }

    try {
      await axios.post(
        process.env.BACKEND_URL + "/api/publicbillboardata",
        formData,
        {
          headers: {
            Authorization: `Bearer ${store.token}`,
          },
        }
      );

      setFormData({
        fecha_viaje: "",
        empresa: "",
        rif: "",
        parada: "",
        ruta: "",
        destino_final: "",
        referencia: "",
        hora_salida: "",
        numero_unidad: "",
        placa: "",
        anden: "",
      });

      MySwal.fire({
        title: "Activación de Pista Registrada",
        text: "La activación de pista ha sido registrada exitosamente.",
        icon: "success",
        confirmButtonText: "Cerrar",
      });

      fetchTodayRecords();
    } catch (error) {
      const errorMessage =
        error.response?.data?.message ||
        "Error desconocido al registrar la activación de pista.";

      MySwal.fire({
        title: "Error",
        text: errorMessage,
        icon: "error",
        confirmButtonText: "Cerrar",
      });
    } finally {
      setIsSubmitting(false); // Rehabilitar el botón al finalizar
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const openCompanySelection = () => {
    MySwal.fire({
      title: (
        <h6>
          <i
            className="fa-solid fa-user-tie"
            style={{ marginRight: "7px", fontSize: "20px" }}
          ></i>
          Empresas de Transporte
        </h6>
      ),
      html: `
        <input
          type="text"
          id="companySearch"
          placeholder="Buscar empresa"
          class="swal2-input"
          style="width: 100%"
        />
        <div id="companyList" class="company-list">
          ${companies.map((company) => `<button class="btn company-option company-list-activation-list">${company}</button>`).join("")}
        </div>
      `,
      showConfirmButton: false,
      showCancelButton: true,
      cancelButtonText: "Cerrar",
      didOpen: () => {
        const companySearch = document.getElementById("companySearch");
        const companyList = document.getElementById("companyList");

        // Manejador de filtro para el input de búsqueda
        companySearch.addEventListener("input", (e) => {
          const query = e.target.value.toLowerCase();
          const filteredCompanies = companies.filter((company) =>
            company.toLowerCase().includes(query)
          );
          companyList.innerHTML = filteredCompanies
            .map(
              (company) =>
                `<button class="btn company-option company-list-activation-list">${company}</button>`
            )
            .join("");

          // Agregar listeners a cada opción filtrada
          const buttons = companyList.querySelectorAll(".company-option");
          buttons.forEach((button) => {
            button.addEventListener("click", () => {
              selectCompany(button.textContent);
            });
          });
        });

        // Listener para opciones de empresa inicialmente
        const buttons = companyList.querySelectorAll(".company-option");
        buttons.forEach((button) => {
          button.addEventListener("click", () => {
            selectCompany(button.textContent);
          });
        });
      },
    });
  };

  const selectCompany = (selectedCompany) => {
    // Buscar el RIF correspondiente en cpsData
    const selectedCps = cpsData.find(
      (item) => item.linea_transporte === selectedCompany
    );
    const rif = selectedCps ? selectedCps.rif : "";

    setFormData((prevData) => ({
      ...prevData,
      empresa: selectedCompany,
      rif, // Autocompletar RIF si hay resultados
      parada: "", // Limpiar parada
      ruta: "", // Limpiar ruta
      destino_final: "", // Limpiar destino final
      referencia: "", // Limpiar referencia
      hora_salida: "", // Limpiar hora de salida
    }));

    const filteredCps = cpsData.filter(
      (item) => item.linea_transporte === selectedCompany
    );

    if (filteredCps.length > 0) {
      const uniqueParadas = Array.from(
        new Set(filteredCps.map((item) => item.parada))
      );
      setFilteredParadas(uniqueParadas);
    } else {
      setFilteredParadas([]); // Limpiar paradas si no hay resultados
    }

    Swal.close(); // Cierra el Swal
  };

  const filterCompanies = (query) => {
    const filteredCompanies = companies.filter((company) =>
      company.toLowerCase().includes(query.toLowerCase())
    );
    // Actualiza el HTML del Swal para mostrar solo los resultados filtrados
    document.getElementById("companyList").innerHTML = filteredCompanies
      .map((company) => `<button class="company-option">${company}</button>`)
      .join("");
  };

  // Filtrar rutas basadas en la Parada seleccionada
  const handleParadaChange = (e) => {
    const selectedParada = e.target.value;

    const filteredCps = cpsData.filter(
      (item) =>
        item.linea_transporte === formData.empresa &&
        item.parada === selectedParada
    );

    setFormData((prevData) => ({
      ...prevData,
      parada: selectedParada,
    }));

    // Filtrar rutas únicas
    const uniqueRutas = Array.from(
      new Set(filteredCps.map((item) => item.ruta))
    );
    setFilteredRutas(uniqueRutas);
  };

  const handleRutaChange = (e) => {
    const selectedRuta = e.target.value;

    const filteredCps = cpsData.filter(
      (item) =>
        item.linea_transporte === formData.empresa &&
        item.parada === formData.parada &&
        item.ruta === selectedRuta
    );

    if (filteredCps.length > 0) {
      setFormData((prevData) => ({
        ...prevData,
        ruta: selectedRuta,
        destino_final: filteredCps[0].destino_final, // Autocompletar destino final
      }));

      // Extraer las referencias de turno_hora_cps de los datos filtrados
      const uniqueRefs = Array.from(
        new Set(filteredCps.map((item) => item.turno_hora_cps))
      );
      setReferencia(uniqueRefs);
    } else {
      // Limpiar la referencia si no hay coincidencias
      setReferencia([]);
    }
  };

  const handleReferenciaChange = (e) => {
    const selectedRef = e.target.value;

    // Actualizar el estado de referencia en formData
    setFormData((prevData) => ({
      ...prevData,
      referencia: selectedRef,
    }));
  };

  return (
    <div className="container-fluid contarinerGeneralUserHomejs">
      <div
        style={{
          textAlign: "center",
          padding: "10px",
          display: "flex",
          flexDirection: "column",
        }}
        className="sdfdfgrt54w98e7we9contewe"
      >
        <h2 style={{ margin: "0", marginTop: "20px" }}>Activación de Ruta</h2>
        <p
          style={{ fontSize: "13px", margin: "0" }}
          className="asdjhfnerer4pdeterminal"
        >
          La activación que hagas no será reversible, y aparecerá en la
          programación en vivo, incluyendo el Terminal en Vivo
        </p>
      </div>
      <div className="container asklnduiashndaijsnxansxsaas3">
        <form
          className="d-flex sd98f4e8wf4cwe987cwe984cwe"
          onSubmit={handleSubmit}
        >
          <div className="aoshduiahsdkasnmdasdas">
            <div className="input-group-login2">
              <input
                className="inputDeLogin"
                type="date"
                name="fecha_viaje"
                value={formData.fecha_viaje}
                onChange={handleChange}
                required
                style={{ cursor: "pointer" }}
              />
              <label>Fecha del viaje</label>
            </div>
            <div className="input-group-login2">
              <input
                className="inputDeLogin"
                type="text"
                name="empresa"
                value={
                  formData.empresa ? formData.empresa : "Selecciona Empresa"
                }
                onClick={openCompanySelection}
                required
                readOnly
                style={{ cursor: "pointer" }}
              />
              <label>Empresa</label>
            </div>
            <div className="input-group-login2">
              <input
                className="inputDeLogin"
                type="text"
                name="rif"
                value={formData.rif}
                required
                readOnly
              />
              <label>RIF</label>
            </div>
            <div className="input-group-login2">
              <select
                className="inputDeLogin"
                name="parada"
                value={formData.parada}
                onChange={handleParadaChange}
                required
                style={{ cursor: "pointer" }}
              >
                <option value="">Selecciona Parada</option>
                {filteredParadas.map((parada, index) => (
                  <option key={index} value={parada}>
                    {parada}
                  </option>
                ))}
              </select>
              <label>Parada</label>
            </div>
          </div>

          <div className="aoshduiahsdkasnmdasdas">
            <div className="input-group-login2">
              <select
                className="inputDeLogin"
                name="ruta"
                value={formData.ruta}
                onChange={handleRutaChange}
                required
                style={{ cursor: "pointer" }}
              >
                <option value="">Selecciona Ruta</option>
                {filteredRutas.map((ruta, index) => (
                  <option key={index} value={ruta}>
                    {ruta}
                  </option>
                ))}
              </select>
              <label>Ruta</label>
            </div>
          </div>

          <div className="aoshduiahsdkasnmdasdas">
            <div className="input-group-login2">
              <input
                className="inputDeLogin"
                type="text"
                name="destino_final"
                value={formData.destino_final}
                readOnly
                required
              />
              <label>Destino Final</label>
            </div>

            <div className="input-group-login2">
              <select
                className="inputDeLogin"
                name="referencia"
                value={formData.referencia}
                onChange={handleReferenciaChange}
                required
                style={{ cursor: "pointer" }}
              >
                <option value="">Selecciona Referencia</option>
                {referencia.map((referencia, index) => (
                  <option key={index} value={referencia}>
                    {referencia}
                  </option>
                ))}
              </select>
              <label>Referencia</label>
            </div>

            <div className="input-group-login2">
              <input
                className="inputDeLogin"
                type="time"
                name="hora_salida"
                value={formData.hora_salida}
                onChange={handleChange}
                required
                style={{ cursor: "pointer" }}
              />
              <label>Hora de Salida</label>
            </div>
          </div>
          <div className="aoshduiahsdkasnmdasdas">
            <div className="input-group-login2">
              <input
                className="inputDeLogin"
                type="text"
                name="numero_unidad"
                value={formData.numero_unidad}
                onChange={handleChange}
                required
              />
              <label>Número de Unidad</label>
            </div>
            <div className="input-group-login2">
              <input
                className="inputDeLogin"
                type="text"
                name="placa"
                value={formData.placa}
                onChange={handleChange}
                required
                style={{ textTransform: "uppercase" }}
              />
              <label>Placa</label>
            </div>
            <div className="input-group-login2">
              <input
                className="inputDeLogin"
                type="number"
                name="anden"
                value={formData.anden}
                onChange={handleChange}
                required
              />
              <label>Puerta</label>
            </div>
          </div>
          <div
            style={{
              flexDirection: "column",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
              alignItems: "center",
            }}
          >
            <button
              className="buttonCargadeDatosDeLoginregistromovimiento"
              type="submit"
              disabled={isSubmitting}
            >
              {isSubmitting ? "Registrando..." : "Registrar"}{" "}
              <i className="fa-solid fa-arrow-right"></i>
            </button>
            <div className="d-flex" style={{ marginTop: "12px" }}>
              <p>¿Alguna duda?,</p>
              <Link
                style={{
                  marginLeft: "5px",
                  textDecoration: "none",
                  color: "#24827C",
                }}
                to="/AyudaYSoporte"
              >
                <strong>Contáctanos</strong>
              </Link>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
