import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { Context } from "../../../store/appContext.js";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFDownloadLink,
} from "@react-pdf/renderer";

const MySwal = withReactContent(Swal);

export const RegistroCps = () => {
  const navigate = useNavigate();
  const { store } = useContext(Context);
  const [formData, setFormData] = useState({
    empresa_terminal_id: "",
    empresa: "",
    numero_cps: "",
    ruta_n: "",
    ruta: "",
    parada: "",
    destino_final: "",
    status: "",
    horas: [], // Nuevo campo para manejar las horas
  });
  const [isSubmitting, setIsSubmitting] = useState(false); // Nuevo estado para controlar el estado de envío
  const [empresas, setEmpresas] = useState([]); // Nuevo estado para controlar el estado de envío
  const [cpsOptions, setCpsOptions] = useState([]); // Opciones de CPS para el <select>

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true); // Desactiva el botón

    try {
      // Enviar los datos con las horas
      await axios.post(
        process.env.BACKEND_URL + "/api/registro_ruta_directores_terminal",
        formData,
        {
          headers: {
            Authorization: "Bearer " + store.token,
          },
        }
      );
      setFormData({
        empresa_terminal_id: "",
        empresa: "",
        numero_cps: "",
        ruta_n: "",
        ruta: "",
        destino_final: "",
        status: "",
        horas: [], // Limpiar las horas después de enviar
      });
      mostrarAlerta1();
    } catch (error) {
      const errorMessage =
        error.response?.data?.message ||
        "Error desconocido al registrar la Cps";
      mostrarAlerta2(errorMessage);
      console.error("Error al registrar", error);
    } finally {
      setIsSubmitting(false); // Reactiva el botón después del envío
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const mostrarAlerta1 = () => {
    MySwal.fire({
      title: <p>CPS Registrada con éxito</p>,
      icon: "success",
      position: "center",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
    });
  };

  const mostrarAlerta2 = (errorMessage) => {
    MySwal.fire({
      title: <p>Error al Registrar la CPS</p>,
      html: <p>{errorMessage}</p>,
      icon: "error",
      position: "center",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
    });
  };

  const fetchempresasysuscps = async () => {
    try {
      const response = await axios.get(
        process.env.BACKEND_URL + "/api/ver_empresas_por_terminal_con_cps",
        {
          headers: {
            Authorization: `Bearer ${store.token}`,
          },
        }
      );
      setEmpresas(response.data.empresas);
    } catch (error) {
      MySwal.fire({
        title: "Error",
        text: "Error al cargar las empresas disponibles.",
        icon: "error",
        confirmButtonText: "Cerrar",
      });
    }
  };

  useEffect(() => {
    fetchempresasysuscps();
  }, []);

  const openCompanySelection = () => {
    MySwal.fire({
      title: (
        <p>
          <i
            className="fa-solid fa-user-tie"
            style={{ marginRight: "7px", fontSize: "20px" }}
          ></i>
          Empresas de Transporte
        </p>
      ),
      html: `
        <input
          type="text"
          id="companySearch"
          placeholder="Buscar empresa"
          class="swal2-input"
          style="width: 100%"
        />
        <div id="companyList" class="company-list">
          ${empresas
            .map(
              (company) => `
            <button 
              class="btn company-option company-list-activation-list" 
              data-id="${company.id}"
              data-name="${company.empresa.nombre}">  <!-- Añadimos data-name con el nombre -->
              ${company.empresa.nombre} <br> ${company.empresa.rif}
            </button>`
            )
            .join("")}
        </div>
      `,
      showConfirmButton: false,
      showCancelButton: true,
      cancelButtonText: "Cerrar",
      didOpen: () => {
        const companySearch = document.getElementById("companySearch");
        const companyList = document.getElementById("companyList");

        // Manejador de filtro para el input de búsqueda
        companySearch.addEventListener("input", (e) => {
          const query = e.target.value.toLowerCase();
          const filteredEmpresas = empresas.filter(
            (company) =>
              company.empresa.nombre.toLowerCase().includes(query) ||
              company.empresa.rif.toLowerCase().includes(query)
          );
          companyList.innerHTML = filteredEmpresas
            .map(
              (company) => `
                <button 
                  class="btn company-option company-list-activation-list" 
                  data-id="${company.id}"
                  data-name="${company.empresa.nombre}">  <!-- Añadimos data-name con el nombre -->
                  ${company.empresa.nombre} <br> ${company.empresa.rif}
                </button>`
            )
            .join("");

          // Agregar listeners a cada opción filtrada
          const buttons = companyList.querySelectorAll(".company-option");
          buttons.forEach((button) => {
            button.addEventListener("click", () => {
              const companyId = button.dataset.id;
              const companyName = button.dataset.name; // Usamos el nombre de la empresa
              selectCompany(companyId, companyName); // Pasamos el ID y el nombre
            });
          });
        });

        // Listener para opciones de empresa inicialmente
        const buttons = companyList.querySelectorAll(".company-option");
        buttons.forEach((button) => {
          button.addEventListener("click", () => {
            const companyId = button.dataset.id;
            const companyName = button.dataset.name;
            selectCompany(companyId, companyName); // Pasamos el ID y el nombre
          });
        });
      },
    });
  };

  const selectCompany = (companyId, companyName) => {
    // Encuentra la empresa seleccionada
    const empresaSeleccionada = empresas.find(
      (empresa) => empresa.id === parseInt(companyId)
    );

    // Extrae los CPS de la empresa seleccionada
    const cpsOptions = empresaSeleccionada.empresa.cps.map((cps) => ({
      id: cps.id,
      numero_cps: cps.numero_cps,
    }));

    setFormData({
      ...formData,
      empresa: companyName, // Nombre de la empresa
      empresa_terminal_id: companyId, // ID de la empresa
      numero_cps: "", // Resetea el campo de CPS
    });

    setCpsOptions(cpsOptions); // Guarda las opciones de CPS para el <select>
    MySwal.close(); // Cierra el modal
  };

  const addHora = () => {
    setFormData((prevData) => ({
      ...prevData,
      horas: [...prevData.horas, ""], // Agregar un nuevo campo vacío para la hora
    }));
  };

  const removeHora = (index) => {
    setFormData((prevData) => ({
      ...prevData,
      horas: prevData.horas.filter((_, i) => i !== index), // Eliminar la hora por índice
    }));
  };

  const fetchTerminal = async () => {
    MySwal.fire({
      title: "Cargando...",
      html: `<p style="text-align:center; margin:20px;">Cargando Empresas...</p>`,
      allowOutsideClick: false,
      didOpen: () => {
        MySwal.showLoading();
      },
    });

    try {
      const response = await axios.get(
        process.env.BACKEND_URL + "/api/ver_empresas_por_terminal_con_cps",
        {
          headers: {
            Authorization: `Bearer ${store.token}`,
          },
        }
      );

      const data = response.data.empresas;

      MySwal.close();

      if (!data || data.length === 0) {
        MySwal.fire({
          title: "Empresas",
          html: `<p style="text-align:center; margin:20px;">No se encontraron Empresas.</p>`,
          confirmButtonText: "Cerrar",
        });
      } else {
        const tableHeaders = `
          <tr>
            <th style="text-align: start">EMPRESA</th>
            <th style="text-align: start">RIF</th>
            <th style="text-align: start">ESTATUS</th>
          </tr>
        `;

        const statusMap = {
          ACTIVE: "ACTIVO",
          NO_ACTIVE: "INACTIVO",
        };

        const tableRows = data
          .map((item, index) => {
            const empresaRow = `
              <tr>
                <td>
                  <button class="toggle-rutas" data-index="${index}" style="background: none; border: none; cursor: pointer;">
                    ➕ ${item.empresa.nombre}
                  </button>
                </td>
                <td>${item.empresa.rif}</td>
                <td>
                  <button class="change-status" data-id="${item.id}" data-status="${item.status}" style="color: white; background-color: ${item.status == "ACTIVE" ? "green" : "red"}; border: none; padding: 5px; cursor: pointer;">
                    ${statusMap[item.status] || item.status}
                  </button>
                </td>
              </tr>
              <tr class="rutas-row" id="rutas-row-${index}" style="display: none;">
                <td colspan="3">
                  <table style="width: 100%; margin-left: 20px; font-size: 12px;">
                    <thead>
                      <tr>
                        <th style="width: 500px">RUTA</th>
                        <th style="width: 200px">DESTINO FINAL</th>
                        <th style="width: 130px">ESTATUS</th>
                        <th style="width: 150px">TURNOS CPS</th>
                      </tr>
                    </thead>
                    <tbody>
                      ${item.rutas
                        .map(
                          (ruta) => `
                          <tr>
                            <td>${ruta.ruta}</td>
                            <td>${ruta.destino_final}</td>
                            <td>${statusMap[ruta.status] || ruta.status}</td>
                            <td>
                              ${ruta.turnos_cps
                                .map((turno) => `<span>${turno.hora}</span>`)
                                .join(", ")}
                            </td>
                          </tr>
                        `
                        )
                        .join("")}
                    </tbody>
                  </table>
                </td>
              </tr>
            `;
            return empresaRow;
          })
          .join("");

        const table = `
          <input type="text" id="search-listines" placeholder="Buscar..." class="swal2-input" style="margin-bottom: 10px;">
          <div style="max-height: 400px; overflow-y: auto;">
            <table style="width: 500px; border-collapse: collapse; font-size: 12px;">
              <thead>
                ${tableHeaders}
              </thead>
              <tbody id="listines-list" style="text-align: start">
                ${tableRows}
              </tbody>
            </table>
          </div>
        `;

        MySwal.fire({
          title: "Empresas",
          html: table,
          showConfirmButton: true,
          confirmButtonText: "Cerrar",
          didRender: () => {
            // Agregar buscador
            const searchInput = document.getElementById("search-listines");
            searchInput.addEventListener("input", (event) => {
              const searchTerm = event.target.value.toLowerCase();
              const listinesList = document.getElementById("listines-list");
              const filteredRows = data
                .filter(
                  (item) =>
                    item.empresa.nombre.toLowerCase().includes(searchTerm) ||
                    item.empresa.rif.toLowerCase().includes(searchTerm)
                )
                .map((item, index) => {
                  const empresaRow = `
                    <tr>
                      <td>
                        <button class="toggle-rutas" data-index="${index}" style="background: none; border: none; cursor: pointer;">
                          ➕ ${item.empresa.nombre}
                        </button>
                      </td>
                      <td>${item.empresa.rif}</td>
                      <td>
                        <button class="change-status" data-id="${item.id}" data-status="${item.status}" style="color: white; background-color: ${item.status == "ACTIVE" ? "green" : "red"}; border: none; padding: 5px; cursor: pointer;">
                          ${statusMap[item.status] || item.status}
                        </button>
                      </td>
                    </tr>
                    <tr class="rutas-row" id="rutas-row-${index}" style="display: none;">
                      <td colspan="3">
                        <table style="width: 100%; margin-left: 20px; font-size: 12px;">
                          <thead>
                            <tr>
                              <th style="width: 500px">RUTA</th>
                              <th style="width: 200px">DESTINO FINAL</th>
                              <th style="width: 130px">ESTATUS</th>
                              <th style="width: 150px">TURNOS CPS</th>
                            </tr>
                          </thead>
                          <tbody>
                            ${item.rutas
                              .map(
                                (ruta) => `
                                <tr>
                                  <td>${ruta.ruta}</td>
                                  <td>${ruta.destino_final}</td>
                                  <td>${statusMap[ruta.status] || ruta.status}</td>
                                  <td>
                                    ${ruta.turnos_cps
                                      .map(
                                        (turno) => `<span>${turno.hora}</span>`
                                      )
                                      .join(", ")}
                                  </td>
                                </tr>
                              `
                              )
                              .join("")}
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  `;
                  return empresaRow;
                })
                .join("");
              listinesList.innerHTML = filteredRows;
            });

            // Expandir o contraer rutas
            document.querySelectorAll(".toggle-rutas").forEach((button) => {
              button.addEventListener("click", () => {
                const index = button.getAttribute("data-index");
                const rutasRow = document.getElementById(`rutas-row-${index}`);
                rutasRow.style.display =
                  rutasRow.style.display === "none" ? "" : "none";
              });
            });
          },
        });
      }
    } catch (error) {
      console.error("Error al obtener las empresas", error);
      MySwal.fire({
        title: "Error",
        html: `<p>Error al obtener las empresas. Por favor, inténtelo de nuevo.</p>`,
        icon: "error",
        confirmButtonText: "Cerrar",
      });
    }
  };

  return (
    <div
      className="container-fluid contarinerGeneralUserHomejs"
      style={{
        display: "flex",
        paddingBottom: "40px",
      }}
    >
      <div className="container asklnduiashndaijsnxansxsaas m-0 p-0">
        <div className="sdfdfgrt54w98e7we9contewe w-100">
          <div
            className="i-fewf5c9ew5ce9wdc1w4c1s sd56f4e8r97g8yrh4be8g9e4rf89er45s4"
            style={{
              marginTop: "30px",
            }}
          ></div>
          <p style={{ fontSize: "13px" }}>
            Agrega tus rutas de cada Empresa de Transporte
          </p>
        </div>
        <div
          className="container asklnduiashndaijsnxansxsaas3"
          style={{ marginBottom: "20px" }}
        >
          <form
            onSubmit={handleSubmit}
            className="formularioDeLogin sd98f4e8wf4cwe987cwe984cwe w-100"
          >
            <div className="aoshduiahsdkasnmdasdas">
              <div className="input-group-login2">
                <input
                  className="inputDeLogin"
                  type="text"
                  name="empresa"
                  onClick={openCompanySelection}
                  required
                  readOnly
                  onChange={handleChange}
                  value={formData.empresa}
                  style={{ textTransform: "uppercase" }}
                />
                <label>Línea de Transporte</label>
              </div>
              <div className="input-group-login2">
                <select
                  className="inputDeLogin"
                  name="numero_cps"
                  onChange={handleChange}
                  value={formData.numero_cps}
                  required
                >
                  <option value="" disabled>
                    Selecciona el número de CPS
                  </option>
                  {cpsOptions.map((cps) => (
                    <option key={cps.id} value={cps.id}>
                      {cps.numero_cps}
                    </option>
                  ))}
                </select>
                <label>Número de CPS</label>
              </div>
              <div className="input-group-login2">
                <input
                  className="inputDeLogin"
                  type="number"
                  name="ruta_n"
                  required
                  onChange={handleChange}
                  placeholder="12"
                  value={formData.ruta_n}
                  style={{ textTransform: "uppercase" }}
                />
                <label>Número de Ruta</label>
              </div>
            </div>
            <div className="aoshduiahsdkasnmdasdas">
              <div className="input-group-login2">
                <textarea
                  className="inputDeLogin"
                  type="text"
                  name="ruta"
                  required
                  placeholder="TERMINAL LA BANDERA, MERIDA"
                  onChange={handleChange}
                  value={formData.ruta}
                  style={{ textTransform: "uppercase" }}
                />
                <label>Ruta</label>
              </div>
            </div>
            <div className="aoshduiahsdkasnmdasdas">
              <div className="aoshduiahsdkasnmdasdas">
                <div className="input-group-login2">
                  <input
                    className="inputDeLogin"
                    type="text"
                    name="destino_final"
                    required
                    placeholder="MERIDA"
                    onChange={handleChange}
                    value={formData.destino_final}
                    style={{ textTransform: "uppercase" }}
                  />
                  <label>Destino Final</label>
                </div>
              </div>
              <div className="input-group-login2">
                <select
                  className="inputDeLogin"
                  name="parada"
                  onChange={handleChange}
                  value={formData.parada}
                  required
                >
                  <option value="" disabled>
                    Selecciona
                  </option>
                  <option value="SALIDA-TERMINAL">
                    SALIDA DESDE EL TERMINAL
                  </option>
                  <option value="TOKEN">TOKEN</option>
                </select>
                <label>Parada</label>
              </div>
              <div className="input-group-login2">
                <select
                  className="inputDeLogin"
                  name="status"
                  onChange={handleChange}
                  value={formData.status}
                  required
                >
                  <option value="" disabled>
                    Selecciona
                  </option>
                  <option value="ACTIVE">ACTIVO</option>
                  <option value="NO_ACTIVE">INACTIVO</option>
                </select>
                <label>Estatus de Ruta</label>
              </div>
            </div>
            <div
              className="aoshduiahsdkasnmdasdas"
              style={{ flexDirection: "column" }}
            >
              {formData.horas.map((hora, index) => (
                <div key={index} className="input-group-login2 d-flex">
                  <input
                    className="inputDeLogin w-80"
                    type="time"
                    value={hora}
                    onChange={(e) => {
                      const updatedHoras = [...formData.horas];
                      updatedHoras[index] = e.target.value;
                      setFormData({ ...formData, horas: updatedHoras });
                    }}
                    required
                  />
                  <button
                    type="button"
                    onClick={() => removeHora(index)}
                    className="btn btn-danger"
                  >
                    Eliminar
                  </button>
                  <label>Hora CPS</label>
                </div>
              ))}
              <button
                type="button"
                onClick={addHora}
                className="btn btn-secondary"
              >
                Añadir Hora CPS (Opcional)
              </button>
            </div>
            <button
              className="buttonCargadeDatosDeLoginregistromovimiento"
              type="submit"
              disabled={isSubmitting}
            >
              {isSubmitting ? "Registrando..." : "Registrar"}{" "}
              <i className="fa-solid fa-arrow-right"></i>
            </button>
          </form>
        </div>
        <div className="w-100 asd9as-da9s84dx9a8qdw-qdw asdqw98d4qv895y4n9r84bvr98ebtnbtn poja65s4d8a9s7d89errg98er7fqwd9849iu9ykj7asj">
          <button
            className="asd98q7wd65q1wd8xqw49x8e4wc"
            onClick={fetchTerminal}
          >
            <i
              className="fa-solid fa-list"
              style={{ marginRight: "7px", fontSize: "20px" }}
            ></i>
            Ver CPS Empresas
          </button>
        </div>
        <div className="d-flex" style={{ marginTop: "12px" }}>
          <p>¿Alguna duda?,</p>
          <Link
            style={{
              marginLeft: "5px",
              textDecoration: "none",
              color: "#24827C",
            }}
            to="/AyudaYSoporte"
          >
            <strong>Contáctanos</strong>
          </Link>
        </div>
      </div>
    </div>
  );
};
