import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { Context } from "../../../store/appContext.js";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFDownloadLink,
} from "@react-pdf/renderer";

const MySwal = withReactContent(Swal);

export const RegistroConductorEmpresas = () => {
  const navigate = useNavigate();
  const { store } = useContext(Context);
  const [formData, setFormData] = useState({
    empresaId: "",
    empresa: "",
    first_name: "",
    second_name: "",
    last_name: "",
    second_last_name: "",
    nacionalidad: "",
    cedula: "",
    fecha_nacimiento: "",
    grado_licencia: "",
    fecha_vencimiento_licencia: "",
    photo_cara_link: "",
    photo_licencia_link: "",
  });
  const [loading, setLoading] = useState({
    photo_cara_link: false,
    photo_licencia_link: false,
  });
  const [isSubmitting, setIsSubmitting] = useState(false); // Nuevo estado para controlar el estado de envío
  const [empresas, setEmpresas] = useState([]); // Nuevo estado para controlar el estado de envío

  const handleImageUpload = async (file, fieldName) => {
    setIsSubmitting(true);
    try {
      const folderPath =
        fieldName === "photo_cara_link"
          ? "TERMINALES/TLB/CONDUCTORES_LINEAS"
          : "TERMINALES/TLB/CONDUCTORES_LICENCIA_LINEAS";
      // Solicitar firma desde el backend
      const response = await axios.post(
        process.env.BACKEND_URL + "/api/generar_firma_cloudinary",
        { folder: folderPath },
        {
          headers: {
            Authorization: "Bearer " + store.token,
          },
        }
      );

      const { signature, timestamp, api_key, cloud_name, folder } =
        response.data;

      // Crear FormData para subir la imagen a Cloudinary
      const formData = new FormData();
      formData.append("file", file);
      formData.append("timestamp", timestamp);
      formData.append("signature", signature);
      formData.append("api_key", api_key);
      formData.append("folder", folder);

      // Subir la imagen directamente a Cloudinary
      const uploadResponse = await axios.post(
        `https://api.cloudinary.com/v1_1/${cloud_name}/image/upload`,
        formData
      );

      const imageUrl = uploadResponse.data.secure_url;

      // Guardar el enlace en el estado del formulario
      setFormData((prev) => ({
        ...prev,
        [fieldName]: imageUrl,
      }));
    } catch (error) {
      console.error("Error al subir la imagen:", error);
    } finally {
      setIsSubmitting(false); // Reactiva el botón después del envío
    }
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    if (files && files[0]) {
      handleImageUpload(files[0], name);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true); // Desactiva el botón

    try {
      await axios.post(
        process.env.BACKEND_URL + "/api/ConductorEmpresasTerminal",
        formData,
        {
          headers: {
            Authorization: "Bearer " + store.token,
          },
        }
      );
      setFormData({
        empresaId: "",
        empresa: "",
        first_name: "",
        second_name: "",
        last_name: "",
        second_last_name: "",
        nacionalidad: "",
        cedula: "",
        fecha_nacimiento: "",
        grado_licencia: "",
        fecha_vencimiento_licencia: "",
        photo_cara_link: "",
        photo_licencia_link: "",
      });
      mostrarAlerta1();
    } catch (error) {
      const errorMessage =
        error.response?.data?.message ||
        "Error desconocido al registrar al usuario";
      mostrarAlerta2(errorMessage);
      console.error("Error al registrar", error);
    } finally {
      setIsSubmitting(false); // Reactiva el botón después del envío
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const mostrarAlerta1 = () => {
    MySwal.fire({
      title: <p>Conductor Registrado con éxito</p>,
      icon: "success",
      position: "center",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
    });
  };

  const mostrarAlerta2 = (errorMessage) => {
    MySwal.fire({
      title: <p>Error al Registrar el Conductor</p>,
      html: <p>{errorMessage}</p>,
      icon: "error",
      position: "center",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
    });
  };

  const fetchEmpresasDisponibles = async () => {
    try {
      const response = await axios.get(
        process.env.BACKEND_URL + "/api/ver_empresas_terminal_solo_cps",
        {
          headers: {
            Authorization: `Bearer ${store.token}`,
          },
        }
      );
      setEmpresas(response.data.empresas_terminal);
    } catch (error) {
      MySwal.fire({
        title: "Error",
        text: "Error al cargar las empresas disponibles.",
        icon: "error",
        confirmButtonText: "Cerrar",
      });
    }
  };

  useEffect(() => {
    fetchEmpresasDisponibles();
  }, []);

  const openCompanySelection = () => {
    MySwal.fire({
      title: (
        <p>
          <i
            className="fa-solid fa-user-tie"
            style={{ marginRight: "7px", fontSize: "20px" }}
          ></i>
          Empresas de Transporte
        </p>
      ),
      html: `
        <input
          type="text"
          id="companySearch"
          placeholder="Buscar empresa"
          class="swal2-input"
          style="width: 100%"
        />
        <div id="companyList" class="company-list">
          ${empresas
            .map(
              (company) => `
            <button 
              class="btn company-option company-list-activation-list" 
              data-id="${company.id}"
              data-name="${company.empresa.nombre}">  <!-- Añadimos data-name con el nombre -->
              ${company.empresa.nombre} <br> ${company.empresa.rif}
            </button>`
            )
            .join("")}
        </div>
      `,
      showConfirmButton: false,
      showCancelButton: true,
      cancelButtonText: "Cerrar",
      didOpen: () => {
        const companySearch = document.getElementById("companySearch");
        const companyList = document.getElementById("companyList");

        // Manejador de filtro para el input de búsqueda
        companySearch.addEventListener("input", (e) => {
          const query = e.target.value.toLowerCase();
          const filteredEmpresas = empresas.filter(
            (company) =>
              company.empresa.nombre.toLowerCase().includes(query) ||
              company.empresa.rif.toLowerCase().includes(query)
          );
          companyList.innerHTML = filteredEmpresas
            .map(
              (company) => `
                <button 
                class="btn company-option company-list-activation-list" 
                data-id="${company.id}"
                data-name="${company.empresa.nombre}">  <!-- Añadimos data-name con el nombre -->
                ${company.empresa.nombre} <br> ${company.empresa.rif}
                </button>`
            )
            .join("");

          // Agregar listeners a cada opción filtrada
          const buttons = companyList.querySelectorAll(".company-option");
          buttons.forEach((button) => {
            button.addEventListener("click", () => {
              const companyId = button.dataset.id;
              const companyName = button.dataset.name; // Usamos el nombre de la empresa
              selectCompany(companyId, companyName); // Pasamos el ID y el nombre
            });
          });
        });

        // Listener para opciones de empresa inicialmente
        const buttons = companyList.querySelectorAll(".company-option");
        buttons.forEach((button) => {
          button.addEventListener("click", () => {
            const companyId = button.dataset.id;
            const companyName = button.dataset.name;
            selectCompany(companyId, companyName); // Pasamos el ID y el nombre
          });
        });
      },
    });
  };

  const selectCompany = (companyId, companyName) => {
    setFormData({
      ...formData,
      empresa: companyName, // Guardamos el nombre de la empresa para mostrarlo en el input
      empresaId: companyId, // Guardamos el ID de la empresa para enviarlo al backend
    });
    MySwal.close(); // Cerrar la ventana de SweetAlert
  };

  return (
    <div
      className="container-fluid contarinerGeneralUserHomejs"
      style={{
        display: "flex",
        paddingBottom: "40px",
      }}
    >
      <div className="container asklnduiashndaijsnxansxsaas m-0 p-0">
        <div className="sdfdfgrt54w98e7we9contewe w-100">
          <div style={{ margin: "0", marginTop: "20px" }}>
            <i
              className="fa-solid seraUnMarinsupre fa-id-card"
              id="icon-tittle-general"
            ></i>
          </div>
          <p style={{ fontSize: "13px" }}>
            Agrega los conductores autorizados por las Empresas de Transporte y
            tu Terminal
          </p>
        </div>
        <div
          className="container asklnduiashndaijsnxansxsaas3"
          style={{ marginBottom: "20px" }}
        >
          <form
            onSubmit={handleSubmit}
            className="formularioDeLogin sd98f4e8wf4cwe987cwe984cwe w-100"
          >
            <div className="aoshduiahsdkasnmdasdas">
              <div className="input-group-login2">
                <input
                  className="inputDeLogin"
                  type="text"
                  name="empresa"
                  onClick={openCompanySelection}
                  required
                  readOnly
                  onChange={handleChange}
                  value={formData.empresa}
                  style={{ textTransform: "uppercase" }}
                />
                <label>Línea de Transporte</label>
              </div>
            </div>
            <div className="aoshduiahsdkasnmdasdas">
              <div className="input-group-login2">
                <input
                  className="inputDeLogin"
                  type="text"
                  name="first_name"
                  required
                  onChange={handleChange}
                  value={formData.first_name}
                  maxLength={50}
                  minLength={2}
                  style={{ textTransform: "uppercase" }}
                />
                <label>Primer Nombre</label>
              </div>
              <div className="input-group-login2">
                <input
                  className="inputDeLogin"
                  type="text"
                  name="second_name"
                  onChange={handleChange}
                  value={formData.second_name}
                  maxLength={50}
                  style={{ textTransform: "uppercase" }}
                />
                <label>Segundo Nombre</label>
              </div>
              <div className="input-group-login2">
                <input
                  className="inputDeLogin"
                  type="text"
                  name="last_name"
                  required
                  maxLength={50}
                  onChange={handleChange}
                  minLength={2}
                  value={formData.last_name}
                  style={{ textTransform: "uppercase" }}
                />
                <label>Primer Apellido</label>
              </div>
            </div>
            <div className="aoshduiahsdkasnmdasdas">
              <div className="input-group-login2">
                <input
                  className="inputDeLogin"
                  type="text"
                  name="second_last_name"
                  maxLength={50}
                  onChange={handleChange}
                  value={formData.second_last_name}
                  style={{ textTransform: "uppercase" }}
                />
                <label>Segundo Apellido</label>
              </div>
              <div className="input-group-login2">
                <select
                  className="inputDeLogin"
                  name="nacionalidad"
                  onChange={handleChange}
                  value={formData.nacionalidad}
                  required
                >
                  <option value="" disabled>
                    Selecciona
                  </option>
                  <option value="" disabled>
                    Selecciona
                  </option>
                  <option value="V">VENEZOLANO</option>
                  <option value="E">EXTRANJERO</option>
                </select>
                <label>Nacionalidad</label>
              </div>
              <div className="input-group-login2">
                <input
                  className="inputDeLogin"
                  type="number"
                  name="cedula"
                  onChange={handleChange}
                  value={formData.cedula}
                  style={{ textTransform: "uppercase" }}
                />
                <label>Cédula</label>
              </div>
            </div>
            <div className="aoshduiahsdkasnmdasdas">
              <div className="input-group-login2">
                <input
                  className="inputDeLogin"
                  type="date"
                  name="fecha_nacimiento"
                  onChange={handleChange}
                  value={formData.fecha_nacimiento}
                  style={{ textTransform: "uppercase" }}
                />
                <label>Fecha de Nacimiento</label>
              </div>
              <div className="input-group-login2">
                <select
                  className="inputDeLogin"
                  name="grado_licencia"
                  onChange={handleChange}
                  value={formData.grado_licencia}
                  required
                >
                  <option value="" disabled>
                    Selecciona
                  </option>
                  <option value="QUINTA">QUINTA(5)</option>
                  <option value="PROFESIONAL">PROFESIONAL</option>
                </select>
                <label>Grado Licencia</label>
              </div>
              <div className="input-group-login2">
                <input
                  className="inputDeLogin"
                  type="date"
                  name="fecha_vencimiento_licencia"
                  onChange={handleChange}
                  value={formData.fecha_vencimiento_licencia}
                  style={{ textTransform: "uppercase" }}
                />
                <label>Fecha Vencimiento Licencia</label>
              </div>
            </div>
            <div className="aoshduiahsdkasnmdasdas">
              <div className="input-group-login2">
                <input
                  type="file"
                  name="photo_cara_link"
                  className="inputDeLogin"
                  accept="image/*"
                  onChange={handleFileChange}
                  disabled={loading.photo_cara_link}
                />
                {loading.photo_cara_link && <p>Subiendo foto de la cara...</p>}
                {formData.photo_cara_link && (
                  <div
                    style={{
                      backgroundImage: `url(${formData.photo_cara_link})`,
                    }}
                    className="photo_cara_link_conductor"
                  ></div>
                )}
                <label>Foto del Conductor</label>
              </div>
              <div className="input-group-login2">
                <input
                  type="file"
                  name="photo_licencia_link"
                  className="inputDeLogin"
                  accept="image/*"
                  onChange={handleFileChange}
                  disabled={loading.photo_licencia_link}
                />
                {loading.photo_licencia_link && (
                  <p>Subiendo foto de la licencia...</p>
                )}
                {formData.photo_licencia_link && (
                  <div
                    style={{
                      backgroundImage: `url(${formData.photo_licencia_link})`,
                    }}
                    className="photo_cara_link_conductor"
                  ></div>
                )}
                <label>Foto de la Licencia</label>
              </div>
            </div>
            <button
              className="buttonCargadeDatosDeLoginregistromovimiento"
              type="submit"
              disabled={isSubmitting}
            >
              {isSubmitting ? "Registrando..." : "Registrar"}{" "}
              <i className="fa-solid fa-arrow-right"></i>
            </button>
          </form>
        </div>
        <div className="d-flex" style={{ marginTop: "12px" }}>
          <p>¿Alguna duda?,</p>
          <Link
            style={{
              marginLeft: "5px",
              textDecoration: "none",
              color: "#24827C",
            }}
            to="/AyudaYSoporte"
          >
            <strong>Contáctanos</strong>
          </Link>
        </div>
      </div>
    </div>
  );
};
