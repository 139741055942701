import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import moment from "moment-timezone"; // Asegúrate de instalar esta librería
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import "../../../../styles/RegistroActivacionesHoy.css";
import { Context } from "../../../store/appContext";

const MySwal = withReactContent(Swal);

const EstatusIcon = {
  ACTIVO: {
    icon: "fa-check",
    name: "ACTIVO",
    color: "linear-gradient(to right, #28a745, #31c353)", // Verde
  },
  ANULADO: {
    icon: "fa-ban",
    name: "CANCELADO",
    color: "linear-gradient(to right, #dc3545, #f24d5e)", // Rojo
  },
  "EN PISTA": {
    icon: "fa-house",
    name: "EN PISTA",
    color: "linear-gradient(to right, #ffc107, #ffd24a)", // Amarillo
  },
  CARGANDO: {
    icon: "fa-person-walking-luggage",
    name: "ABORDANDO",
    color: "linear-gradient(to right, #176ec6, #2a89e8)", // Blanco
  },
  SALIO: {
    icon: "fa-van-shuttle",
    name: "SALIÓ",
    color: "linear-gradient(to right, #6c757d, #969ca2)", // Gris
  },
  RETRASADO: {
    icon: "fa-hourglass-end fa-spin",
    name: "CON RETRASO",
    color: "linear-gradient(to right, #ff6347, #ff856f)", // Rojo Claro
  },
  "POR APROBAR": {
    icon: "fa-spinner fa-spin-pulse",
    name: "POR APROBAR",
    color: "linear-gradient(to right, #ffc107, #ffd24a)", // Amarillo
  },
};

export const MisActivaciones = () => {
  const { store } = useContext(Context);
  const [records, setRecords] = useState([]);
  const [currentTime, setCurrentTime] = useState(moment.tz("America/Caracas"));
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  const filteredRecords = records.filter((record) => {
    const empresa = record.empresa.nombre?.toLowerCase() || "";
    const destinoFinal =
      record.ruta?.destino_final?.destino_final_detalle?.descripcion?.toLowerCase() ||
      "";
    const numeroUnidad = record.numero_de_unidad?.toLowerCase() || "S/I";
    const placa = record.dt_terminal_empresa?.placa?.toLowerCase() || "S/I";

    return (
      empresa.includes(searchTerm) ||
      destinoFinal.includes(searchTerm) ||
      numeroUnidad.includes(searchTerm) ||
      placa.includes(searchTerm)
    );
  });

  const [user, setUser] = useState([]);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(
          `${process.env.BACKEND_URL}/api/protected`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setUser(response.data);
        fetchTodayRecords(response.data.e);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          localStorage.removeItem("token");
          alert("Su sesión ha expirado, por favor vuelva a iniciar sesión");
          window.location.reload();
        } else {
          console.log("Axios error:", error.message);
        }
      }
    };

    fetchUserData();
  }, [store.token]);

  const fetchTodayRecords = async (id) => {
    try {
      const response = await axios.get(
        process.env.BACKEND_URL +
          "/api/empresas/get_activations_last_7_days/" +
          id,
        {
          headers: {
            Authorization: `Bearer ${store.token}`,
          },
        }
      );
      setRecords(response.data.activations);
    } catch (error) {
      console.error("Error al obtener registros de hoy", error);
    } finally {
      setLoading(false); // Cambia a `false` al terminar la carga
    }
  };

  const ViewRoute = (record) => {
    MySwal.fire({
      title: <h6>Ruta de {record.empresa.nombre}</h6>,
      html: <p>{record.ruta.ruta}</p>, // JSX ahora es aceptado con `withReactContent`
      focusConfirm: false,
    });
  };

  const handleChangeStatus = (record) => {
    MySwal.fire({
      title: (
        <h6>
          <i
            className="fa-solid fa-pen-to-square"
            style={{ marginRight: "7px", fontSize: "20px" }}
          ></i>
          Editar Datos
        </h6>
      ),
      html: `
            <div class="input-group-login2"><input style="width: 100%; margin-bottom: 20px" id="swal-numero-de-unidad" class="inputDeLogin" value="${record.numero_de_unidad || ""}"><label>Número Unidad</label></div>
            <div class="input-group-login2"><input style="width: 100%; margin-bottom: 20px; text-transform: uppercase" id="swal-placa" class="inputDeLogin" value="${record.dt_terminal_empresa?.placa || ""}"><label>Placa</label></div>
            <div class="input-group-login2"><input style="width: 100%; margin-bottom: 20px" id="swal-anden" class="inputDeLogin" value="${record.anden || "AÚN NO ASIGNADO"}" readonly><label>Puerta</label></div>
            <div class="input-group-login2">
              <input id="swal-status" class="inputDeLogin" style="width: 100%" value="${record.estatus}" readonly>
            <label>Estatus</label></div>
            `,
      focusConfirm: false,
      showCancelButton: true,
      confirmButtonText: "Actualizar",
      preConfirm: () => {
        const numero_de_unidad = document.getElementById(
          "swal-numero-de-unidad"
        ).value;
        const placa = document.getElementById("swal-placa").value;

        return { numero_de_unidad, placa };
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        const { numero_de_unidad, placa } = result.value;

        try {
          const response = await axios.put(
            `${process.env.BACKEND_URL}/api/activation_empresa/${record.id}/updateStatus`,
            {
              numero_de_unidad,
              placa,
            },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          );

          Swal.fire({
            icon: "success",
            title: response.data.message,
          });

          fetchTodayRecords(user.e);
        } catch (error) {
          Swal.fire({
            icon: "error",
            title: "Error al cambiar el estatus",
            text: error.response?.data?.message || "Error interno",
          });
        }
      }
    });
  };

  const handleDelete = (record) => {
    MySwal.fire({
      title: (
        <h6>
          <i
            className="fa-solid fa-trash"
            style={{ marginRight: "7px", fontSize: "20px" }}
          ></i>
          Eliminar Activación
        </h6>
      ),
      html: `
        <div style="text-align: center; margin-bottom: 20px">
          <i class="fa-solid fa-triangle-exclamation" style="font-size: 50px; color: #ff4d4f"></i>
        </div>
        <p>¿Estás seguro que deseas eliminar esta activación?</p>
        <p><strong>Esta acción no se puede deshacer</strong></p>
        <div style="background: #f0f0f0; padding: 10px; border-radius: 5px; margin-top: 15px">
          <small>
            <strong>Detalles:</strong><br>
            Unidad: ${record.numero_de_unidad || "N/A"}<br>
            Placa: ${record.dt_terminal_empresa?.placa || "N/A"}<br>
            Estatus: ${record.estatus}
          </small>
        </div>
      `,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sí, eliminar",
      cancelButtonText: "Cancelar",
      confirmButtonColor: "#ff4d4f",
      reverseButtons: true,
      focusCancel: true,
      customClass: {
        confirmButton: "swal-delete-button",
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          // Mostrar loader mientras se procesa
          MySwal.fire({
            title: "Eliminando...",
            html: "Por favor espere mientras se procesa la solicitud",
            allowOutsideClick: false,
            didOpen: () => {
              MySwal.showLoading();
            },
          });

          const response = await axios.delete(
            `${process.env.BACKEND_URL}/api/activation_empresa/${record.id}/delete`,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          );

          // Cerrar loader
          MySwal.close();

          // Mostrar confirmación de éxito
          MySwal.fire({
            icon: "success",
            title:
              response.data.message || "Activación eliminada correctamente",
            timer: 2000,
            showConfirmButton: false,
          });

          // Actualizar los datos
          if (typeof fetchTodayRecords === "function") {
            fetchTodayRecords(user.e);
          }
        } catch (error) {
          MySwal.fire({
            icon: "error",
            title: "Error al eliminar",
            text:
              error.response?.data?.message ||
              "Ocurrió un error al intentar eliminar",
            confirmButtonColor: "#1890ff",
          });
        }
      }
    });
  };

  const calculateTimeDiff = (horaSalida, estatus) => {
    const salidaTime = moment.tz(horaSalida, "HH:mm", "America/Caracas");

    const today = currentTime.clone().startOf("day");
    const fullSalidaTime = today.clone().set({
      hour: salidaTime.hour(),
      minute: salidaTime.minute(),
    });

    const diffInMinutes = fullSalidaTime.diff(currentTime, "minutes");

    // Si el tiempo restante es mayor a 0, mostramos el tiempo restante en verde
    if (diffInMinutes > 0) {
      return { time: `${diffInMinutes} min`, color: "green", estatus: estatus };
    } else {
      // Si el tiempo restante es menor o igual a 0, mostramos el tiempo pasado en rojo
      return {
        time: `${Math.abs(diffInMinutes)} min`,
        color: "red",
        estatus: estatus,
      };
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString); // Asegúrate de que `dateString` esté en formato válido
    const day = String(date.getUTCDate()).padStart(2, "0"); // Usa UTC para evitar problemas de zonas horarias
    const month = String(date.getUTCMonth() + 1).padStart(2, "0"); // Meses son 0 indexados
    const year = date.getUTCFullYear();
    return `${day}/${month}/${year}`;
  };

  const ListinAunNo = () => {
    MySwal.fire({
      title: <p>La Activación aun no tiene listín!</p>,
      html: (
        <p>
          Verifica en tiempo real cuando la unidad ya tenga haya retirado el
          Listín.
        </p>
      ),
      icon: "warning",
      position: "center",
      showConfirmButton: false,
      timer: 10000,
      timerProgressBar: true,
    });
  };

  const YaTieneListin = () => {
    MySwal.fire({
      title: <p>Ya la unidad tiene Listín!</p>,
      html: <p>Buen trabajo!</p>,
      icon: "warning",
      position: "center",
      showConfirmButton: false,
      timer: 10000,
      timerProgressBar: true,
    });
  };

  return (
    <div
      className="container-fluid contarinerGeneralUserHomejs"
      style={{ paddingBottom: "40px" }}
    >
      <div className="container asklnduiashndaijsnxansxsaas6548 m-0 p-0">
        <div
          style={{
            textAlign: "center",
            padding: "10px",
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
          className="sdfdfgrt54w98e7we9contewe"
        >
          <div style={{ margin: "20px 0px 0px" }}>
            <i
              className="fa-solid seraUnMarinsupre fa-file-circle-check"
              id="icon-tittle-general"
            ></i>
          </div>
          <p
            style={{ fontSize: "13px", margin: "0" }}
            className="asdjhfnerer4pdeterminal"
          >
            Consulta y Gestiona las siguientes activaciones de hoy a 7 días y
            sus estatus.
          </p>
        </div>
        <div className="df6g54er89gr7t98vw4e8f9e4rwe-fwe65fw4e984dq">
          <div className="input-group-login2">
            <input
              className="inputDeLogin2958"
              type="text"
              required
              onChange={handleSearchChange}
              value={searchTerm}
              style={{ backgroundColor: "white" }}
            />
            <label>Buscar por destino, unidad o placa...</label>
          </div>
        </div>

        {loading ? (
          <div style={{ textAlign: "center", padding: "20px" }}>
            <img
              src="https://res.cloudinary.com/dirpdlfbu/image/upload/v1731494258/1/dvhl0hwbx4adb8uceymr.gif"
              alt="Cargando..."
              style={{ width: "150px" }}
            />
          </div>
        ) : (
          <div className="contianer-fluid p-0 m-0 w-100">
            <div className="sdf5erg8e9r7f8weactivac mobile-er65g48e9r7we984fwe594">
              {filteredRecords
                .sort((a, b) => {
                  const estatusOrdenA =
                    a.estatus === "SALIO" || a.estatus === "ANULADO" ? 1 : 0;
                  const estatusOrdenB =
                    b.estatus === "SALIO" || b.estatus === "ANULADO" ? 1 : 0;

                  if (estatusOrdenA !== estatusOrdenB) {
                    return estatusOrdenA - estatusOrdenB;
                  }

                  const diffA = moment
                    .tz(a.hora_de_salida, "HH:mm", "America/Caracas")
                    .diff(currentTime, "minutes");
                  const diffB = moment
                    .tz(b.hora_de_salida, "HH:mm", "America/Caracas")
                    .diff(currentTime, "minutes");

                  return diffA - diffB;
                })
                .map((record, index) => {
                  const {
                    empresa: { nombre: empresa },
                    dt_terminal_empresa,
                    ruta: {
                      destino_final: {
                        destino_final_detalle: { descripcion: destino_final },
                      },
                    },
                    anden,
                    estatus,
                    hora_de_salida,
                    numero_de_unidad,
                    listin,
                    status_listin,
                    fecha_de_viaje,
                  } = record;

                  // Asegurar valores predeterminados
                  const placaFinal = dt_terminal_empresa?.placa || "S/I"; // Validar si dt_terminal_empresa es null antes de acceder a placa
                  const andenFinal = anden || "S/I";
                  const numeroDeUnidadFinal = numero_de_unidad || "S/I";

                  const {
                    time,
                    color,
                    estatus: updatedStatus,
                  } = calculateTimeDiff(hora_de_salida, estatus);

                  // Si el estatus es "ACTIVO" y faltan 30 minutos o menos, cambiar el estatus a "RETRASADO"
                  const finalStatus =
                    estatus === "ACTIVO" &&
                    moment
                      .tz(hora_de_salida, "HH:mm", "America/Caracas")
                      .diff(currentTime, "minutes") <= 30
                      ? "RETRASADO"
                      : updatedStatus;

                  // Obtener el ícono y color según el estatus final
                  const icono = EstatusIcon[finalStatus];

                  return (
                    <div
                      key={index}
                      className="container asklnduiashndaijsnxansxsaas"
                    >
                      <div
                        className="container asune-rv56er48v9wec-s2 mgjmnh98-sdf9w8e7f9wewefwes"
                        style={{ height: "177px" }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "end",
                          }}
                        >
                          <button
                            className="btn btn-danger d-flex"
                            onClick={() => handleDelete(record)}
                            style={{
                              color: "white",
                              flexDirection: "row",
                              justifyContent: "center",
                              alignItems: "center",
                              width: "3rem",
                            }}
                          >
                            <i
                              className="fa-solid fa-trash"
                              style={{ fontSize: "17px" }}
                            ></i>
                          </button>
                        </div>
                        <div className="container aoshdnuqiwhnd897">
                          <div className="container ds897fq4w9dc9w8c4-we89c7w2 w-100">
                            <p
                              style={{
                                margin: "0",
                                fontSize: "14px",
                              }}
                              id="pderegistros2"
                            >
                              {formatDate(fecha_de_viaje)}
                            </p>
                            <h5 style={{ marginTop: "15px" }}>
                              {destino_final}
                              <i
                                className="fa-solid fa-circle-info"
                                onClick={() => ViewRoute(record)} // Evento para cambiar estatus
                                style={{ marginLeft: "7px", color: "gray" }}
                              ></i>
                            </h5>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              width: "100%",
                              maxWidth: "50px",
                            }}
                          >
                            <div
                              className="container ds9f848dsfwef498we4fwd-57982 w-100"
                              onClick={() => handleChangeStatus(record)} // Evento para cambiar estatus
                            >
                              <div
                                className="sad8qw9d4asas6x4a89s42"
                                style={{ backgroundImage: icono.color }}
                              >
                                <i
                                  className={`fa-solid asd98asdas894xsa ${icono.icon}`}
                                ></i>
                              </div>
                            </div>
                            {/* Renderizar el ícono según las condiciones */}
                            {!listin ? (
                              <div
                                onClick={() => ListinAunNo()}
                                className="btn-warning"
                                style={{
                                  borderRadius: "7px",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  display: "flex",
                                  padding: "5px",
                                  color: "white",
                                }}
                              >
                                <i
                                  className="fa-solid fa-spinner fa-spin"
                                  style={{ fontSize: "14px" }}
                                ></i>
                              </div>
                            ) : status_listin === "APROBADO" ? (
                              <div
                                onClick={() => YaTieneListin()}
                                className="btn-success"
                                style={{
                                  borderRadius: "7px",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  display: "flex",
                                  padding: "5px",
                                  color: "white",
                                }}
                              >
                                <i
                                  className="fa-solid fa-file-circle-check fa-bounce"
                                  style={{ fontSize: "14px" }}
                                ></i>
                              </div>
                            ) : (
                              <div
                                className="btn-danger"
                                style={{
                                  borderRadius: "7px",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  display: "flex",
                                  padding: "5px",
                                  color: "white",
                                }}
                              >
                                <i
                                  className="fa-solid fa-file-circle-xmark fa-bounce"
                                  style={{ fontSize: "14px" }}
                                ></i>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="asdw-w9d84c9w8e4dcd"></div>
                        <div className="container aoshdnuqiwhnd8972">
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              width: "100%",
                            }}
                          >
                            <div
                              style={{
                                width: "33.33%",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <p style={{ margin: "0", color }}>{time}</p>
                            </div>
                            <div
                              style={{
                                width: "33.33%",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                fontSize: "14px",
                                textAlign: "center",
                              }}
                            >
                              <p style={{ margin: "0", textAlign: "center" }}>
                                Puerta: {andenFinal}
                              </p>
                              <p style={{ margin: "0", textAlign: "center" }}>
                                Hora: {hora_de_salida}
                              </p>
                            </div>
                            <div
                              style={{
                                width: "33.33%",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "end",
                                fontSize: "14px",
                              }}
                            >
                              <p style={{ margin: "0", textAlign: "end" }}>
                                Unidad: {numeroDeUnidadFinal}
                              </p>
                              <p style={{ margin: "0", textAlign: "end" }}>
                                {placaFinal}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
            <div className="custom-table-container desktop-er65g48e9r7we984fwe594 m-0">
              <table className="custom-table rounded-table">
                <thead>
                  <tr>
                    <th className="table-header">Fecha De Viaje</th>
                    <th className="table-header">Destino Final</th>
                    <th className="table-header">Contador</th>
                    <th className="table-header">Estatus</th>
                    <th className="table-header">Listín</th>
                    <th className="table-header">Puerta</th>
                    <th className="table-header">Hora</th>
                    <th className="table-header">Unidad</th>
                    <th className="table-header">Placa</th>
                    <th className="table-header">Acciones</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredRecords
                    .sort((a, b) => {
                      const estatusOrdenA =
                        a.estatus === "SALIO" || a.estatus === "ANULADO"
                          ? 1
                          : 0;
                      const estatusOrdenB =
                        b.estatus === "SALIO" || b.estatus === "ANULADO"
                          ? 1
                          : 0;

                      if (estatusOrdenA !== estatusOrdenB) {
                        return estatusOrdenA - estatusOrdenB;
                      }

                      const diffA = moment
                        .tz(a.hora_de_salida, "HH:mm", "America/Caracas")
                        .diff(currentTime, "minutes");
                      const diffB = moment
                        .tz(b.hora_de_salida, "HH:mm", "America/Caracas")
                        .diff(currentTime, "minutes");

                      return diffA - diffB;
                    })
                    .map((record, index) => {
                      const {
                        empresa: { nombre: empresa },
                        dt_terminal_empresa,
                        ruta: {
                          destino_final: {
                            destino_final_detalle: {
                              descripcion: destino_final,
                            },
                          },
                        },
                        anden,
                        estatus,
                        hora_de_salida,
                        numero_de_unidad,
                        listin,
                        status_listin,
                        fecha_de_viaje,
                      } = record;

                      // Asegurar valores predeterminados
                      const placaFinal = dt_terminal_empresa?.placa || "S/I"; // Validar si dt_terminal_empresa es null antes de acceder a placa
                      const andenFinal = anden || "S/I";
                      const numeroDeUnidadFinal = numero_de_unidad || "S/I";
                      const {
                        time,
                        color,
                        estatus: updatedStatus,
                      } = calculateTimeDiff(hora_de_salida, estatus);

                      // Si el estatus es "ACTIVO" y faltan 30 minutos o menos, cambiar el estatus a "RETRASADO"
                      const finalStatus =
                        estatus === "ACTIVO" &&
                        moment
                          .tz(hora_de_salida, "HH:mm", "America/Caracas")
                          .diff(currentTime, "minutes") <= 30
                          ? "RETRASADO"
                          : updatedStatus;

                      // Obtener el ícono y color según el estatus final
                      const icono = EstatusIcon[finalStatus];

                      return (
                        <tr key={record.id} className="table-row">
                          <td className="table-cell">
                            {formatDate(fecha_de_viaje)}
                          </td>
                          <td className="table-cell">{destino_final}</td>
                          <td style={{ color: color }} className="table-cell">
                            {time}
                          </td>
                          <td className="table-cell">
                            <div
                              className="sad8qw9d4asas6x4a89s42"
                              style={{
                                backgroundImage: icono.color,
                                width: "90%",
                                height: "20px",
                              }}
                            >
                              <i
                                className={`fa-solid asd98asdas894xsa ${icono.icon}`}
                                style={{ fontSize: "14px" }}
                              ></i>
                            </div>
                          </td>
                          <td className="table-cell">
                            <div>
                              {/* Renderizar el ícono según las condiciones */}
                              {!listin ? (
                                <div
                                  className="btn-warning"
                                  onClick={() => ListinAunNo()}
                                  style={{
                                    borderRadius: "7px",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    display: "flex",
                                    padding: "5px",
                                    color: "white",
                                  }}
                                >
                                  <i
                                    className="fa-solid fa-spinner fa-spin"
                                    style={{ fontSize: "14px" }}
                                  ></i>
                                </div>
                              ) : status_listin === "APROBADO" ? (
                                <div
                                  className="btn-success"
                                  onClick={() => YaTieneListin()}
                                  style={{
                                    borderRadius: "7px",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    display: "flex",
                                    padding: "5px",
                                    color: "white",
                                  }}
                                >
                                  <i
                                    className="fa-solid fa-file-circle-check fa-bounce"
                                    style={{ fontSize: "14px" }}
                                  ></i>
                                </div>
                              ) : (
                                <div
                                  className="btn-danger"
                                  style={{
                                    borderRadius: "7px",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    display: "flex",
                                    padding: "5px",
                                    color: "white",
                                  }}
                                >
                                  <i
                                    className="fa-solid fa-file-circle-xmark fa-bounce"
                                    style={{ fontSize: "14px" }}
                                  ></i>
                                </div>
                              )}
                            </div>
                          </td>
                          <td className="table-cell">{andenFinal}</td>
                          <td className="table-cell">{hora_de_salida}</td>
                          <td className="table-cell">{numeroDeUnidadFinal}</td>
                          <td className="table-cell">{placaFinal}</td>
                          <td className="table-cell d-flex">
                            <button
                              className="btn btn-secondary d-flex"
                              onClick={() => handleChangeStatus(record)}
                              style={{
                                color: "white",
                                flexDirection: "row",
                                justifyContent: "center",
                                alignItems: "center",
                                marginRight: "7px",
                              }}
                            >
                              <i
                                className="fa-solid fa-circle-info"
                                style={{ fontSize: "17px", marginRight: "7px" }}
                              ></i>
                              <p className="m-0">Editar</p>
                            </button>
                            <button
                              className="btn btn-danger d-flex"
                              onClick={() => handleDelete(record)}
                              style={{
                                color: "white",
                                flexDirection: "row",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <i
                                className="fa-solid fa-trash"
                                style={{ fontSize: "17px" }}
                              ></i>
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
