import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { Context } from "../../../store/appContext.js";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

export const AgregarTurnoHoraTerminal = () => {
  const [formData, setFormData] = useState({ horas: [] });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { store } = useContext(Context);
  const navigate = useNavigate();
  const { ruta_id } = useParams(); // Obtener ruta_id desde la URL

  // Función para obtener las horas actuales
  const getHoras = async () => {
    try {
      const response = await axios.get(
        `${process.env.BACKEND_URL}/api/registro_turnos_get/${ruta_id}`,
        {
          headers: {
            Authorization: "Bearer " + store.token,
          },
        }
      );
      if (response.data && response.data.turno_horas) {
        // Extraer solo las horas (campo 'turno_hora_real') y almacenarlas en el estado
        const horas = response.data.turno_horas.map(
          (turno) => turno.turno_hora_real
        );
        setFormData({ horas: horas });
      }
    } catch (error) {
      console.error("Error al obtener las horas:", error);
    }
  };

  useEffect(() => {
    // Obtener las horas actuales cuando se carga el componente
    getHoras();
  }, [ruta_id, store.token]);

  const addHora = () => {
    setFormData((prevState) => ({
      ...prevState,
      horas: [...prevState.horas, ""],
    }));
  };

  const removeHora = (index) => {
    setFormData((prevState) => ({
      ...prevState,
      horas: prevState.horas.filter((_, i) => i !== index),
    }));
  };

  const mostrarAlerta1 = () => {
    MySwal.fire({
      title: <p>Horas Guardadas con Éxito</p>,
      icon: "success",
      position: "center",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
    });
  };

  const mostrarAlerta2 = (errorMessage) => {
    MySwal.fire({
      title: <p>Error al guardar las horas, por favor vuelve a intentarlo</p>,
      html: <p>{errorMessage}</p>,
      icon: "error",
      position: "center",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      // Si las horas ya existen, se actualizan, si no, se crean
      const response = await axios.post(
        `${process.env.BACKEND_URL}/api/registro_turnos_post/${ruta_id}`,
        { turno_horas: formData.horas },
        {
          headers: {
            Authorization: "Bearer " + store.token,
          },
        }
      );
      mostrarAlerta1();
      navigate("/RegistrosCpsVision");
    } catch (error) {
      mostrarAlerta2("Hubo un error al guardar las horas.");
      console.error("Error:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div
      className="container-fluid contarinerGeneralUserHomejs"
      style={{ display: "flex" }}
    >
      <div className="container asklnduiashndaijsnxansxsaas m-0 p-0">
        <div className="sdfdfgrt54w98e7we9contewe w-100">
          <div
            className="i-fewf5c9ew5ce9wdc1w4c1s sd56f4e8r97g8yrh4be8g9e4rf89er45s4"
            style={{
              marginTop: "30px",
            }}
          ></div>
          <p style={{ fontSize: "13px" }}>
            Agrega y Actualiza las horas de la ruta seleccionada
          </p>
        </div>
        <div
          className="container asklnduiashndaijsnxansxsaas3"
          style={{ marginBottom: "20px" }}
        >
          <form onSubmit={handleSubmit} className="formularioDeLogin w-100">
            {formData.horas.map((hora, index) => (
              <div key={index} className="input-group-login2 d-flex mb-2">
                <input
                  className="inputDeLogin w-80"
                  type="time"
                  value={hora} // Asigna la hora almacenada en el estado
                  onChange={(e) => {
                    const updatedHoras = [...formData.horas];
                    updatedHoras[index] = e.target.value;
                    setFormData({ ...formData, horas: updatedHoras });
                  }}
                  required
                />
                <button
                  type="button"
                  onClick={() => removeHora(index)}
                  className="btn btn-danger"
                >
                  Eliminar
                </button>
              </div>
            ))}
            <button
              type="button"
              onClick={addHora}
              className="btn btn-secondary mb-3"
            >
              Añadir Hora CPS
            </button>
            <button
              type="submit"
              className="buttonCargadeDatosDeLoginregistromovimiento"
              disabled={isSubmitting}
            >
              {isSubmitting ? "Guardando..." : "Guardar"}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};
