import React, { useState, useEffect, useContext, Fragment } from "react";
import axios from "axios";
import { Context } from "../store/appContext";
import "../../styles/CierreListinTerminal.css";
import "../../styles/Consulta.css";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

export const RespuestaConsulta = () => {
  const reservar = () => {
    MySwal.fire({
      title: <p>Próximamente</p>,
      html: (
        <p>
          Reserva directamente desde nuestra página web, crea tu propio usuario,
          lleva el control de tus viajes y mucho más! Por ahora te recomendamos
          colocarte en contacto con tu Empresa de Transporte de Preferencia.
        </p>
      ),
      icon: "warning",
      position: "center",
      showConfirmButton: false,
      timer: 10000,
      timerProgressBar: true,
    });
  };
  return (
    <div
      className="container-fluid contarinerGeneralUserHomejs"
      style={{
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
        padding: 0,
        background: "none",
        minHeight: "100vh",
      }}
    >
      <div
        className="container asklnduiashndaijsnxansxsaas69 m-0 p-0"
        style={{ marginTop: 0, minHeight: "100vh" }}
      >
        <div className="sdfdfgrt54w98e7we9contewe68 w-100">
          <Link
            style={{
              display: "flex",
              justifyContent: "start",
              alignItems: "center",
              width: "90%",
              textDecoration: "none",
            }}
            to="/Consulta"
          >
            <i
              class="fa-solid fa-left-long"
              style={{ color: "white", fontSize: "32px" }}
            ></i>
          </Link>
          <div>
            {/* <div>
              <i
                className="fa-solid seraUnMarinsupre fa-route"
                id="icon-tittle-general"
                style={{ color: "white" }}
              ></i>
            </div> */}
            <img
              src="https://res.cloudinary.com/dqt0d66h7/image/upload/v1739293891/TERMINALES/TLB/FOTOS_TLB/gqejvvof37xwy53idmgl.gif"
              alt="Ruta animada"
              style={{
                width: "136px", // Ajusta el tamaño según sea necesario
                height: "100px",
                objectFit: "cover",
              }}
            />
            <p>Hemos encontrado algunas opciones interesantes para ti...</p>
          </div>
        </div>
        <div className="container" style={{ marginTop: "20px" }}>
          <div className="container-viajes-usuarios">
            <div className="container-viajes-usuarios-horas">
              <div className="container-viajes-usuarios-horas-mini-1">
                <h1 className="viaje-hora-usuario-public">12:00</h1>
                <p className="viaje-desde-public-usuario">CSS</p>
                <p className="viaje-descripcion-public-usuario m-0">
                  Salida desde el Terminal La Bandera
                </p>
              </div>
              <div className="container-viajes-usuarios-horas-mini-2">
                <i
                  className="fa-solid fa-bus fa-bounce"
                  style={{ color: "#24827c", fontSize: "32px" }}
                ></i>
                <div className="container-desde-hasta-puntos">
                  <div className="container-desde-hasta-punto-1"></div>
                  <hr style={{ width: "100%", border: "2px" }}></hr>{" "}
                  <div className="container-desde-hasta-punto-1"></div>
                </div>
              </div>
              <div className="container-viajes-usuarios-horas-mini-3">
                <h1
                  className="viaje-hora-usuario-public"
                  style={{ textAlign: "end" }}
                >
                  20:00
                </h1>
                <p
                  className="viaje-desde-public-usuario"
                  style={{ textAlign: "end" }}
                >
                  MRD
                </p>
                <p
                  className="viaje-descripcion-public-usuario m-0"
                  style={{ textAlign: "end" }}
                >
                  Llegada Terminal de Mérida Jose...
                </p>
              </div>
            </div>
            <div className="container-info-empresa-viaje">
              <h3 style={{ color: "gray" }}>Expreso Mérida, C.A.</h3>
              <div className="container-info-empresa-detalle-viaje">
                <button
                  className="btn btn-info"
                  style={{ fontSize: "14px", color: "white" }}
                >
                  <i className="fa-solid fa-phone-volume"></i>
                </button>
                <button
                  className="btn btn-secondary"
                  onClick={() => reservar()}
                  style={{
                    fontSize: "14px",
                    color: "white",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  RESERVAR{" "}
                  <i
                    className="fa-solid fa-chevron-right"
                    style={{ marginLeft: "7px" }}
                  ></i>
                </button>
              </div>
            </div>
          </div>
        </div>

        <hr style={{ width: "90%" }}></hr>

        <div className="d-flex" style={{ marginTop: "10px" }}>
          <Link
            style={{
              marginLeft: "5px",
              textDecoration: "none",
              color: "#24827C",
            }}
            to="/Terminal"
          >
            <strong>Terminal en Vivo</strong>
          </Link>
        </div>

        <div className="d-flex" style={{ marginTop: "20px" }}>
          <p>¿Alguna duda?,</p>
          <Link
            style={{
              marginLeft: "5px",
              textDecoration: "none",
              color: "#24827C",
            }}
            to="/AyudaYSoporte"
          >
            <strong>Contáctanos</strong>
          </Link>
        </div>
      </div>
    </div>
  );
};
