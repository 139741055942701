import React, { useContext, useState, useEffect } from "react";
import axios from "axios";
import { Context } from "../../../store/appContext";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import * as XLSX from "xlsx";

export const ReporteExcelEmpresas = () => {
  const { store } = useContext(Context);
  const [fechaInicio, setFechaInicio] = useState("");
  const [fechaFinal, setFechaFinal] = useState("");
  const [reportes, setReportes] = useState(null);
  const [reporte, setReporte] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const descargarExcel = () => {
    if (!reportes || reportes.length === 0) {
      Swal.fire("Error", "No hay datos para exportar", "error");
      return;
    }

    // Crear una hoja de cálculo a partir de los datos
    const ws = XLSX.utils.json_to_sheet(reportes);

    // Crear un libro de trabajo
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Reporte");

    // Generar el archivo Excel y descargarlo
    XLSX.writeFile(wb, "Reporte_Empresas.xlsx");
  };

  const generarReporte = async () => {
    setIsSubmitting(true);
    try {
      const response = await axios.get(
        process.env.BACKEND_URL + "/api/ResumenEmpresasTerminal",
        {
          headers: { Authorization: "Bearer " + store.token },
        }
      );

      Swal.fire("Éxito", "Reporte generado exitosamente", "success");
      setReportes(response.data);
    } catch (error) {
      Swal.fire(
        "Error",
        error.response?.data?.message || "Error interno",
        "error"
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div
      className="container-fluid contarinerGeneralUserHomejs"
      style={{
        display: "flex",
        paddingBottom: "40px",
      }}
    >
      <div className="container asklnduiashndaijsnxansxsaas m-0 p-0">
        <div className="sdfdfgrt54w98e7we9contewe w-100">
          <div style={{ margin: "0", marginTop: "20px" }}>
            <i
              className="fa-solid seraUnMarinsupre fa-chart-simple"
              id="icon-tittle-general"
            ></i>
          </div>
          <p>Reporte Entre Fechas</p>
        </div>
        <div className="formularioDeLogin sd98f4e8wf4cwe987cwe984cwe w-100">
          <div
            className="container asklnduiashndaijsnxansxsaas3"
            style={{ marginBottom: "20px" }}
          >
            <div className="aoshduiahsdkasnmdasdas">
              <div className="input-group-login2">
                <input
                  className="inputDeLogin"
                  type="date"
                  required
                  value={fechaInicio}
                  onChange={(e) => setFechaInicio(e.target.value)}
                />
                <label>Fecha Inicio:</label>
              </div>
              <div className="input-group-login2">
                <input
                  className="inputDeLogin"
                  type="date"
                  required
                  value={fechaFinal}
                  onChange={(e) => setFechaFinal(e.target.value)}
                />
                <label>Fecha Final:</label>
              </div>
            </div>
            <button
              className="buttonCargadeDatosDeLoginregistromovimiento"
              onClick={generarReporte}
              disabled={isSubmitting}
              style={{ width: "200px" }}
            >
              {isSubmitting ? "Generando..." : "Generar Reporte"}{" "}
              <i className="fa-solid fa-arrow-right"></i>
            </button>
          </div>
        </div>

        <div className="w-100 asd9as-da9s84dx9a8qdw-qdw asdqw98d4qv895y4n9r84bvr98ebtnbtn poja65s4d8a9s7d89errg98er7fqwd9849iu9ykj7asj">
          {/* <button
            className="asd98q7wd65q1wd8xqw49x8e4wc"
            onClick={() => showRecordsPdfDownload(reporte)}
          >
            <i
              className="fa-solid fa-file-pdf"
              style={{ marginRight: "7px", fontSize: "20px" }}
            ></i>
            Descargar Reporte
          </button> */}
          <button
            className="asd98q7wd65q1wd8xqw49x8e4wc"
            onClick={descargarExcel}
          >
            <i
              className="fa-solid fa-file-excel"
              style={{ marginRight: "7px", fontSize: "20px" }}
            ></i>
            Descargar Excel
          </button>
        </div>
        <div className="d-flex" style={{ marginTop: "12px" }}>
          <p>¿Alguna duda?,</p>
          <Link
            style={{
              marginLeft: "5px",
              textDecoration: "none",
              color: "#24827C",
            }}
            to="/AyudaYSoporte"
          >
            <strong>Contáctanos</strong>
          </Link>
        </div>
        <hr style={{ width: "90%" }}></hr>
      </div>
    </div>
  );
};
