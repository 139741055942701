import React, { useContext, useState, useEffect } from "react";
import { Context } from "../../store/appContext";
import { Link, Navigate, useLocation } from "react-router-dom";
import "../../../styles/companyAdminNavbarSuperior.css";
import axios from "axios";
import { motion } from "framer-motion";

export const Navbar_sup = () => {
  const { store, actions } = useContext(Context);
  const [menuOpen, setMenuOpen] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState(null);
  const [currentDateTime, setCurrentDateTime] = useState(new Date());
  const [user, setUser] = useState({
    firstName: "",
    lastName: "",
    greeting: "",
  });
  const location = useLocation();

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentDateTime(new Date());
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  const formattedTime = currentDateTime.toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  });
  const formattedDate = currentDateTime.toLocaleDateString();

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const token = localStorage.getItem("token");
        const response = await axios.get(
          `${process.env.BACKEND_URL}/api/protected`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setUser({
          firstName: response.data.first_name,
          lastName: response.data.last_name,
          greeting: response.data.greeting,
        });
      } catch (error) {
        if (error.response && error.response.status === 401) {
          localStorage.removeItem("token");
          alert("Su sesión ha expirado, por favor vuelva a iniciar sesión");
          window.location.reload();
        } else {
          console.log("Axios error:", error.message);
        }
      }
    };

    fetchUserData();
  }, [store.token]);

  const handleClick = (menu) => {
    setSelectedMenu(menu);
  };

  const sidebarVariants = {
    open: {
      opacity: 1,
      x: 0,
      transition: {
        opacity: { duration: 0.3 },
        x: { type: "ease", stiffness: 100 },
      },
    },
    closed: {
      opacity: 0,
      x: "-100%",
      transition: {
        opacity: { duration: 0.3 },
        x: { type: "ease", stiffness: 100 },
      },
    },
  };

  useEffect(() => {
    const pathname = location.pathname;

    const menuMapping = {
      "": {
        icon: "fa-globe",
        name: "Inicio",
        text: "Explora tus estadísticas y mucho más",
      },
      "/": {
        icon: "fa-globe",
        name: "Inicio",
        text: "Explora tus estadísticas y mucho más",
      },
      "/RegistroCps": {
        icon: "fa-file-circle-plus",
        name: "Registro CPS",
        text: "Registra tus CPS aquí",
      },
      "/EmpresasDeTransporte": {
        icon: "fa-user-tie",
        name: "Empresas de Transporte",
        text: "Gestiona las empresas de transporte",
      },
      "/Configuraciones": {
        icon: "fa-bus",
        name: "Configuraciones",
        text: "Configura tu experiencia",
      },
      "/RegistroUsuarios": {
        icon: "fa-users",
        name: "Registro Usuarios Terminal",
        text: "Gestiona los usuarios del Terminal.",
      },
      "/RegistroUsuariosEmpresa": {
        icon: "fa-people-group",
        name: "Registro Usuarios",
        text: "Gestiona los usuarios de las Empresas Privadas.",
      },
      "/Destinos": {
        icon: "fa-location-dot",
        name: "Destinos",
        text: "Gestiona los destinos",
      },
      "/Programacion": {
        icon: "fa-calendar-check",
        name: "Programación",
        text: "Gestiona la programación",
      },
      "/ActivacionParaBillBoard": {
        icon: "fa-bullhorn",
        name: "Terminal en Vivo",
        text: "Gestiona y Registra tus activaciones, y disfruta de una administración con tu Terminal en Vivo",
      },
      "/ActivacionCalendario": {
        icon: "fa-calendar-days",
        name: "Calendario de Salidas",
        text: "Gestiona el calendario de salidas",
      },
      "/PrecioDestinoFinalRL": {
        icon: "fa-tags",
        name: "Rutas Largas Precios",
        text: "Lleva el control del precio de la cobranza por Destino Final",
      },
      "/PrecioDestinoFinalRC": {
        icon: "fa-tags",
        name: "Rutas Cortas Precios",
        text: "Lleva el control del precio de la cobranza por Destino Final",
      },
      "/KmDistancia": {
        icon: "fa-route",
        name: "Destino Final Km",
        text: "Establece a cuantos km de distancia está cada destino",
      },
      "/Autorizacionporpuesto": {
        icon: "fa-road-circle-check",
        name: "Autorización Por Puesto",
        text: "Autoriza Rutas a Salir como Rutas cortas Por Puesto",
      },
      "/RegistrosCpsEdit": {
        icon: "fa-list-check",
        name: "Listado de CPS",
        text: "Aquí podrás visualizas todas las CPS que has registrado hasta el momento, pudiedo así modificar estatus",
      },
      "/CierresRutasCortas": {
        icon: "fa-bus",
        name: "Cierres Rutas Cortas",
        text: "Verifica y controla los cierres de todos tus taquilleros de Rutas Cortas",
      },
      "/RegistroActivacionesHoy": {
        icon: "fa-file-circle-check",
        name: "Activaciones de Hoy",
        text: "Cambia el Estatus de tus activaciones, a tiempo para el Cartel en Vivo",
      },
      "/ActivacionesHoyNuevo": {
        icon: "fa-file-circle-check",
        name: "Activaciones de Hoy Nuevo",
        text: "Cambia el Estatus de tus activaciones, a tiempo para el Cartel en Vivo",
      },
      "/AyudaYSoporte": {
        icon: "fa-headset",
        name: "Ayuda Y Soporte",
        text: "Siéntete seguro de comunicarte con nosotros si te surge alguna duda",
      },
      "/EmpresaTerminal": {
        icon: "fa-user-tie",
        name: "Empresas Terminal",
        text: "Aquí seleccionarás y asignarás una taquilla a las empresas de transporte.",
      },
      "/RegistrosCpsVision": {
        icon: "fa-list-check",
        name: "Listado Rutas Empresas",
        text: "Aquí podrás visualizas todas las Rutas, pudiendo así modificar el estatus de cada una.",
      },
      "/DestinoFinal": {
        icon: "fa-route",
        name: "Destino Final",
        text: "Registra a que distancia está cada destino final de tu Terminal, para mejor control de distancias.",
      },
      "/DTplacaTerminalEmpresa": {
        icon: "fa-bus",
        name: "DT Empresas",
        text: "Registra aquí, unicamente las placas que están en la DT de la Empresa de Transporte.",
      },
      "/RegistroContingenciaRuta": {
        icon: "fa-triangle-exclamation",
        name: "Registro Ruta Contingencia",
        text: "En caso de contingencia, autorizar ruta que no estén en la CPS.",
      },
      "/DTContingenciaPlaca": {
        icon: "fa-triangle-exclamation",
        name: "DT Contingencia Empresa",
        text: "En caso de contingencia, autorizar Placa que no esté en la DT.",
      },
      "/RegistroConductorEmpresa": {
        icon: "fa-id-card",
        name: "Registro Conductor Empresa",
        text: "Registra los conductores de cada una de las empresas.",
      },
      "/ActivacionNuevo": {
        icon: "fa-bullhorn",
        name: "Registro de Activación",
        text: "Gestiona y Registra las activaciones de tu Terminal con ayuda de esta pantalla.",
      },
      "/ReporteEntreFechas": {
        icon: "fa-chart-simple",
        name: "Reporte Entre Fechas",
        text: "Visualiza los datos entre las fechas indicadas en un PDF.",
      },
      "/DestinoRetorno": {
        icon: "fa-route",
        name: "Destino Retorno",
        text: "Registra los destinos que no veas en la lista.",
      },
      "/EmpresaRetorno": {
        icon: "fa-user-tie",
        name: "Empresa Retorno",
        text: "Registra las empresas que no veas en la lista.",
      },
      "/RegistroRetornosEst": {
        icon: "fa-rotate-left",
        name: "Retorno",
        text: "Registra los buses que descarguen dentro del terminal, sea parada o token.",
      },
      "/ReporteDiarioActivaciones": {
        icon: "fa-file-invoice",
        name: "Reporte Diario Activaciones",
        text: "Visualiza de manera óptima el rendimineto del Terminal en un solo Reporte.",
      },
      "/Plantillas": {
        icon: "fa-file-zipper",
        name: "Plantillas",
        text: "Personaliza alguna plantilla a tu gusto, y guardala para enviarla cuando quieras, a quien quieras.",
      },
      "/edit-template": {
        icon: "fa-wand-magic-sparkles",
        name: "Editar Plantilla",
        text: "Hora de inspirarte, si necesitas ayuda no dudes en comunicarte con nosotros, y crearemos una plantilla a tu gusto.",
      },
      "/VistaRetornosAgregados": {
        icon: "fa-rectangle-list",
        name: "Retornos Agregados",
        text: "Visualiza los retornos agregados entre las fechas escogidas, elimina, edita y mucho más.",
      },
      "/ActivacionesPendientesAprobar": {
        icon: "fa-clock",
        name: "Solicitud de Activación",
        text: "Gestiona las diferentes solicitudes de Acticación, de la Empresas de Transporte de tu Terminal.",
      },
      "/RegistroPasajeroIntravialca": {
        icon: "fa-person-circle-plus",
        name: "Registro Pasajero",
        text: "Registra el pasajero que abordará proximamente la activación escogida.",
      },
    };

    const menu = menuMapping[pathname] || menuMapping["/"];
    if (menu) {
      setSelectedMenu(menu);
    }
  }, [location.pathname]);

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("userData");
    window.location.reload();
  };

  const handleMenuToggle = () => {
    setMenuOpen(!menuOpen);
  };

  const [isOpen, setIsOpen] = useState(true);
  const [isOpen1, setIsOpen1] = useState(true);
  const [isOpen2, setIsOpen2] = useState(true);
  const [isOpen3, setIsOpen3] = useState(true);
  const [isOpen4, setIsOpen4] = useState(true);

  return (
    <div className="containerCompanyAdminNavbarSuperior">
      <div className="s56d4as897jk9hy7t98q4wf9r8gt7rwe98">
        <div>
          <div className="a65s48t7yrt977675695847f983273">
            <div className="s56d4asd6897asd987ff98e79w8e7fwe98icon23423">
              <i className={`fa-solid ${selectedMenu?.icon}`}></i>
            </div>
            <div className="d6f5s4df89sd798er7wf98we7fwe98d4qw64">
              <div className="q6oui4uyt89red7we987e9ty8u7tyrt9ere8d7c98">
                {selectedMenu?.name}
              </div>
              <div className="sdf65sd4f89t7r9er8we7w98qe7r9r8e7wqijm">
                {selectedMenu?.text}
              </div>
            </div>
          </div>

          <i
            className="fa-solid fa-bars qpwjaisodnasdasdalsdaspqowm"
            onClick={handleMenuToggle}
          ></i>
        </div>
        <div className="d-flex justify-content-center align-items-center">
          <div>
            <p
              className="pNavbarSuperior"
              style={{
                display: "flex",
                justifyContent: "end",
                color: "rgb(33, 33, 39)",
                textAlign: "end",
              }}
            >
              Buenas, {user.firstName}
            </p>
            <p
              className="pNavbarSuperior"
              style={{
                display: "flex",
                justifyContent: "end",
                color: "rgb(33, 33, 39)",
                textAlign: "end",
              }}
            >
              Bienvenido
            </p>
          </div>

          <div
            style={{
              width: "40px",
              height: "40px",
              border: "solid 0.1px #006AA1",
              borderRadius: "50%",
              marginLeft: "20px",
              backgroundSize: "130%",
              backgroundPosition: "center",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: "22px",
              backgroundColor: "#22C4B940",
            }}
          >
            {user?.firstName?.charAt()}.
          </div>
        </div>
        {menuOpen && (
          <div className={`menu-overlay ${menuOpen ? "menu-open" : ""}`}>
            <div
              className={`menu-content ${menuOpen ? "menu-content-open" : ""}`}
            >
              <div className="as56d4s9f87er98fwe4fwr987fwee98d4qw987">
                <img
                  src="https://res.cloudinary.com/dirpdlfbu/image/upload/v1713119110/INTRAVIALCA/truxqodtlg1vzishxhot.png"
                  alt="Logo"
                  style={{ height: "45px" }}
                  className="d65f4sd9f8w7ef98we7qw654er987fw"
                />
                <div onClick={handleMenuToggle}>
                  <i
                    style={{ fontSize: "30px", color: "white" }}
                    className="fa-regular fa-circle-xmark icon-98716598"
                  ></i>
                </div>
              </div>
              <div className="asdw-w9d84c9w8e4dcd2"></div>
              <div className="louqjwnedjsdas">
                <div className="sdsdf98u7yt9htg7wfqe987879467434">
                  <div onClick={handleMenuToggle}>
                    <Link
                      className={`subtitulosMenuUserHomeCompany ${
                        selectedMenu?.name === "Inicio" ? "selected-menu" : ""
                      }`}
                      to="/"
                      onClick={() => handleClick("/")}
                    >
                      <i
                        className="fa-solid seraUnMarinsupre fa-globe"
                        style={{ fontSize: "26px" }}
                      ></i>
                      <div
                        className="LetterInvisible2"
                        style={{ fontSize: "16px" }}
                      >
                        Inicio
                      </div>
                    </Link>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      background: "rgba(255, 255, 255, 0.481)",
                      height: ".07px",
                      margin: "4px 0 4px 0",
                    }}
                  ></div>
                  <div className="menu-container">
                    <fieldset
                      className={`menu-fieldset ${isOpen ? "open" : ""}`}
                    >
                      <legend
                        onClick={() => setIsOpen(!isOpen)}
                        className={`subtitulosMenuUserHomeCompany2 menu-legend m-0`}
                        style={{
                          textAlign: "center",
                          display: "flex",
                          justifyContent: "center",
                          flexDirection: "row",
                          fontSize: "12px",
                          padding: "15px 10px 10px 24px",
                          cursor: "pointer",
                        }}
                      >
                        <i
                          style={{
                            marginRight: "10px",
                            alignItems: "center",
                            display: "flex",
                          }}
                          className="fa-solid fa-bolt-lightning"
                        ></i>
                        EMPRESAS{" "}
                        <i
                          style={{ marginLeft: "auto" }}
                          className="fa-solid fa-chevron-down"
                        ></i>
                      </legend>
                      {isOpen && (
                        <div className="menu-links" onClick={handleMenuToggle}>
                          <Link
                            className={`subtitulosMenuUserHomeCompany ${
                              selectedMenu?.name === "Empresas Terminal"
                                ? "selected-menu"
                                : ""
                            }`}
                            onClick={() => handleClick("EmpresaTerminal")}
                            to="/EmpresaTerminal"
                          >
                            <i
                              className="fa-solid seraUnMarinsupre fa-user-tie"
                              style={{ fontSize: "26px" }}
                            ></i>
                            <div
                              className="LetterInvisible2"
                              style={{ fontSize: "16px" }}
                            >
                              Empresas Terminal
                            </div>
                          </Link>
                          <Link
                            className={`subtitulosMenuUserHomeCompany ${
                              selectedMenu?.name === "Registro Pasajero"
                                ? "selected-menu"
                                : ""
                            }`}
                            onClick={() =>
                              handleClick("RegistroPasajeroIntravialca")
                            }
                            to="/RegistroPasajeroIntravialca"
                          >
                            <i
                              className="fa-solid seraUnMarinsupre fa-person-walking-luggage"
                              style={{ fontSize: "26px" }}
                            ></i>
                            <div
                              className="LetterInvisible2"
                              style={{ fontSize: "16px" }}
                            >
                              Registro Pasajero (Cortesía)
                            </div>
                          </Link>
                          <Link
                            className={`subtitulosMenuUserHomeCompany ${
                              selectedMenu?.name === "Registro CPS"
                                ? "selected-menu"
                                : ""
                            }`}
                            onClick={() => handleClick("RegistroCps")}
                            to="/RegistroCps"
                          >
                            <i
                              className="fa-solid seraUnMarinsupre fa-file-circle-plus"
                              style={{ fontSize: "26px" }}
                            ></i>
                            <div
                              className="LetterInvisible2"
                              style={{ fontSize: "16px" }}
                            >
                              Registro Ruta CPS
                            </div>
                          </Link>
                          <Link
                            className={`subtitulosMenuUserHomeCompany ${
                              selectedMenu?.name ===
                              "Registro Ruta Contingencia"
                                ? "selected-menu"
                                : ""
                            }`}
                            onClick={() =>
                              handleClick("RegistroContingenciaRuta")
                            }
                            to="/RegistroContingenciaRuta"
                          >
                            <i
                              className="fa-solid seraUnMarinsupre fa-triangle-exclamation"
                              style={{ fontSize: "26px" }}
                            ></i>
                            <div
                              className="LetterInvisible2"
                              style={{ fontSize: "16px" }}
                            >
                              Registro Ruta Contingencia
                            </div>
                          </Link>
                          <Link
                            className={`subtitulosMenuUserHomeCompany ${
                              selectedMenu?.name === "Listado Rutas Empresas"
                                ? "selected-menu"
                                : ""
                            }`}
                            onClick={() => handleClick("RegistrosCpsVision")}
                            to="/RegistrosCpsVision"
                          >
                            <i
                              className="fa-solid seraUnMarinsupre fa-list-check"
                              style={{ fontSize: "26px" }}
                            ></i>
                            <div
                              className="LetterInvisible2"
                              style={{ fontSize: "16px" }}
                            >
                              Listado Rutas Empresas
                            </div>
                          </Link>
                          <Link
                            className={`subtitulosMenuUserHomeCompany ${
                              selectedMenu?.name === "DT Empresas"
                                ? "selected-menu"
                                : ""
                            }`}
                            onClick={() =>
                              handleClick("DTplacaTerminalEmpresa")
                            }
                            to="/DTplacaTerminalEmpresa"
                          >
                            <i
                              className="fa-solid seraUnMarinsupre fa-bus"
                              style={{ fontSize: "26px" }}
                            ></i>
                            <div
                              className="LetterInvisible2"
                              style={{ fontSize: "16px" }}
                            >
                              DT Empresas
                            </div>
                          </Link>
                          <Link
                            className={`subtitulosMenuUserHomeCompany ${
                              selectedMenu?.name === "DT Contingencia Empresa"
                                ? "selected-menu"
                                : ""
                            }`}
                            onClick={() => handleClick("DTContingenciaPlaca")}
                            to="/DTContingenciaPlaca"
                          >
                            <i
                              className="fa-solid seraUnMarinsupre fa-triangle-exclamation"
                              style={{ fontSize: "26px" }}
                            ></i>
                            <div
                              className="LetterInvisible2"
                              style={{ fontSize: "16px" }}
                            >
                              DT Contingencia Empresa
                            </div>
                          </Link>
                          <Link
                            className={`subtitulosMenuUserHomeCompany ${
                              selectedMenu?.name ===
                              "Registro Conductor Empresa"
                                ? "selected-menu"
                                : ""
                            }`}
                            onClick={() =>
                              handleClick("RegistroConductorEmpresa")
                            }
                            to="/RegistroConductorEmpresa"
                          >
                            <i
                              className="fa-solid seraUnMarinsupre fa-id-card"
                              style={{ fontSize: "26px" }}
                            ></i>
                            <div
                              className="LetterInvisible2"
                              style={{ fontSize: "16px" }}
                            >
                              Registro Conductor Empresa
                            </div>
                          </Link>
                          <div onClick={handleMenuToggle}>
                            <Link
                              className={`subtitulosMenuUserHomeCompany ${
                                selectedMenu?.name === "Destino Final"
                                  ? "selected-menu"
                                  : ""
                              }`}
                              onClick={() => handleClick("DestinoFinal")}
                              to="/DestinoFinal"
                            >
                              <i
                                className="fa-solid seraUnMarinsupre fa-route"
                                style={{ fontSize: "26px" }}
                              ></i>
                              <div
                                className="LetterInvisible2"
                                style={{ fontSize: "16px" }}
                              >
                                Destino Final
                              </div>
                            </Link>
                          </div>
                        </div>
                      )}
                    </fieldset>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      background: "rgba(255, 255, 255, 0.481)",
                      height: ".07px",
                      margin: "4px 0 4px 0",
                    }}
                  ></div>
                  <div className="menu-container">
                    <fieldset
                      className={`menu-fieldset ${isOpen1 ? "open" : ""}`}
                    >
                      <legend
                        onClick={() => setIsOpen1(!isOpen1)}
                        className={`subtitulosMenuUserHomeCompany2 menu-legend m-0`}
                        style={{
                          textAlign: "center",
                          display: "flex",
                          justifyContent: "center",
                          flexDirection: "row",
                          fontSize: "12px",
                          padding: "15px 10px 10px 24px",
                          cursor: "pointer",
                        }}
                      >
                        <i
                          style={{
                            marginRight: "10px",
                            alignItems: "center",
                            display: "flex",
                          }}
                          className="fa-solid fa-plus"
                        ></i>
                        ACTIVACIONES
                        <i
                          style={{ marginLeft: "auto" }}
                          className="fa-solid fa-chevron-down"
                        ></i>
                      </legend>
                      {isOpen1 && (
                        <div className="menu-links" onClick={handleMenuToggle}>
                          <Link
                            className={`subtitulosMenuUserHomeCompany ${
                              selectedMenu?.name === "Registro de Activación"
                                ? "selected-menu"
                                : ""
                            }`}
                            onClick={() => handleClick("ActivacionNuevo")}
                            to="/ActivacionNuevo"
                          >
                            <i
                              className="fa-solid seraUnMarinsupre fa-bullhorn"
                              style={{ fontSize: "26px" }}
                            ></i>
                            <div
                              className="LetterInvisible2"
                              style={{ fontSize: "16px" }}
                            >
                              Registro Activación
                            </div>
                          </Link>
                          <Link
                            className={`subtitulosMenuUserHomeCompany ${
                              selectedMenu?.name === "Activaciones de Hoy Nuevo"
                                ? "selected-menu"
                                : ""
                            }`}
                            onClick={() => handleClick("ActivacionesHoyNuevo")}
                            to="/ActivacionesHoyNuevo"
                          >
                            <i
                              className="fa-solid seraUnMarinsupre fa-file-circle-check"
                              style={{ fontSize: "26px" }}
                            ></i>
                            <div
                              className="LetterInvisible2"
                              style={{ fontSize: "16px" }}
                            >
                              Activaciones Hoy
                            </div>
                          </Link>
                          <Link
                            className={`subtitulosMenuUserHomeCompany ${
                              selectedMenu?.name === "Solicitud de Activación"
                                ? "selected-menu"
                                : ""
                            }`}
                            onClick={() =>
                              handleClick("ActivacionesPendientesAprobar")
                            }
                            to="/ActivacionesPendientesAprobar"
                          >
                            <i
                              className="fa-solid seraUnMarinsupre fa-clock"
                              style={{ fontSize: "26px" }}
                            ></i>
                            <div
                              className="LetterInvisible2"
                              style={{ fontSize: "16px" }}
                            >
                              Solicitud de Activación
                            </div>
                          </Link>
                          <Link
                            className={`subtitulosMenuUserHomeCompany ${
                              selectedMenu?.name ===
                              "Reporte Diario Activaciones"
                                ? "selected-menu"
                                : ""
                            }`}
                            onClick={() =>
                              handleClick("ReporteDiarioActivaciones")
                            }
                            to="/ReporteDiarioActivaciones"
                          >
                            <i
                              className="fa-solid seraUnMarinsupre fa-file-invoice"
                              style={{ fontSize: "26px" }}
                            ></i>
                            <div
                              className="LetterInvisible2"
                              style={{ fontSize: "16px" }}
                            >
                              Reporte Diario
                            </div>
                          </Link>
                        </div>
                      )}
                    </fieldset>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      background: "rgba(255, 255, 255, 0.481)",
                      height: ".07px",
                      margin: "4px 0 4px 0",
                    }}
                  ></div>

                  <div className="menu-container">
                    <fieldset
                      className={`menu-fieldset ${isOpen3 ? "open" : ""}`}
                    >
                      <legend
                        onClick={() => setIsOpen3(!isOpen3)}
                        className={`subtitulosMenuUserHomeCompany2 menu-legend m-0`}
                        style={{
                          textAlign: "center",
                          display: "flex",
                          justifyContent: "center",
                          flexDirection: "row",
                          fontSize: "12px",
                          padding: "15px 10px 10px 24px",
                          cursor: "pointer",
                        }}
                      >
                        <i
                          style={{
                            marginRight: "10px",
                            alignItems: "center",
                            display: "flex",
                          }}
                          className="fa-solid fa-chart-simple"
                        ></i>
                        ESTADÍSTICAS
                        <i
                          style={{ marginLeft: "auto" }}
                          className="fa-solid fa-chevron-down"
                        ></i>
                      </legend>
                      {isOpen3 && (
                        <div className="menu-links" onClick={handleMenuToggle}>
                          <Link
                            className={`subtitulosMenuUserHomeCompany ${
                              selectedMenu?.name === "Reporte Entre Fechas"
                                ? "selected-menu"
                                : ""
                            }`}
                            onClick={() => handleClick("ReporteEntreFechas")}
                            to="/ReporteEntreFechas"
                          >
                            <i
                              className="fa-solid seraUnMarinsupre fa-chart-simple"
                              style={{ fontSize: "26px" }}
                            ></i>
                            <div
                              className="LetterInvisible2"
                              style={{ fontSize: "16px" }}
                            >
                              Reporte Entre Fechas
                            </div>
                          </Link>
                          <Link
                            className={`subtitulosMenuUserHomeCompany ${
                              selectedMenu?.name === "Registro Retorno"
                                ? "selected-menu"
                                : ""
                            }`}
                            onClick={() => handleClick("/RegistroRetornosEst")}
                            to="/RegistroRetornosEst"
                          >
                            <i
                              className="fa-solid seraUnMarinsupre fa-rotate-left"
                              style={{ fontSize: "26px" }}
                            ></i>
                            <div
                              className="LetterInvisible2"
                              style={{ fontSize: "16px" }}
                            >
                              Registro Retorno
                            </div>
                          </Link>
                          <Link
                            className={`subtitulosMenuUserHomeCompany ${
                              selectedMenu?.name === "Retornos Agregados"
                                ? "selected-menu"
                                : ""
                            }`}
                            onClick={() =>
                              handleClick("VistaRetornosAgregados")
                            }
                            to="/VistaRetornosAgregados"
                          >
                            <i
                              className="fa-solid seraUnMarinsupre fa-rectangle-list"
                              style={{ fontSize: "26px" }}
                            ></i>
                            <div
                              className="LetterInvisible2"
                              style={{ fontSize: "16px" }}
                            >
                              Retornos Agregados
                            </div>
                          </Link>
                          <Link
                            className={`subtitulosMenuUserHomeCompany ${
                              selectedMenu?.name === "Empresa Retorno"
                                ? "selected-menu"
                                : ""
                            }`}
                            onClick={() => handleClick("EmpresaRetorno")}
                            to="/EmpresaRetorno"
                          >
                            <i
                              className="fa-solid seraUnMarinsupre fa-user-tie"
                              style={{ fontSize: "26px" }}
                            ></i>
                            <div
                              className="LetterInvisible2"
                              style={{ fontSize: "16px" }}
                            >
                              Empresa Retorno
                            </div>
                          </Link>
                          <Link
                            className={`subtitulosMenuUserHomeCompany ${
                              selectedMenu?.name === "Destino Retorno"
                                ? "selected-menu"
                                : ""
                            }`}
                            onClick={() => handleClick("DestinoRetorno")}
                            to="/DestinoRetorno"
                          >
                            <i
                              className="fa-solid seraUnMarinsupre fa-route"
                              style={{ fontSize: "26px" }}
                            ></i>
                            <div
                              className="LetterInvisible2"
                              style={{ fontSize: "16px" }}
                            >
                              Destino Retorno
                            </div>
                          </Link>
                        </div>
                      )}
                    </fieldset>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      background: "rgba(255, 255, 255, 0.481)",
                      height: ".07px",
                      margin: "4px 0 4px 0",
                    }}
                  ></div>

                  <div className="menu-container">
                    <fieldset
                      className={`menu-fieldset ${isOpen2 ? "open" : ""}`}
                    >
                      <legend
                        onClick={() => setIsOpen2(!isOpen2)}
                        className={`subtitulosMenuUserHomeCompany2 menu-legend m-0`}
                        style={{
                          textAlign: "center",
                          display: "flex",
                          justifyContent: "center",
                          flexDirection: "row",
                          fontSize: "12px",
                          padding: "15px 10px 10px 24px",
                          cursor: "pointer",
                        }}
                      >
                        <i
                          style={{
                            marginRight: "10px",
                            alignItems: "center",
                            display: "flex",
                          }}
                          className="fa-solid fa-users"
                        ></i>
                        USUARIOS
                        <i
                          style={{ marginLeft: "auto" }}
                          className="fa-solid fa-chevron-down"
                        ></i>
                      </legend>
                      {isOpen2 && (
                        <div className="menu-links" onClick={handleMenuToggle}>
                          <Link
                            className={`subtitulosMenuUserHomeCompany ${
                              selectedMenu?.name ===
                              "Registro Usuarios Terminal"
                                ? "selected-menu"
                                : ""
                            }`}
                            onClick={() => handleClick("RegistroUsuarios")}
                            to="/RegistroUsuarios"
                          >
                            <i
                              className="fa-solid seraUnMarinsupre fa-users"
                              style={{ fontSize: "26px" }}
                            ></i>
                            <div
                              className="LetterInvisible2"
                              style={{ fontSize: "16px" }}
                            >
                              Registro Usuarios Terminal
                            </div>
                          </Link>
                          <Link
                            className={`subtitulosMenuUserHomeCompany ${
                              selectedMenu?.name === "Registro Usuarios Empresa"
                                ? "selected-menu"
                                : ""
                            }`}
                            onClick={() =>
                              handleClick("RegistroUsuariosEmpresa")
                            }
                            to="/RegistroUsuariosEmpresa"
                          >
                            <i
                              className="fa-solid seraUnMarinsupre fa-people-group"
                              style={{ fontSize: "26px" }}
                            ></i>
                            <div
                              className="LetterInvisible2"
                              style={{ fontSize: "16px" }}
                            >
                              Registro Usuarios Empresa
                            </div>
                          </Link>
                        </div>
                      )}
                    </fieldset>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      background: "rgba(255, 255, 255, 0.481)",
                      height: ".07px",
                      margin: "4px 0 4px 0",
                    }}
                  ></div>

                  <div className="menu-container">
                    <fieldset
                      className={`menu-fieldset ${isOpen4 ? "open" : ""}`}
                    >
                      <legend
                        onClick={() => setIsOpen4(!isOpen4)}
                        className={`subtitulosMenuUserHomeCompany2 menu-legend m-0`}
                        style={{
                          textAlign: "center",
                          display: "flex",
                          justifyContent: "center",
                          flexDirection: "row",
                          fontSize: "12px",
                          padding: "15px 10px 10px 24px",
                          cursor: "pointer",
                        }}
                      >
                        <i
                          style={{
                            marginRight: "10px",
                            alignItems: "center",
                            display: "flex",
                          }}
                          className="fa-solid fa-wand-magic-sparkles"
                        ></i>
                        CORREOS PERSONALIZADOS
                        <i
                          style={{ marginLeft: "auto" }}
                          className="fa-solid fa-chevron-down"
                        ></i>
                      </legend>
                      {isOpen4 && (
                        <div className="menu-links" onClick={handleMenuToggle}>
                          <Link
                            className={`subtitulosMenuUserHomeCompany ${
                              selectedMenu?.name === "Plantillas"
                                ? "selected-menu"
                                : ""
                            }`}
                            onClick={() => handleClick("Plantillas")}
                            to="/Plantillas"
                          >
                            <i
                              className="fa-solid seraUnMarinsupre fa-file-zipper"
                              style={{ fontSize: "26px" }}
                            ></i>
                            <div
                              className="LetterInvisible2"
                              style={{ fontSize: "16px" }}
                            >
                              Plantillas
                            </div>
                          </Link>
                        </div>
                      )}
                    </fieldset>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      background: "rgba(255, 255, 255, 0.481)",
                      height: ".07px",
                      margin: "4px 0 4px 0",
                    }}
                  ></div>

                  {/* <Link
                    className={`subtitulosMenuUserHomeCompany ${
                      selectedMenu?.name === "Empresas de Transporte"
                        ? "selected-menu"
                        : ""
                    }`}
                    onClick={() => handleClick("EmpresasDeTransporte")}
                    to="/EmpresasDeTransporte"
                  >
                    <i
                      className="fa-solid seraUnMarinsupre fa-user-tie"
                      style={{ fontSize: "26px" }}
                    ></i>
                    <div
                      className="LetterInvisible2"
                      style={{ fontSize: "16px" }}
                    >
                      Empresas de Transporte
                    </div>
                  </Link>
                  <Link
                    className={`subtitulosMenuUserHomeCompany ${
                      selectedMenu?.name === "Destinos" ? "selected-menu" : ""
                    }`}
                    onClick={() => handleClick("Destinos")}
                    to="/Destinos"
                  >
                    <i
                      className="fa-solid seraUnMarinsupre fa-location-dot"
                      style={{ fontSize: "26px" }}
                    ></i>
                    <div
                      className="LetterInvisible2"
                      style={{ fontSize: "16px" }}
                    >
                      Destinos
                    </div>
                  </Link> */}

                  {/* <Link
                    className={`subtitulosMenuUserHomeCompany ${
                      selectedMenu?.name === "Programación"
                        ? "selected-menu"
                        : ""
                    }`}
                    onClick={() => handleClick("Programacion")}
                    to="/Programacion"
                  >
                    <i
                      className="fa-solid seraUnMarinsupre fa-calendar-check"
                      style={{ fontSize: "26px" }}
                    ></i>
                    <div
                      className="LetterInvisible2"
                      style={{ fontSize: "16px" }}
                    >
                      Programación
                    </div>
                  </Link>
                  <Link
                    className={`subtitulosMenuUserHomeCompany ${
                      selectedMenu?.name === "Calendario de Salidas"
                        ? "selected-menu"
                        : ""
                    }`}
                    onClick={() => handleClick("ActivacionCalendario")}
                    to="/ActivacionCalendario"
                  >
                    <i
                      className="fa-solid seraUnMarinsupre fa-calendar-days"
                      style={{ fontSize: "26px" }}
                    ></i>
                    <div
                      className="LetterInvisible2"
                      style={{ fontSize: "16px" }}
                    >
                      Calendario de Salidas
                    </div>
                  </Link>
                  <Link
                    className={`subtitulosMenuUserHomeCompany ${
                      selectedMenu?.name === "PrecioDestinoFinalRC"
                        ? "selected-menu"
                        : ""
                    }`}
                    onClick={() => handleClick("PrecioDestinoFinalRC")}
                    to="/PrecioDestinoFinalRC"
                  >
                    <i
                      className="fa-solid seraUnMarinsupre fa-tags"
                      style={{ fontSize: "26px" }}
                    ></i>
                    <div
                      className="LetterInvisible2"
                      style={{ fontSize: "16px" }}
                    >
                      Rutas Cortas Precios
                    </div>
                  </Link>
                  <Link
                    className={`subtitulosMenuUserHomeCompany ${
                      selectedMenu?.name === "PrecioDestinoFinalRL"
                        ? "selected-menu"
                        : ""
                    }`}
                    onClick={() => handleClick("PrecioDestinoFinalRL")}
                    to="/PrecioDestinoFinalRL"
                  >
                    <i
                      className="fa-solid seraUnMarinsupre fa-tags"
                      style={{ fontSize: "26px" }}
                    ></i>
                    <div
                      className="LetterInvisible2"
                      style={{ fontSize: "16px" }}
                    >
                      Rutas Largas Precios
                    </div>
                  </Link>
                  <Link
                    className={`subtitulosMenuUserHomeCompany ${
                      selectedMenu?.name === "KmDistancia"
                        ? "selected-menu"
                        : ""
                    }`}
                    onClick={() => handleClick("KmDistancia")}
                    to="/KmDistancia"
                  >
                    <i
                      className="fa-solid seraUnMarinsupre fa-route"
                      style={{ fontSize: "26px" }}
                    ></i>
                    <div
                      className="LetterInvisible2"
                      style={{ fontSize: "16px" }}
                    >
                      Destino Final Km
                    </div>
                  </Link>
                  <Link
                    className={`subtitulosMenuUserHomeCompany ${
                      selectedMenu?.name === "Autorizacionporpuesto"
                        ? "selected-menu"
                        : ""
                    }`}
                    onClick={() => handleClick("Autorizacionporpuesto")}
                    to="/Autorizacionporpuesto"
                  >
                    <i
                      className="fa-solid seraUnMarinsupre fa-road-circle-check"
                      style={{ fontSize: "26px" }}
                    ></i>
                    <div
                      className="LetterInvisible2"
                      style={{ fontSize: "16px" }}
                    >
                      Autorización Por Puesto
                    </div>
                  </Link>
                  <Link
                    className={`subtitulosMenuUserHomeCompany ${
                      selectedMenu?.name === "CierresRutasCortas"
                        ? "selected-menu"
                        : ""
                    }`}
                    onClick={() => handleClick("CierresRutasCortas")}
                    to="/CierresRutasCortas"
                  >
                    <i
                      className="fa-solid seraUnMarinsupre fa-bus"
                      style={{ fontSize: "26px" }}
                    ></i>
                    <div
                      className="LetterInvisible2"
                      style={{ fontSize: "16px" }}
                    >
                      Cierres Rutas Cortas
                    </div>
                  </Link> */}
                  <div onClick={handleMenuToggle}>
                    <Link
                      className={`subtitulosMenuUserHomeCompany ${
                        selectedMenu?.name === "Ayuda Y Soporte"
                          ? "selected-menu"
                          : ""
                      }`}
                      onClick={() => handleClick("AyudaYSoporte")}
                      to="/AyudaYSoporte"
                    >
                      <i
                        className="fa-solid seraUnMarinsupre fa-headset"
                        style={{ fontSize: "26px" }}
                      ></i>
                      <div
                        className="LetterInvisible2"
                        style={{ fontSize: "16px" }}
                      >
                        Ayuda Y Soporte
                      </div>
                    </Link>
                  </div>
                </div>
                <div
                  className="as65ad4s8d9a7s8d9as4d9reg49wq8ew4v641w"
                  style={{ marginBottom: "15px" }}
                  onClick={handleMenuToggle}
                >
                  <Link
                    className={`subtitulosMenuUserHomeCompany btn b65d4fs8d9f7q98wd4qw98d7q8w9d4q ${
                      selectedMenu === "Configuraciones" ? "selected-menu" : ""
                    }`}
                    onClick={() => handleClick("Configuraciones")}
                    to="/Configuraciones"
                  >
                    <i
                      className="fa-solid seraUnMarinsupre fa-gear"
                      style={{ color: "#151b26" }}
                    ></i>
                    <div style={{ color: "#151b26" }}>Configuraciones</div>
                  </Link>
                </div>
                <div
                  className="as65ad4s8d9a7s8d9as4d9reg49wq8ew4v641w"
                  style={{ height: "0px" }}
                  onClick={handleMenuToggle}
                >
                  <button
                    className="btn b65d4fs8d9f7q98wd4qw98d7q8w9d4q w-100"
                    onClick={handleLogout}
                  >
                    <i
                      className="fa-solid seraUnMarinsupre fa-right-from-bracket"
                      style={{ color: "#151b26" }}
                    ></i>
                    <div
                      href="#"
                      style={{
                        color: "#151b26",
                        paddingTop: "5px",
                        paddingBottom: "5px",
                      }}
                    >
                      Cerrar sesión
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
