import React, { useState, useEffect } from "react";
import axios from "axios";
import moment from "moment-timezone"; // Asegúrate de instalar esta librería
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import "../../../../styles/RegistroActivacionesHoy.css";

const MySwal = withReactContent(Swal);

const EstatusIcon = {
  ACTIVO: {
    icon: "fa-check",
    name: "ACTIVO",
    color: "linear-gradient(to right, #28a745, #31c353)", // Verde
  },
  ANULADO: {
    icon: "fa-ban",
    name: "CANCELADO",
    color: "linear-gradient(to right, #dc3545, #f24d5e)", // Rojo
  },
  "EN PISTA": {
    icon: "fa-house",
    name: "EN PISTA",
    color: "linear-gradient(to right, #ffc107, #ffd24a)", // Amarillo
  },
  CARGANDO: {
    icon: "fa-person-walking-luggage",
    name: "ABORDANDO",
    color: "linear-gradient(to right, #176ec6, #2a89e8)", // Blanco
  },
  SALIO: {
    icon: "fa-van-shuttle",
    name: "SALIÓ",
    color: "linear-gradient(to right, #6c757d, #969ca2)", // Gris
  },
  RETRASADO: {
    icon: "fa-hourglass-end",
    name: "CON RETRASO",
    color: "linear-gradient(to right, #ff6347, #ff856f)", // Rojo Claro
  },
};

export const RegistroActivacionesHoy = () => {
  const [records, setRecords] = useState([]);
  const [currentTime, setCurrentTime] = useState(moment.tz("America/Caracas"));
  const [loading, setLoading] = useState(true);

  const fetchTodayRecords = async () => {
    try {
      const response = await axios.get(
        process.env.BACKEND_URL + "/api/public/getTodayActivacionPista2"
      );
      setRecords(response.data);
    } catch (error) {
      console.error("Error al obtener registros de hoy", error);
    } finally {
      setLoading(false); // Cambia a `false` al terminar la carga
    }
  };

  useEffect(() => {
    // Actualizar la hora de Venezuela cada segundo
    const interval = setInterval(() => {
      setCurrentTime(moment.tz("America/Caracas"));
    }, 1000);

    fetchTodayRecords();

    return () => clearInterval(interval); // Limpiar el intervalo al desmontar el componente
  }, []);

  const handleChangeStatus = (record) => {
    MySwal.fire({
      title: (
        <h6>
          <i
            className="fa-solid fa-pen-to-square"
            style={{ marginRight: "7px", fontSize: "20px" }}
          ></i>
          Editar Datos
        </h6>
      ),
      html: `
            <div class="input-group-login2"><input style="width: 100%; margin-bottom: 20px" id="swal-numero-de-unidad" class="inputDeLogin" value="${record.numero_de_unidad || ""}"><label>Número Unidad</label></div>
            <div class="input-group-login2"><input style="width: 100%; margin-bottom: 20px; text-transform: uppercase" id="swal-placa" class="inputDeLogin" value="${record.placa || ""}"><label>Placa</label></div>
            <div class="input-group-login2"><input style="width: 100%; margin-bottom: 20px" id="swal-anden" class="inputDeLogin" value="${record.anden || ""}"><label>Puerta</label></div>
            <div class="input-group-login2">
              <select id="swal-status" class="inputDeLogin" style="width: 100%">
                <option value="" disabled ${!record.estatus ? "selected" : ""}>Selecciona un estatus</option>
                <option value="ACTIVO" ${record.estatus === "ACTIVO" ? "selected" : ""}>ACTIVO</option>
                <option value="ANULADO" ${record.estatus === "ANULADO" ? "selected" : ""}>CANCELADO</option>
                <option value="EN PISTA" ${record.estatus === "EN PISTA" ? "selected" : ""}>EN PISTA</option>
                <option value="CARGANDO" ${record.estatus === "CARGANDO" ? "selected" : ""}>ABORDANDO</option>
                <option value="SALIO" ${record.estatus === "SALIO" ? "selected" : ""}>SALIÓ</option>
                <option value="RETRASADO" ${record.estatus === "RETRASADO" ? "selected" : ""}>CON RETRASO</option>
              </select>
            <label>Estatus</label></div>
            `,
      focusConfirm: false,
      showCancelButton: true,
      confirmButtonText: "Actualizar",
      preConfirm: () => {
        const estatus = document.getElementById("swal-status").value;
        const numero_de_unidad = document.getElementById(
          "swal-numero-de-unidad"
        ).value;
        const placa = document.getElementById("swal-placa").value;
        const anden = document.getElementById("swal-anden").value;

        if (!estatus) {
          Swal.showValidationMessage("Debes seleccionar un estatus!");
          return false;
        }

        return { estatus, numero_de_unidad, placa, anden };
      },
    }).then(async (result) => {
      if (result.isConfirmed) {
        const { estatus, numero_de_unidad, placa, anden } = result.value;

        try {
          const response = await axios.put(
            `${process.env.BACKEND_URL}/api/activacion/${record.id}/updateStatus`,
            {
              estatus,
              numero_de_unidad,
              placa,
              anden,
            },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          );

          Swal.fire({
            icon: "success",
            title: response.data.message,
          });

          fetchTodayRecords();
        } catch (error) {
          Swal.fire({
            icon: "error",
            title: "Error al cambiar el estatus",
            text: error.response?.data?.message || "Error interno",
          });
        }
      }
    });
  };

  // Función para calcular tiempo restante o retraso
  const calculateTimeDiff = (horaSalida) => {
    // Suponiendo que `horaSalida` es solo la hora en formato "HH:mm"
    const salidaTime = moment.tz(horaSalida, "HH:mm", "America/Caracas");

    // Si `currentTime` tiene la fecha actual, también asegurémonos de que `salidaTime` la tenga
    const today = currentTime.clone().startOf("day"); // Mantenemos la parte de la fecha actual
    const fullSalidaTime = today.clone().set({
      hour: salidaTime.hour(),
      minute: salidaTime.minute(),
    });

    const diffInMinutes = fullSalidaTime.diff(currentTime, "minutes");

    if (diffInMinutes > 0) {
      return { time: `${diffInMinutes} min`, color: "green" };
    } else {
      return { time: `${Math.abs(diffInMinutes)} min`, color: "red" };
    }
  };

  return (
    <div className="container-fluid contarinerGeneralUserHomejs">
      <div
        style={{
          textAlign: "center",
          padding: "10px",
          display: "flex",
          flexDirection: "column",
        }}
        className="sdfdfgrt54w98e7we9contewe"
      >
        <h2 style={{ margin: "0", marginTop: "20px" }}>Activaciones de Hoy</h2>
        <p
          style={{ fontSize: "13px", margin: "0" }}
          className="asdjhfnerer4pdeterminal"
        >
          Consulta y gestiona todos las activaciones realizadas durante el día,
          visualizando información detallada y actualizada para un control
          eficiente y puntual.
        </p>
      </div>
      {loading ? (
        <div style={{ textAlign: "center", padding: "20px" }}>
          <img
            src="https://res.cloudinary.com/dirpdlfbu/image/upload/v1731494258/1/dvhl0hwbx4adb8uceymr.gif"
            alt="Cargando..."
            style={{ width: "150px" }}
          />
        </div>
      ) : (
        <div className="sdf5erg8e9r7f8weactivac">
          {records
            .sort((a, b) => {
              const estatusOrdenA =
                a.estatus === "SALIO" || a.estatus === "ANULADO" ? 1 : 0;
              const estatusOrdenB =
                b.estatus === "SALIO" || b.estatus === "ANULADO" ? 1 : 0;

              if (estatusOrdenA !== estatusOrdenB) {
                return estatusOrdenA - estatusOrdenB;
              }

              const diffA = moment
                .tz(a.hora_salida, "HH:mm", "America/Caracas")
                .diff(currentTime, "minutes");
              const diffB = moment
                .tz(b.hora_salida, "HH:mm", "America/Caracas")
                .diff(currentTime, "minutes");

              return diffA - diffB;
            })
            .map((record, index) => {
              const {
                empresa,
                destino_final,
                estatus,
                hora_salida,
                numero_de_unidad,
                placa,
                anden,
              } = record;
              const { time, color } = calculateTimeDiff(hora_salida);
              const icono = EstatusIcon[estatus];

              return (
                <div
                  key={index}
                  className="container asklnduiashndaijsnxansxsaas"
                >
                  <div className="container asune-rv56er48v9wec-s2 mgjmnh98-sdf9w8e7f9wewefwes">
                    <div className="container aoshdnuqiwhnd897">
                      <div className="container ds897fq4w9dc9w8c4-we89c7w2">
                        <p
                          style={{
                            margin: "0",
                            fontSize: "10px",
                          }}
                          id="pderegistros"
                        >
                          {empresa}
                        </p>
                        <h5 style={{ marginTop: "15px" }}>{destino_final}</h5>
                      </div>
                      <div
                        className="container ds9f848dsfwef498we4fwd-57982"
                        onClick={() => handleChangeStatus(record)} // Evento para cambiar estatus
                      >
                        <div
                          className="sad8qw9d4asas6x4a89s42"
                          style={{ backgroundImage: icono.color }}
                        >
                          <i
                            className={`fa-solid asd98asdas894xsa ${icono.icon}`}
                          ></i>
                        </div>
                      </div>
                    </div>
                    <div className="asdw-w9d84c9w8e4dcd"></div>
                    <div className="container aoshdnuqiwhnd8972">
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          width: "100%",
                        }}
                      >
                        <div
                          style={{
                            width: "33.33%",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <p style={{ margin: "0", color }}>{time}</p>
                        </div>
                        <div
                          style={{
                            width: "33.33%",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            fontSize: "14px",
                            textAlign: "center",
                          }}
                        >
                          <p style={{ margin: "0", textAlign: "center" }}>
                            Puerta: {anden}
                          </p>
                          <p style={{ margin: "0", textAlign: "center" }}>
                            Hora: {hora_salida}
                          </p>
                        </div>
                        <div
                          style={{
                            width: "33.33%",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "end",
                            fontSize: "14px",
                          }}
                        >
                          <p style={{ margin: "0", textAlign: "end" }}>
                            Unidad: {numero_de_unidad}
                          </p>
                          <p style={{ margin: "0", textAlign: "end" }}>
                            {placa}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      )}
    </div>
  );
};
